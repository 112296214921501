import React, {
    Fragment, useCallback, useContext, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import Make02 from './Make02';
import Make02Step02 from './Make02Step02';
import Make02Step03 from './Make02Step03';
import {
    CampIdContext,
    CampTabClickContext, CampUpdateModeContext, ExistEditingContext, ProjectIdContext,
} from './Make02Empty';
import alertify from 'alertifyjs'
import Make02Step03PageLeaveDialog from "./Make02Step03PageLeaveDialog";

const CampTab = () => {
  const { campUpdateMode } = useContext(CampUpdateModeContext);
  const { setCampIdParam } = useContext(CampIdContext);
  const { tabClick, setTabClick } = useContext(CampTabClickContext);
  const { existEditing } = useContext(ExistEditingContext);
  const { projectIdParam } = useContext(ProjectIdContext);

  const [showPageLeaveDialog, setShowPageLeaveDialog] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('');

  const tabClickEvent = (tab:string) => {
    if ((projectIdParam === 'null' || projectIdParam === null || projectIdParam === '' || projectIdParam === undefined) && tab !== 'step01') {
      alertify.error('광고를 집행할 프로젝트를 먼저 선택해주시기 바랍니다.');
    } else {

      if(tabClick === 'step03' && existEditing){
          setTabValue(tab);
          setShowPageLeaveDialog(true);
      }else{
        setTabClick(tab);
      }
    }
  };
  const Make02StepEvent = useCallback(() => {
    if (tabClick === 'step01') {
      return <Make02/>;
    } if (tabClick === 'step02') {
      return <Make02Step02/>;
    } if (tabClick === 'step03') {
      return <Make02Step03/>;
    }

    return null;
  }, [tabClick]);

  useEffect(() => {
    if ((projectIdParam === 'null' || projectIdParam === null || projectIdParam === '' || projectIdParam === undefined) && tabClick !== 'step01' && campUpdateMode) {
      alertify.error('광고를 집행할 프로젝트를 먼저 선택해주시기 바랍니다.');
      setTabClick('step01');
    }
    if (!campUpdateMode) {
      if (tabClick === undefined || tabClick === '') {
        setCampIdParam(null);
        setTabClick('step01');
      }
    }
  }, [campUpdateMode]);
  return (
        <Fragment>
            {
                showPageLeaveDialog? <Make02Step03PageLeaveDialog setShowPageLeaveDialog={setShowPageLeaveDialog}  mode={'tab'} tabAfter={tabValue} tabBefore={tabClick} setTabClick={setTabClick}/> : null
            }
            <div className="comp-tab">
                <Link to="#" onClick={() => tabClickEvent('step01')} className={tabClick === 'step01' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">캠페인 정보 입력</span>
                </Link>
                <Link to="#" onClick={() => tabClickEvent('step02')} className={tabClick === 'step02' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">타겟 정보 입력</span>
                </Link>
                <Link to="#" onClick={() => tabClickEvent('step03')} className={tabClick === 'step03' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">소재 정보 입력</span>
                </Link>
            </div>
            <Make02StepEvent/>
        </Fragment>
  );
};
export default CampTab;
