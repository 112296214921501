import React, {
  Fragment, useEffect, useRef,
} from 'react';
import FileUploaderGgCreation from '../../fileUploader/FileUploaderGgCreation';

const CreationFileUploadGdn = (props:any) => {
  const creationFileUploadRef = useRef<any>(null);
  // 메이커 소재 파일 정보
  // const { gdnMakerCreationFiles } = useContext(GdnMakerCreationFilesContext);

  useEffect(() => {
    // 가이드 다이얼로그 오픈(현재 페북용)
    $('#guideDialogOpen').click(() => {
      // $('#creationFileUploadGuideDialog').dialog({ width: 900, title: '소재 가이드', closeOnEscape: true });
      // // 오픈시 해줄것
      // $('#creationFileUploadGuideDialog').dialog('open');
      window.open('https://bizstatic.wadiz.kr/static/usingGuide/%5B%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%5D%E1%84%8B%E1%85%AA%E1%84%83%E1%85%B5%E1%84%8C%E1%85%B3+%E1%84%90%E1%85%A1%E1%84%80%E1%85%A6%E1%86%BA%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%89%E1%85%A9%E1%84%8C%E1%85%A2%E1%84%80%E1%85%A5%E1%86%B7%E1%84%89%E1%85%AE+%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3+%E1%84%8C%E1%85%A5%E1%86%A8%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%80%E1%85%B5%E1%84%8C%E1%85%AE%E1%86%AB(ver.202306).pdf')
    });
    // 파일 업로드 다이얼로그 닫기
    $('#fileUploadGdndialogClose').click(() => {
      // 다이얼로그 닫을시 데이터 초기화(FileUploadGgCreation.tsx)
      creationFileUploadRef.current!.uploadInit();
      $('#creationFileUploadGdnDialog').dialog('close');
      // $('.dialog').dialog('destroy');
    });
    // 파일 업로드 제출
    $('#fileCheckUploadGdn').click(() => {
      // 제출 버튼 클릭시 업로드 실행(FileUploadGgCreation.tsx)
      creationFileUploadRef.current!.fileCheckUpload();
      // Make02Step03Gdn.tsx 에서 넘겨받은 단일 소재 파일 리스트 호출 이벤트
      // 여기서 파일 목록 가져오는것만 별도로 호출
    });
    $('.ico-tooltip').tooltip({
      content: '<p class="fz-12 fc-0 fw-medium">정사각형 이미지와 가로 이미지가 각각 1장 이상씩 등록되어야 합니다.</p><p class="fz-12 fc-0"><br/>가로형(1.91:1)<br/>권장 1200 x 628 픽셀<br/>최소 600 x 314 픽셀<br/>최소 1장 이상 등록<br/><br/>정사각형(1:1)<br/>권장 1200 x 1200 픽셀<br/>최소 300 x 300 픽셀<br/>최소 1장 이상 등록</p>',
    });
    return () => {
      $('.tooltip').remove();
    };
  }, []);
  useEffect(() => {
    $('[id^=fileSpanId_]').on('click', (e) => {
      const selectFileId = e.currentTarget.id.split('_')[1];
      const selectFileUrl = e.currentTarget.dataset.url;
      creationFileUploadRef.current!.previewFileSelect(selectFileId, selectFileUrl);
    });
  }, [props.makerFiles]);
  return (
  <Fragment>
    <div className="dialog-body">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-4">
            <div className="wrap-section wrap-tbl wrap-thumb-history">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                        { props.makerFiles.length !== 0
                          ? props.makerFiles.map((files:any, index:any) => {
                            const makeKey = `gndCreationFile_${index}`;
                            return (<div className="form-group" key={makeKey}>
                            <div className="flex-container">
                               {files.map((file:any) => {
                                 const makeFileKey = `fileDivKey_${file.id}`;
                                 const makeFileId = `fileDivId_${file.id}`;
                                 const makeFileSpanId = `fileSpanId_${file.id}`;
                                 const img = new Image();
                                 let { width } = file.afMeta;
                                 let { height } = file.afMeta;
                                 if (width === undefined) {
                                   img.onload = function () {
                                     width = img.width;
                                     height = img.height;
                                     $(`#${makeFileSpanId}`).text(`${width}x${height}`);
                                   };
                                   img.src = file.fileName;
                                 }
                                 return (
                                     <div className="box-group col-10-5" key={makeFileKey}>
                                       <div className="comp-thumb">
                                         <span className="txt-size" id={makeFileSpanId} data-url={file.fileName}>{width}x{height}</span>
                                         <img src={file.fileName} alt="" id={makeFileId}/>
                                       </div>
                                     </div>
                                 );
                               })}
                            </div>
                          </div>);
                          })
                          : null
                        }
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-8">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <button type="button" className="btn btn-primary" id="guideDialogOpen">소재 가이드</button>
                      </div>
                      <FileUploaderGgCreation ref={creationFileUploadRef} />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="dialog-footer">
      <button type="button" className="btn btn-secondary-outline large" id='fileUploadGdndialogClose'>취소</button>
      <button type="button" className="btn btn-primary large" id='fileCheckUploadGdn'>제출</button>
    </div>
  </Fragment>);
};
export default CreationFileUploadGdn;
