import React, {
  Fragment, useCallback, useContext,
} from 'react';
import { CampMediaDiv4Context } from './Make02Empty';
import Make02Step03Gdn from './Make02Step03Gdn';
import Make02Step03Fb from './Make02Step03Fb';
import Make02Step03Itg from './Make02Step03Itg';

const Make02Step03 = () => {
  const { mediaDiv4Param } = useContext(CampMediaDiv4Context);
  const Make02Step03Event = useCallback(() => {
    if (mediaDiv4Param === 'GDN') { // 구글
      return <Make02Step03Gdn/>;
    } if (mediaDiv4Param === 'ITG') { // 인스타그램
      return <Make02Step03Itg/>;
    }
    return <Make02Step03Fb/>; // 페북 , 페북/인스타
  }, [mediaDiv4Param]);
  return (
    <Fragment>
        <Make02Step03Event/>
    </Fragment>
  );
};
export default Make02Step03;
