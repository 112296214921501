import React, { FC, useState, useCallback } from 'react'
import className from 'classnames/bind'
import type { FunctionKeyword, FunctionPopularKeyword, FunctionKeywordValidation } from '@wad/api'
import { KeywordSelectPopup } from './KeywordSelectPopup'
import style from './KeywordAdField.module.scss'
const cx = className.bind(style)

export const KeywordAdField: FC<{
  fetchGetKeyword: FunctionKeyword
  fetchGetKeywordValidation: FunctionKeywordValidation
  fetchGetPopularKeyword: FunctionPopularKeyword
  creative_id: number | undefined
  start_date: string
  end_date: string
  keyword: string
  onChange: (value: string) => void
  disabled: boolean
  children: React.ReactNode
}> = ({
  children,
  onChange,
  keyword,
  creative_id,
  start_date,
  end_date,
  fetchGetKeyword,
  fetchGetKeywordValidation,
  fetchGetPopularKeyword,
  disabled,
}) => {
  const [isOpen, setOpen] = useState(false)
  const doSelectKeyword = useCallback(
    (value:string) => {
      onChange(value)
      setOpen(false)
    },
    [onChange]
    )
    return (
    <>
      {children}
      <div className={cx('guide_wrap')}>
        <label>키워드</label>
      </div>
      <div className={cx('search_keyword_container')}>
        <div className={cx('wz input')}>
          <input type="text" value={keyword} disabled placeholder="광고 노출할 키워드를 선택합니다." />
        </div>
        <button type="button" className={cx('wz button primary')} onClick={() => setOpen(true)} disabled={disabled}>
          키워드 선택
        </button>
      </div>
      <KeywordSelectPopup
        {...{ fetchGetKeyword, fetchGetKeywordValidation, fetchGetPopularKeyword }}
        {...{ creative_id, start_date, end_date }}
        isOpen={isOpen}
        onCancel={() => setOpen(false)}
        onSelectKeyword={doSelectKeyword}
      />
    </>
  )
}
