import { forwardRef, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Dialog } from 'rc-easyui'
import { ApiUtil2 } from '@biz/api'
import CreationChatGptForm from './CreationChatGptForm'
import _ from 'lodash'

const CreationChatGptDialog = (props: any, ref: any) => {
  const dialogRef = useRef<any>()
  const [chatGptHisForm, setChatGptHisForm] = useState([])
  const gptFormRef = useRef<any>({})

  const getChatGptHis = useCallback(() => {
    ApiUtil2.get(`/api/mngAd/creation/chatGptHis/${props.creationDataId}`).then((resp) => {
      const { data } = resp.data
      setChatGptHisForm(data)
    })
  }, [])

  useEffect(() => {
    getChatGptHis()
  }, [props.creationDataId])

  useEffect(() => {
    if (props.creationDataGptHisId != null && gptFormRef.current[props.creationDataGptHisId] != null) {
      gptFormRef.current[props.creationDataGptHisId].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [chatGptHisForm])

  useEffect(() => {}, [])

  return (
    <Fragment>
      <Dialog title={`chatGPT 검수 결과`} modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
        <div id="dialog37" className="dialog">
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">캠페인ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{props.campId}</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">소재ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{props.creationDataId}</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="wrap-section wrap-tbl h-500 scroll-y">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        {chatGptHisForm.map((e: any) => (
                          <CreationChatGptForm
                            key={e.creationDataGptHisId}
                            hisData={e}
                            creationDataId={props.creationDataId}
                            ref={(e2: any) => {
                              gptFormRef.current[e.creationDataGptHisId] = e2
                            }}
                          />
                        ))}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button
              type="button"
              className="btn btn-primary large"
              onClick={(e) => {
                props.setShowCreationChatGptDialog(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}

export default forwardRef(CreationChatGptDialog)
