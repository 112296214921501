import { Fragment, useEffect, useRef } from 'react'
import { Dialog } from 'rc-easyui'
import dayjs from 'dayjs'
import alertify from 'alertifyjs'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
export interface ICampPayType {
  ok: boolean
  campId: number
  payType: string
}
const CampPayTypeSelectDialog = (props: any) => {
  const dialogRef = useRef<any>()
  const { register } = useForm<ICampPayType>()

  const payTypeSelectEvent = () => {
    props.setCampInspValidOk(true)
    props.setPomValidOkEvent()
  }
  useEffect(() => {
    if (props.payType === '') {
      props.setPayType('POST_PAYMENT')
    }
  })
  return (
    <Fragment>
      <form>
        <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '700px' }}>
          <div className="dialog">
            <div className="dialog-body">
              <div className="wrap-section wrap-tbl">
                <div className="box-body">
                  <div className="tbl">
                    <dl className="vertical">
                      <dd>
                        <div className="form-group">
                          <p className="comp-txt center">
                            <span className="table">
                              <span className="table-cell">
                                <b className="fz-18 fc-1 fw-medium">광고비로 사용하실 머니 종류를 선택해주세요.</b>
                              </span>
                            </span>
                          </p>
                        </div>
                      </dd>
                    </dl>
                    <dl className="vertical">
                      <dd>
                        <div className="form-group txt-center">
                          <div className="comp-radio">
                            <input
                              type="radio"
                              id="radioPostPayment"
                              value="POST_PAYMENT"
                              defaultChecked={true}
                              onClick={() => props.setPayType('POST_PAYMENT')}
                              {...register('payType')}
                            />
                            <label htmlFor="radioPostPayment">후불머니</label>
                          </div>
                          <div className="comp-radio">
                            <input
                              type="radio"
                              id="radioBizMoney"
                              value="BIZ_MONEY"
                              onClick={() => props.setPayType('BIZ_MONEY')}
                              {...register('payType')}
                            />
                            <label htmlFor="radioBizMoney">비즈머니</label>
                          </div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="dialog-footer">
              <button type="button" className="btn btn-secondary-outline" onClick={() => props.setPomValidOkEvent()}>
                취소
              </button>
              <button type="button" className="btn btn-primary" onClick={payTypeSelectEvent}>
                선택
              </button>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}
export default CampPayTypeSelectDialog
