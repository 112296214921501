import {Fragment, useEffect, useRef, useState} from "react";
import { Dialog } from 'rc-easyui'
import alertify from 'alertifyjs'
import _ from "lodash";
import { ApiUtil2 } from '@biz/api'

interface CatInspConfig{
    isCommon: boolean;
    content: string;
}
interface Checkbox {
    id: number;
    checked: boolean;
}
const Make02SelfCheckDialog = (props:any) => {
    const dialogRef = useRef<any>();

    // 셀프 체크리스트 페이징 처리 관린
    const [checkList, setCheckList] = useState<CatInspConfig[]>([]);
    const itemsPerPage: number = 8;
    const [visibleItems, setVisibleItems] = useState<CatInspConfig[]>(checkList.slice(0, itemsPerPage));
    const [currentPage, setCurrentPage] = useState<number>(1);

    // 카테고리
    const [catCodeText, setcatCodeText] = useState<string>();

    // 체크박스
    const initialCheckboxes: Checkbox[] = checkList.map((item, index) => ({ id: index, checked: false }));
    const [checkboxes, setCheckboxes] = useState<Checkbox[]>(initialCheckboxes);
    const areAllCheckboxesChecked = checkboxes.every((checkbox) => checkbox.checked);

    const loadMoreItems = () => {
        const nextPage = currentPage + 1;
        const startIndex = (nextPage - 1) * itemsPerPage;
        const endIndex = nextPage * itemsPerPage;

        const newVisibleItems = checkList.slice(startIndex, endIndex);

        setVisibleItems((prevItems) => [...prevItems, ...newVisibleItems]);
        setCurrentPage(nextPage);
    };

    const handleCheckboxChange = (id: number) => {
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
            )
        );
    };

    const selfCheckListInspValidateReqEvent = () => {
        if(areAllCheckboxesChecked){
            props.inspValidEvent();
            props.setSelfCheckDialog(false);
        } else {
            alertify.error('셀프 체크리스트 항목을 꼼꼼히 읽고 체크해 주셔야\n' + '원활한 광고 진행이 가능합니다.\n');
        }
    }
    useEffect(() => {
        ApiUtil2.get("/api/camp/make02/findCatCreationCheckConfig",{ params: { campId : props.campId } })
            .then((checkListData) => {
                const { data } = checkListData.data;
                /** json 가공하여 입력 **/
                if (_.isEmpty(data) || _.isEmpty(data.creationCheckData) || _.isEmpty(JSON.parse(data.creationCheckData))) {
                    setCheckList([]);
                    // 체크리스트 값이 없으면 다이얼로그 닫힘 -> 검수요청 로직 탐
                    props.setSelfCheckDialog(false);
                    props.inspValidEvent();
                } else if (!_.isEmpty(data.creationCheckData)) {
                    const checkData = JSON.parse(data.creationCheckData);
                    setCheckList(checkData);
                    setVisibleItems(checkData.slice(0, itemsPerPage));
                    setcatCodeText(data.catCodeDesc);
                    setCheckboxes(checkData.map((item:CatInspConfig, index:number) => ({ id: index, checked: false })))
                }

            });
    }, [props.campId]);

    return (
        <Fragment>
            <Dialog title='' modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
                <div className="dialog">
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <p className="comp-txt center">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-18 fc-1 fw-medium"><span className="fc-5">{catCodeText}</span> 카테고리에서 가장 많이 반려되는 사유입니다.</b>
                                                                </span>
                                                            </span>
                                                </p>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="vertical mxh-500 scroll-y">
                                        <dd>
                                            {
                                                visibleItems.map((item, index) => (
                                                    <div className="form-group" key={`key_${index}`}>
                                                        <div className="comp-checkbox block" key={`key2_${index}`}>
                                                            <input
                                                                type="checkbox"
                                                                id={`check_${index}`}
                                                                onChange={() => handleCheckboxChange(index)}
                                                            />
                                                            <label key={`label_${index}`} htmlFor={`check_${index}`}>{decodeURI(item.content)}</label>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                            {
                                                visibleItems.length < checkList.length && (
                                                <div className="form-group">
                                                    <button type="button" className="btn btn btn-secondary-outline btn-ico expand" onClick={loadMoreItems}><i className="ico ico-more"></i>더보기</button>
                                                </div>
                                            )
                                            }
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline large" onClick={() => props.setSelfCheckDialog(false) }>수정하기</button>
                        <button type="button" className="btn btn-primary large" onClick={selfCheckListInspValidateReqEvent}>검수요청</button>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );

};

export default Make02SelfCheckDialog;
