import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useLocation, useOutletContext} from 'react-router-dom';
import _ from 'lodash';
import {ICreationData, ICreationLink} from './CreationDetailItg';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import PreviewImgDialog from './PreviewImgDialog';
import {GdnCreationDataIdContext} from './CreationDetailGdn';
import FailCreationDialog from "./FailCreationDialog";
import {useNavigate} from "react-router";
import {getAttachBtn, getMemoBtn, inspStatus, inspStatusColor} from "./CreationDetailFb";
import CampAttachDialogForAdmin from "../../camp/make02/CampAttachDialogForAdmin";
import {Af} from "../../camp/make02/CampAttachDialog";
import CreationDataInspHisDialog from "./CreationDataInspHisDialog";
import CreationDataInspMemoDialog from "./CreationDataInspMemoDialog";
import CreationChatGptDialog from "./CreationChatGptDialog";

const CreationDetailFbAll = (props: any) => {
  const setTitle = useOutletContext<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { campIds } : any = state;
  const [creationDataId, setCreationDataId] = useState<any>(null);
  const [creationData, setCreationData] = useState<ICreationLink[]>([]);
  const chunked = _.chunk(creationData, 3);
  const [fileData, setFileData] = useState<any[]>([]);
  const campIdRef = useRef<number>();
  const creationDataIdRef = useRef<number>();

  // 검수 이력 다이얼로그 관련
  const [showCreationDataInspHisDialog, setShowCreationDataInspHisDialog] = useState<boolean>(false);
  // 검수이력 다이얼로그 파람값
  const setCreationDataInspHisParams = (creationDataId: number) => {
    creationDataIdRef.current = creationDataId;
    setShowCreationDataInspHisDialog(true)
  }

  // 챗지피티 다이얼로그 관련
  const [showCreationChatGptDialog, setShowCreationChatGptDialog] = useState<boolean>(false);
  const setChatGptDialogParams = (creationDataId: number, campId: number) => {
    creationDataIdRef.current = creationDataId;
    campIdRef.current = campId;
    setShowCreationChatGptDialog(true);
  }

  const setDialogMemoParams = (campId: number, creationDataId: number) => {
    campIdRef.current = campId;
    creationDataIdRef.current = creationDataId;
    setIsOpenedInspMemoDialog(true)
  }

  const getPrevData = async () => {
    ApiUtil2.get<ICreationData>('/api/mngAd/creation/detail', { params: { data: { campIds: campIds.join(',')} } }).then((resp) => {
      setCreationData(resp.data.data);
      if (resp.data.data.length === 0) navigate('/mngAd/creation')
    });
  };

  //일괄승인
  const confirmAllEvent = () => {
    const formData: { id: string; campId: any; status: string; }[] = [];
    const updatedItems = creationData.filter(item => item.creationData.wabizInspStatus === "ing");
    for (let i = 0; i < updatedItems.length; i++) {
        formData.push({
          id: updatedItems[i].creationDataId,
          campId: updatedItems[i].campId,
          status: 'ok',
        });
    }

    alertify.confirm('승인 진행시, 검사 필요 유무와 상관없이 <br/> 모든 소재가 승인됩니다. <br/><br/> * 캠페인 기간이 지난 소재는 제외되어 승인됩니다.', () => {
      ApiUtil.post('/api/mngAd/creation/updateCreationStatusAll', {
        data : formData
      }).then((resp) => {
        if(resp.data.data.intervalValid !== undefined && !resp.data.data.intervalValid){
          alertify.alert('', resp.data.data.resultMsg).set('label', '확인');
        }
        confirmSuccess('정상적으로 승인 되었습니다.');
        getPrevData();
      });
    }).set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('모든 항목을 승인 하시겠습니까?');
  };

  const confirmClickEvent = (campId: any, id: any) => {
    alertify.confirm('', () => {
      ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
        id,
        campId,
        status: 'ok',
      }).then((resp) => {
        if(resp.data.data.intervalValid !== undefined && !resp.data.data.intervalValid){
          alertify.alert('', resp.data.data.resultMsg).set('label', '확인');
        }
        getPrevData();
      });
    }).set({ labels: { cancel: '취소', ok: '승인' } })
      .setHeader('해당 소재를 검수 승인하시겠습니까?');
  };

  const getAf = async (id:any) => {
    ApiUtil2.post('/api/mngAd/creation/getFiles', { refId: id }).then((resp) => {
      // eslint-disable-next-line no-use-before-define
      if (resp.data.data.length !== 0) {
        setFileData(resp.data.data);
      }
    });
  };

  const setDialogParams = (campId: number, creationDataId: number, campAttach: any) => {
    campIdRef.current = campId;
    creationDataIdRef.current = creationDataId;
    campDialogAfRef.current = [];
    campDialogAfRef.current = campAttach.afs.map((v: Af) => new Af(v.fileName, v.fileSize, v.filePath, v.afId, v.lastModified));
    setIsOpenedCampAttachDialog(true)
  }

  const event = (id: any) => {
    creationDataIdRef.current = id
    setCreationDataId(id);
    getAf(id);
    $('#previewDialog').dialog({ width: 1200, title: '이미지 미리보기', closeOnEscape: true });
    $('#previewDialog').dialog('open');
  };

  //소재반려 다이얼로그 추가
  const rejectClickEvent = (campId:any, id: any) => {
    campIdRef.current = campId
    creationDataIdRef.current = id
    setCreationDataId(id);
    $('#returnDialog').dialog({ width: 900, height : 600, title: '소재 반려사유를 입력하세요.', closeOnEscape: true });
    $('#returnDialog').dialog('open');
  };

  const handleDeleteItem = (id: any) => {
    const updatedItems = creationData.filter(item => item.creationDataId !== id.toString());
    setCreationData(updatedItems);
    if(updatedItems.length === 0) navigate('/mngAd/creation');
  };

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1);
  };

  const confirmError = (msg: string) => {
    errorEvent(msg);
  };

  const successEvent = (msg: string) => {
    alertify.success(msg, 1, () => {
    });
  };

  const confirmSuccess = (msg: string) => {
    successEvent(msg);
  };

  useEffect(() => {
    setTitle('일괄 검수');
    getPrevData();
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    $('#closePreview').click(() => {
      setFileData([]);
      $('#previewDialog').dialog('close');
    });
    return () => {
      $('#previewDialog').dialog('destroy');
    };
  }, []);

  useEffect(() => {
    $('#closeBtn').click(() => {
      $('#returnDialog').dialog('close');
    });
    return () => {
      $('#returnDialog').dialog('destroy');
    };
  }, []);

  useEffect(() => {
    $('#okBtn').click(() => {
      if($('#msg').val() === ''){
        confirmError('반려사유를 작성해주세요')
        return;
      }

      ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
        id: creationDataIdRef.current,
        campId: campIdRef.current,
        status: 'fail',
        msg: $('#msg').val(),
      }).then(() => {
        getPrevData();
        $('#msg').val('');
        $('#returnDialog').dialog('close');
      });
    });
  }, []);

  const {
    isOpenedCampAttachDialog,
    setIsOpenedCampAttachDialog,
    campDialogAfRef,
  } = getAttachBtn();

  const {
    isOpenedInspMemoDialog,
    setIsOpenedInspMemoDialog,
  } = getMemoBtn()

  return (
        <Fragment>
            <GdnCreationDataIdContext.Provider value={{ creationDataId, setCreationDataId }}>
                <PreviewImgDialog fileData={fileData}/>
                <FailCreationDialog campId={campIdRef.current} creationDataId={creationDataId} getPrevData={getPrevData}/>
            </GdnCreationDataIdContext.Provider>
            { isOpenedCampAttachDialog ? <CampAttachDialogForAdmin setIsOpenedCampAttachDialog={setIsOpenedCampAttachDialog} campId={campIdRef.current} creationDataId={creationDataIdRef.current} afs={campDialogAfRef}/> : null}
            { isOpenedInspMemoDialog ? <CreationDataInspMemoDialog setIsOpenedInspMemoDialog={setIsOpenedInspMemoDialog} campId={campIdRef.current} creationDataId={creationDataIdRef.current}/> : null}
            { showCreationDataInspHisDialog ? <CreationDataInspHisDialog setShowCreationDataInspHisDialog = {setShowCreationDataInspHisDialog} creationDataId={creationDataIdRef.current} searchType='admin'/> : null}
            { showCreationChatGptDialog ? <CreationChatGptDialog setShowCreationChatGptDialog={setShowCreationChatGptDialog} campId={campIdRef.current} creationDataId={creationDataIdRef.current}/> : null }
                {
                    chunked.map((a: any, k: any) => (
                            <section className="wrap-section wrap-tbl" key={k}>
                              {
                                k == 0 ? <div className="box-header">
                                  <div className="box-tit">
                                    <button type="button" className="btn btn-primary-outline" onClick={()=>{navigate('/mngAd/creation')}}>이전 목록으로</button>
                                  </div>
                                  <div className="box-option">
                                    <button type="button" className="btn btn-primary" onClick={confirmAllEvent}>일괄 승인</button>
                                  </div>
                                </div> : null
                              }
                              <div className="box-body">
                                <div className="tbl">
                                  <dl className="vertical">
                                    <dd>
                                      <div className="form-group">
                                        <div className="container-fluid">
                                          <div className="row">
                                          {
                                            /**
                                             * 정책정리,, 230823
                                             * 검수필요키워드 X + 일반 => 일반, 검정색
                                             * 검수필요키워드 o + 일반 => 검수필요, 검정색
                                             *         x        + 검수필요 => 검수필요, 빨간색
                                             */
                                              a.map((aa: any, kk: any) => (
                                                  <div className="col col-4" key={kk}>
                                                    <div className="wrap-preview">
                                                      <div className="box-header">
                                                        <div className="box-tit">
                                                          {
                                                            aa.inspNeedStatus === 'invalid_must' || aa.inspNeedStatus === 'invalid_store' ? <>
                                                              <div className="txt-state red">검수 필요</div>
                                                              <div className="txt-state black"><span className="fc-7 fw-bold txt-underline">{aa.catCodeDesc}</span></div>
                                                            </> : null
                                                          }
                                                          {
                                                            aa.inspNeedStatus === 'invalid_proh_kwd' ?
                                                                <>
                                                                  <div className="txt-state red">검수 필요</div>
                                                                  <div className="txt-state black">{aa.catCodeDesc}</div>
                                                                </> : null
                                                          }
                                                          {
                                                            aa.inspNeedStatus === 'valid' ?
                                                                <>
                                                                  <div className="txt-state black">일반</div>
                                                                  <div className="txt-state black">{aa.catCodeDesc}</div>
                                                                </> : null
                                                          }
                                                        </div>
                                                        <div className="box-option">
                                                          {
                                                            aa.creationData.wabizInspStatus === 'ing'
                                                                ? <>
                                                                  <button type="button"
                                                                          className="btn btn-danger xsmall w-auto"
                                                                          onClick={() => {
                                                                            rejectClickEvent(aa.campId, aa.creationData.id);
                                                                          }}>반려
                                                                  </button>
                                                                  <button type="button"
                                                                          className="btn btn-primary xsmall w-auto"
                                                                          onClick={() => {
                                                                            confirmClickEvent(aa.campId, aa.creationData.id);
                                                                          }}>승인
                                                                  </button>
                                                                  <button type="button" className="btn btn-secondary-outline xsmall w-auto" onClick={() => {
                                                                    handleDeleteItem(aa.creationData.id)
                                                                  }}>제외
                                                                  </button>
                                                                </>
                                                                : null
                                                          }
                                                          {
                                                            aa.creationData.wabizInspStatus === 'ok' || aa.creationData.wabizInspStatus === 'fail'
                                                                ?
                                                                <button
                                                                    type="button"
                                                                    className={`btn ${aa.creationData.wabizInspStatus === 'ok' ? 'btn-primary-outline' : 'btn-danger-outline'} xsmall w-auto`}
                                                                    onClick={(event) => {
                                                                      if (aa.creationData.wabizInspStatus === 'ok') rejectClickEvent(aa.campId, aa.creationData.id);
                                                                      if (aa.creationData.wabizInspStatus === 'fail') confirmClickEvent(aa.campId, aa.creationData.id);
                                                                    }}>{aa.creationData.wabizInspStatus === 'ok' ? '승인' : '반려'}
                                                                </button>
                                                                : null
                                                          }
                                                        </div>
                                                      </div>
                                                      <div className="box-header">
                                                        <div className="box-tit">
                                                          {
                                                            aa.gptInspStatus !== undefined ? <button type="button"
                                                                                                     className={`btn btn ${inspStatusColor[aa.gptInspStatus]} xsmall w-auto`}
                                                                                                     onClick={() => setChatGptDialogParams(aa.creationDataId, aa.campId)}
                                                            >{inspStatus[aa.gptInspStatus]}</button> : <button type="button"
                                                                                                               className={`btn btn btn-info xsmall w-auto`}
                                                                                                               onClick={() => setChatGptDialogParams(aa.creationDataId, aa.campId)}>chatGPT – 검수중</button>
                                                          }
                                                        </div>
                                                        <div className="box-option">
                                                          {
                                                            aa.campAttchAf.afs.length != 0 ?
                                                                <button id={'temp_' + aa.creationDataId}
                                                                        onClick={() => setDialogParams(aa.campId, aa.creationDataId, aa.campAttchAf)}
                                                                        type="button"
                                                                        className="btn btn-info-outline xsmall w-auto">첨부서류</button>
                                                                : null
                                                          }
                                                          <button type="button" className="btn btn-primary-outline xsmall w-auto" onClick={() => setCreationDataInspHisParams(aa.creationDataId)} >검수이력</button>
                                                          <button type="button" className="btn btn-secondary-outline xsmall w-auto" onClick={()=> setDialogMemoParams(aa.campId, aa.creationDataId)}>메모</button>
                                                        </div>
                                                      </div>
                                                      <div className="box-header">
                                                        <div className="box-tit">
                                                          <span className="fz-14 fc-1">캠페인ID: {aa.campId}</span>
                                                          <span className="fz-14 fc-1">소재ID: {aa.creationData.id}</span>
                                                        </div>
                                                      </div>
                                                      <div className="box-body">
                                                        <div className="box-group flex-container">
                                                          <div className="box-left">
                                                            <img
                                                                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-01.png"
                                                                alt="img1"/>
                                                          </div>
                                                          <div className="box-right">
                                                            <img
                                                                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-02.png"
                                                                alt="img1"/>
                                                          </div>
                                                        </div>
                                                        <div className="box-group">
                                                          <div className="box-left">
                                                            <img
                                                                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-48x48.png"
                                                                className="logo" alt="img1"/>
                                                          </div>
                                                          <div className="box-right">
                                                            <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                                                            <p className="fz-12 fc-3 lh15">광고</p>
                                                          </div>
                                                        </div>
                                                        <div className="box-group">
                                                          {
                                                              aa.catCode !== undefined && aa.catInspConfig.catProhKwd !== undefined && aa.catInspConfig.catProhKwd.split("|").some((kwd: any) => {
                                                                aa.adDesc.includes(kwd) ? aa.adDesc = aa.adDesc.replaceAll(kwd, `<span class="fc-7 fw-bold txt-underline">${kwd}</span>`) : aa.adDesc
                                                              })
                                                          }
                                                          <p id="gdnAdDesc"
                                                             dangerouslySetInnerHTML={{__html: aa.adDesc.replace(/\n/g, '<br/>')}}
                                                             className={`fz-14 fc-2 lh15 ${aa.adDesc !== '' && aa.catCode !== undefined && aa.catInspConfig.catProhKwd !== undefined && aa.catInspConfig.catProhKwd !== '' && aa.catInspConfig.catProhKwd.split("|").some((kwd: any) => (aa.adDesc.includes(kwd))) ? 'error' : ''}`}
                                                          ></p>
                                                        </div>
                                                        <div className="box-group">
                                                          {
                                                            aa.af.length === 0
                                                                ? <div className="comp-no-data">
                                                                  <div className="box-group">
                                                                    <i className="ico ico-no-img"></i>
                                                                    <p className="fz-14 fc-2">이미지 혹은 영상을 선택해주세요.</p>
                                                                  </div>
                                                                </div>
                                                                : <div className="comp-thumb" style={{}}>
                                                                  <button type="button" onClick={() => event(aa.creationData.id)}>
                                                                    <div className="txt-state mint">{aa.af[0].afMeta.fileDiv === 'VIDEO' ? '동영상' : '이미지'}</div>
                                                                    <div className="txt-state blue">{aa.af[0].afMeta.ext.toUpperCase()}</div>
                                                                    {aa.af[0].afMeta.fileDiv === 'VIDEO' && aa.af[0].afMeta.ext !== 'gif'
                                                                        // eslint-disable-next-line jsx-a11y/media-has-caption
                                                                        ?
                                                                        <video
                                                                            autoPlay
                                                                            controls
                                                                            muted
                                                                            loop
                                                                            /*style={{ width: '100%' }}*/
                                                                        >
                                                                          <source src={`${aa.af[0].fileName}`}/>
                                                                        </video>
                                                                        :
                                                                        <img
                                                                            /*className={`${aa.af[0].afMeta.width/aa.af[0].afMeta.height === 4/5 ? 'landscape' : null}`}*/
                                                                            src={`${aa.af[0].fileName}`}
                                                                            /*style={{ width: '100%' }}*/
                                                                            alt="img22"/>
                                                                    }
                                                                  </button>
                                                                </div>

                                                          }
                                                        </div>
                                                        <div className="box-group">
                                                          <div className="box-row">
                                                            <span className="fz-12 fc-3 lh15">wadiz.kr</span>
                                                          </div>
                                                          <div className="box-row flex-container">
                                                            <div className="box-left">
                                                              {
                                                                  aa.catCode !== undefined && aa.catInspConfig.catProhKwd !== undefined && aa.catInspConfig.catProhKwd.split("|").some((kwd: any) => {
                                                                    aa.adTitle01.includes(kwd) ? aa.adTitle01 = aa.adTitle01.replaceAll(kwd, `<span class="fc-7 fw-bold txt-underline">${kwd}</span>`) : aa.adTitle01
                                                                  })
                                                              }
                                                              <p dangerouslySetInnerHTML={{__html: aa.adTitle01.replace(/\n/g, '<br/>')}}
                                                                 className={`fz-14 fc-1 fw-medium lh15 ${aa.adTitle01 !== '' && aa.catCode !== undefined && aa.catInspConfig.catProhKwd !== undefined && aa.catInspConfig.catProhKwd !== '' && aa.catInspConfig.catProhKwd.split("|").some((kwd: any) => (aa.adTitle01.includes(kwd))) ? 'error' : ''}`}
                                                              ></p>
                                                            </div>
                                                            <div className="box-right">
                                                              <button type="button" className="btn btn-secondary-outline xsmall w-auto">{aa.clickBtnDiv}</button>
                                                            </div>
                                                          </div>
                                                          <div className="box-row">
                                                            {
                                                                aa.catCode !== undefined && aa.catInspConfig.catProhKwd !== undefined && aa.catInspConfig.catProhKwd.split("|").some((kwd: any) => {
                                                                  aa.adTitle02.includes(kwd) ? aa.adTitle02 = aa.adTitle02.replaceAll(kwd, `<span class="fc-7 fw-bold txt-underline">${kwd}</span>`) : aa.adTitle02
                                                                })
                                                            }
                                                            <p dangerouslySetInnerHTML={{__html: aa.adTitle02.replace(/\n/g, '<br/>')}}
                                                               className={`fz-12 fc-4 lh15 ${aa.adTitle02 !== '' && aa.catCode !== undefined && aa.catInspConfig.catProhKwd !== undefined && aa.catInspConfig.catProhKwd !== '' && aa.catInspConfig.catProhKwd.split("|").some((kwd: any) => (aa.adTitle02.includes(kwd))) ? 'error' : ''}`}
                                                            ></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="box-footer">
                                                        <div className="box-group">
                                                          <div className="border-top">
                                                            <img src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-03.png" alt="img1"/>
                                                          </div>
                                                        </div>
                                                        <div className="box-group">
                                                          <img src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-04.png" alt="img1"/>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                              ))
                                          }
                                          </div>
                                        </div>
                                      </div>
                                    </dd>
                                  </dl>
                                </div>
                              </div>
                            </section>
                    ))
                }
        </Fragment>
  );
};

export default CreationDetailFbAll;
