import React, { Fragment, useContext } from 'react';
import { CampMediaDiv4Context } from './Make02Empty';
import Make02Step02Gdn from './Make02Step02Gdn';
import Make02Step02Fb from './Make02Step02Fb';

const Make02Step02 = () => {
  const { mediaDiv4Param } = useContext(CampMediaDiv4Context);

  return (
    <Fragment>
      {mediaDiv4Param === 'GDN' ? <Make02Step02Gdn/> : <Make02Step02Fb/> }
    </Fragment>
  );
};
export default Make02Step02;
