import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from './LeftMenu';

// Type 'undefined' is not assignable to type
// 이런 에러가 나오면 Menu[] | undefined; 이렇게 바꿔주고
// props.items -> props.items? 로 변경해줘야 한다....
// 데이터 가져오는 loading 여부 타입 boolean
interface MenuListProps {
    items: Menu[] | undefined;
    subItems: Menu[][] | undefined;
    loading:boolean;
}
const LeftMenuList = (props: MenuListProps) => {
  // 화면 리프레시시 발생하는 메뉴 선택 부분으로 인해 추가
  const url = useLocation();
  const menuPath = url.pathname.split('/');
  let depth1Menu = '';
  if (menuPath.length > 1) {
    depth1Menu = `/${url.pathname.split('/')[1]}`;
  }
  const subMenuClickEvent = (event: React.MouseEvent<HTMLElement>) => {
    // 전체 중메뉴 selected 해제
    $('#menuList').find('li').not('.one-depth').attr('class', '');
    const menuTagLi = event.currentTarget.parentElement;
    menuTagLi!.className = 'selected';
  };
  return (
    <Fragment>
      {/* 대메뉴를 먼저 세팅 */}
      {!props.loading &&
        props.items?.map((menu, menuIndex) =>
          menu.id === 'onlyCaption' ? (
            <li
              key={menu.menuId}
              style={{
                fontSize: '12px',
                color: '#868E96',
                width: '100%',
                padding: '14px 40px',
              }}
            >
              {menu.name}
            </li>
          ) : menu.id === 'onlyDivider' ? (
            <li key={menu.menuId} style={{ border: 'none' }}>
              <hr
                style={{
                  borderColor: '#868E96',
                  height: 0,
                  borderBottom: 'none',
                }}
              />
            </li>
          ) : menu.show === true ? (
            <li
              key={menu.menuId}
              className={
                depth1Menu === menu.path.replace('/api', '') ||
                (menuPath.length > 1 &&
                  menuPath.join('/') === menu.path.replace('/api', ''))
                  ? 'one-depth treeview active'
                  : 'one-depth treeview'
              }
            >
              <Link
                {...((props.subItems?.[menuIndex]?.length || 0) > 0
                  ? {
                      to: '#',
                    }
                  : {
                      to: menu.path.replace('/api', ''),
                      onClick: subMenuClickEvent,
                      id: menu.menuId,
                    })}
              >
                {menu.name}
                {menu.isLeaf === false ? (
                  <i className='ico i-16 ico-arrow'></i>
                ) : null}
              </Link>
              {/* 중메뉴를 세팅 시 map에서의 첫번째 값에 ul 태그를 넣음 */}
              {props.subItems?.map((subMemu) =>
                subMemu.map((subMenu2, index) =>
                  index === 0 &&
                  menu.id ===
                    subMenu2.path.replace('/api', '').split('/')[1] ? (
                    <ul
                      key={subMenu2.menuId}
                      className={
                        depth1Menu === menu.path.replace('/api', '')
                          ? 'two-depth treeview-menu menu-open'
                          : 'two-depth treeview-menu'
                      }
                      style={{
                        display:
                          depth1Menu === menu.path.replace('/api', '')
                            ? 'block'
                            : 'none',
                      }}
                    >
                      {subMenu2.show === true ? (
                        <li
                          key={subMenu2.menuId}
                          className={
                            url.pathname ===
                              subMenu2.path.replace('/api', '') ||
                            url.pathname.indexOf(
                              subMenu2.path.replaceAll('/api', '')
                            ) >= 0
                              ? 'selected'
                              : ''
                          }
                        >
                          <Link
                            to={subMenu2.path.replace('/api', '')}
                            onClick={subMenuClickEvent}
                            id={subMenu2.menuId}
                          >
                            {subMenu2.name}
                          </Link>
                        </li>
                      ) : null}
                      {/* 대메뉴의 2번째 메뉴 부터 여기서 세팅하여 생성 */}
                      {props.subItems?.map((subMemuLi) =>
                        subMemuLi.map((subMenuLi2, index2) =>
                          index2 !== 0 &&
                          subMenuLi2.show === true &&
                          menu.id ===
                            subMenuLi2.path
                              .replace('/api', '')
                              .split('/')[1] ? (
                            <li
                              key={subMenuLi2.menuId}
                              className={
                                url.pathname ===
                                  subMenuLi2.path.replace('/api', '') ||
                                url.pathname.indexOf(
                                  subMenuLi2.path.replaceAll('/api', '')
                                ) >= 0
                                  ? 'selected'
                                  : ''
                              }
                            >
                              <Link to={subMenuLi2.path.replace('/api', '')}>
                                {subMenuLi2.name}
                              </Link>
                            </li>
                          ) : null
                        )
                      )}
                    </ul>
                  ) : null
                )
              )}
            </li>
          ) : null
        )}
    </Fragment>
  );
};
export default LeftMenuList;
