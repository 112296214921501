import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useOutletContext} from "react-router-dom";
import dayjs, {Dayjs} from "dayjs";
import SingleSelect2 from "../../accAdjust/SingleSelect2";
import RangeDatePicker from "./RangeDatePicker";
import {DataFormat} from "select2";
import { useForm } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import _ from "lodash";
import {AgGridReact} from "ag-grid-react";
import { ApiUtil, ApiUtil2 } from '@biz/api'
import NoDataTemplate from "../../common/NoDataTemplate";
import Pagination from "../../common/Pagination";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import CreationDataInspHisPreviewDialog from '../creation/CreationDataInspHisPreviewDialog'
import CreationChatGptDialog from "../creation/CreationChatGptDialog";

interface SearchForm {
  searchType: string | undefined,
  searchTxt: string | undefined,
  isInspEqual: string | undefined,
  gptInspDiv: string | undefined,
  inspDiv: string | undefined,
  catCodes: string | undefined,
  adminInspDivs: string | undefined,

  startDate: string;
  endDate: string;
}

const GptHis = () => {
  const setTitle = useOutletContext<any>();
  const {
    register, setValue, handleSubmit, getValues,
  } = useForm<SearchForm>();

  const [ categoryDesc, setCategoryDesc ] = useState('전체');
  const [ adminInspDivDesc, setAdminInspDivDesc ] = useState('전체');

  const [cateDiv, setCateDiv] = useState([]);
  const [showCreationDataPreviewDialog, setShowCreationDataPreviewDialog] = useState<boolean>(false);
  const [previewData,setPreviewData] = useState<any>('');
  const [showCreationChatGptDialog, setShowCreationChatGptDialog] = useState<boolean>(false);

  const [campId, setCampId] = useState(null);
  const [creationDataId, setCreationDataId] = useState(null);
  const [creationDataGptHisId, setCreationDataGptHisId] = useState(null);



  const searchTypes:DataFormat[] = [{ id: 'makerName', text: '메이커명' }, { id: 'creationDataId', text: '소재 ID' }];
  const isInspEqual:DataFormat[] = [ { id: 'all', text: '전체', selected: true }, { id: 'true', text: '일치' }, { id: 'false', text: '불일치' }];
  const gptInspDiv:DataFormat[] = [{ id: 'all', text: '전체', selected: true }, { id: 'ing', text: '검수중' }, { id: 'ok', text: '승인' }, { id: 'fail', text: '반려' }, { id: 'error', text: '실패' }];
  const inspDiv:DataFormat[] = [{ id: 'all', text: '전체', selected: true }, { id: 'ing', text: '검수중' }, { id: 'ok', text: '승인' }, { id: 'fail', text: '반려' }];
  const adminInspDivs : any = { 'all' : '전체', 'text' : '텍스트', 'img' : '이미지', 'movie' : '영상', 'slide' : '슬라이드'};

  const cateDivRef = useRef<any>([]);
  const adminInspDivRef = useRef<any>([]);

  const inspStatus : any = { 'ing' : '검수중', 'error' : '실패', 'ok' : '승인', 'fail' : '반려' };

  /** 그리드 **/
  const setCreationDataPreviewParams = (param: any) => {
    ApiUtil.get(`/api/mngAd/gptHis/getCreationDataInspHis?creationDataInspHisId=${param.creationDataInspHisId}`).then((resp) => {
      const { data } = resp.data;
      setPreviewData(data);
      setShowCreationDataPreviewDialog(true);
    });
  }

  const setCreationDataGptPreviewParams = (param: any) => {
    setShowCreationChatGptDialog(true);
    setCampId(param.campId);
    setCreationDataId(param.creationDataId);
    setCreationDataGptHisId(param.creationDataGptHisId);
  }

  const creationDataInspHisRenderer = (props: ICellRendererParams) => {
    return <a className="txt-link" onClick={() => setCreationDataPreviewParams(props.data)}>미리보기</a>;
  };

  const gptInspStatusRenderer = (props: ICellRendererParams) => {
    return <a className="txt-link" onClick={() => setCreationDataGptPreviewParams(props.data)}>{ inspStatus[props.value] }</a>;
  };

  const adminInspStatusRenderer = (props: ICellRendererParams) => {
    return <a className="txt-link" onClick={() => setCreationDataGptPreviewParams(props.data)}>{ _.isEmpty(props.value) ? '-' : inspStatus[props.value] }</a>;
  };

  const adminInspDivRenderer = (props: ICellRendererParams) => {
    const { value } = props;
    let replaceValue = value;

    if (_.isEmpty(value)) return "-";
    Object.entries(adminInspDivs).map((e, idx) => {
      replaceValue = replaceValue.replace(e[0], e[1]);
    });

    return replaceValue;
  };

  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: 'chatGPT 검수요청일시',
      cellClass: 'ag-center-aligned-cell',
      sort:'desc',
    },
    {
      field: 'completeTime',
      headerName: 'chatGPT 검수완료일시',
      cellClass: 'ag-center-aligned-cell',
      valueFormatter: (params :any) => (_.isEmpty(params.value) ? '-' : params.value),
    },
    {
      field: 'makerName',
      headerName: '메이커명',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'creationDataId',
      headerName: '소재 ID',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'catCodeDesc',
      headerName: '카테고리',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'creationDataInspHisId',
      headerName: '검수요청 소재',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer : creationDataInspHisRenderer,
    },
    {
      field: 'isInspEqual',
      headerName: '검수결과 일치여부',
      cellClass: 'ag-center-aligned-cell',
      valueFormatter : (params :any) => (params.value ? '일치' : '불일치'),
    },
    {
      field: 'gptInspStatus',
      headerName: 'chatGPT 검수결과',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: gptInspStatusRenderer,
    },
    {
      field: 'adminInspStatus',
      headerName: '광고사업팀 검수결과',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: adminInspStatusRenderer,
    },
    {
      field: 'adminInspDiv',
      headerName: '광고사업팀 검수유형',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: adminInspDivRenderer,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReady = useCallback(() => {
    // const {
    //   projectDiv3s,
    //   mediaDiv2,
    //   useYn,
    //   mediaAccNm,
    // } = getValues();
    // 데이터가 여부에 따라 height를 다르게 주어야 한다....
    // AG GRID 에 대한 디자인을 진행하다보니 일단 이렇게... ApiUtil2에 처리되어 있다.
    ApiUtil2.get<any>('/api/mngAd/gptHis/list', {
      params: {
        data: { ...getValues() }
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);

  const changePagination = () => {
    childRef.current!.onPaginationChanged();
  };

  /** 그리드 끝 **/

  const getCate = async () => {
    // COMMON 은 공통 적용을 위한 분류로서, '공통'이라는 카테고리가 따로 존재하지는 않음
    const { data } = await ApiUtil2.get(`/api/mngAd/gptHis/getCateDiv`);
    return data;
  };

  const getCateDiv = useQuery(['getCateDiv', 0], getCate, {
    enabled: false,
  });

  const checkCateDiv = (e : any) => {
    const checkedCateDivs : string[] = [];
    let firstCheckedCat = '전체';

    if (e.target.value == 'all') {
      if (e.target.checked) {
        _.each(cateDivRef.current, (v : any) => {
          v.checked = true;
        });

        setCategoryDesc('전체');
        setValue('catCodes', '');
      } else {
        _.each(cateDivRef.current, (v : any) => {
          v.checked = false;
        });

        setCategoryDesc('선택안함');
        setValue('catCodes', '');
      }
    } else {
      _.each(cateDivRef.current, (v : any) => {
        if (v.checked) {
          checkedCateDivs.push(v.value);

          if (firstCheckedCat == '전체') {
            firstCheckedCat = cateDiv[v.value];
          }

        }
      });

      if (checkedCateDivs.length == Object.keys(cateDiv).length - 1 && !cateDivRef.current[0].checked){
        cateDivRef.current[0].checked = true;
        setCategoryDesc('전체');
        setValue('catCodes', '');
      } else {
        if (checkedCateDivs.length < Object.keys(cateDiv).length){
          cateDivRef.current[0].checked = false;
        }

        setCategoryDesc(checkedCateDivs.length <= 1 ? firstCheckedCat : `${firstCheckedCat} 외 ${checkedCateDivs.length-1}개`);
        setValue('catCodes', checkedCateDivs.filter((v : any) => v!='all').join(','));
      }
    }
  };

  const checkAdminInspDiv = (e : any) => {
    const checkedAdminInspDivs : string[] = [];
    let firstChecked = '전체';

    if (e.target.value == 'all') {
      if (e.target.checked) {
        _.each(adminInspDivRef.current, (v : any) => {
          v.checked = true;
        });

        setAdminInspDivDesc('전체');
        setValue('adminInspDivs', '');
      } else {
        _.each(adminInspDivRef.current, (v : any) => {
          v.checked = false;
        });

        setAdminInspDivDesc('선택안함');
        setValue('adminInspDivs', '');
      }
    } else {
      _.each(adminInspDivRef.current, (v : any) => {
        if (v.checked) {
          checkedAdminInspDivs.push(v.value);

          if (firstChecked == '전체') {
            firstChecked = adminInspDivs[v.value];
          }

        }
      });

      if (checkedAdminInspDivs.length == 4 && !adminInspDivRef.current[0].checked){
        adminInspDivRef.current[0].checked = true;
        setAdminInspDivDesc('전체');
        setValue('adminInspDivs', '');
      } else {
        if (checkedAdminInspDivs.length < 5){
          adminInspDivRef.current[0].checked = false;
        }

        setAdminInspDivDesc(checkedAdminInspDivs.length <= 1 ? firstChecked : `${firstChecked} 외 ${checkedAdminInspDivs.length-1}개`);
        setValue('adminInspDivs', checkedAdminInspDivs.filter((v : any) => v!='all').join(','));
      }
    }
  };
  const registerDate = (start:Dayjs, end:Dayjs) => {
    setValue('startDate', start.format('YYYYMMDD'));
    setValue('endDate', end.format('YYYYMMDD'));
  };

  const downloadHandler = () => {
    const data:any = getValues();
    const url = `/api/mngAd/gptHis/list/download?data=${encodeURIComponent(JSON.stringify(data))}`;
    window.location.assign(url);
  };

  useEffect(() => {
    setTitle('chatGPT 소재 검수 현황');

    getCateDiv.refetch().then((response) => {
      const { data } = response.data;
      setCateDiv(data);
    });

    setValue('searchType', 'makerName');

    return () => {
      setTitle('');
    };
  }, []);

  return (
    <Fragment>
      <section className="wrap-section wrap-datagrid">
        <form onSubmit={handleSubmit(onGridReady)}>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">chatGPT 검수요청/완료</p>
                  </div>
                  <div className="box-filter">
                    <RangeDatePicker
                      changeEvent={registerDate}
                      defaultStr={'최근 7일'}
                      option={{
                        startDate: dayjs().startOf('day').subtract(7, 'd'),
                        endDate: dayjs().startOf('day').subtract(1, 'd'),
                      }}
                    />
                  </div>
                </div>

                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      data={searchTypes}
                      selectEvent={(v: any) => {
                        setValue('searchType', v)
                      }}
                    />
                  </div>
                </div>

                <div className="item-filter">
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          type="text"
                          className="tf-comm"
                          placeholder="검색어를 입력해 주세요."
                          {...register('searchTxt')}
                          maxLength={30}
                        ></input>
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">카테고리</p>
                  </div>
                  <div className="box-filter">
                    <div className="comp-dropdown select2 w-200">
                      <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                        <div className="box-left">
                          <span className="fz-14">{categoryDesc}</span>
                        </div>
                        <div className="box-right">
                          <i className="ico ico-arrow"></i>
                        </div>
                      </a>
                      <div className="dropdown-menu expand">
                        <ul className="opt-selectbox">
                          {Object.entries(cateDiv).map((e, idx) => {
                            return (
                              <li className="opt-menu" key={`cateDiv_${e[0]}`}>
                                <div className="comp-checkbox small">
                                  <input
                                    type="checkbox"
                                    id={`cateDiv_${e[0]}`}
                                    onChange={checkCateDiv}
                                    value={`${e[0]}`}
                                    ref={(e) => {
                                      cateDivRef.current[idx] = e
                                    }}
                                    defaultChecked={true}
                                  />
                                  <label htmlFor={`cateDiv_${e[0]}`}>{e[1]}</label>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검수결과 일치여부</p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      data={isInspEqual}
                      selectEvent={(v: any) => {
                        setValue('isInspEqual', v == 'all' ? '' : v)
                      }}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">chatGPT 검수결과</p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      data={gptInspDiv}
                      selectEvent={(v: any) => {
                        setValue('gptInspDiv', v == 'all' ? '' : v)
                      }}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">광고사업팀 검수결과</p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      data={inspDiv}
                      selectEvent={(v: any) => {
                        setValue('inspDiv', v == 'all' ? '' : v)
                      }}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">광고사업팀 검수유형</p>
                  </div>
                  <div className="box-filter">
                    <div className="comp-dropdown select2 w-150">
                      <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                        <div className="box-left">
                          <span className="fz-14">{adminInspDivDesc}</span>
                        </div>
                        <div className="box-right">
                          <i className="ico ico-arrow"></i>
                        </div>
                      </a>
                      <div className="dropdown-menu expand">
                        <ul className="opt-selectbox">
                          <li className="opt-menu">
                            <div className="comp-checkbox small">
                              <input
                                type="checkbox"
                                id={`all_chk`}
                                onChange={checkAdminInspDiv}
                                value={`all`}
                                ref={(e) => {
                                  adminInspDivRef.current[0] = e
                                }}
                                defaultChecked={true}
                              />
                              <label htmlFor={`all_chk`}>전체</label>
                            </div>
                          </li>
                          <li className="opt-menu">
                            <div className="comp-checkbox small">
                              <input
                                type="checkbox"
                                id={`txt_chk`}
                                onChange={checkAdminInspDiv}
                                value={`text`}
                                ref={(e) => {
                                  adminInspDivRef.current[1] = e
                                }}
                                defaultChecked={true}
                              />
                              <label htmlFor={`txt_chk`}>텍스트</label>
                            </div>
                          </li>
                          <li className="opt-menu">
                            <div className="comp-checkbox small">
                              <input
                                type="checkbox"
                                id={`img_chk`}
                                onChange={checkAdminInspDiv}
                                value={`img`}
                                ref={(e) => {
                                  adminInspDivRef.current[2] = e
                                }}
                                defaultChecked={true}
                              />
                              <label htmlFor={`img_chk`}>이미지</label>
                            </div>
                          </li>
                          <li className="opt-menu">
                            <div className="comp-checkbox small">
                              <input
                                type="checkbox"
                                id={`movie_chk`}
                                onChange={checkAdminInspDiv}
                                value={`movie`}
                                ref={(e) => {
                                  adminInspDivRef.current[3] = e
                                }}
                                defaultChecked={true}
                              />
                              <label htmlFor={`movie_chk`}>영상</label>
                            </div>
                          </li>
                          <li className="opt-menu">
                            <div className="comp-checkbox small">
                              <input
                                type="checkbox"
                                id={`slide_chk`}
                                onChange={checkAdminInspDiv}
                                value={`slide`}
                                ref={(e) => {
                                  adminInspDivRef.current[4] = e
                                }}
                                defaultChecked={true}
                              />
                              <label htmlFor={`slide_chk`}>슬라이드</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  <i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="box-header">
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}>
              <i className="ico ico-download"></i>다운로드
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                suppressRowClickSelection={true}
                rowSelection={'multiple'}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
      {showCreationDataPreviewDialog ? (
        <CreationDataInspHisPreviewDialog
          previewData={previewData}
          setShowCreationDataPreviewDialog={setShowCreationDataPreviewDialog}
        />
      ) : null}
      {showCreationChatGptDialog ? (
        <CreationChatGptDialog
          setShowCreationChatGptDialog={setShowCreationChatGptDialog}
          campId={campId}
          creationDataId={creationDataId}
          creationDataGptHisId={creationDataGptHisId}
        />
      ) : null}
    </Fragment>
  )

};

export default GptHis;


