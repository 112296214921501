import React, {Fragment, useEffect, useRef, useState} from 'react';
import {bytesToSize} from '../../fileUploader/MultiFileUploaderForConsulting';
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil2, ApiUtil3 } from '@biz/api'
import {Dialog} from 'rc-easyui';
import { fileDownHandler } from '../../bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryDetail'
import {IPomInquiryDetailForMaker} from "../../pom/PomInquiryDetailDialog";
export interface ICampAttach {
    campId: number;
    project2Id: string;
    afs: Af[];
    data: any;
}
export class Af {
    fileName: string | undefined;
    fileSize: number = 0;
    filePath: string | undefined;
    uploadId: any;
    afId: any;
    lastModified : string | undefined;

    constructor(fileName: string | undefined, fileSize: number, filePath: string | undefined, uploadId?: number | undefined, lastModified?: string | undefined) {
        this.fileName = fileName;
        this.fileSize = fileSize;
        this.filePath = filePath;
        this.uploadId = uploadId;
        this.lastModified = lastModified;
    }
}

const CampAttachDialog = (props: any) => {
    const dialogRef = useRef<any>();
    const afs = useRef<Af[]>([]);
    const afsSize = useRef<number>(0);
    const uploadIdRef = useRef<number>();
    const [fileCnt, setFileCnt] = useState<number>(0);
    const [project2Id, setProject2Id] = useState<string>('-');
    const campAttachIdRef = useRef<number>();
    const isExistHistoryRef = useRef<boolean>(false);
    const maxFileSize = 31457280;
    const fileUploadHandler = async (e: any) => {
        const file: File = e.target.files[0];
        const fileName: string = file.name;
        const fileSize: number = file.size;

        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf') {
            // 성공
        } else {
            alertify.error('첨부 파일은 30MB 이내의 PDF, JPG, JPEG, PNG 파일만 \n' +
                '업로드가 가능합니다.');
            return false;
        }

        if (afs.current.length >= 10) {
            alertify.error('첨부 파일은 최대 10개까지만 업로드 가능합니다.\n');
            return;
        }

        if (afsSize.current + fileSize >= maxFileSize) {
            alertify.error('업로드 가능한 용량을 초과하였습니다.\n' +
                '30MB 이내');
            return;
        }

        await ApiUtil.get('/api/common/getUploadId')
            .then((response) => {
                uploadIdRef.current = response.data.data;
            });

        const xhr = new XMLHttpRequest();
        const data = {uploadId: uploadIdRef.current, originFileName: file.name, myS3Upload: 'camp_attach'};
        ApiUtil3.post('/api/file/uploadReq', data)
            .then((resp) => {
                const {presignUrl} = resp.data.data;
                const {publicUrl} = resp.data.data;

                xhr.responseType = 'json';
                xhr.open('PUT', presignUrl, true); // xhr url에다가 s3 url 반환해줘야 함.
                xhr.setRequestHeader('Access-Control-Allow-Headers', '*');
                xhr.send(file);

                const af = new Af(fileName, fileSize, publicUrl, uploadIdRef.current);
                afs.current.push(af);
                afsSize.current += fileSize;
                setFileCnt(afs.current.length);
            });
    }
    const fileDeleteHandler = (e: any) => {
        let id = e.currentTarget.id;
        let idxToDel = afs.current.findIndex((it) => {
            return it.uploadId == id;
        });
        if (idxToDel !== -1) {
            const temp = [...afs.current];
            temp.splice(idxToDel, 1);
            afs.current = temp;
        }
        let filesSize = 0;
        afs.current.map((it: any) => filesSize += it.fileSize);
        afsSize.current -= filesSize;
        setFileCnt(afs.current.length);
    }

    const uploadCreationAttach = () => {
        ApiUtil3.post('/api/campAttach', {
            project2Id: props.project2Id,
            campId: props.campId,
            afs: afs.current,
        }).then(() => {
            alertify.success('제출이 완료되었습니다.');
            props.setIsAttachDialogOpened(false);
        });
    }
    const modifyCreationAttach = () => {
        ApiUtil3.post('/api/campAttach/modify', {
            campAttachId: campAttachIdRef.current,
            project2Id,
            campId: props.campId,
            afs: afs.current,
        }).then(() => {
            alertify.success('수정이 완료되었습니다.');
            props.setIsAttachDialogOpened(false);
        });
    }

    const [doesCanAttach, setDoesCanAttach] = useState<boolean>(true);

    useEffect(() => {
        ApiUtil2.get<ICampAttach>('/api/campAttach/'+props.campId).then((resp) => {
            let result = resp.data.data;
            if (result.id == undefined) {
                isExistHistoryRef.current = false;
                return;
            } else {
                campAttachIdRef.current = result.id;
                isExistHistoryRef.current = true;
            }
            setProject2Id(result.project2Id);
            afs.current = (result.afs.map((v:Af) => new Af(v.fileName, v.fileSize, v.filePath, v.afId, v.lastModified)));
            setFileCnt(result.afs.length);
        });

        if (props.campViewStatusParam === 'complete' || props.campViewStatusParam === 'del_ready' ||  props.campViewStatusParam === 'del_complete') {
            setDoesCanAttach(false);
        }

        $(document).on('click', '#cancelBtn', () => {
            props.setIsAttachDialogOpened(false);
        });

        $('#regBtn').on('click', () => {
            if (afs.current.length === 0) {
                alertify.confirm('', '해당 소재는 첨부서류가 없는 상태로\n 진행 하시겠습니까?', () => {
                    isExistHistoryRef.current ? modifyCreationAttach() : uploadCreationAttach();
                }, {}).set({labels: {cancel: '취소', ok: '확인'}}).setHeader('');
            } else {
                isExistHistoryRef.current ? modifyCreationAttach() : uploadCreationAttach();
            }
        });

    }, []);

    return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{width: '1000px'}}
                        title="첨부 서류 등록">
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <div className="box-header">
                                        <div className="box-tit">
                                            <h2 className="fz-20 fc-1 fw-bold">프로젝트 및 캠페인 정보</h2>
                                        </div>
                                    </div>
                                    <dl className="column-two">
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    프로젝트ID
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm" disabled
                                                               value={ !isExistHistoryRef.current ? (props.project2Id || '-') : project2Id}/>
                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    캠페인ID
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm" disabled
                                                               value={props.campId || ''}/>
                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <div className="box-header">
                                        <div className="box-tit">
                                            <h2 className="fz-20 fc-1 fw-bold">첨부 파일</h2>
                                        </div>
                                    </div>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    *파일 첨부(필수)
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="comp-file-upload expand">
                                                    <input type="file" id="fileUploader" multiple={true}
                                                           onChange={fileUploadHandler}/>
                                                    <i className="ico i-16 ico-file"></i>
                                                    <input type="text" id="filePlaceHolder" className="tf-comm" disabled={
                                                        !doesCanAttach
                                                    }
                                                           placeholder="파일을 선택하세요." readOnly={true}/>
                                                    <label className="btn" htmlFor="fileUploader">
                                                        첨부파일 +
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <p className="comp-txt">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-12 fc-3 lh20"><i
                                                                className="fz-12 fc-5">*</i>광고를 진행하는 소재의 정보 진위를 확인할 수 있는 서류를 올려주세요. (ex.상표권, 상품 판매 인증서류 등)</b>
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                    {
                                                        afs.current.length > 0 ?
                                                            <div className="form-group">
                                                                <div className="comp-file-list">
                                                                    <div className="file-list-top">
                                                                        <span className="fz-14 fc-2">첨부 파일
                                                                            <span className="fc-5"><span className="fc-2" id='fileCnt'>{fileCnt}</span></span>개
                                                                        </span>
                                                                    </div>
                                                                    <div className="file-list-bottom">
                                                                        {afs.current.map((af, key: number) => {
                                                                            return (
                                                                                <div className="file-item" key={key} id={`file-${af.uploadId}`}>
                                                                                    <i className="ico ico-file-list"></i>
                                                                                    <span className="fz-12 fc-2">{af.fileName}</span>
                                                                                    <span className="fz-12 fc-3">({bytesToSize(af.fileSize)})</span>
                                                                                    <i className="ico ico-download" onClick={() => {  fileDownHandler(af.filePath, af.fileName)}}/>
                                                                                    <button onClick={fileDeleteHandler} disabled={!doesCanAttach} id={`${af.uploadId}`} className="ico ico-delete"/>
                                                                                    {
                                                                                        af.lastModified !== undefined ? <span className="fz-12 fc-3">&nbsp;&nbsp;&nbsp;&nbsp;업로드 일시 {af.lastModified}</span> : null
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                            </div>
                                        </dd>
                                    </dl>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-primary-outline" id={'cancelBtn'}>취소</button>
                        <button type="button" className="btn btn-primary" id={'regBtn'}>확인</button>
                    </div>
                </Dialog>
            </form>
        </Fragment>
    );
};

export default CampAttachDialog;
