import React, { Fragment, useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import { ApiUtil2 } from '@biz/api'

export interface ISystemParam {
    data: any;
    id: string | undefined;
    value: string | undefined;
    consultingCnt: number | undefined;
}

export interface ISystemParamDto {
    consultingFeeValue: number | undefined;
    consultingFreeYnValue: string | undefined;
    consultingFeeVatValue: number | undefined;
    consultingFeeLimitYnValue: string | undefined;
    consultingFeeLimitValue: number | undefined | string;
    consultingFeeLimitMsgValue: string | undefined;
}

const ConsultingSetting = () => {
  const navigate = useNavigate();
  const setTitle = useOutletContext<any>();
  const [count1, setCount1] = React.useState(0);
  const [disabled, setDisabled] = useState<any>(false);
  const [fee, setFee] = useState<any>(false);
  const [isFree, setIsFree] = useState<any>('');

  const {
    handleSubmit, setValue, getValues, register,
  } = useForm<ISystemParamDto>();

  const {consultingFeeLimitValue} = getValues();

  const successEvent = (msg: string) => {
    alertify.success(msg, 1);
  };
  const confirmSuccess = (msg: string) => {
    successEvent(msg);
  };

  const getPrevData = async () => {
    const { data } = await ApiUtil2.get<ISystemParam>('/api/mngAd/consulting/settingDetail');
    return data;
  };
  const detailData = useQuery(['settingDetail', 1], getPrevData);

  const setPrevData = () => {
    detailData.refetch().then((data: any) => {
      if (data.isSuccess) {
        const s = data.data.data;
        if (Array.isArray(s) && s.length !== 0) {
          const feeVal = s.find((val: { id: string; }) => val.id === 'CONSULTING_FEE');
          const feeVatVal = s.find((val: { id: string; }) => val.id === 'CONSULTING_FEE_VAT');
          const freeYnVal = s.find((val: { id: string; }) => val.id === 'CONSULTING_FREE_YN').value;
          const limitYnVal = s.find((val: { id: string; }) => val.id === 'CONSULTING_LIMIT_YN');
          const limitVal = s.find((val: { id: string; }) => val.id === 'CONSULTING_LIMIT');
          const limitMsgVal = s.find((val: { id: string; }) => val.id === 'CONSULTING_LIMIT_MSG');
          setValue('consultingFeeValue', feeVal === undefined ? '' : feeVal.value);
          setFee(feeVal.value);
          setIsFree(freeYnVal);
          setValue('consultingFeeVatValue', feeVatVal === undefined ? '' : feeVatVal.value);
          setValue('consultingFeeLimitYnValue', limitYnVal === undefined ? 'false' : limitYnVal.value);
          if (getValues('consultingFeeLimitYnValue') === 'false') setDisabled(true);
          setValue('consultingFeeLimitValue', limitVal === undefined ? '' : Number(limitVal.value));
          setValue('consultingFeeLimitMsgValue', limitMsgVal === undefined ? '' : limitMsgVal.value);
          setCount1(limitMsgVal.value.length);
        } else {
          setValue('consultingFeeLimitYnValue', 'false');
          setValue('consultingFeeLimitValue', 10000);
        }
      }
    });
  };

  useEffect(() => {
    setPrevData();
  }, []);

  useEffect(() => {
    setTitle('광고 대행 관리');
    return () => {
      setTitle('');
    };
  }, []);

  const onValid = (error: any) => {
    if (error.title !== undefined) {
      if (error.title.message !== '') {
        alertify.error(error.title.message);
      }
    }
  };

  const onSubmit = () => {
    const fee = $('#fee').val() as string;
    const msg = $('#msg').val() as string;
    const limit = $('#limit').val() as string;
    let body = [];

    if (getValues('consultingFeeLimitYnValue') === 'false') {
      body = [
        {
          id: 'CONSULTING_FEE',
          value: Number(fee.replaceAll(/,/g, '')),
        },
        {
          id: 'CONSULTING_FEE_VAT',
          value: Number(fee.replaceAll(',', '')) * 0.1,
        },
        {
          id: 'CONSULTING_LIMIT_YN',
          value: getValues('consultingFeeLimitYnValue'),
        },
        {
          id: 'CONSULTING_FREE_YN',
          value: isFree,
        },
      ];
    } else {
      body = [
        {
          id: 'CONSULTING_FEE',
          value: Number(fee.replaceAll(/,/g, '')),
        },
        {
          id: 'CONSULTING_FEE_VAT',
          value: Number(fee.replaceAll(',', '')) * 0.1,
        },
        {
          id: 'CONSULTING_LIMIT_YN',
          value: getValues('consultingFeeLimitYnValue'),
        },
        {
          id: 'CONSULTING_LIMIT',
          value: Number(limit.replaceAll(/,/g, '')),
        },
        {
          id: 'CONSULTING_LIMIT_MSG',
          value: msg,
        },
          {
              id: 'CONSULTING_FREE_YN',
              value: isFree,
          },];
    }
    ApiUtil.post<ISystemParamDto>('/api/mngAd/consulting/setting', body).then(() => {
      confirmSuccess('저장되었습니다.');
      navigate('/mngAd/consulting');
    });
  };

    const handleAmountChange = (values: { value: any; }) => {
        const { value } = values;
        if (value < 10) {
            setFee(10); // 입력된 금액이 10원 미만일 경우 자동으로 10원으로 변경
        } else {
            setFee(value); //
        }
    };

  return (
        <Fragment>
            <section className="wrap-section wrap-tbl">
                <div className="box-body">
                    <form onSubmit={handleSubmit(onSubmit, onValid)}>
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                           광고 대행 수수료
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="inner-input-group">
                                                <NumberFormat id="fee" className="tf-comm tf-unit"
                                                              defaultValue={fee}
                                                              allowNegative={false}
                                                              value={fee}
                                                              thousandSeparator={true}
                                                              onValueChange={handleAmountChange}
                                                              onChange={(e: any) => {
                                                                setValue('consultingFeeValue', Number(e.target.value.replaceAll(',', '')));
                                                                setValue('consultingFeeVatValue', Number(e.target.value.replaceAll(',', '')) * 0.1);
                                                              }}
                                                              disabled={isFree === 'true'}
                                                />
                                                <span className="fz-14 fc-1">원</span>
                                            </div>
                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                        </div>
                                        <span className="comp-txt">
                                            <span className="table">
                                                <span className="table-cell">
                                                    <b className="fz-12 fc-3">(VAT 별도)</b>
                                                </span>
                                            </span>
                                        </span>
                                        <div className="comp-checkbox">
                                            <input type="checkbox" id="freeBtn"
                                                   checked={isFree === 'true'}
                                                   onChange={(e)=>{
                                                       setIsFree(String(e.target.checked))
                                                   }}
                                            ></input>
                                            <label htmlFor="freeBtn">무료</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <span className="comp-txt">
                                            <span className="table">
                                                <span className="table-cell">
                                                    <b className="fz-12 fc-3 lh20"><i className="fz-12 fc-5">*</i>변경 이후 등록되는 광고 대행부터 적용됩니다.</b>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                           광고 대행 지연 메시지
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <div className="comp-radio">
                                            <input type="radio" id="inp-radio-01" name="consultingFeeLimitYnValue"
                                                   checked={getValues('consultingFeeLimitYnValue') === 'true'} onChange={() => {
                                                     setValue('consultingFeeLimitYnValue', 'true');
                                                     setDisabled(false);
                                                   }}/>
                                            <label htmlFor="inp-radio-01">사용</label>
                                        </div>
                                        <div className="comp-radio">
                                            <input type="radio" id="inp-radio-02" name="consultingFeeLimitYnValue"
                                                   checked={getValues('consultingFeeLimitYnValue') === 'false'} onChange={() => {
                                                     setValue('consultingFeeLimitYnValue', 'false');
                                                     setDisabled(true);
                                                   }}/>

                                            <label htmlFor="inp-radio-02">미사용</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <section className="wrap-section wrap-tbl">
                                            <div className="box-body">
                                                <div className="tbl">
                                                    <dl>
                                                        <dt>
                                                            <div className="dt-inner">
                                                                <span className="fz-16 fc-1">
                                                                   메시지 노출 조건
                                                                </span>
                                                            </div>
                                                        </dt>
                                                        <dd>
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <div className="inner-input-group">
                                                                        <NumberFormat id="limit"
                                                                                      className="tf-comm tf-unit"
                                                                                      value={consultingFeeLimitValue}
                                                                                      thousandSeparator={true}
                                                                                      disabled={disabled}/>
                                                                        <span className="fz-14 fc-1">개</span>
                                                                    </div>
                                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <p className="comp-txt">
                                                                    <span className="table">
                                                                        <span className="table-cell">
                                                                            <b className="fz-12 fc-3 lh20"><i
                                                                                className="fz-12 fc-5">*</i>담당자 미배정 광고 대행 신청건이 지정 수량 이상이면 메시지가 노출됩니다.</b>
                                                                            <b className="fz-12 fc-3 lh20"><i
                                                                                className="fz-12 fc-5">*</i>종료 및 중단 캠페인은 담당자가 미배정되어 있어도 조건 수량에 포함하지 않습니다.</b>
                                                                        </span>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                    <dl>
                                                        <dt>
                                                            <div className="dt-inner">
                                                                <span className="fz-16 fc-1">
                                                                   노출 메시지
                                                                </span>
                                                            </div>
                                                        </dt>
                                                        <dd>
                                                            <div className="form-group">
                                                                <div className="input-group expand">
                                                                    <div className="inner-input-group">
                                                                        <input type="text" id="msg" className="tf-comm"
                                                                               placeholder="노출 메시지를 입력해 주세요."
                                                                               maxLength={90} {...register('consultingFeeLimitMsgValue')}
                                                                               disabled={disabled}
                                                                               onChange={(e) => {
                                                                                 setCount1(e.target.value.length);
                                                                                 setValue('consultingFeeLimitMsgValue', e.target.value);
                                                                               }}></input>
                                                                    </div>
                                                                    <p className="fz-12 fc-3"><span
                                                                        className="fc-2">{count1}</span> / 90</p>
                                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <p className="comp-txt">
                                                        <span className="table">
                                                            <span className="table-cell">
                                                                <b className="fz-12 fc-3 lh20"><i
                                                                    className="fz-12 fc-5">*</i>메시지 노출 조건이 충족되면 메이커가 광고 대행 신청 페이지에 진입할 때 노출됩니다.</b>
                                                                <b className="fz-12 fc-3 lh20"><i
                                                                    className="fz-12 fc-5">*</i>노출 조건이 충족되지 않으면 미노출됩니다.</b>
                                                            </span>
                                                        </span>
                                                                </p>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </form>
                </div>
                <div className="box-footer">
                    <div className="box-right">
                        <Link to="/mngAd/consulting">
                            <button type="button" className="btn btn-secondary-outline">취소</button>
                        </Link>
                        <button type="submit" className="btn btn-primary" onClick={onSubmit}>저장</button>
                    </div>
                </div>
            </section>
        </Fragment>
  );
};

export default ConsultingSetting;
