import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Tooltip } from 'rc-easyui';
import BzmTab from './BzmTab';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import { IMultiSelect, multiSelectHandler, toMultiSelect } from '../accAdjust/AccAdjustTargetUtil';
import RangeDatePicker from '../accAdjust/RangeDatePicker';

interface IMngRefundSearchForm {
    reqStartDate:string;
    reqEndDate:string;
    makerStatus:string | undefined;
    refundStatus:string | undefined;
    searchType: string;
    keyword?: string;
    refundAccountYn:string;
}
interface IMngRefund {
    data:any;
    makerId:number;
    makerNm:string;
    accRefundId:number;
    makerStatus:string;
    refundStatus:string;
    refundAccountYn:boolean;
    bizDiv:string;
    userNo:number;
    sidNo:number;
    refundCostCard:number;
    refundCostAtrt:number;
    refundCostAtam:number;
    refundCostAdjust:number;
    refundCostMng:number;
    reqTime:string;
    updateTime:string;
    updateAdmin:string;
    refundComment:string;
}
const makerStatusData = {
  all: { text: '전체', selected: true },
  NM: { text: '정상', selected: true },
  IA: { text: '휴면', selected: true },
  DO: { text: '탈퇴', selected: true },
};
const refundStatusData = {
  all: { text: '전체', selected: false },
  req: { text: '환불 신청', selected: true },
  ing: { text: '환불 진행중', selected: true },
  rejected: { text: '환불 거절', selected: false },
  ok: { text: '환불 완료', selected: false },
};
// 렌더러 모음
const refundCostCardRenderer = (props: ICellRendererParams) => (props.data.refundCostCard.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
const refundCostAtrtRenderer = (props: ICellRendererParams) => (props.data.refundCostAtrt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
const refundCostMngRenderer = (props: ICellRendererParams) => (props.data.refundCostMng.toLocaleString());
const refundAcountYnRenderer = (props: ICellRendererParams) => (props.data.refundAccountYn === true ? '등록' : '미등록');
const userNoRenderer = (props: ICellRendererParams) => (props.data.bizDiv === 'user' ? props.data.sidNo : props.data.userNo);
const reqTimeRenderer = (props: ICellRendererParams) => (dayjs(props.data.reqTime).format('YYYY-MM-DD HH:mm'));
const updateTimeRenderer = (props: ICellRendererParams) => (dayjs(props.data.updateTime).format('YYYY-MM-DD HH:mm'));
const makerDetailRenderer = (props: ICellRendererParams) => {
  const { accRefundId } = props.data;
  return <Link className="txt-link" to={`/bzmAdmin/mng/detail/${accRefundId}`}>{props.data.makerLoginId}</Link>;
};
const refundStatusDescRenderer = (props: ICellRendererParams) => {
  const { refundStatusDesc } = props.data;
  if (refundStatusDesc === '환불 거절') {
    return <Tooltip tooltipCls={'ui-corner-all ui-widget-shadow'} content={<p className="fz-12 fc-0">{props.data.refundComment}</p>}><p className ='txt-tooltip'>{refundStatusDesc}</p></Tooltip>;
  } return refundStatusDesc;
};

const BzmMng = () => {
  const setTitle = useOutletContext<any>();
  // const [statusList, setStatusList] = useState<string[][]>([]);
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<IMngRefundSearchForm>();

  const [makerStatus, setMakerStatus] = useState<IMultiSelect>(toMultiSelect(makerStatusData));
  const [makerStatusLabel, setMakerStatusLabel] = useState<string>('전체');
  const updateMakerStatusForm = (value: string) => setValue('makerStatus', value);
  const updateMakerStatusLabel = (label: string) => setMakerStatusLabel(label);
  const updateMakerStatus = (newState: Map<string, boolean>) => setMakerStatus({ ...makerStatus, selects: newState });

  const [refundStatus, setRefundStatus] = useState<IMultiSelect>(toMultiSelect(refundStatusData));
  const [refundStatusLabel, setRefundStatusLabel] = useState<string>('환불 신청 외 1');
  const updateRefundStatusForm = (value: string) => setValue('refundStatus', value);
  const updateRefundStatusLabel = (label: string) => setRefundStatusLabel(label);
  const updateRefundStatus = (newState: Map<string, boolean>) => setRefundStatus({ ...refundStatus, selects: newState });
  const MakerStatusSelectBox = () => (
        <div className="dropdown-menu expand">
            <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                {
                    Array.from(makerStatus.texts, ([key, value]) => ({ key, value }))
                      .map(({ key, value }) => {
                        const checkboxId = `ms-cb-${key}`;
                        return <li className="opt-menu" key={key}>
                                <div className="comp-checkbox small">
                                    <input name="makerStatus" type="checkbox"
                                           id={checkboxId}
                                           onChange={() => 0}
                                           onClick={(e: any) => { multiSelectHandler(key, e.target.checked, makerStatus, updateMakerStatus, updateMakerStatusLabel, updateMakerStatusForm); }}
                                           checked={makerStatus.selects.get(key) === true}/>
                                    <label htmlFor={checkboxId}>{value}</label>
                                </div>
                            </li>;
                      })
                }
            </ul>
        </div>
  );
  const RefundStatusSelectBox = () => (
        <div className="dropdown-menu expand">
            <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                {
                    Array.from(refundStatus.texts, ([key, value]) => ({ key, value }))
                      .map(({ key, value }) => {
                        const checkboxId = `rs-cb-${key}`;
                        return <li className="opt-menu" key={key}>
                                <div className="comp-checkbox small">
                                    <input name="refundStatus" type="checkbox"
                                           id={checkboxId}
                                           onChange={() => 0}
                                           onClick={(e: any) => { multiSelectHandler(key, e.target.checked, refundStatus, updateRefundStatus, updateRefundStatusLabel, updateRefundStatusForm); }}
                                           checked={refundStatus.selects.get(key) === true}/>
                                    <label htmlFor={checkboxId}>{value}</label>
                                </div>
                            </li>;
                      })
                }
            </ul>
        </div>
  );

  const onGridReady = useCallback(() => {
    const data : IMngRefundSearchForm = getValues();
    ApiUtil2.get<IMngRefund>('/api/bzmAdmin/mng/refundList', {
      params: {
        data: { ...data },
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
    // 데이터그리드 관련
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'makerLoginId',
      headerName: '메이커ID',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: makerDetailRenderer,
      minWidth: 250,
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'makerStatusDesc',
      headerName: '계정 상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'userNo',
      headerName: '충전 사업자 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: userNoRenderer,
    },
    {
      field: 'refundAccountYn',
      headerName: '환불 계좌 등록 여부',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: refundAcountYnRenderer,
    },
    {
      field: 'refundStatusDesc',
      headerName: '환불상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: refundStatusDescRenderer,
    },
    {
      field: 'refundCostCard',
      headerName: '환불 신청 금액 (카드)',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: refundCostCardRenderer,
    },
    {
      field: 'refundCostAtrt',
      headerName: '환불 신청 금액 (실시간)',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: refundCostAtrtRenderer,
    },
    {
      field: 'refundCostMng',
      headerName: '환불 신청 금액 (관리자)',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: refundCostMngRenderer,
    },
    {
      field: 'reqTime',
      headerName: '환불 요청 일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: reqTimeRenderer,
    },
    {
      field: 'updateTime',
      headerName: '최근 환불 상태 변경 일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: updateTimeRenderer,
    },
    {
      field: 'updateId',
      headerName: '수정자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const registerReqDate = (start:Dayjs, end:Dayjs) => {
    const startDate = start.format('YYYYMMDD');
    const endDate = end.format('YYYYMMDD');
    setValue('reqStartDate', startDate);
    setValue('reqEndDate', endDate);
  };
  useEffect(() => {
    setTitle('비즈머니 관리');
    return () => {
      setTitle('');
    };
  }, []);
  useEffect(() => {
    // 입금 상태
    $('#refundAccountYnSelect').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      setValue('refundAccountYn', e.params.data.id);
    });

    // 검색 구분
    $('#srchType').select2({
      width: '150',
      placeholder: '선택',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      setValue('searchType', e.params.data.id);
    });

    // 환불 상태 초기값
    setValue('refundStatus', 'req,ing');
  }, []);
  useEffect(() => () => {
    $('.tooltip').remove();
  });
  return (
    <Fragment>
      <BzmTab />
      <section className="wrap-section wrap-datagrid">
        <form onSubmit={handleSubmit(onGridReady)}>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">환불 요청 기간</p>
                  </div>
                  <div className="box-filter">
                    <RangeDatePicker
                      changeEvent={registerReqDate}
                      option={{ startDate: dayjs().startOf('day').subtract(60, 'd') }}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">계정 상태</p>
                  </div>
                  <div className="box-filter">
                    <div id="makerStatus" className="comp-dropdown select2 w-150 selected">
                      <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                        <div className="box-left">
                          <span className="fz-14">{makerStatusLabel}</span>
                        </div>
                        <div className="box-right">
                          <i className="ico ico-arrow"></i>
                        </div>
                      </a>
                      <MakerStatusSelectBox />
                    </div>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">환불계좌 등록 여부</p>
                  </div>
                  <div className="box-filter">
                    <select
                      className="select2-single w-150"
                      id="refundAccountYnSelect"
                      {...register('refundAccountYn')}
                    >
                      <option value="" defaultChecked={true}>
                        전체
                      </option>
                      <option value="true">등록</option>
                      <option value="false">미등록</option>
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">환불 상태</p>
                  </div>
                  <div className="box-filter">
                    <div id="makerStatus" className="comp-dropdown select2 w-150 selected">
                      <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                        <div className="box-left">
                          <span className="fz-14">{refundStatusLabel}</span>
                        </div>
                        <div className="box-right">
                          <i className="ico ico-arrow"></i>
                        </div>
                      </a>
                      <RefundStatusSelectBox />
                    </div>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색 구분</p>
                  </div>
                  <div className="box-filter">
                    <select className="select2-single w-150" id="srchType" {...register('searchType')}>
                      <option value="MAKER_ID" defaultChecked={true}>
                        메이커ID
                      </option>
                      <option value="MAKER_NM">메이커명</option>
                      <option value="USER_NO">충전 사업자 번호</option>
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          type="text"
                          className="tf-comm"
                          placeholder="검색어를 입력해 주세요."
                          {...register('keyword')}
                        ></input>
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  <i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                suppressRowClickSelection={true}
                rowSelection={'multiple'}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
    </Fragment>
  )
};
export default BzmMng;
