import React, {
    ChangeEvent,
    forwardRef,
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import {Link} from 'react-router-dom';
import { useForm } from 'react-hook-form'
import {useMutation, useQuery} from '@tanstack/react-query' // try next react-query;
import alertify from 'alertifyjs'
import { ApiUtil2, ApiUtil3, uploadUrl } from '@biz/api'
import { CampIdContext, GdnCreationDataIdContext, GdnCreationFileUploadIdContext } from '../camp/make02/Make02Empty'
import { FileValidationCreationFb } from './FileFbCreationValidator'
import { reloadCountContext } from '../camp/make02/Make02Step03Fb'
import CreationGifSelectDialog from '../camp/make02/CreationGifSelectDialog'
import { ratioCheck } from './FileFbUtil'

interface IFileTypes {
  uploadId: number
  fileId: number
  publicUrl: string
  afName: string
  fileName: string
  afMeta: any
  fileDiv: string
  gifDiv: string
  width: number
  height: number
  fileSize: number
  object: File
}

interface uploadFileForm {
  ok: boolean
  creationDataId: any
  campId: any
  uploadFile: IFileTypes | any
}

export interface uploadFiles {
  ok: boolean
  data: IFileTypes[] | any
}

let uploadId: number
let submitFile: any = []
const FileUploadFbCreation = (props: any, ref: any) => {
  const [imageUrl, setImageUrl] = useState<any>()
  const [uploadFileId, setUploadFileId] = useState<any>()
  const [afId, setAfId] = useState<any>(null)
  const [gifDiv, setGifDiv] = useState<string>('vod')
  const [gifFile, setGifFile] = useState<any>()
  const [fileUrl, setFileUrl] = useState()
  const [selectedYn, setSelectedYn] = useState<boolean>(false)
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const [ext, setExt] = useState<string>('')
  const [creationFileDiv, setCreationFileDiv] = useState<string>('IMG')
  const { gdnFileUploadId } = useContext(GdnCreationFileUploadIdContext)
  const { campIdParam } = useContext(CampIdContext)
  const { gdnCreationDataId } = useContext(GdnCreationDataIdContext)
  const { setReloadCount } = useContext(reloadCountContext)
  const { setValue, getValues } = useForm<uploadFileForm>()
  const fbCreationUploadFileForm = useForm<IFileTypes>()

  const fbCreationDataFileSearchApi = async () => {
    const { data } = await ApiUtil2.get('/api/camp/make02/fbCreationDataFileSearch', {
      params: { creationDataId: gdnCreationDataId },
    })
    return data
  }

  const fbCreationDataFileSearch = useQuery(['fbCreationDataFileSearchApi'], fbCreationDataFileSearchApi, {
    enabled: false,
  })
  // 파일 등록 처리
  const fbCreationDataFileInsertApi = () => {
    const { uploadFile, campId } = getValues()
    const result = ApiUtil2.post<uploadFileForm>('/api/camp/make02/fbCreationDataFileInsert', {
      creationDataId: gdnCreationDataId,
      uploadFile,
      campId,
    })
    return result
  }
  const fbCreationDataFileInsert = useMutation(fbCreationDataFileInsertApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 컴포넌트를 리로딩 하기 위한 값 세팅
        setReloadCount(1)
      }
    },
  })
  // 미리보기 파일 등록 처리
  const fbCreationDataFilePreviewInsertApi = () => {
    const result = ApiUtil2.post<uploadFileForm>('/api/camp/make02/fbCreationDataFilePreviewInsert', {
      afId,
      campId: campIdParam,
      creationDataId: gdnCreationDataId,
      gifDiv,
    })
    return result
  }
  const fbCreationDataFilePreviewInsert = useMutation(fbCreationDataFilePreviewInsertApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 컴포넌트를 리로딩 하기 위한 값 세팅
        setReloadCount(1)
      }
    },
  })

  const uploadInit = () => {
    $('#fbCreationFileUpload').val('')
    setImageUrl(null)
    setAfId(null)
    submitFile = []
  }
  // 여기서 최종 데이터 등록
  const creationFileUpload = (
    file: any,
    fileDiv: string,
    gifFileDiv: string,
    width: number,
    height: number,
    fileSize: number
  ) => {
    if (uploadId !== null) {
      ApiUtil3.post('/api/file/success', { uploadId, fileId: uploadFileId }).then((successRes) => {
        if (successRes.status === 200) {
          fbCreationUploadFileForm.setValue('uploadId', uploadId)
          fbCreationUploadFileForm.setValue('fileId', uploadFileId)
          fbCreationUploadFileForm.setValue('publicUrl', imageUrl)
          fbCreationUploadFileForm.setValue('afName', file.name)
          fbCreationUploadFileForm.setValue('fileName', file.name)
          fbCreationUploadFileForm.setValue('fileDiv', fileDiv)
          fbCreationUploadFileForm.setValue('width', width)
          fbCreationUploadFileForm.setValue('height', height)
          fbCreationUploadFileForm.setValue('fileSize', fileSize)
          fbCreationUploadFileForm.setValue('gifDiv', gifFileDiv)
          setValue('creationDataId', gdnCreationDataId)
          setValue('uploadFile', fbCreationUploadFileForm.getValues())
          setValue('campId', campIdParam)
          fbCreationDataFileInsert.mutate()
        }
      })
    }
  }
  const fileUploadFirstStep02 = (file: any) => {
    ApiUtil3.post('/api/file/uploadReq', {
      uploadId,
      originFileName: file.name,
      myS3Upload: 'creationFb',
    }).then((res) => {
      if (res.status === 200) {
        const { presignUrl, publicUrl, fileId } = res.data.data
        const commonLoader = document.querySelector('.loader.file2')
        if (commonLoader !== null) commonLoader.classList.remove('none')
        uploadUrl(presignUrl, file)
          .then((uploadRes) => {
            if (uploadRes.status === 200) {
              setImageUrl(publicUrl)
              setCreationFileDiv(file.type === 'video/mp4' || file.type === 'video/quicktime' ? 'VIDEO' : 'IMG')
              setUploadFileId(fileId)
              submitFile = file
            }
          })
          .finally(() => {
            if (commonLoader !== null) commonLoader.classList.add('none')
          })
      }
    })
  }
  const fileUploadFirstStep01 = (file: any) => {
    if (file.type === 'image/gif') {
      $('#creationGifSelectDialog').dialog({
        width: 600,
        title: '업로드하고자 하는 소재의 유형을 선택해주세요.',
        closeOnEscape: true,
      })
      $('#creationGifSelectDialog').dialog('open')
      setGifFile(file)
      // fileUploadFirstStep02(file);
    } else {
      fileUploadFirstStep02(file)
    }
  }
  const onChangeFiles = useCallback((e: ChangeEvent<HTMLInputElement> | any): void => {
    let selectFiles: File[] = []
    if (e.type === 'drop') {
      selectFiles = e.dataTransfer.files
      if (selectFiles.length >= 10) {
        alertify.error('슬라이드의 경우, 10장 이내의 소재만 등록 가능합니다.')
        selectFiles = []
        return
      }
    } else {
      selectFiles = e.target.files
      if (selectFiles.length >= 10) {
        alertify.error('슬라이드의 경우, 10장 이내의 소재만 등록 가능합니다.')
        selectFiles = []
        return
      }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const file of selectFiles) {
      setImageUrl(null)
      setAfId(null)
      fileUploadFirstStep01(file)
    }
  }, [])
  // 미리보기 이미지 클릭시 셋팅
  const previewFileSelect = (
    selectAfFileId: any,
    selectFileUrl: any,
    selectFileDiv: any,
    afname: string,
    width: number,
    height: number
  ) => {
    const fileExt = afname.substring(afname.lastIndexOf('.') + 1, afname.length).toLowerCase()
    setExt(fileExt)

    if (fileExt === 'gif') {
      uploadInit()
      setFileUrl(selectFileUrl)
      setCreationFileDiv(selectFileDiv)
      setSelectedYn(true)
      setAfId(selectAfFileId)
      // 기등록된 이미지 정보
      setWidth(width)
      setHeight(height)
      $('#creationGifSelectDialog').dialog({
        width: 600,
        title: '업로드하고자 하는 소재의 유형을 선택해주세요.',
        closeOnEscape: true,
      })
      $('#creationGifSelectDialog').dialog('open')
    } else {
      uploadInit()
      setImageUrl(selectFileUrl)
      setCreationFileDiv(selectFileDiv)
      setAfId(selectAfFileId)
      // 기등록된 이미지 정보
      setWidth(width)
      setHeight(height)
    }
  }
  const currentCreationFileCnt = (callback: any) => {
    fbCreationDataFileSearch.refetch().then((r) => {
      if (r.isSuccess) {
        // setCurrentFileRegCnt(r.data.data.length);
        callback(r.data.data.length)
      }
    })
  }

  const fileCheckUpload = () => {
    // 기존 작업. 주석처리함.
    if (imageUrl !== null && afId === null) {
      FileValidationCreationFb(
        submitFile,
        gifDiv,
        (finalCheck: boolean, file: any, fileDiv: string, width: number, height: number, fileSize: number) => {
          if (finalCheck) {
            // 현재 등록된 파일 개수 체크
            currentCreationFileCnt((fileCnt: any) => {
              if (fileCnt >= 10) {
                alertify.error('슬라이드의 경우, 10장 이내의 소재만 등록 가능합니다.')
                return
              }

              if (fileCnt >= 1 && width / height !== 1 / 1) {
                alertify.error('슬라이드의 경우 1:1 규격의 이미지/영상 첨부만 가능합니다.')
                return
              }
              creationFileUpload(file, fileDiv, gifDiv, width, height, fileSize)
              uploadInit()
              $('#creationFileUploadFbDialog').dialog('close')
              // $('.dialog').dialog('destroy');
            })
          }
        }
      )
    } else if (imageUrl !== null && afId !== null) {
      // 기등록된 소재 이미지/동영상 비율이 1:1 or 4/5 인지 판별
      const ratio = Math.round((width / height) * 100) / 100
      ratioCheck(ratio)
      // console.log(`ratid : ${ratio}`);
      // const validRatios = [1/1, 4/5, 1.91, 1.77];
      // const tRatio1:number = 1.91;
      // const tRatio2:number = 1.77;
      // console.log( Math.abs(ratio - tRatio2));
      // if(!validRatios.includes(ratio) && (
      //     Math.abs(ratio - tRatio1) > 0.03 || Math.abs(ratio - tRatio2) > 0.03
      // )){
      //     alertify.error('1:1, 4:5, 1.91:1, 1.77:1 규격의 이미지/영상 첨부만 가능합니다.');
      //     return;
      // }

      // 현재 등록된 파일 개수 체크
      currentCreationFileCnt((fileCnt: any) => {
        if (fileCnt >= 10) {
          alertify.error('슬라이드의 경우, 10장 이내의 소재만 등록 가능합니다.')
          return
        }

        if (fileCnt >= 1 && width / height !== 1 / 1) {
          alertify.error('슬라이드의 경우 1:1 규격의 이미지/영상 첨부만 가능합니다.')
          return
        }

        fbCreationDataFilePreviewInsert.mutate()
        uploadInit()
        $('#creationFileUploadFbDialog').dialog('close')
        // $('.dialog').dialog('destroy');
      })
    } else {
      alertify.error('선택된 이미지 파일이 없습니다.')
    }
  }
  // 부모컴포넌트에서 자식 컴포넌트의 함수를 실행하기 위한 forwardRef와 useImperativeHandle을 사용
  useImperativeHandle(ref, () => ({
    uploadInit,
    fileCheckUpload,
    previewFileSelect,
  }))

  useEffect(() => {
    // 이미지 보여주기 초기화
    if (imageUrl !== undefined && imageUrl !== null) {
      $('#emptyImage').hide()
      $('#showImage').show()
    } else {
      $('#emptyImage').show()
      $('#showImage').hide()
    }
  }, [imageUrl])
  useEffect(() => {
    uploadInit()
    if (gdnFileUploadId !== null) {
      uploadId = gdnFileUploadId
      $('#fbCreationFileUpload').on('change', onChangeFiles)
    }
  }, [gdnFileUploadId])
  return (
    <Fragment>
      <CreationGifSelectDialog
        setGifDiv={setGifDiv}
        fileUploadFirstStep02={fileUploadFirstStep02}
        gifFile={gifFile}
        setImageUrl={setImageUrl}
        fileUrl={fileUrl}
        selectedYn={selectedYn}
      />
      <div className="form-group" id="emptyImage">
        <div className="comp-thumb expand">
          <div className="comp-no-data">
            <div className="box-group">
              <i className="ico ico-no-img"></i>
              <p className="fz-18 fc-2">
                이미지 혹은 영상을 선택해주세요.<Link to="#!" className="ico-tooltip tooltip-f" title=""></Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group" id="showImage" style={{ display: 'none' }}>
        <div className="comp-thumb expand">
          {creationFileDiv === 'VIDEO' && ext !== 'gif' ? (
            <video
              autoPlay
              controls
              muted
              loop
              // style={{width: '100%'}}
              src={`${imageUrl}`}
            ></video>
          ) : (
            <img
              src={`${imageUrl}`}
              // style={{ width: '100%' }}
              alt=""
            />
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="comp-file-upload expand">
          <input
            type="file"
            id="fbCreationFileUpload"
            accept="image/jpeg, image/jpg, image/png,image/gif,video/mp4, video/quicktime"
          />
          <i className="ico ico-file"></i>
          <input type="text" className="tf-comm" placeholder="파일을 선택하세요." readOnly={true} />
          <label className="btn" htmlFor="fbCreationFileUpload">
            파일 선택
          </label>
        </div>
      </div>
    </Fragment>
  )
}

export default forwardRef(FileUploadFbCreation);
