import { Fragment, useEffect, useRef, useState } from 'react'
import alertify from 'alertifyjs'
import { Dialog } from 'rc-easyui'
import { ApiUtil2 } from '@biz/api'

export interface ICreationDataInspMemo {
  data: any
  creationDataInspMemoId: number
  campId: number
  creationDataId: number
  content: any
}

const CreationDataInspMemoDialog = (props: any) => {
  const dialogRef = useRef<any>()
  const [content, setContent] = useState<string>('')
  const [contentCnt, setContentCnt] = useState<number>(0)
  const creationDataInspMemoRef = useRef<HTMLTextAreaElement>(null)
  const creationDataInspMemoIdRef = useRef<number>(0)

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  const successEvent = (msg: string) => {
    alertify.success(msg, 1)
  }

  const confirmSuccess = (msg: string) => {
    successEvent(msg)
  }

  useEffect(() => {
    ApiUtil2.get<ICreationDataInspMemo>('/api/mngAd/creationInspMemo/detail', {
      params: {
        data: {
          campId: props.campId,
          creationDataId: props.creationDataId,
        },
      },
    }).then((resp) => {
      let result = resp.data.data
      if (result !== undefined) {
        const length = result.content?.length ?? 0
        setContent(result.content)
        setContentCnt(length)
        creationDataInspMemoIdRef.current = result.creationDataInspMemoId
      }
    })

    $(document).on('click', '#cancelBtn', () => {
      props.setIsOpenedInspMemoDialog(false)
      $('#creationDataInspMemoDialog').dialog('close')
      return () => {
        // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
        $('#creationDataInspMemoDialog').dialog('destroy')
      }
    })

    $('#regBtn').on('click', () => {
      if (creationDataInspMemoRef.current?.value.length === 0) {
        confirmError('메모 내용을 기입해 주세요.')
        return
      } else {
        // console.log(creationDataInspMemoIdRef.current)
        ApiUtil2.post<ICreationDataInspMemo>('/api/mngAd/creationInspMemo/save', {
          creationDataInspMemoId: creationDataInspMemoIdRef.current === 0 ? null : creationDataInspMemoIdRef.current,
          campId: props.campId,
          creationDataId: Number(props.creationDataId),
          content: creationDataInspMemoRef.current?.value,
        }).then((resp) => {
          const { data } = resp.data
          creationDataInspMemoIdRef.current = data
          confirmSuccess('정상적으로 저장 되었습니다.')
          props.setIsOpenedInspMemoDialog(false)
        })
      }
    })

    /** 글자수 제한 10000자*/
    $('#content').on('keyup', () => {
      const value = $('#content').val() as string
      setContentCnt(value.length)
      if (value.length > 10000) {
        $('#content').val(value.substring(0, 10000))
      }
    })
  }, [])

  return (
    <Fragment>
      <form>
        <Dialog
          id="creationDataInspMemoDialog"
          modal
          resizable
          shadow={false}
          ref={dialogRef}
          style={{ width: '900px' }}
          title="메모"
        >
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">캠페인 및 소재 정보</h2>
                            </div>
                          </div>
                          <dl className="column-two">
                            <dt>
                              <div className="dt-inner">
                                <span className="fz-16 fc-1">캠페인 ID</span>
                              </div>
                            </dt>
                            <dd>
                              <div className="form-group">
                                <div className="input-group expand">
                                  <div className="inner-input-group">
                                    <input type="text" className="tf-comm" disabled value={props.campId} />
                                  </div>
                                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                </div>
                              </div>
                            </dd>
                            <dt>
                              <div className="dt-inner">
                                <span className="fz-16 fc-1">소재 ID</span>
                              </div>
                            </dt>
                            <dd>
                              <div className="form-group">
                                <div className="input-group expand">
                                  <div className="inner-input-group">
                                    <input type="text" className="tf-comm" disabled value={props.creationDataId} />
                                  </div>
                                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                </div>
                              </div>
                            </dd>
                          </dl>
                        </div>
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">메모내용</h2>
                            </div>
                          </div>
                          <div className="box-body">
                            <div className="tbl">
                              <dl className="vertical">
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <textarea
                                          id="content"
                                          className="tf-textarea"
                                          placeholder="메모를 입력해 주세요."
                                          maxLength={10000}
                                          defaultValue={content}
                                          onChange={(e) => {
                                            setContentCnt(e.target.value.length)
                                          }}
                                          ref={creationDataInspMemoRef}
                                        ></textarea>
                                      </div>
                                      <p className="fz-12 fc-3">
                                        <span className="fc-2">{contentCnt}</span> / 10000
                                      </p>
                                      <p className="fz-12">
                                        <i className="ico"></i>체크 / 에러 문구 내용 영역
                                      </p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-primary-outline" id={'cancelBtn'}>
              취소
            </button>
            <button type="button" className="btn btn-primary" id={'regBtn'}>
              저장
            </button>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}

export default CreationDataInspMemoDialog
