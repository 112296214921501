import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, GridSizeChangedEvent, ICellRendererParams,} from 'ag-grid-community';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {useOutletContext} from 'react-router';
import Pagination from '../common/Pagination';
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil } from '@biz/api'
import {GridCustomHeader} from '../common/GridCustomHeader';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import DlDialog from "../mngMakerAccount/DlDialog";

interface ITargetCost {
    bzmIn: string;
    bzmOut: string;
}

interface IPubHis {
    pubTargetCnt: string;
    pubCost: string;
}

interface INonPubHis {
    nonPubTargetCnt: string;
    nonPubCost: string;
}

interface ITaxbillDefData {
    bzmIn: string;
    bzmOut: string;
    pubTargetCnt: string;
    pubCost: string;
    nonPubTargetCnt: string;
    nonPubCost: string;
}
interface ITaxbill {
    adjustTargetId: number
    approvalStatus: string
    bizDiv: string
    bzmIn: number
    bzmInTotal: number
    bzmInVat: number
    bzmOut: number
    bzmOutTotal: number
    bzmOutVat: number
    campId: number
    campNm: string
    comEmail: string
    comNm: string
    comRepNm: string
    declareStatus: string
    declareStatusCode: string
    items : string[]
    makerId: string
    taxbillCancellable: boolean
    taxbillDiv: string
    taxbillDocId: string
    taxbillId : number
    taxbillStatus: string
    updateId: string
    updateTime: string
    userNo : string
    errMsg: string
    desc: string
}

interface ITaxbillDefDataApi {
    ok: boolean;
    data: ITaxbillDefData;
}

export interface ISearch {
    bizDiv: string;
    billPubStatus: string; //국세청 발행 상태코드
    taxbillStatus: string; //발행상태
    taxbillDiv: string;
    searchDiv: string;
    searchTxt: string;
    api: { sizeColumnsToFit: () => void; };
}

const makerDetailRenderer = (props: ICellRendererParams) => {
  const {
    taxbillId, taxbillDiv, adjustTargetId,
  } = props.data;
  if (taxbillDiv !== '임의조정') {
    return <Link className="txt-link" to={`/mngTaxBill/mngTaxBill/detail/${taxbillId}`}>{props.value}</Link>;
  }
  return <Link className="txt-link" to={`/mngTaxBill/mngTaxBill/adjustDetail?id=${adjustTargetId}&prev=taxbill`}>{props.value}</Link>;
};
const userNoDetailRenderer = (props: ICellRendererParams) => {
  const {
    taxbillId, taxbillDiv, adjustTargetId,
  } = props.data;
  if (taxbillDiv !== '임의조정') {
    return <Link className="txt-link" to={`/mngTaxBill/mngTaxBill/detail/${taxbillId}`}>{props.value}</Link>;
  }
  return <Link className="txt-link" to={`/mngTaxBill/mngTaxBill/adjustDetail?id=${adjustTargetId}&prev=taxbill`}>{props.value}</Link>;
};


const DeclareStatusCodeMap: {
    id: string; text: string; }[] =
    [
        { id: 'SUC001', text: '성공' },
        { id: 'SYN001', text: '복호화 실패' },
        { id: 'SYN002', text: '공급사업자, 수탁자 전자서명 오류' },
        { id: 'SYN003', text: '승인번호 중복 오류' },
        { id: 'SYN004', text: '전자세금계산서 스키마 오류' },
        { id: 'ERR001', text: '공급자 사업자등록번호 오류' },
        { id: 'ERR002', text: '공급받는(사업)자 사업자등록번호 오류' },
        { id: 'ERR003', text: '수탁 사업자등록번호 오류' },
        { id: 'ERR004', text: '전송일시 오류' },
        { id: 'ERR005', text: '발행(교부) 일시 오류' },
        { id: 'ERR006', text: '작성일자 오류' },
        { id: 'ERR007', text: '공급가액, 세액 오류' },
        { id: 'ERR008', text: '코드 유형 오류' },
        { id: 'ERR009', text: '폐업사업자 발행 오류' },
        { id: 'ERR010', text: '국세청 등록번호 오류' },
        { id: 'ERR011', text: '당초승인번호 오류' },
        { id: 'ERR999', text: '정의되지 않은 기타오류' },
        { id: 'SYS001', text: '연계사업자 인증 오류' },
        { id: 'SYS002', text: '중복된 SubmitID(문서번호)' },
        { id: 'SYS003', text: '존재하지 않는 SubmitID(문서번호)' },
        { id: 'WSM001', text: 'SOAP Message 통신 오류' },
        { id: 'WSM002', text: 'SOAP Message 구조 오류' },
        { id: 'WSM003', text: 'SOAP Message 전자서명 오류' },
        { id: '-', text: 'API 404오류로 정상처리되지 않았습니다. <br/>하이웍스에 접속하시어 확인 부탁 드립니다.' },
    ];
function getTextById(id: string): string | undefined {
    const foundStatus = DeclareStatusCodeMap.find(status => status.id === id);
    return foundStatus?.text;
}

const declareStatusRenderer = (props: ICellRendererParams) => {
  const { approvalStatus } = props.data;
  const { declareStatus } = props.data;
  const { declareStatusCode } = props.data;
    props.data.errMsg = getTextById(props.data.declareStatusCode) as string;
    if(declareStatusCode !== 'SUC001') {
        customTooltip2(props)
    }
  return declareStatus === '4' ? '완료' :  declareStatus === '3' || declareStatus === '5' || approvalStatus === 'E' ? '실패' : '대기'
};

const taxbillStatusRenderer = (props: ICellRendererParams) => {
    customTooltip2(props)
    return props.data.taxbillStatus
};

export const customTooltip2 = (props: ICellRendererParams) => {
    const data = useMemo(
        () => props.api.getDisplayedRowAtIndex(props.rowIndex!)!.data,
        [],
    );

    if(props.colDef?.field === 'taxbillStatus'){
        if (data.taxbillStatus === '발행') {
            return <div></div>;
        }else if(data.taxbillStatus === '실패'){
            return (
                <div className="ag-grid-tooltip">
                    <p className="fz-12 fw-medium fc-0" dangerouslySetInnerHTML={{__html : data.errMsg}}></p>
                </div>
            )
        }
    }

    if(props.colDef?.field === 'declareStatus'){
        if(data.taxbillStatus === '발행' && data.declareStatusCode !== ('SUC001' || '-')) {
            let textById = getTextById(props.data.declareStatusCode) as string;
            return (
                <div className="ag-grid-tooltip">
                    <p className="fz-12 fw-medium fc-0" dangerouslySetInnerHTML={{__html : textById}}></p>
                </div>
            );
        }
    }
      // return (
      //         <div className="ag-grid-tooltip">
      //             <p className="fz-12 fw-medium fc-0">
      //                 {data.errMsg}
      //             </p>
      //         </div>
      //       );
};
const MngTaxbill = () => {
  const setTitle = useOutletContext<any>();
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
    // tooltipComponent: TaxbillTooltip,
    tooltipComponent: customTooltip2,
  }), []);
  const gridRef: any = useRef(null); // useRef 에러 수정(초기화)
  const childRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any[]>();
  const columnDefs = [
    {
      headerName: '발행대상 정보 및 발행상태',
      children: [
        {
          field: 'makerId',
          headerName: '메이커ID',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          cellRenderer: makerDetailRenderer,
          width: 500,
        },
        {
          field: 'userNo',
          headerComponent: GridCustomHeader,
          headerComponentParams: { header1: '사업자번호', header2: '/주민번호' },
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
          cellRenderer: userNoDetailRenderer,
          width: 300,
        },
        {
          field: 'bizDiv',
          headerName: '구분',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
        },
        {
          field: 'taxbillStatus',
          headerName: '발행상태',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
          tooltipField: 'taxbillStatus',
          tooltipComponentParams: { color: '#ececec' },
          cellRenderer: taxbillStatusRenderer,
        },
        {
          field: 'declareStatus',
          headerName: '국세청 발행상태',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
          tooltipField: 'declareStatus',
          tooltipComponentParams: { color: '#ececec' },
          width: 300,
          cellRenderer: declareStatusRenderer,
        },
        {
          field: 'taxbillDiv',
          headerName: '종류',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
        },
        {
          field: 'comNm',
          headerName: '상호',
          cellClass: 'ag-left-aligned-cell',
        },
        {
          field: 'comRepNm',
          headerComponent: GridCustomHeader,
          headerComponentParams: { header1: '대표자명', header2: '/개인 성명' },
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
        },
        {
          field: 'comEmail',
          headerName: '이메일',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
          width: 400,
        },
        {
          field: 'campNm',
          headerName: '캠페인 명',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
        },
        {
          field: 'campId',
          headerName: '캠페인 번호',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (props!.data.taxbillDiv === 'SNS캠페인'  ?  props!.data.campId : '-'),
        },
      ],
    },
    {
      headerName: '광고 서비스 이용 비용',
      children: [
        {
          field: 'bzmOut',
          headerName: '광고 소진액',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (props!.data.bzmOut === 0 ? '-' : (String(props!.data.bzmOut).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))),
        },
        {
          field: 'bzmOutVat',
          headerName: '부가세',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (props!.data.bzmOutVat === 0 ? '-' : (String(props!.data.bzmOutVat).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))),
        },
        {
          field: 'bzmOutTotal',
          headerName: '합계',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (props!.data.bzmOutTotal === 0 ? '-' : (String(props!.data.bzmOutTotal).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))),
        },
      ],
    },
    {
      headerName: '광고 서비스 환급 비용',
      children: [
        {
          field: 'bzmIn',
          headerName: '환급액',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (props!.data.bzmIn === 0 ? '-' : (String(props!.data.bzmIn).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))),
        },
        {
          field: 'bzmInVat',
          headerName: '부가세',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (props!.data.bzmInVat === 0 ? '-' : (String(props!.data.bzmInVat).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))),
        },
        {
          field: 'bzmInTotal',
          headerName: '합계',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (props!.data.bzmInTotal === 0 ? '-' : (String(props!.data.bzmInTotal).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))),
        },
      ],
    },
    {
      headerName: '수정 정보',
      children: [
        {
          field: 'updateTime',
          headerName: '최근 수정일시',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
        },
        {
          field: 'updateId',
          headerName: '수정자',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
        },
      ],
    },
  ];
  const [targetCost, setTargetCost] = useState<ITargetCost>();
  const [pubHis, setPubHis] = useState<IPubHis>();
  const [nonPubHis, setNonPubHis] = useState<INonPubHis>();

  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);

  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<ISearch>();

  const onGridReady = useCallback(() => {
    const yearSelectVal = $('#yearSelect option:selected').val();
    const monthSelectVal = $('#monthSelect option:selected').val();
    const basicMonth = String(yearSelectVal) + String(monthSelectVal);
    const {
      bizDiv, taxbillStatus, taxbillDiv, searchDiv, searchTxt, billPubStatus
    } = getValues();

    ApiUtil2.post('/api/mngTaxbill/mngTaxbill/list', {
      basicMonth, bizDiv, taxbillStatus, taxbillDiv, searchDiv, searchTxt, billPubStatus
    }).then((resp) => setRowData(resp.data.data));
  }, []);

  const now = new Date();
  let monthVal:any = now.getMonth();
  if (now.getMonth() === 1) monthVal = `0${now.getMonth() + 1}`;
  else if (now.getMonth() <= 9) monthVal = `0${now.getMonth()}`;
  else monthVal = now.getMonth();

  const yearRendering = () => {
    const result = [];
    for (let i = 2022; i <= 2050;) {
      result.push(<option key={i} value={i}>{i}년</option>);
      i += 1;
    }
    return result;
  };

  const monthRendering = () => {
    const result = [];
    for (let i = 1; i <= 12;) {
      result.push(<option key={i} value={i <= 9 ? `0${i}` : i}>{i}월</option>);
      i += 1;
    }
    return result;
  };

  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const taxbillDefDataApi = () => {
    const yearSelectVal = $('#yearSelect option:selected').val();
    const monthSelectVal = $('#monthSelect option:selected').val();
    const basicMonthData = String(yearSelectVal) + String(monthSelectVal);
    ApiUtil.get<ITaxbillDefDataApi>('/api/mngTaxbill/mngTaxbill/taxbillDefData', { params: { basicMonth: basicMonthData } }).then((resp) => {
      setTargetCost({ bzmIn: (String(resp.data.data.bzmIn).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')), bzmOut: (String(resp.data.data.bzmOut).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')) });
      setPubHis({ pubTargetCnt: (String(resp.data.data.pubTargetCnt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')), pubCost: (String(resp.data.data.pubCost).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')) });
      setNonPubHis({ nonPubTargetCnt: (String(resp.data.data.nonPubTargetCnt).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')), nonPubCost: (String(resp.data.data.nonPubCost).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')) });
    });
  };

  const taxbillPubHandler = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length === 0) {
      alertify.error('발행하실 대상을 선택하신 후 다시 시도해 주세요.\n');
    } else if (selectedRows.filter((v:any) => v.taxbillDiv === '임의조정').length > 0) {
      alertify.error('발행하실 대상을 선택하신 후 다시 시도해 주세요.\n');
    } else if (selectedRows.filter((v:any) => v.taxbillCancellable).length > 0) {
      alertify.error('발행하실 대상을 선택하신 후 다시 시도해 주세요.\n');
    } else {
      const taxbillIds = selectedRows.map((item:any) => item.taxbillId);
      alertify.confirm(`선택하신 ${taxbillIds.length}개의 대상에게<br>세금계산서를 발행하시겠습니까?<br><br>'취소'된 임의조정 세금계산서의 경우 해당 메뉴로 발행이 불가 합니다.`, () => {
        ApiUtil2.post('/api/mngTaxbill/mngTaxbill/pub', { taxbillIds }).then(() => {
          onGridReady();
          alertify.success('정상적으로 발행 되었습니다.\n');
        });
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    }
  };

  const taxbillNonPubHandler = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length === 0) {
      alertify.error('이미 발행한 대상을 선택해 주셔야 합니다.\n');
    } else if (selectedRows.filter((v:any) => !v.taxbillCancellable).length > 0) {
      alertify.error('이미 발행한 대상을 선택해 주셔야 합니다.\n');
    } else {
      const taxbillIds = selectedRows.map((item:any) => item.taxbillId);
      alertify.confirm(`선택하신 ${taxbillIds.length}개의 대상에게 발행된<br>세금계산서를 취소하시겠습니까?<br>임의조정으로 발행된 세금계산서는.<br>해당메뉴에서 취소가 불가 합니다.`, () => {
        ApiUtil2.post('/api/mngTaxbill/mngTaxbill/cancel', { taxbillIds }).then((resp: any) => {
          onGridReady();
          if (resp.data.data > 0) {
            alertify.warning(`정상적으로 ${taxbillIds.length - resp.data.data}개의 수정 세금계산서가 발행 되었습니다.\n${resp.data.data}개의 수정 세금계산서가 실패 되었습니다.`);
          } else {
            alertify.success('정상적으로 수정 세금계산서가 발행 되었습니다.\n');
          }
          alertify.success(`${taxbillIds.length - resp.data.data}개의 정상적으로 수정 세금계산서가 발행 되었습니다.\n`);
        });
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    }
  };

  const taxbillDown = useCallback(() => {
    const yearSelectVal = $('#yearSelect option:selected').val();
    const monthSelectVal = $('#monthSelect option:selected').val();
    const basicMonth = String(yearSelectVal) + String(monthSelectVal);

    setDlParam({
      ...getValues(),
      basicMonth,
    });

    setIsDlDialogOpened(true);
  }, []);


    const taxbillSync = useCallback(() => {
        alertify.confirm('국세청 발행상태 강제 동기화', '강제 동기화 하시겠습니까?', () => {
            ApiUtil.post('/api/mngTaxbill/mngTaxbill/sync', { menuPath: '/api/mngTaxbill/mngTaxbill/list' }).then(() => {
                alertify.success('국세청 발행상태 강제 동기화가 진행되고 있습니다. 약 10분가량 소요될 수 있습니다.');

            });
        }, {}).set({ labels: { cancel: '취소', ok: '확인' } });
    },[]);

  useEffect(() => {
    taxbillDefDataApi();
  }, []);
  useEffect(() => {
    setTitle('세금계산서 관리');
    $('.select2-single.w-150').select2({ width: '150', minimumResultsForSearch: Infinity });
    $('#yearSelect').on('select2:select', () => {
      taxbillDefDataApi();
      onGridReady();
    });
    $('#monthSelect').on('select2:select', () => {
      taxbillDefDataApi();
      onGridReady();
    });
    $('#bizDiv').on('select2:select', (e:any) => {
      setValue('bizDiv', e.target.value);
    });
    $('#taxbillStatus').on('select2:select', (e:any) => {
      setValue('taxbillStatus', e.target.value);
    });
    $('#billPubStatus').on('select2:select', (e:any) => {
      setValue('billPubStatus', e.target.value);
    });
    $('#taxbillDiv').on('select2:select', (e:any) => {
      setValue('taxbillDiv', e.target.value);
    });
    $('#searchDiv').on('select2:select', (e:any) => {
      setValue('searchDiv', e.target.value);
    });
    return () => {
      setTitle('');
    };
  }, []);
  useEffect(() => {
    $('#searchTxt').on('keyup', (e:any) => {
      if (e.keyCode === 13) {
        onGridReady();
        e.preventDefault();
      }
      if (e.target.value.length > 100) {
        e.target.value = e.target.value.substring(0, 90) + ((e.target.value.substring(101, 102) === '') ? e.target.value.substring(100, 101) : e.target.value.substring(101, 102));
        e.preventDefault();
      }
    });
  }, []);

    return (
      <Fragment>
        <div className="wrap-section wrap-tbl">
          <div className="box-header">
            <div className="box-tit">
              <h2 className="fz-20 fc-1 fw-bold">정산 연월</h2>
              <select className="select2-single w-150" id="yearSelect" defaultValue={now.getFullYear()}>
                {yearRendering()}
              </select>
              <select className="select2-single w-150" id="monthSelect" defaultValue={monthVal}>
                {monthRendering()}
              </select>
            </div>
          </div>
          <div className="box-body">
            <div className="tbl">
              <dl className="vertical">
                <dd>
                  <div className="form-group">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col col-4">
                          <div className="wrap-section wrap-tbl wrap-dashboard">
                            <div className="box-body">
                              <div className="tbl">
                                <dl className="vertical">
                                  <dt>
                                    <div className="dt-inner">
                                      <span className="fz-14 fc-4">세금계산서 발행 대상 금액</span>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="form-group flex-container">
                                      <div className="box-left">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">유상 비즈머니 소진액</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div className="box-right">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">{targetCost?.bzmOut}</b>
                                              <b className="fz-16 fc-4 fw-medium">원</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group flex-container">
                                      <div className="box-left">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">유상 비즈머니 환급액</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div className="box-right">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">{targetCost?.bzmIn}</b>
                                              <b className="fz-16 fc-4 fw-medium">원</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col col-4">
                          <div className="wrap-section wrap-tbl wrap-dashboard">
                            <div className="box-body">
                              <div className="tbl">
                                <dl className="vertical">
                                  <dt>
                                    <div className="dt-inner">
                                      <span className="fz-14 fc-4">세금계산서 발행 내역</span>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="form-group flex-container">
                                      <div className="box-left">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">세금계산서 발행 대상</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div className="box-right">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">{pubHis?.pubTargetCnt}</b>
                                              <b className="fz-16 fc-4 fw-medium">건</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group flex-container">
                                      <div className="box-left">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">세금계산서 발행 금액</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div className="box-right">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">{pubHis?.pubCost}</b>
                                              <b className="fz-16 fc-4 fw-medium">원</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col col-4">
                          <div className="wrap-section wrap-tbl wrap-dashboard">
                            <div className="box-body">
                              <div className="tbl">
                                <dl className="vertical">
                                  <dt>
                                    <div className="dt-inner">
                                      <span className="fz-14 fc-4">세금계산서 미발행 내역</span>
                                    </div>
                                  </dt>
                                  <dd>
                                    <div className="form-group flex-container">
                                      <div className="box-left">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">세금계산서 미발행 대상</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div className="box-right">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">{nonPubHis?.nonPubTargetCnt}</b>
                                              <b className="fz-16 fc-4 fw-medium">건</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="form-group flex-container">
                                      <div className="box-left">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">세금계산서 미발행 금액</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div className="box-right">
                                        <span className="comp-txt">
                                          <span className="table">
                                            <span className="table-cell">
                                              <b className="fz-16 fc-2 fw-medium">{nonPubHis?.nonPubCost}</b>
                                              <b className="fz-16 fc-4 fw-medium">원</b>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <span className="comp-txt h-auto">
                      <span className="table h-auto">
                        <span className="table-cell">
                          <b className="fz-12 fc-3 lh20">
                            <i className="fz-12 fc-5">*</i>요약 금액은 모두 VAT 미포함 금액
                          </b>
                        </span>
                      </span>
                    </span>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <section className="wrap-section wrap-datagrid wrap-double-header">
          <div className="wrap-filter">
            <form onSubmit={handleSubmit(onGridReady)}>
              <div className="inner-filter">
                <div className="box-left">
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">구분</p>
                    </div>
                    <div className="box-filter">
                      <select className="select2-single w-150" id="bizDiv" defaultValue="none" {...register('bizDiv')}>
                        <option value="none">전체</option>
                        <option value="biz">사업자</option>
                        <option value="user">개인</option>
                      </select>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">발행 상태</p>
                    </div>
                    <div className="box-filter">
                      <select
                        className="select2-single w-150"
                        id="taxbillStatus"
                        defaultValue="none"
                        {...register('taxbillStatus')}
                      >
                        <option value="none">전체</option>
                        <option value="published">발행</option>
                        <option value="wait">미발행</option>
                        <option value="reserved">발행예약</option>
                        <option value="cancel">취소</option>
                        <option value="cancelReserved">예약취소</option>
                        <option value="fail">실패</option>
                      </select>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">국세청 발행 상태</p>
                    </div>
                    <div className="box-filter">
                      <select
                        className="select2-single w-150"
                        id="billPubStatus"
                        defaultValue="none"
                        {...register('billPubStatus')}
                      >
                        <option value="none">전체</option>
                        <option value="success">완료</option>
                        <option value="fail">실패</option>
                        <option value="wait">대기</option>
                      </select>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">종류</p>
                    </div>
                    <div className="box-filter">
                      <select
                        className="select2-single w-150"
                        id="taxbillDiv"
                        defaultValue="none"
                        {...register('taxbillDiv')}
                      >
                        <option value="none">전체</option>
                        <option value="basic_use">일반</option>
                        <option value="basic_return">환급</option>
                        <option value="adjust">임의조정</option>
                        <option value="basic_camp">SNS 캠페인</option>
                      </select>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">검색 구분</p>
                    </div>
                    <div className="box-filter">
                      <select
                        className="select2-single w-150"
                        id="searchDiv"
                        defaultValue="makerId"
                        {...register('searchDiv')}
                      >
                        <option value="makerId">메이커ID</option>
                        <option value="userNo">사업자번호/주민번호</option>
                        <option value="comNm">상호</option>
                        <option value="comRepNm">대표자명/개인 성명</option>
                        <option value="comEmail">이메일</option>
                        <option value="campNm">캠페인 명</option>
                        <option value="campId">캠페인 ID</option>
                      </select>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">검색어</p>
                    </div>
                    <div className="box-filter">
                      <div className="input-group comp-search">
                        <div className="inner-input-group">
                          <input
                            type="text"
                            className="tf-comm"
                            id="searchTxt"
                            placeholder="검색어를 입력해 주세요."
                            {...register('searchTxt')}
                          />
                          <i id="search" className="ico ico-search"></i>
                        </div>
                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-right">
                  <button type="submit" className="btn btn-tertiary-mint btn-ico">
                    <i className="ico ico-filter"></i>필터 조회
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="box-header">
            <div className="box-tit">
              <h2 className="fz-20 fc-1 fw-bold">세금계산서 발행 대상</h2>
            </div>
          </div>
          <div className="box-header">
            <div className="box-tit">
              <button type="button" className="btn btn btn-primary" onClick={taxbillPubHandler}>
                발행
              </button>
              <button type="button" className="btn btn btn-secondary-outline" onClick={taxbillNonPubHandler}>
                발행 취소
              </button>
            </div>
            <div className="box-option">
              <button type="button" className="btn btn btn-primary" onClick={taxbillSync}>
                강제 동기화
              </button>
              <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={taxbillDown}>
                <i className="ico ico-download"></i>다운로드
              </button>
            </div>
          </div>
          <div className="box-body">
            <div className="ag-grid">
              <div className="ag-grid-inner">
                {
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    // onGridReady={onGridReady}
                    // onFirstDataRendered={onFirstDataRendered}
                    onGridSizeChanged={onGridSizeChanged}
                    rowHeight={48}
                    domLayout={'autoHeight'}
                    pagination={true}
                    paginationPageSize={10}
                    suppressPaginationPanel={true}
                    onPaginationChanged={changePagination}
                    noRowsOverlayComponent={NoDataTemplate}
                    suppressRowClickSelection={true}
                    rowSelection={'multiple'}
                    tooltipShowDelay={0}
                    tooltipHideDelay={5000}
                    enableCellTextSelection={true}
                    alwaysShowHorizontalScroll={true}
                  ></AgGridReact>
                }
              </div>
            </div>
          </div>
          <div className="box-footer">
            <Pagination gridRef={gridRef} ref={childRef} />
          </div>
        </section>
        <div className="comp-help">
          <ul className="help-list">
            <li className="list">
              <i className="ico ico-help"></i>
              <span className="fz-14 fc-2">유의사항</span>
            </li>
            <li className="list">
              <span className="fz-14 fc-4 bullet">세금계산서 발행 취소는 정산 연월의 익월 10일까지 만 가능합니다.</span>
            </li>
            <li className="list">
              <span className="fz-14 fc-4 bullet">
                익월 10일 이후 발행 취소가 필요한 경우 가비아 사이트 혹은 국세청 사이트를 이용하여 취소를 진행하셔야
                합니다.
              </span>
            </li>
            <li className="list">
              <span className="fz-14 fc-4 bullet">
                정산 연월 익월 10일 이후 신규 세금계산서 발행 시 가산세가 부과될 수 있는 점 유의해 주시기 바랍니다.
                (신규 발행 시 정산 연월의 말일로 공급일자가 적용)
              </span>
            </li>
          </ul>
        </div>
        {isDlDialogOpened ? (
          <DlDialog
            setIsDlDialogOpened={setIsDlDialogOpened}
            dlUrl={'/api/mngTaxbill/mngTaxbill/download'}
            dlParam={dlParam}
          />
        ) : null}
      </Fragment>
    )
};

export default MngTaxbill;
