import React, {
  forwardRef, Fragment, useContext, useImperativeHandle, useState,
} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query' // try next react-query;
import {
  CampIdContext, CampTabClickContext, CampValidDataContext, CatProhValidDataContext,
} from './Make02Empty';
import { ApiUtil2 } from '@biz/api'
import {CampInspValids, PomValid} from './Make02';
import CampAdReqDialog from './CampAdReqDialog';
import alertify from 'alertifyjs'
import CampPayTypeSelectDialog from "./CampPayTypeSelectDialog";
import CatProhKwdDialog from "./CatProhKwdDialog";
import Make02SelfCheckDialog from "./Make02SelfCheckDialog";

export interface CreationDataCatProhKwdValid {
  ok:boolean;
  data:any;
  catInspDiv:string;
  catProhKwdValid:boolean;
  catProhKwdValidsByCreationData:any
  category: string;
  catProhKwd:string[]
}
const Make02InspReqValidation = (props: any, ref: any) => {
  const [campInspValidOk, setCampInspValidOk] = useState<boolean>(false);
  const [pomValidOk, setPomValidOk] = useState<boolean>(false);
  const [payType, setPayType] = useState<string>('');
  const [isCatProhKwdDialog, setIsCatProhKwdDialog] = useState<boolean>(false);
  const [catProhKwd, setCatProhKwd] = useState<any>([]);
  const [category, setCategory] = useState<string>('');
  const [creationDataId, setCreationDataId] = useState<number>();
  const [selfCheckDialog, setSelfCheckDialog] = useState<boolean>(false);
  const { tabClick, setTabClick } = useContext(CampTabClickContext);
  const { campIdParam } = useContext(CampIdContext);
  const { setCampValidData } = useContext(CampValidDataContext);
  const { setCatProhValidData } = useContext(CatProhValidDataContext);

  // 검수 밸리데이션 api
  const campInspValidData = async () => {
    const { data } = await ApiUtil2.get<CampInspValids>('/api/camp/make02/campInspValid', { params: { campId: campIdParam } });
    return data;
  };
  const campInspValidApi = useQuery(['campInspValidData'], campInspValidData, {
    enabled: false,
  })

  // 후불머니사용 가능여부
  const pomValid = async () => {
    const { data } = await ApiUtil2.get<PomValid>('/api/camp/make02/pomValid', { params: { campId: campIdParam, creationDataId: creationDataId } });
    return data;
  };

  const pomValidApi = useQuery(['pomValid'], pomValid, {
    enabled: false,
  })

  const creationDataCatProhKwdValidApi = async  () => {
    const { data } = await ApiUtil2.get<CreationDataCatProhKwdValid>('/api/camp/make02/creationDataCatProhKwdValid', { params: { campId: campIdParam} });
    return data;
  };

  const creationDataCatProhKwdValidEvent = useQuery(
    ['creationDataCatProhKwdValidApi'],
    creationDataCatProhKwdValidApi,
    {
      enabled: false,
    }
  )

  const tabClickEvent = (value:any) => {
    setTabClick(value);
  };
  const setCampInspValidOkEvent = () => {
    setCampInspValidOk(false);
    setPayType('');
  };
  const setPomValidOkEvent = () => {
    setPomValidOk(false);
  }
  const validCheckEvent = () => {
    creationDataCatProhKwdValidEvent.refetch().then((r) => {
      if(r.isSuccess){
        if(r.data.data.catProhKwdValid) {
          setSelfCheckDialog(true);
        } else {
          setCatProhValidData(r.data.data);
          setCatProhKwd(r.data.data.catProhKwd);
          setCategory(r.data.data.category);
          setIsCatProhKwdDialog(true);
        }
      }
    });
  }
  const inspValidEvent = () => {
    campInspValidApi.refetch().then((r) => {
      if (r.isSuccess) {
        if (!r.data.data.projectValid || !r.data.data.campPeriodValid || !r.data.data.campBudgetValid) {
          // 캠페인 정보 입력 부분 벨리데이션시???
          alertify.error('필수 입력값을 확인하세요');
          setCampValidData(r.data.data);
          if (tabClick !== 'step01') {
            tabClickEvent('step01');
          }
        } else if (r.data.data.creationCnt !== r.data.data.creationCorrectCnt) {
          alertify.error('최소 1개 이상의 소재를 완성해주세요');
          setCampValidData(r.data.data);
          if (tabClick !== 'step03') {
            tabClickEvent('step03');
          }
        } else {
          pomValidApi.refetch().then((pom) => {
            if (pom.isSuccess){
              if(pom.data.data){
                setPomValidOk(true);
              }else {
                setPayType('BIZ_MONEY');
                setPomValidOk(false)
                setCampInspValidOk(true); // 검수통과했다는
                setCampValidData(r.data.data);
              }
            }
          })
        }
      }
    });
  };

  // 부모컴포넌트에서 자식 컴포넌트의 함수를 실행하기 위한 forwardRef와 useImperativeHandle을 사용
  useImperativeHandle(ref, () => ({
    validCheckEvent,inspValidEvent
  }));
  return (
    <Fragment>
      {
        isCatProhKwdDialog ? <CatProhKwdDialog catProhKwds = {catProhKwd} setIsCatProhKwdDialog = {setIsCatProhKwdDialog} category = {category} setSelfCheckDialog={setSelfCheckDialog } tabClickEvent = {tabClickEvent}  showSelfCheck = {true}/> : null
      }
      {
        selfCheckDialog? <Make02SelfCheckDialog setSelfCheckDialog={setSelfCheckDialog } campId={campIdParam} inspValidEvent={inspValidEvent}/> : null
      }
      {
        pomValidOk
            ? <CampPayTypeSelectDialog campId = {campIdParam}  setCampInspValidOk={setCampInspValidOk} setPomValidOkEvent={setPomValidOkEvent} setPayType = {setPayType} payType={payType}/>
            : campInspValidOk ? <CampAdReqDialog campId={campIdParam} setCampInspValidOk={setCampInspValidOkEvent} payType={payType}/> : null
      }
    </Fragment>
  );
};
export default forwardRef(Make02InspReqValidation);
