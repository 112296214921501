import alertify from 'alertifyjs'
import { ratioCheck } from './FileFbUtil'

/*
파일 관련 각종 밸리데이션
 */

const imgMaxFileSize = 31457280
const videoMaxFileSize = 209715200
let type: string
export const FileFbCreationValidator = (file: any, gifDiv: string) => {
  let maxFileSize: number
  const ext = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase()
  if (ext === 'jpeg' || ext === 'jpg' || ext === 'png' || (ext === 'gif' && gifDiv === 'img')) {
    // if (ext === 'jpeg' || ext === 'jpg' || ext === 'png') {
    type = 'img'
    maxFileSize = imgMaxFileSize
  } else if (ext === 'mp4' || ext === 'mov' || (ext === 'gif' && gifDiv === 'vod')) {
    type = 'vod'
    maxFileSize = videoMaxFileSize
  } else {
    alertify.error('다음의 확장자만 허용합니다.<br/>JPEG, JPG, PNG, MP4, MOV, GIF')
    return false
  }
  // 용량 체크
  if (file.size > maxFileSize) {
    alertify.error(
      '업로드 가능한 용량을 초과하였습니다.<br/>JPEG, JPG, PNG, GIF: 30MB 이내<br/>MP4, MOV, GIF: 200MB 이내'
    )
    return false
  }
  return true
}
const errMsgSizeMaxImg = () => {
  alertify.error(
    '이미지 가로 혹은 세로 한변의 길이는 9,000 픽셀을 초과할 수 없습니다. <br> 사이즈를 조정하여 재등록 바랍니다.'
  )
}
const errMsgSizeMinImg = () => {
  alertify.error('이미지가 최소 픽셀사이즈보다 작습니다.<br/><br/>최소 사이즈:500 x 500 픽셀')
}
const errMsgSizeMinVideo = () => {
  alertify.error('영상 너비가 최소 픽셀사이즈보다 작습니다.<br/><br/>최소 너비: 600 픽셀')
}
const errMsgDurationVideo = () => {
  alertify.error('영상 길이가 너무 깁니다. <br/>60초 이내 영상만 허용')
}
const errMsg = () => {
  alertify.error('다음의 확장자만 허용합니다.<br/>JPEG, JPG, PNG, MP4, MOV, GIF')
}

// /**
//  * 소재 비율 밸리데이션
//  * 참고 : https://wadiz.atlassian.net/browse/BIZ-395?focusedCommentId=164991&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-164991
//  * 1:1,4:5
//  * 비율을 소수 3자리에서 반올림해서 1.91+-0.03 이 된다면 통과
//  * 비율을 소수 3자리에서 반올림해서 1.77+-0.03 이 된다면 통과
//  * */
// const validRatios = [1/1, 4/5, 1.91, 1.77];
// const tRatio1:number = 1.91;
// const tRatio2:number = 1.77;
// const ratioCheck = (ratio: number, callback: any) => {
//   console.log(`ratid!!!!! 1 : ${ratio}`);
//   console.log(!validRatios.includes(ratio));
//   console.log(Math.abs(ratio - tRatio1) > 0.03);
//   console.log( Math.abs(ratio - tRatio2) > 0.03);
//
//   if(!validRatios.includes(ratio) && (
//       Math.abs(ratio - tRatio1) > 0.03 || Math.abs(ratio - tRatio2) > 0.03
//   )){
//     console.log('???');
//     errMsgRatio;
//     callback(false);
//     return;
//   }
// }
export const FileFbCreationSizeCheck = (file: any, gifDiv: string, callback: any) => {
  const URL = window.URL || window.webkitURL
  const objectUrl = URL.createObjectURL(file)

  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/jpg' ||
    file.type === 'image/png' ||
    file.type === 'image/gif'
  ) {
    // if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
    type = 'img'
  } else if (file.type === 'video/mp4' || file.type === 'video/quicktime') {
    type = 'vod'
  }
  let width = 0
  let height = 0
  if (type === 'img') {
    const image = new Image()
    image.onload = function () {
      URL.revokeObjectURL(objectUrl)
      width = image.width
      height = image.height
      if (gifDiv === 'vod' && width < 600) {
        errMsgSizeMinVideo()
        callback(false)
        return
      }
      if (width < 500 || height < 500) {
        errMsgSizeMinImg()
        callback(false)
        return
      }

      if (width > 9000 || height > 9000) {
        errMsgSizeMaxImg()
        callback(false)
        return
      }

      const imgRatio = Math.round((width / height) * 100) / 100
      if (!ratioCheck(imgRatio)) {
        callback(false)
        return false
      }

      URL.revokeObjectURL(objectUrl)
      callback(true, type, width, height, file.size)
    }
    image.src = objectUrl
  } else if (type === 'vod') {
    let video: any | undefined = document.getElementById('video')
    // video.remove();
    const v = document.createElement('video')
    v.setAttribute('id', 'video')
    v.setAttribute('src', objectUrl)
    video = v

    document.getElementById('section')?.append(v)
    // eslint-disable-next-line consistent-return
    video.onloadeddata = function () {
      URL.revokeObjectURL(objectUrl)
      width = video.videoWidth
      height = video.videoHeight
      const time = video.duration
      const duration = Math.floor(time)
      video.src = ''
      if (duration >= 61) {
        errMsgDurationVideo()
        callback(false)
        return false
      }
      if (width < 600) {
        errMsgSizeMinVideo()
        callback(false)
        return false
      }
      const videoRatio = Math.round((width / height) * 100) / 100
      if (!ratioCheck(videoRatio)) {
        callback(false)
        return false
      }
      // if(videoRatio !== 1/1 && videoRatio !== 4/5){
      //   errMsgRatio();
      //   callback(false);
      //   return;
      // }
      URL.revokeObjectURL(objectUrl)
      callback(true, type, width, height, file.size)
    }
    video.src = objectUrl
  } else {
    errMsg()
    callback(false)
  }
}
export const FileValidationCreationFb = (file: any, gifDiv: string, callback: any) => {
  // eslint-disable-next-line no-restricted-syntax
  FileFbCreationSizeCheck(
    file,
    gifDiv,
    (checkStep1: boolean, fileDiv: string, width: number, height: number, fileSize: number) => {
      if (!checkStep1) {
        callback(false)
        return
      }
      const checkStep2 = FileFbCreationValidator(file, gifDiv)
      if (!checkStep2) {
        callback(false)
        return
      }
      callback(true, file, fileDiv, width, height, fileSize)
    }
  )
}
