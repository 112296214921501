import React, {
  Fragment, useRef,
} from 'react';
import { Dialog } from 'rc-easyui';
import dayjs from 'dayjs';
import alertify from 'alertifyjs'

const Make02Step03PageLeaveDialog = (props:any) => {
  const dialogRef = useRef<any>();

  const cancelEvent = () => {
      if(props.mode === 'tab'){
          props.setShowPageLeaveDialog(false);
          props.setTabClick(props.tabBefore);

      }else{
          props.cancelNavigation();
      }
  };

  const moveEvent = () => {
      if(props.mode === 'tab'){
          props.setShowPageLeaveDialog(false);
          props.setTabClick(props.tabAfter);
      }else{
          props.confirmNavigation();
      }
  };
  return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '700px' }} >
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="wrap-section wrap-tbl">
                                <div className="box-body">
                                    <div className="tbl">
                                        <dl className="vertical">
                                            <dt>
                                                <div className="dt-inner">
                                                        <span className="fz-16 fc-1">
                                                            수정하신 소재가 있습니다.
                                                        </span>
                                                </div>
                                            </dt>
                                            <dd>
                                                <div className="form-group">
                                                    <p className="comp-txt">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-14 fc-4">수정하신 소재를 ‘변경요청’ 혹은 ‘검수요청‘ 하지 않고 다른 페이지로 이동 하시겠습니까?</b>
                                                                    <b className="fz-14 fc-4">이동 시에는 수정된 내용이 메타에 반영되지 않습니다.</b>
                                                                    <b className="fz-14 fc-7 bullet">변경요청’ 혹은 ‘검수요청’을 진행하지 않은 소재의 경우 테두리의 색이 빨간색으로 표시됩니다.</b>
                                                                </span>

                                                            </span>
                                                    </p>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-secondary-outline" onClick={cancelEvent}>취소</button>
                            <button type="button" className="btn btn-primary" onClick={moveEvent}>이동하기</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>

  );
};
export default Make02Step03PageLeaveDialog;
