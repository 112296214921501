import React, {Fragment, useContext, useEffect, useState,} from 'react';
import {useOutletContext} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router';
import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useUsableCostQuery } from '@biz/query'
import MultiFileUploaderForConsulting from '../../../fileUploader/MultiFileUploaderForConsulting'
import alertify from 'alertifyjs'
import {ISystemParam} from '../../../mngAd/consulting/ConsultingSetting';
import GeneralChargeDialog from '../../../bizmoney/GeneralChargeDialog'
import {Project} from "../../../camp/make02/Make02";
import CampProjectSelectDialogForConsulting from "./CampProjectSelectDialogForConsulting";

export interface IConsulting {
    projectId: string,
    expYn: boolean,
    mediaDiv3Str: string[],
    consultingT01: string,
    consultingT02: string,
    consultingStatus: string,
    projectFundingDiv: string;
    projectDiv: string;
    totalBudget: number,
    consultingFees: number,
}

const Make01Req = () => {
  const { update: setUsableCostChange } = useUsableCostQuery()
  const setTitle = useOutletContext<any>();
  const [usingUserNo, setUsingUserNo] = useState<string>('');
  // const [mediaDiv3List, setMediaDiv3List] = useState<string[][]>([]);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();
  const mediaDiv: string[] = [];
  const [uploadId, setUploadId] = React.useState(0);
  const [bizmoney, setBizmoney] = React.useState(0);
  const [count1, setCount1] = React.useState(0);
  const [count2, setCount2] = React.useState(0);
  const [fee, setFee] = useState(0);
  const [feebf, setFeebf] = useState(0);
  const [isFree, setIsFree] = useState('');
  const [wadizFundingText, setWadizFundingText] = useState('');
  const [userNo, setUserNo] = useState<string>('');
  /** 다이얼로그 */
  const [projectAdPossStartDate, setProjectAdPossStartDate] = useState<any>(null);
  const [projectAdPossEndDate, setProjectAdPossEndDate] = useState<any>(null);
  const [projectRcsStartDate, setProjectRcsStartDate] = useState<any>(null);
  const [projectRcsEndDate, setProjectRcsEndDate] = useState<any>(null);
  const [projectDiv, setProjectDiv] = useState('');
  const [projectNm, setProjectNm] = useState('프로젝트 선택');
  const [projectSelectDialog, setProjectSelectDialog] = useState(false);

  /** 토스 모듈 리다이렉트로 인한 파일 정보 세션에 담기 */
  const [fileInfo, setFileInfo] = useState<any>([]);
  const [agreeYn, setAgreeYn] = useState<boolean>(false);

  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<IConsulting>({
    defaultValues: { consultingStatus: 'WAIT' },
  });

  /** 토스 모듈 성공시 리다이렉트 되는 이슈 때문에 */
  let sessionStorageInfo:any = {};
  const bzmChargeHandler = () => {
    const projectId = getValues('projectId');
    const expYn = getValues('expYn');
    const mediaDiv3Str = getValues('mediaDiv3Str');
    const consultingT01 = getValues('consultingT01');
    const consultingT02 = getValues('consultingT02');
    const totalBudget = getValues('totalBudget');
    const projectFundingDiv = getValues('projectFundingDiv');
    const consultingStatus = getValues('consultingStatus');
    const consultingFees = getValues('consultingFees');
    sessionStorageInfo = {
      projectId,
      expYn,
      mediaDiv3Str,
      consultingT01,
      consultingT02,
      totalBudget,
      projectFundingDiv,
      consultingStatus,
      consultingFees,
      uploadId,
      wadizFundingText,
      fileInfo,
    };
    sessionStorage.setItem('make01ReqInfo', JSON.stringify(sessionStorageInfo));
    setIsClicked(true);
  };
  const sessionInfo = sessionStorage.getItem('make01ReqInfo');
  useEffect(() => {
    if (sessionInfo !== null) {
      const data = JSON.parse(sessionInfo);

      /** init value setting */
      setValue('projectId', data.projectId);
      setValue('projectFundingDiv', data.projectFundingDiv);
      setValue('expYn', data.expYn);
      setValue('mediaDiv3Str', data.mediaDiv3Str);
      setValue('consultingT01', data.consultingT01);
      setValue('consultingT02', data.consultingT02);
      setValue('totalBudget', Number(data.totalBudget));
      setValue('consultingStatus', data.consultingStatus);
      setWadizFundingText(data.wadizFundingText);

      /** consultingT01, T02 Text Length setting */
      setCount1(data.consultingT01.length);
      setCount2(data.consultingT02.length);

      /** select2 setting */
      if (data.projectFundingDiv !== undefined) {
        setTimeout(() => {
          if (data.projectFundingDiv === 'NON') {
            $('.select2-single').val(`NON-${data.projectId}`).trigger('change');
          } else if (data.projectFundingDiv.startsWith('FUNDING')) {
            $('.select2-single').val(`FUNDING-${data.projectId}`).trigger('change');
          }
        }, 1500);
      }

      /** checkbox setting */
      if (data.mediaDiv3Str !== undefined) {
        data.mediaDiv3Str.forEach((v:any) => {
          if ($(`#${v}`).val() !== undefined) {
            $(`#${v}`).prop('checked', true);
          } else {
            setTimeout(() => {
              $(`#${v}`).prop('checked', true);
            }, 1000);
          }
        });
      }

      /** expYn radio button */
      if (data.expYn != null) {
        if (data.expYn === 'true') {
          $('#inp-radio-01').prop('checked', true);
        } else {
          $('#inp-radio-02').prop('checked', true);
        }
      }

      /** totalBudget setting */
      if (data.totalBudget !== undefined) {
        $('#totalBudget').val(Number(data.totalBudget).toLocaleString());
      }

      /** prevFiles setting */
      setUploadId(data.uploadId);
    }
  }, []);

  // 광고대행 수수료
  const getSetting = async () => {
    const api = ApiUtil2.get<ISystemParam>('/api/camp/make01/settingDetail').then((resp) => {
      const fees = Number(resp.data.data.find((val: { id: string; }) => val.id === 'CONSULTING_FEE').value);
      const feeVat = Number(resp.data.data.find((val: { id: string; }) => val.id === 'CONSULTING_FEE_VAT').value);
      const freeYn = resp.data.data.find((val: { id: string; }) => val.id === 'CONSULTING_FREE_YN').value;
      setIsFree(freeYn);
      setFeebf(fees);
      setFee(fees + feeVat);
      setValue('consultingFees', fee);
    });
    return api;
  };

  const getPrevData = async () => {
    const data = await ApiUtil2.get<ISystemParam>('/api/camp/make01/settingDetail').then((resp) => {
      const setting = resp.data.data;
      if (Array.isArray(setting) && setting.length !== 0) {
        const limit = setting.find((val: { id: string; }) => val.id === 'CONSULTING_LIMIT');
        const limitYnVal = setting.find((val: { id: string; }) => val.id === 'CONSULTING_LIMIT_YN');
        const limitMsgVal = setting.find((val: { id: string; }) => val.id === 'CONSULTING_LIMIT_MSG');
        if (limitYnVal.value === 'true' && Number(limit.value) <= limit.consultingCnt) {
          alertify.alert('', limitMsgVal.value).set('label', '확인');
        }
      }
    });
    return data;
  };

  /*const mediaDiv3Handler = (e: any) => {
    if ($('input:checkbox[name="mediaDiv3Str"]').is(':checked') === true) {
      mediaDiv.push(`${e.target.id}`);
    }
    setValue('mediaDiv3Str', mediaDiv, { shouldValidate: true });
  };*/

  const successEvent = (msg: string) => {
    alertify.alert('', msg, () => {
      navigate('/camp/make01');
    }).set('label', '확인');
  };

  const confirmSuccess = (msg: string) => {
    successEvent(msg);
  };

  const onSubmit = () => {
    const {
        projectId, expYn, consultingT01, consultingT02, projectFundingDiv, projectDiv, consultingStatus
    } = getValues();

      if ((projectId === '' || undefined ) || (expYn === undefined) || (consultingT01 === '') ||(consultingT02 === '') || (fileInfo.length === 0)) {
          alertify.error('미입력 항목이 있습니다.');
          return;
      }
      if (agreeYn === false) {
          alertify.error('이용 사항에 동의해야 신청이 가능합니다.');
          return;
      }

    /*if (totalBudget === undefined || Number(totalBudget.toString().replaceAll(/,/g, '')) === 0) {
      alertify.error('미입력 항목이 있습니다.');
      return;
    }*/

    const body = {
      projectId,
      expYn,
      // mediaDiv3Str,
      consultingT01,
      consultingT02,
      //totalBudget: totalBudget !== undefined ? totalBudget.toString().replaceAll(/,/g, '') : 0,
      projectFundingDiv,
      projectDiv,
      consultingStatus,
      consultingFees: isFree === 'true' ? 0 : feebf,
      uploadId,
      fileInfos: fileInfo,
    };

    if (isFree === 'false' && userNo !== usingUserNo) {
      alertify.confirm('충전 주체와 사용 주체가 다른 경우 와디즈는 불이익에 대하여 책임지지 않습니다.', () => {
        ApiUtil2.post<IConsulting>('/api/camp/make01/post', body).then(() => {
          setUsableCostChange(`${(bizmoney - fee).toLocaleString()}`);
          confirmSuccess('광고 대행 신청이 완료되었습니다.');
          sessionStorage.removeItem('make01ReqInfo');
        });
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('선택한 프로젝트의 사업자번호와 <br/> 비즈머니를 충전한 사업자번호가 다릅니다.');
    } else {
      ApiUtil2.post<IConsulting>('/api/camp/make01/post', body).then(() => {
        setUsableCostChange(`${(bizmoney - fee).toLocaleString()}`);
        confirmSuccess('광고 대행 신청이 완료되었습니다.');
        sessionStorage.removeItem('make01ReqInfo');
      });
    }
  };

  // 벨리데이션시 사용
  const onErr = (error: any) => {
    if (error.projectId !== undefined) {
      if (error.projectId.message !== '') {
        alertify.error(error.projectId.message);
      }
    }
    if (error.password !== undefined) {
      if (error.password.message !== '') {
        alertify.error(error.password.message);
      }
    }
  };

  /** 컴포넌트*/
  const ProjectListSelectBox = () => {
      const projectHandler = (project: Project) : void => {
          setValue('projectId', project.id.toString())
          setValue('projectDiv', project.projectDiv)
          if (project.bizDiv === 'user') ApiUtil.get('/api/camp/make01/getUserNoForUser', { params: { data: { projectId: project.id.toString() } } }).then((resp) => { setUserNo(resp.data.data); });
          else setUserNo(project.userNo);
      };

      return (
          <div className="comp-dropdown select2 w-300 expand selected">
              <a className="dropdown-toggle" data-toggle="dropdown">
                  <div className="box-left" id="box">
                      <i id="icon" className={projectDiv === '스토어' ? "ico ico-store" : projectDiv === '펀딩' ? "ico ico-funding" : ""}></i>
                      <span className="fz-12 fw-bold" id="projectDiv">{projectDiv}</span>
                      <span className="fz-14" id="projectNm">{projectNm}</span>
                  </div>
                  <div className="box-right">
                      <i className="ico ico-arrow"></i>
                  </div>
              </a>
              <div className="dropdown-menu expand">
                  <ul className="opt-selectbox" onClick={(e: any) => e.stopPropagation()} onKeyUp={(e: any) => e.handleKeyUp} role="presentation">
                      {
                          projectList.map((a: any, key: any) => {
                              return (
                                  <li id={a.id}
                                      className="opt-menu"
                                      onClick={()=>{
                                          setProjectNm(a.projectNm)
                                          setProjectDiv(a.projectDiv === 'STORE' ? '스토어' : '펀딩')
                                          setProjectAdPossStartDate(a.projectAdPossStartDate)
                                          setProjectAdPossEndDate(a.beforeProjectAdPossEndDate)
                                          setProjectRcsStartDate(a.projectRcsStartDate)
                                          setProjectRcsEndDate(a.projectRcsEndDate)
                                          projectHandler(a)

                                          if(a.projectDiv === 'FUNDING') setProjectSelectDialog(true)
                                          else setWadizFundingText(''); setValue('projectFundingDiv', 'NON')
                                          $('.comp-dropdown').addClass('selected').removeClass('open')
                                      }}
                                      key={key}>
                                      <i className={a.projectDiv === 'STORE' ? 'ico ico-store' : 'ico ico-funding'}></i>
                                      <span className="fz-12 fw-bold">{a.projectDiv === 'STORE' ? '스토어' : '펀딩'}</span>
                                      <span className="fz-14 fc-2" id={`projectNm${key}`}> {a.projectNm}</span>
                                  </li>
                              );
                          })
                      }
                  </ul>
              </div>
          </div>
      );
  };

  useEffect(()=>{
    $('.opt-menu').on('click',function (){
        $('.opt-menu').removeClass('selected')
        $(this).addClass("selected")
    });
  });

  useEffect(() => {
    setTitle('광고 대행 요청하기');
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    getPrevData();
  }, []);

  useEffect(() => {
    /* 메이커 활성 사업자번호 */
    ApiUtil.get('/api/camp/make01/getUserNos').then((resp) => {
      if (resp.data.data.usingUserNo !== undefined) {
        setUsingUserNo(resp.data.data.usingUserNo.userNo);
      }
    });

      /* mediadiv3 */
    // ApiUtil.get('/api/camp/make01/getMediaDivs').then((resp) => {
    //   const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]]);
    //   setMediaDiv3List(options);
    // });

    /* projectList */
    ApiUtil.get('/api/camp/make01/projectList').then((resp) => {
        setProjectList(resp.data.data);
    });

    /* 보유 비즈머니 */
    ApiUtil.get('/api/camp/make01/getBizmoney').then((resp) => {
      if (resp.data.data.isEmpty === true) {
        setBizmoney(0);
      } else {
        setBizmoney(Number(resp.data.data));
        setUsableCostChange(`${resp.data.data.toLocaleString()}`);
      }
    });
    getSetting();

    /** 라디오 버튼 내 핸들러 작동이 되지 않아 jquery 선택자 이용 */
    $('input[name=expYn]').on('click', (e:any) => {
      setValue('expYn', e.currentTarget.value);
    });

    /** 충전 금액 키업 이벤트 */
    $('#totalBudget').on('keyup', () => {
      const value = $('#totalBudget').val() as string;
      if (/[^0-9,]/g.test(value)) {
        $('#totalBudget').val(value.substring(0, value.length - 1));
      } else {
        $('#totalBudget').val(value.replace(/,/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'));
      }
      setValue('totalBudget', Number(value.replaceAll(',', '')));
    });
  }, []);

  return (
        <Fragment>
            {projectSelectDialog ? <CampProjectSelectDialogForConsulting projectRcsStartDate={projectRcsStartDate} projectRcsEndDate={projectRcsEndDate} projectAdPossStartDate={projectAdPossStartDate} projectAdPossEndDate={projectAdPossEndDate} setProjectSelectDialog = {setProjectSelectDialog} setWadizFundingText = {setWadizFundingText} setValue={setValue} projectNm = {projectNm}/> : null}
            {isClicked === true ? <GeneralChargeDialog isClicked={isClicked} setIsClicked={setIsClicked}/> : null}
            <div className="wrap-banner">
                <div className="box-left">
                    <h3 className="fz-32 fc-0">
                        <span className="fw-thin">광고를 직접 만들기 어려우시다면</span>
                        <br></br>
                        <span className="fw-medium">광고 대행 서비스를 이용해보세요.</span>
                    </h3>
                    <p className="fz-16">와디즈의 마케팅 전문가들이 광고 소재를 <br></br> 제작해드리고 캠페인 세팅을 도와드립니다</p>
                    <a target='_blank' rel='noreferrer'
                       href="https://wabiz-static-prod.s3.ap-northeast-2.amazonaws.com/static/usingGuide/%EB%B9%84%EC%A6%88%EC%84%BC%ED%84%B0_%EB%8C%80%ED%96%89%EC%84%9C%EB%B9%84%EC%8A%A4_ver+23.07.pdf">
                        <button type="button" className="btn btn-secondary-outline xlarge w-300">자세히 알아보기</button>
                    </a>

                </div>
            </div>
            <div className="comp-help">
                <ul className="help-list">
                    <li className="list">
                        <i className="ico ico-help"></i><span className="fz-14 fc-2">광고 대행 진행을 위해 아래 정보를 모두 입력해주세요.</span>
                    </li>
                    <li className="list">
                        <i className="ico ico-help"></i><span className="fz-14 fc-2">광고 대행 서비스는 오픈예정 단계에서 신청이 가능합니다.</span>
                    </li>
                    <li className="list">
                        <i className="ico ico-help"></i><span className="fz-14 fc-2">서비스를 신청하면 프로젝트 [오픈예정 단계]에서 캠페인 1개, [본펀딩 단계]에서 캠페인 1개 총 2개의 캠페인이 신규 세팅되며 2개 캠페인 예산 합이 최소 300만원 이상이어야 합니다.</span>
                    </li>
                    <li className="list depth">
                        <span className="fz-14 fc-2">* 총 예산 300만원 미만 시 집행 예산과는 무관하게 이용료 9만원이 부과됩니다.</span>
                    </li>
                    <li className="list depth">
                         <span className="fz-14 fc-2">* 신청한 예산(오픈예정+본펀딩)에 해당하는 비즈머니가 사전 충전되어 있어야 합니다.</span>
                    </li>
                    <li className="list"><span className="fz-14 fc-2"></span></li>
                    <li className="list depth">
                        <span className="fz-14 fc-2">[ 서비스 항목 ]</span>
                    </li>
                    <li className="list depth">
                        <span className="fz-14 fc-4 bullet">오픈예정 캠페인 + 본펀딩 캠페인 = 총 2개 캠페인 신규 세팅</span>
                    </li>
                    <li className="list depth">
                        <span className="fz-14 fc-4 bullet">이미지 3장 + 문구 1개 신규 제작 (수정 1회)</span>
                    </li>
                </ul>
            </div>
            <section className="wrap-section wrap-tbl">
                <form onSubmit={handleSubmit(onSubmit, onErr)}>
                    <div className="box-body">
                        <div className="tbl">
                            <dl className="vertical column-two">
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            광고할 와디즈 프로젝트를 선택해주세요.
                                            <span className="fz-12 fc-5">[필수]</span>
                                            <h3 className="fz-12 fc-3">{wadizFundingText}</h3>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <ProjectListSelectBox/>
                                    </div>
                                </dd>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            페이스북, 인스타그램, 구글 등의 온라인 광고를 직접 집행해보신 경험이 있으신가요?
                                            <span className="fz-12 fc-5">[필수]</span>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <div className="comp-radio">
                                            <input type="radio" id="inp-radio-01" name="expYn" value="true"
                                                   /* onChange={expYnHandler} */ ></input>
                                            <label htmlFor="inp-radio-01">예</label>
                                        </div>
                                        <div className="comp-radio">
                                            <input type="radio" id="inp-radio-02" name="expYn" value="false"
                                                   /* onChange={expYnHandler} */ ></input>
                                            <label htmlFor="inp-radio-02">아니오</label>
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                            <dl className="vertical column-two">
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            필수로 포함되었으면 하는 키워드를 입력해주세요.
                                            <span className="fz-12 fc-5">[필수]</span>
                                            <h3 className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>광고 카피 제작시 참고하여 제작해드립니다.</h3>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <div className="input-group expand">
                                            <div className="inner-input-group">
                                                <textarea className="tf-textarea" maxLength={10000} {...register('consultingT01', {
                                                  required: {
                                                    value: true,
                                                    message: '미입력 항목이 있습니다.',
                                                  },
                                                })} onChange={(e) => {
                                                  setCount1(e.target.value.length);
                                                }}></textarea>
                                            </div>
                                            <p className="fz-12 fc-3"><span className="fc-2" id="length01">{count1}</span> / 10,000</p>
                                            <p className="fz-12"><i className="ico"></i>체크 / 에러 문구 내용 영역</p>
                                        </div>
                                    </div>
                                </dd>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            제품에 대한 소구 포인트를 서술해주세요.
                                            <span className="fz-12 fc-5">[필수]</span>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <div className="input-group expand">
                                            <div className="inner-input-group">
                                                <textarea className="tf-textarea" maxLength={10000} {...register('consultingT02', {
                                                  required: {
                                                    value: true,
                                                    message: '미입력 항목이 있습니다.',
                                                  },
                                                })} onChange={(e) => {
                                                  setCount2(e.target.value.length);
                                                }}></textarea>
                                            </div>
                                            <p className="fz-12 fc-3"><span className="fc-2" id="length02">{count2}</span> / 10,000</p>
                                            <p className="fz-12"><i className="ico"></i>체크 / 에러 문구 내용 영역</p>
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                            <dl className="vertical column-two">
                                {/*<dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            광고를 희망하는 매체를 선택해주세요.
                                            <span className="fz-12 fc-3">(복수선택 가능)</span>
                                            <span className="fz-12 fc-4">[선택]</span>
                                            <h3 className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>컨설팅시 선택하신 내용을 참고하여 매체 전략을 제안해드립니다.</h3>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    {
                                        mediaDiv3List.map((a: any) => (
                                            <div className="form-group" key={a[0]}>
                                                <div className="comp-checkbox">
                                                    <input type="checkbox" name="mediaDiv3Str" id={a[0]} value={a[0]}
                                                           onChange={mediaDiv3Handler}></input>
                                                    <label htmlFor={a[0]}>{a[1]}</label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </dd>*/}
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            광고 소재 제작시 참고할 수 있는 광고용 이미지 및 영상 소재를 등록해주세요.
                                            <span className="fz-12 fc-5">[필수]</span>
                                            <h3 className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>별도로 등록하지 않으시면 프로젝트 페이지 이미지를 참고하여 제작해드립니다.</h3>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <MultiFileUploaderForConsulting setUploadId={setUploadId} setFileInfo={setFileInfo}/>
                                </dd>
                                {/*<dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            광고를 집행할 총 예산을 입력해주세요.
                                            <span className="fz-12 fc-5">[필수]</span>
                                            <h3 className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>입력된 예산 기준으로 광고 대행 서비스 이용료가 부과됩니다.</h3>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="inner-input-group">
                                                <input id='totalBudget' className="tf-comm tf-unit"/>
                                                <span className="fz-14 fc-1">원</span>
                                            </div>
                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                        </div>
                                    </div>
                                </dd>*/}
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-16 fc-1">
                                            광고 기간, 예산, 연락 정보를 입력해주세요
                                            <span className="fz-12 fc-5">[필수]</span>
                                        </span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <a target='_blank' rel='noreferrer'
                                           href="https://app.smartsheet.com/b/form/ab126d13e6df46088de03d00f49fd226">
                                            <button type="button" className="btn btn-primary-outline">정보 입력</button>
                                        </a>
                                    </div>
                                </dd>
                            </dl>
                            {
                                isFree === 'true' ? null :
                                <dl className="vertical total">
                                    <dd>
                                        <div className="form-group">
                                            <div className="box-left">
                                            <span className="fz-18 fc-1">
                                                ‘광고 대행 신청’ 버튼을 누르면 <span
                                                className="fc-5">{feebf.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</span> 원이 결제됩니다.
                                            </span>
                                                <p className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>(10% 부가세 별도 총금액 <span className="fc-5">{fee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</span> 원)
                                                </p>
                                            </div>
                                            <div className="box-right">
                                                <div className="box-group">
                                                    <span className="fz-14 fc-1">보유 비즈머니</span>
                                                    <span className="fz-14 fc-2">{bizmoney.toLocaleString()} 원</span>
                                                </div>
                                                <button type="button" className="btn btn-primary large w-200"
                                                        onClick={bzmChargeHandler}>비즈머니 충전
                                                </button>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            }
                            <dl className="vertical">
                                <dd>
                                    <div className="form-group">
                                        <span className="comp-txt">
                                            <span className="table">
                                                <span className="table-cell">
                                                    <b className="fz-20 fc-1 fw-bold">비즈센터 광고 대행 서비스 이용약관</b>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </dd>
                            </dl>
                            <dl className="vertical wrap-terms">
                                <dd>
                                    <div className="form-group">
                                        <ul className="inner-content-terms">
                                            <li>
                                                <h2>제1조 (목적)</h2>
                                                <p>
                                                    본 비즈센터 광고 대행 서비스 이용약관(이하 “본 약관”이라 합니다)은 비즈센터 서비스 이용약관에 부수되는 약관으로서,
                                                    와디즈 주식회사(이하 “회사”라 합니다)가 운영 및 제공하는 인터넷 사이트(https://www.wadiz.kr, 이하
                                                    “사이트”라 합니다)에서 제공하는 서비스를 이용하는 자(이하 “메이커”라 합니다)가 와디즈 비즈센터 내에서 회사가
                                                    메이커에게 제공하는 광고 관련 제반 서비스 중 광고 대행 서비스를 이용함에 있어 회사와 메이커 간의 권리, 의무 등 필요한
                                                    제반 사항을 규정함을 목적으로 합니다.
                                                </p>
                                            </li>
                                            <li>
                                                <h2>제2조 (정의)</h2>
                                                <ol>
                                                    <li>
                                                        본 약관에서 사용하는 용어는 사이트 및 와디즈 비즈센터(htttps://biz.wadiz.kr)에 게시된 약관과
                                                        정책에 따라 정의된 의미를 가집니다.
                                                    </li>
                                                    <li>
                                                        제1항에서 정의되지 않은 용어는 일반 거래 관행에 따라 정의된 의미를 가집니다.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <h2>제3조 (약관의 효력)</h2>
                                                <ol>
                                                    <li>
                                                        회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 회사가 수시로 개정할 수 있는 광고가이드 및 개별 정책
                                                        등(이하 "정책"이라 합니다)을 제정하여 운영할 수 있으며, 해당 내용을 와디즈 비즈센터 또는 사이트를 통하여
                                                        게시합니다. 정책은 이 약관과 더불어 서비스 이용계약(이하 "이용계약"이라 합니다)의 일부를 구성합니다. 메이커가
                                                        본 약관 및 정책에 동의하는 것은 양 당사자의 인감을 날인하여 이용계약을 체결하는 것과 동일한 효력을 가지며, 동의
                                                        시점부터 이용계약의 효력이 발생합니다.
                                                    </li>
                                                    <li>
                                                        메이커가 본 약관에 동의한 후 메이커의 계정을 통하여 와디즈 비즈센터에서 이루어지는 행위는 모두 메이커의 행위로
                                                        인정됩니다.
                                                    </li>
                                                    <li>
                                                        본 약관에서 규정되지 않은 일체의 사항에 대해서는 비즈센터 서비스 이용약관에서 규정된 내용에 따릅니다.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <h2>제4조 (이용계약의 성립)</h2>
                                                <ol>
                                                    <li>
                                                        이용계약은 서비스를 이용하고자 하는 자(이하 “이용신청자”라 함)가 본 약관 및 정책에 동의하고 회사가 정한 절차에
                                                        따라 광고 대행 서비스를 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써 성립합니다. 회사는 이용승낙의
                                                        의사표시를 광고센터의 해당 서비스 화면에 게시하거나 기타 방법으로 할 수 있습니다.
                                                    </li>
                                                    <li>
                                                        이용신청자는 회사가 별도로 요청하는 증빙서류가 있는 경우 신속히 제출하여야 하며, 회사는 해당 서류를 징구하는
                                                        시점까지 신청에 대한 승낙을 보류하거나 거부할 수 있습니다.
                                                    </li>
                                                    <li>
                                                        회사는 제1항에 따라 광고 대행 서비스를 신청한 자 중에서 정책에 위반되는 사유가 확인 되는 경우 승인을 거부할 수
                                                        있으며, 등록이 된 이후에도 아래 각호의 사유가 확인된 경우에는 승낙을 취소할 수 있습니다.
                                                    </li>
                                                    <li>
                                                        메이커는 광고 대행 서비스 신청시에 기입한 정보와 관련하여 연락처 등의 변경이 있을 경우 즉시 회사에 이를
                                                        통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한 손해에 대하여 책임을 지지 않습니다.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <h2>제5조 (메이커의 권리/의무)</h2>
                                                <ol>
                                                    <li>
                                                        메이커는 회사가 정하는 광고 대행 서비스 세부 운영정책에 따라 광고 대행 서비스를 이용할 수 있습니다.
                                                    </li>
                                                    <li>
                                                        메이커는 캠페인 2개(오픈예정, 본펀딩)의 총 광고 예산이 300만원 이상인 경우에 한하여 본 약관에서 규정하는
                                                        광고 대행 서비스를 이용할 수 있습니다.
                                                    </li>
                                                    <li>
                                                        메이커는 제1항의 서비스를 이용하는 대가로 회사가 정한 수수료를 지급해야 합니다.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <h2>제6조 (회사의 권리/의무)</h2>
                                                <ol>
                                                    <li>
                                                        회사는 메이커의 요청에 따라 광고 소재를 제작하고 와디즈 비즈센터를 통해 광고 대행 서비스를 제공합니다.
                                                    </li>
                                                    <li>
                                                        회사가 제공하는 광고 대행 서비스는 소재 신규 제작, 소재 수정, 비즈센터 신규 광고 세팅 등이 있으며, 구체적인
                                                        서비스 내용에 대해서는 별도 운영정책 및 가이드를 통해 와디즈 비즈센터에 게시합니다. 단, 위의 서비스 내용은
                                                        변경될 수 있으며, 변경 시행 7일 전 와디즈 비즈센터를 통해 변경 사항을 게시합니다.
                                                    </li>
                                                    <li>
                                                        회사는 메이커의 광고 대행 서비스 이용 신청이 접수 된 후 최대 3일 내에 광고 대행 서비스를 진행합니다. 단,
                                                        실제 광고 집행 일정은 광고 별로 상이할 수 있습니다.
                                                    </li>
                                                    <li>
                                                        회사의 책임 없는 귀책사유로 인하여 발생한 일정 지연, 외부 광고 심의 탈락, 광고 진행 불가 등의 상황에 대해
                                                        회사는 어떠한 책임도 부담하지 않습니다.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <h2>제7조 (서비스 이용 요금 등)</h2>
                                                <ol>
                                                    <li>
                                                        회사가 제공하는 서비스는 유료 서비스로, 메이커는 광고 대행 서비스 이용 요금을 지불하거나 회사가 메이커에게
                                                        지급해야 하는 프로젝트 정산금에서 광고 대행 서비스 이용 요금을 공제해야 합니다.
                                                    </li>
                                                    <li>
                                                        회사는 광고 대행 서비스 이용 요금에 대해 사이트 또는 와디즈 비즈센터에 게시해야 합니다.
                                                    </li>
                                                    <li>
                                                        메이커는 광고 대행 서비스 이용 요금에 관한 모든 세금에 대해 책임을 부담합니다.
                                                    </li>
                                                </ol>
                                            </li>

                                        </ul>
                                    </div>
                                </dd>
                            </dl>
                            <dl className="vertical">
                                <dd>
                                    <div className="form-group">
                                        <div className="comp-checkbox">
                                            <input id="termsBtn" onChange={(e)=> setAgreeYn(e.target.checked)} type="checkbox"/>
                                            <label htmlFor="termsBtn">내용을 모두 확인했으며 이용 사항에 동의합니다. <span className="fc-5">[필수]</span></label>
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </form>
            </section>
            <div className="wrap-btn-group">
                <div className="box-center">
                    <button type="button" className="btn btn-primary xlarge" onClick={onSubmit}>광고 대행 신청</button>
                </div>
            </div>
        </Fragment>
  );
};

export default Make01Req;
