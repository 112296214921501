import React, {
  Fragment, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { useUsableCostQuery } from '@biz/query'
import Fb01 from '../../../../assets/images/Img-Facebook-01.png'
import Fb02 from '../../../../assets/images/Img-Facebook-02.png'
import Fb03 from '../../../../assets/images/Img-Facebook-03.png'
import Fb04 from '../../../../assets/images/Img-Facebook-04.png'
import IcoWabiz from '../../../../assets/images/Ico-logo-wadiz-48x48.png'
import MasonryLayout from './MasonryLayout'
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil2 } from '@biz/api'
import {
  AdminContext,
  CampIdContext,
  CampViewStatusContext,
  CatProhUpdateYnContext,
  CatProhValidDataContext,
  GdnCreationDataIdContext,
} from './Make02Empty'
import { IFileTypes } from '../../fileUploader/MultiFileUploaderGgLogo'
import { uploadFiles } from '../../fileUploader/FileUploaderGgCreation'
import { getByteFunction } from '../../common/CommonFunc'
import { CampInspValids } from './Make02'
import { CreationDataCatProhKwdValid } from './Make02InspReqValidation'
import CatProhKwdDialog from './CatProhKwdDialog'
import CreationDataUpdateConfirmDialog from './CreationDataUpdateConfirmDialog'
import CreationDataInspHisDialog from '../../mngAd/creation/CreationDataInspHisDialog'
import Make02SelfCheckDialog from './Make02SelfCheckDialog'

export interface fbCreationFormData {
  ok: boolean
  campId: number
  id: number
  adTitle01: string
  adTitle02: string
  adDesc: string
  sortOrder: number
  clickBtnDiv: string
  creationViewStatus: string
  adSetDatas: any
  preview: boolean
  campBudgetTotal1: number
  isCampBudgetChange: boolean
  changeCampBudget: number
  clickBtnDivs: string[]
  campStartTime: string
  campEndTime: string
  payType: string
  wabizInspConfirmTime: string
  syncLastTime: string
  editStatus: string
  blockSubMenu: boolean
  projectId: string
}

interface fbCreationFileForm {
  afId: number
}

interface fbPreviewLinkForm {
  ok: boolean
  data: any
  snsAdId: number
  linkUrl: string
}

const fbClickBtnDivMap: any = {
  APPLY_NOW: '지금신청하기',
  RESERVE_NOW_FB: '지금 예약하기',
  DOWNLOAD_FB: '다운로드',
  GET_A_COUPON: '쿠폰 받기',
  LEARN_MORE_FB: '더 알아보기',
  ORDER_NOW: '지금 주문하기',
  REQUEST_RESERVATION: '예약 요청하기',
  SHOPPING_NOW: '지금 쇼핑하기',
  JOIN_FB: '가입하기',
  MORE_VIDEO: '동영상 더 보기',
  BUY_2: '구매하기',
  USE_THE_APP: '앱 사용하기',
  BUY_1: '구매하기',
}
const FbAdSetCreatinoStatusMap: any = {
  temp: '등록대기',
  insp_none: '검수전',
  insp_ing: '검수중',
  reg: '등록완료 프로젝트',
  reg_insp: '재검수중',
  reject: '반려',
  stop_creation_off: '중단(소재OFF)',
  stop_ad_set_off: '중단(광고세트OFF)',
  stop_camp_off: '중단(캠페인OFF)',
  stop_camp_range: '중단(캠페인기간)',
}
const FbAdSetCreatinoStatusTooltipMap: any = {
  insp_none: '아직 와디즈에 검수를 요청하지 않은 소재입니다. 우측 상단의 ‘검수요청‘ 버튼을 눌러 검수를 시작하세요',
  insp_ing: '와디즈에서 검수중입니다.',
  reg: '검수를 통과하였습니다.',
  reg_insp: '변경 소재를 와디즈에서 검수중입니다.',
  reject:
    '소재가 반려되었습니다. 소재 변경 후 재검수를 요청하세요. <br> * SNS 매체에서 반려되어 재검수 신청 후에도 장시간 반려상태가 유지되면 관리자에게 문의하세요.',
  stop_creation_off: '소재가 OFF되었습니다.',
  stop_ad_set_off: '광고세트가 OFF되었습니다.',
  stop_camp_off: '캠페인이 OFF되었습니다.',
  stop_camp_range: '프로젝트 기간이 변경되었습니다. 캠페인 기간을 재설정해주세요.',
}

interface fbAdSetCreationOnOffUpdate {
  ok: boolean
  creationLinkId: number
  useYn: boolean
}

interface calculateBalance {
  ok: boolean
  data: any
  campId: number
  changeCampBudget: number
  cost: number
}

interface CampBizmoneyInfo {
  ok: boolean
  data: any
  currentCost: number
}

const Make02Step03CreationFb = (props: any) => {
  const { campIdParam } = useContext(CampIdContext)
  const { campViewStatusParam } = useContext(CampViewStatusContext)
  const { adminParam } = useContext(AdminContext)
  const { setGdnCreationDataId } = useContext(GdnCreationDataIdContext)
  const { update: setUsableCostChange } = useUsableCostQuery()
  const { catProhValidData, setCatProhValidData } = useContext(CatProhValidDataContext)
  const { catProhUpdateYn, setCatProhUpdateYn } = useContext(CatProhUpdateYnContext)
  const { register, setValue, getValues } = useForm<fbCreationFormData>()
  const fbCreationFileInfo = useForm<fbCreationFileForm>()
  const fbPreviewLinkInfo = useForm<fbPreviewLinkForm>()
  // 각 광고세트별 소재 onoff
  const fbAdSetCreationOnOffUpdateForm = useForm<fbAdSetCreationOnOffUpdate>()
  const calculateBalanceForm = useForm<calculateBalance>()
  const [previewRatio, setPreviewRatio] = useState<number>(0)
  const [nonEditable, setNonEditable] = useState<boolean>(false)
  const [blockSubMenu, setBlockSubMenu] = useState<boolean>(false)
  const [wabizInspConfirmYn, setWabizInspConfirmYn] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [updateConfirmDialog, setUpdateConfirmDialog] = useState<boolean>(false)
  const [previewMode, setPreviewMode] = useState<any>()
  const [previewImage, setPreviewImage] = useState<any>()
  const [previewFileDiv, setPreviewFileDiv] = useState<string>('IMG')
  const [previewExt, setPreviewExt] = useState<string>()
  const [creationStatusColor, setCreationStatusColor] = useState<any>('green')
  const [statusChangeBtn, setStatusChangeBtn] = useState<any>('변경요청')
  const [statusCreationView, setStatusCreationView] = useState<any>('')
  const [afList, setAfList] = useState<IFileTypes[]>([])
  const [clickBtnDivsMap, setClickBtnDivsMap] = useState<any>(new Map())
  // 광고세트1 on/off
  const [checkedInput1, setCheckedInput1] = useState<boolean>(false)
  // 광고세트2 on/off
  const [checkedInput2, setCheckedInput2] = useState<boolean>(false)
  // 광고세트3 on/off
  const [checkedInput3, setCheckedInput3] = useState<boolean>(false)
  const [catProhValidErrorAdDesc, setCatProhValidErrorAdDesc] = useState<boolean>(false)
  const [catProhValidErrorAdTitle01, setCatProhValidErrorAdTitle01] = useState<boolean>(false)
  const [catProhValidErrorAdTitle02, setCatProhValidErrorAdTitle02] = useState<boolean>(false)
  const [adDescText, setAdDescText] = useState<string>('')
  const [adTitle01Text, setAdTitle01Text] = useState<string>('')
  const [adTitle02Text, setAdTitle02Text] = useState<string>('')
  const [isCatErrLoading, setIsCatErrLoading] = useState<boolean>(false)
  const [isCatProhKwdDialog, setIsCatProhKwdDialog] = useState<boolean>(false)
  const [catProhKwd, setCatProhKwd] = useState<any>([])
  const [category, setCategory] = useState<string>('')

  // 검수 이력 다이얼로그 관련
  const [showCreationDataInspHisDialog, setShowCreationDataInspHisDialog] = useState<boolean>(false)

  //셀프체크리스트 다이얼로그 관련
  const [selfCheckDialog, setSelfCheckDialog] = useState<boolean>(false)

  const makerFbCreationFileList = async () => {
    const { data } = await ApiUtil2.get<uploadFiles>('/api/camp/make02/makerFbCreationFileList', {})
    return data
  }

  const makerFbCreationFileListApi = useQuery(['makerFbCreationFileList'], makerFbCreationFileList, {
    enabled: false,
  })

  const creationDataCatProhKwdValidApi = async () => {
    const { data } = await ApiUtil2.get<CreationDataCatProhKwdValid>('/api/camp/make02/creationDataCatProhKwdValid', {
      params: { campId: campIdParam, creationDataId: props.creationData.id },
    })
    return data
  }

  const creationDataCatProhKwdValidEvent = useQuery(
    ['creationDataCatProhKwdValidApi'],
    creationDataCatProhKwdValidApi,
    {
      enabled: false,
    }
  )

  const dialogOpen = (refId: any) => {
    // 소재 creationDataId 세팅
    setGdnCreationDataId(refId)
    makerFbCreationFileListApi.refetch().then((r) => {
      if (r.isSuccess) {
        // 메이커 파일 데이터를 세팅
        props.setMakerFilesEvent(r.data.data)
        if (afList.length === 0) {
          $('#creationFileUploadFbDialog').dialog({ width: 1200, title: '', closeOnEscape: true })
          $('#creationFileUploadFbDialog').dialog('open')
        } else {
          if (afList.length >= 10) {
            alertify.error('슬라이드의 경우, 10장 이내의 소재만 등록 가능합니다.')
            return
          }

          for (const af of afList) {
            if (af.afMeta.width / af.afMeta.height !== 1 / 1) {
              alertify.error('슬라이드의 경우 1:1 규격의 이미지/영상 첨부만 가능합니다.')
              return
            }
          }
          $('#creationFileUploadFbDialog').dialog({ width: 1200, title: '', closeOnEscape: true })
          $('#creationFileUploadFbDialog').dialog('open')
        }
      }
    })
  }
  // 비즈머니 or 후불머니
  const getMoney = async () => {
    const payType = props.payType
    const { data } = await ApiUtil.get<CampBizmoneyInfo>('/api/camp/make02/getMoneyInfo', {
      params: { campId: campIdParam, payType },
    })
    return data
  }
  const bizmoneyApi = useQuery(['getBizmoney'], getMoney, { enabled: false })
  // 수정
  const fbCreationDataUpdateApi = () => {
    const { campId, id, adTitle01, adTitle02, adDesc, sortOrder, clickBtnDiv } = getValues()
    const result = ApiUtil2.post<fbCreationFormData>('/api/camp/make02/fbCreationDataUpdate', {
      campId,
      id,
      adTitle01,
      adTitle02,
      adDesc,
      sortOrder,
      clickBtnDiv,
    })
    return result
  }
  const fbCreationDataUpdate = useMutation(fbCreationDataUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 수정
        props.creationComponentRefresh()
        setCatProhUpdateYn(false)
      }
    },
  })
  // 삭제
  const fbCreationDataDeleteApi = () => {
    const { campId, id } = getValues()
    const result = ApiUtil2.post<fbCreationFormData>('/api/camp/make02/fbCreationDataDelete', {
      campId,
      id,
    })
    return result
  }
  const fbCreationDataDelete = useMutation(fbCreationDataDeleteApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 삭제
        props.removeCreationFbEvent(props.order)
        setCatProhUpdateYn(false)
      }
    },
  })
  // 카피 처리
  const fbCreationDataCopyApi = () => {
    const { campId, id, adTitle01, adTitle02, adDesc, sortOrder, clickBtnDiv, isCampBudgetChange, changeCampBudget } =
      getValues()
    const result = ApiUtil2.post<fbCreationFormData>('/api/camp/make02/fbCreationDataInsert', {
      campId,
      id,
      adTitle01,
      adTitle02,
      adDesc,
      sortOrder,
      clickBtnDiv,
      isCampBudgetChange,
      changeCampBudget,
    })
    return result
  }
  const fbCreationDataCopy = useMutation(fbCreationDataCopyApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 카피
        // 상단 비즈머니 업데이트
        if (getValues('isCampBudgetChange') && props.payType === 'BIZ_MONEY') {
          bizmoneyApi.refetch().then((r) => {
            if (r.isSuccess) {
              setUsableCostChange(r.data.data.currentCost.toLocaleString())
            }
          })
        }
        setValue('isCampBudgetChange', false)
        props.creationComponentRefresh()
        setCatProhUpdateYn(false)
      }
    },
  })
  // 소재 파일 삭제 처리
  const fbCreationFileDeleteApi = () => {
    const { afId } = fbCreationFileInfo.getValues()
    const result = ApiUtil2.post<any>('/api/camp/make02/fbCreationFileDelete', {
      afId,
      campId: campIdParam,
      creationDataId: props.creationData.id,
    })
    return result
  }
  const fbCreationFileDelete = useMutation(fbCreationFileDeleteApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
        props.creationComponentRefresh()
      }
    },
  })

  const fbAdSetCreationOnOffUpdateApi = () => {
    const { creationLinkId, useYn } = fbAdSetCreationOnOffUpdateForm.getValues()
    const result = ApiUtil2.post<fbAdSetCreationOnOffUpdate>('/api/camp/make02/fbAdSetCreationOnOffUpdate', {
      creationLinkId,
      useYn,
      campId: campIdParam,
    })
    return result
  }
  const fbAdSetOnOffCreationUpdateEvent = useMutation(fbAdSetCreationOnOffUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
        setCatProhUpdateYn(false)
      }
    },
  })

  const fbPreviewLinkApi = async () => {
    const { data } = await ApiUtil2.get<fbPreviewLinkForm>('/api/camp/make02/fbPreviewLink', {
      params: {
        data: {
          campId: campIdParam,
          snsAdId: fbPreviewLinkInfo.getValues('snsAdId'),
        },
      },
    })
    return data
  }
  const fbPreviewLinkApiEvent = useQuery(['fbPreviewLinkApi'], fbPreviewLinkApi, {
    enabled: false,
  })

  const calculateBalanceApi = async () => {
    const { data } = await ApiUtil2.get<calculateBalance>('/api/camp/make02/calculateBalance', {
      params: {
        campId: campIdParam,
        changeCampBudget: calculateBalanceForm.getValues('changeCampBudget'),
      },
    })
    return data
  }
  const calculateBalanceEvent = useQuery(['calculateBalanceApi'], calculateBalanceApi, {
    enabled: false,
  })

  const fbCreationLinkStatusUpdateReqApi = () => {
    const { id } = getValues()
    const result = ApiUtil2.post<fbAdSetCreationOnOffUpdate>('/api/camp/make02/fbCreationLinkStatusUpdateReq', {
      id,
      campId: campIdParam,
    })
    return result
  }
  const fbCreationLinkStatusUpdateReqEvent = useMutation(fbCreationLinkStatusUpdateReqApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
        props.creationComponentRefresh()
        setCatProhUpdateYn(false)
      }
    },
  })

  const removeFbCreationComponent = () => {
    if (props.maxSize > 1) {
      alertify
        .confirm('해당 광고를 삭제하시겠습니까?', () => {
          fbCreationDataDelete.mutate()
          setCatProhUpdateYn(false)
        })
        .set({ labels: { cancel: '취소', ok: '삭제' } })
        .setHeader('')
    }
  }

  const previewModeChange = () => {
    setPreviewMode(false)
    setValue('preview', false)
    setGdnCreationDataId(getValues('id'))
    if (wabizInspConfirmYn) {
      setBlockSubMenu(false)
      setEditMode(props.creationData.editStatus === 'editing' ? true : false)
    }
  }

  const updateFbCreationComponent = (event: any) => {
    if (event.currentTarget.id === `creationBody_${props.order}`) {
      if (nonEditable) {
        alertify.alert(
          '소재 수정 불가',
          '승인된 소재 정보를 광고 매체에 전달 중입니다.<br><br>정상적으로 소재 정보 전달이 완료 되기 전까지 소재 정보 수정이 불가 합니다. <br><br>정보 전송은 약 10분 정도 진행 되오니 이후 다시 시도해 주세요.<br>'
        )
        return
      }
      if (!nonEditable && wabizInspConfirmYn) {
        setUpdateConfirmDialog(true)
      } else {
        previewModeChange()
      }
    } else if (event.currentTarget.id === `updateModeCreation_${props.order}`) {
      if (!nonEditable && wabizInspConfirmYn) {
        setUpdateConfirmDialog(true)
      } else {
        previewModeChange()
      }
    } else {
      setPreviewMode(editMode ? false : true)
      setValue('preview', editMode ? false : true)
      if (wabizInspConfirmYn) setBlockSubMenu(nonEditable ? true : false)
    }
    setCatProhUpdateYn(false)
  }
  const previewFbCreationComponent = (event: any) => {
    if (event.currentTarget.value === props.order) {
      setPreviewMode(true)
      setValue('preview', true)
      if (wabizInspConfirmYn) {
        setBlockSubMenu(false)
        setEditMode(false)
      }
    }
  }
  const facebookPreviewLink = (event: any) => {
    fbPreviewLinkInfo.setValue('snsAdId', event.currentTarget.dataset.snsadid)
    fbPreviewLinkApiEvent.refetch().then((r) => {
      if (r.isSuccess) {
        window.open(r.data.data.linkUrl)
      }
    })
  }
  const copyFbCreationComponent = () => {
    if (props.maxSize < 10) {
      setValue('sortOrder', props.maxSize + 1)
      // 기타 상태일 경우
      if (
        props.creationData.campBudget !== undefined &&
        campViewStatusParam !== undefined &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        campViewStatusParam !== 'del_ready' &&
        campViewStatusParam !== 'del_complete' &&
        !blockSubMenu
      ) {
        // const chaBudget = (props.maxSize + 1) * 5000;
        // // 현재 설정된 예산체크 추가했을때 예산이 초과되면??
        // if (props.creationData.campBudget < chaBudget) {
        //   const changeCampBudgetTxt = (props.creationData.campBudget + 5000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        //   const changeCampBudget = props.creationData.campBudget + 5000;
        //   calculateBalanceForm.setValue('changeCampBudget', changeCampBudget);
        //   calculateBalanceEvent.refetch().then((r) => {
        //     if (r.isSuccess) {
        //       alertify.confirm(`소재당 1일 최소 5천원의 예산이 필요합니다. 캠페인 총 예산을 ${changeCampBudgetTxt}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 ${props.payType === 'POST_PAYMENT'? '후불머니' : '비즈머니'} 에서 ${r.data.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 (VAT포함)이 차감됩니다.`, () => {
        //         setValue('changeCampBudget', changeCampBudget);
        //         setValue('isCampBudgetChange', true);
        //         fbCreationDataCopy.mutate();
        //       }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        //     }
        //   });
        // } else {
        //   fbCreationDataCopy.mutate();
        // }

        calculateBalanceEvent.refetch().then((r) => {
          if (r.isSuccess) {
            if (r.data.data.additionalCharge) {
              alertify
                .confirm(
                  `소재당 1일 최소 5천원의 예산이 필요합니다. 캠페인 총 예산을${r.data.data.changeCampBudgetTotal1.toLocaleString()}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 ${
                    getValues('payType') === 'POST_PAYMENT' ? '후불머니' : '비즈머니'
                  } 에서 ${r.data.data.cost.toLocaleString()} 원 (VAT포함)이 차감됩니다.`,
                  () => {
                    setValue('isCampBudgetChange', true)
                    fbCreationDataCopy.mutate()
                  }
                )
                .set({ labels: { cancel: '취소', ok: '확인' } })
                .setHeader('')
            } else {
              fbCreationDataCopy.mutate()
            }
          }
        })
      } else {
        fbCreationDataCopy.mutate()
      }
    } else {
      alertify.error('소재는 10개까지 추가 가능합니다.')
    }
    setCatProhUpdateYn(false)
  }
  const changeCreationData = () => {
    fbCreationDataUpdate.mutate()
    setCatProhUpdateYn(false)
  }

  // 등록된 소재 이미지 삭제 이벤트
  const creationFileDeleteEvent = (fileId: any) => {
    fbCreationFileInfo.setValue('afId', fileId)
    fbCreationFileDelete.mutate()
    setCatProhUpdateYn(false)
  }
  const setEvent = () => {
    if (
      campViewStatusParam === 'complete' ||
      campViewStatusParam === 'del_ready' ||
      campViewStatusParam === 'del_complete' ||
      (!adminParam.isAdmin && adminParam.forAdmin)
    ) {
      return
    }
    // 수정 불가능 상태일때도 클릭은 가능하도록.
    if (nonEditable) {
      $('[id^=creationBody_]').on('click', updateFbCreationComponent)
      return
    }
    $(`#removeCreation_${props.order}`).off('click').on('click', removeFbCreationComponent)
    $(`#copyCreation_${props.order}`).off('click').on('click', copyFbCreationComponent)
    $(`#previewCreation_${props.order}`).off('click').on('click', previewFbCreationComponent)
    $(`#facebookPreview_${props.order}`).off('click').on('click', facebookPreviewLink)

    $('[id^=creationBody_]').on('click', updateFbCreationComponent)
    $('[id^=updateModeCreation_]').on('click', updateFbCreationComponent)
  }

  let adTitle01 = ''
  let adTitle02 = ''
  let adDesc = ''
  const checkByteEvent = useCallback((id: any, value: any) => {
    const checkByteId = id
    const checkByteValue = value
    const registerName = id.split('_')[0]
    const byteTextHtml = document.getElementById(`${checkByteId}_Byte`) as HTMLInputElement
    let maxLength = 0
    if (checkByteId.split('_')[0] === 'adDesc') {
      maxLength = 2000
    } else if (checkByteId.split('_')[0] === 'adTitle01') {
      maxLength = 255
    } else {
      maxLength = 10000
    }
    // 이거 해줘야 데이터 등록됨
    if (registerName === 'adTitle01') {
      adTitle01 = value
    } else if (registerName === 'adTitle02') {
      adTitle02 = value
    } else {
      adDesc = value
    }
    setValue(registerName, value)
    if (getByteFunction(checkByteValue) > maxLength) {
      if (registerName === 'adTitle01') {
        setValue(registerName, adTitle01)
      } else if (registerName === 'adTitle02') {
        setValue(registerName, adTitle02)
      } else {
        setValue(registerName, adDesc)
      }
      alertify.alert('입력 최대치 도달', '더 이상 텍스트를 추가 할 수 없습니다.')
      return false
    }
    byteTextHtml.innerText = getByteFunction(checkByteValue).toString()
    return true
  }, [])

  // 소재별 검수 요청
  const creationInspValidData = async () => {
    const { data } = await ApiUtil2.get<CampInspValids>('/api/camp/make02/creationInspValid', {
      params: {
        campId: campIdParam,
        creationDataId: getValues('id'),
      },
    })
    return data
  }
  const creationInspValidDataApi = useQuery(['creationInspValidData'], creationInspValidData, {
    enabled: false,
  })
  const creationInspValidDataApiEvent = () => {
    // 개별 검수 요청 로직 추가
    creationInspValidDataApi.refetch().then((r) => {
      if (r.isSuccess) {
        if (r.data.data.creationCnt !== r.data.data.creationCorrectCnt) {
          alertify.error(`소재의 검수가 통과되지 않았습니다. <br/>소재ID : ${getValues('id')}`)
        } else {
          fbCreationLinkStatusUpdateReqEvent.mutate()
        }
      }
    })
  }
  const validCheckEvent = () => {
    creationDataCatProhKwdValidEvent.refetch().then((r) => {
      if (r.isSuccess) {
        if (r.data.data.catProhKwdValid) {
          creationInspValidDataApiEvent()
        } else {
          if (!r.data.data.catProhKwdValid) {
            setCatProhValidData(r.data.data)
            setCatProhKwd(r.data.data.catProhKwd)
            setCategory(r.data.data.category)
            setIsCatProhKwdDialog(true)
          }
        }
      }
    })
  }
  const creationStatusUpdate = (e: any) => {
    const status = e.currentTarget.value
    let statusChangeMsg = ''
    if (
      status === 'stop_creation_off' ||
      status === 'stop_ad_set_off' ||
      status === 'stop_camp_off' ||
      status === 'stop_camp_range'
    ) {
      statusChangeMsg = '소재 변경을 신청합니다.<br/>중단 사유가 해제되야 노출이 가능합니다.'
    } else if (status === 'temp' || status === 'insp_none' || status === 'reject') {
      statusChangeMsg = '소재 검수를 신청합니다.'
    } else {
      statusChangeMsg = '소재 변경을 신청합니다.<br/>검수를 통과하면 신규 소재로 대체됩니다.'
    }
    alertify
      .confirm(statusChangeMsg, () => {
        if (afList.length === 0) {
          alertify.error('이미지를 추가해주세요.')
          return
        }
        validCheckEvent()
      })
      .set({ labels: { cancel: '취소', ok: '신청' } })
      .setHeader('')
  }

  $('.tooltip-btn').tooltip({
    content: '<p class="fz-12 fc-0">클립보드에 복사</p>',
  })

  const handleCopyClipBoard = (id: string) => {
    const dumTA = document.createElement('input')
    document.body.appendChild(dumTA)
    dumTA.value = id
    dumTA.select()
    document.execCommand('copy')
    dumTA.remove()
    alertify.success('소재ID를 복사하였습니다.')
  }

  useEffect(() => {
    // 최초 진입시 모든 데이터 전달
    if (campIdParam !== null) {
      setValue('campId', campIdParam)
    }
    setValue('id', props.creationData.id)
    setValue('adTitle01', props.creationData.adTitle01)
    setValue('adTitle02', props.creationData.adTitle02)
    setValue('adDesc', props.creationData.adDesc)
    setValue('sortOrder', props.creationData.sortOrder)
    setValue('clickBtnDiv', props.creationData.clickBtnDiv)
    setValue('creationViewStatus', props.creationData.creationViewStatus)
    setValue('preview', props.creationData.preview)
    setValue('adSetDatas', props.creationData.adSetDatas)
    setValue('clickBtnDivs', props.creationData.clickBtnDivs)
    setValue('wabizInspConfirmTime', props.creationData.wabizInspConfirmTime)
    setValue('syncLastTime', props.creationData.syncLastTime)
    setValue('editStatus', props.creationData.editStatus)
    setValue('blockSubMenu', props.creationData.blockSubMenu)

    setEditMode(props.creationData.editStatus === 'editing' ? true : false)
    setNonEditable(props.creationData.nonEditable)
    setBlockSubMenu(props.creationData.blockSubMenu)
    setWabizInspConfirmYn(props.creationData.wabizInspConfirmTime ? true : false)
    setAfList(props.creationData.afList)
    setPreviewMode(props.creationData.preview)
    setStatusCreationView(props.creationData.creationViewStatus)
    setAdDescText(props.creationData.adDesc)
    setAdTitle01Text(props.creationData.adTitle01)
    setAdTitle02Text(props.creationData.adTitle02)
  }, [])

  useEffect(() => {
    // 광고세트별 소재상태에 대한 부분처리
    if (getValues('adSetDatas') !== undefined) {
      getValues('adSetDatas')
        .filter((f: any) => `${f.creationDataId}` === `${props.creationData.id}`)
        .forEach((data: any) => {
          $(`#dropdown_${props.order}`).on('click', (event) => {
            // 클릭시 드랍다운 창이 닫히기 때문에 해당 부분 처리
            event.stopPropagation()
          })
          // 여기서 소재별 on/off 이벤트 처리
          $(`#snsAdId${data.adSetOrder}_${props.order}_${data.snsAdId}`).on('click', function (e: any) {
            e.preventDefault()
            e.stopPropagation()
            const id = $(this).prop('id').split('_')[2]
            handleCopyClipBoard(id)
          })
          $(`#fbAdSetS${data.adSetOrder}_${props.order}_${data.id}`).on('change', (e) => {
            const { checked }: any = e.currentTarget
            const adSetOrder: any = e.currentTarget.id.split('_')[0]
            const creationLinkId: any = e.currentTarget.id.split('_')[2]

            const msg = checked ? 'ON' : 'OFF'
            if (adSetOrder === 'fbAdSetS1') {
              setCheckedInput1(checked)
              alertify.success(`논타겟 광고세트 소재가 ${msg} 되었습니다.`)
            } else if (adSetOrder === 'fbAdSetS2') {
              setCheckedInput2(checked)
              alertify.success(`리타겟 광고세트 소재가 ${msg} 되었습니다.`)
            } else {
              setCheckedInput3(checked)
              alertify.success(`리타겟 광고세트(추가) 소재가 ${msg} 되었습니다.`)
            }
            fbAdSetCreationOnOffUpdateForm.setValue('creationLinkId', creationLinkId)
            fbAdSetCreationOnOffUpdateForm.setValue('useYn', checked)
            fbAdSetOnOffCreationUpdateEvent.mutate()
          })
          if (data.adSetOrder === 1) {
            setCheckedInput1(data.useYn)
          } else if (data.adSetOrder === 2) {
            setCheckedInput2(data.useYn)
          } else {
            setCheckedInput3(data.useYn)
          }
          const toolTipText = FbAdSetCreatinoStatusTooltipMap[data.creationViewStatus]
          if (toolTipText !== undefined) {
            $(`#fbAdSet${data.adSetOrder}Tooltip_${props.order}`).tooltip({
              content: `<p class="fz-12 fc-0">${toolTipText}</p>`,
            })
          }
          // 소재 상태에 따른 색상 및 버튼 명칭 빨강 > 주황 > 녹색 순
          if (
            data.creationViewStatus === 'temp' ||
            data.creationViewStatus === 'insp_none' ||
            data.creationViewStatus === 'reject'
          ) {
            setCreationStatusColor('red')
            setStatusChangeBtn('검수요청')
            setStatusCreationView(data.creationViewStatus)
            return
          }
          if (
            data.creationViewStatus === 'stop_creation_off' ||
            data.creationViewStatus === 'stop_ad_set_off' ||
            data.creationViewStatus === 'stop_camp_off' ||
            data.creationViewStatus === 'stop_camp_range'
          ) {
            setCreationStatusColor('orange')
            setStatusChangeBtn('변경요청')
            setStatusCreationView(data.creationViewStatus)
          }
          setStatusCreationView(data.creationViewStatus)
        })
    }
  }, [getValues('adSetDatas')])

  useEffect(() => {
    if (previewMode !== undefined && !props.creationData.clickBtnDivs !== undefined) {
      const clickBtnDivInfos: any[] = []
      const tempArray = new Map(clickBtnDivsMap)
      // 페이스북 인스타 행동유도버튼 관련
      props.creationData.clickBtnDivs.forEach((data: string) => {
        clickBtnDivInfos.push({ id: data, text: fbClickBtnDivMap[data] })
        tempArray.set(data, fbClickBtnDivMap[data])
      })
      setClickBtnDivsMap(tempArray)
      $(`#fbActionButton_${props.order}`)
        .select2({
          width: '150px',
          minimumResultsForSearch: Infinity,
          data: clickBtnDivInfos,
        })
        .on('select2:select', (e) => {
          setValue('clickBtnDiv', e.params.data.id)
          changeCreationData()
        })
      $(`#fbActionButton_${props.order}`).val(getValues('clickBtnDiv')).trigger('change')
      setEvent()
      MasonryLayout()
    }
    if (afList.length > 0) {
      afList.forEach((file, index) => {
        if (index === 0) {
          // setPreviewRatio(Math.round(file.afMeta.width / file.afMeta.height * 100) / 100);
          setPreviewImage(file.fileName)
          setPreviewFileDiv(file.afMeta.fileDiv)
          setPreviewExt(file.afMeta.ext)
        }
      })
    }
    // 파일 이미지 휴지통 버튼 클릭
    $(`#creationFileDelete_${props.order}`).on('click', (e) => {
      const eventTargetCreationDataId = e.currentTarget.dataset.creationdataid
      const imageActive = document.querySelectorAll('li.active')

      imageActive.forEach((list) => {
        const ele = list as HTMLElement
        if (eventTargetCreationDataId !== ele.dataset.creationdataid) return
        if (list.id.startsWith('fileLiKey_')) {
          const delAfId = list.id.split('_')[1]
          creationFileDeleteEvent(delAfId)
        }
      })
    })
    if (getValues('adTitle01') !== undefined) {
      $(`#adTitle01_${props.order}_Byte`).text(getByteFunction(getValues('adTitle01')))
    }
    if (getValues('adTitle02') !== undefined) {
      $(`#adTitle02_${props.order}_Byte`).text(getByteFunction(getValues('adTitle02')))
    }
    if (getValues('adDesc') !== undefined) {
      $(`#adDesc_${props.order}_Byte`).text(getByteFunction(getValues('adDesc')))
    }
  }, [previewMode])

  const catProhKwdCheckAdDesc = (kwds: string[]) => {
    let text = adDescText
    kwds.some((kwd) => {
      if (getValues('adDesc').includes(kwd)) {
        setCatProhValidErrorAdDesc(true)
        text = text.replaceAll(kwd, `<span class="fc-7 fw-bold txt-underline">${kwd}</span>`)
      }
    })
    setAdDescText(text)
  }
  const catProhKwdCheckAdTitle01 = (kwds: string[]) => {
    let text = adTitle01Text
    kwds.some((kwd) => {
      if (getValues('adTitle01').includes(kwd)) {
        setCatProhValidErrorAdTitle01(true)
        text = text.replaceAll(kwd, `<span class="fc-7 fw-bold txt-underline">${kwd}</span>`)
      }
    })
    setAdTitle01Text(text)
  }
  const catProhKwdCheckAdTitle02 = (kwds: string[]) => {
    let text = adTitle02Text
    kwds.some((kwd) => {
      if (getValues('adTitle02').includes(kwd)) {
        setCatProhValidErrorAdTitle02(true)
        text = text.replaceAll(kwd, `<span class="fc-7 fw-bold txt-underline">${kwd}</span>`)
      }
    })
    setAdTitle02Text(text)
  }
  useEffect(() => {
    if (
      !isCatErrLoading &&
      catProhUpdateYn &&
      catProhValidData !== null &&
      catProhValidData !== undefined &&
      adDescText !== ''
    ) {
      const data: CreationDataCatProhKwdValid = catProhValidData as CreationDataCatProhKwdValid
      const creationDataValid = data.catProhKwdValidsByCreationData[props.creationData.id]
      if (
        creationDataValid !== null &&
        creationDataValid !== undefined &&
        !data.catProhKwdValidsByCreationData[props.creationData.id]
      ) {
        catProhKwdCheckAdDesc(data.catProhKwd)
        catProhKwdCheckAdTitle01(data.catProhKwd)
        catProhKwdCheckAdTitle02(data.catProhKwd)
        setIsCatErrLoading(true)
      }
    }
  })
  return (
    <Fragment>
      {selfCheckDialog ? (
        <Make02SelfCheckDialog
          setSelfCheckDialog={setSelfCheckDialog}
          campId={campIdParam}
          inspValidEvent={creationInspValidDataApiEvent}
        />
      ) : null}
      {isCatProhKwdDialog ? (
        <CatProhKwdDialog
          catProhKwds={catProhKwd}
          setIsCatProhKwdDialog={setIsCatProhKwdDialog}
          category={category}
          setSelfCheckDialog={setSelfCheckDialog}
          inspValidEvent={creationInspValidDataApiEvent}
          showSelfCheck={false}
        />
      ) : null}
      {updateConfirmDialog ? (
        <CreationDataUpdateConfirmDialog
          setUpdateConfirmDialog={setUpdateConfirmDialog}
          previewModeChange={previewModeChange}
          setEditMode={setEditMode}
        />
      ) : null}
      {showCreationDataInspHisDialog ? (
        <CreationDataInspHisDialog
          setShowCreationDataInspHisDialog={setShowCreationDataInspHisDialog}
          creationDataId={props.creationData.id}
          searchType="maker"
        />
      ) : null}
      <div className="masonry-item" style={{ gridRowEnd: 'span 90' }}>
        <div className={`wrap-preview ${editMode ? 'edit' : ''}`}>
          <div className="box-header">
            <div className="box-tit">
              {campViewStatusParam !== null &&
              campViewStatusParam !== 'temp' &&
              campViewStatusParam !== 'complete' &&
              campViewStatusParam !== 'del_ready' &&
              campViewStatusParam !== 'del_complete' ? (
                blockSubMenu ? (
                  <div className="comp-dropdown">
                    <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span className="fz-12 fc-1 txt-underline">소재상태</span>
                      <span className={`badge ${creationStatusColor}`}></span>
                    </Link>
                  </div>
                ) : (
                  <div className="comp-dropdown">
                    <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span className="fz-12 fc-1 txt-underline">소재상태</span>
                      <span className={`badge ${creationStatusColor}`}></span>
                    </Link>
                    <div className="dropdown-menu" id={`dropdown_${props.order}`}>
                      <div className="opt-setting">
                        {getValues('adSetDatas') !== undefined
                          ? getValues('adSetDatas').map((data: any) => {
                              let statusColor = 'orange'
                              if (
                                data.creationViewStatus === 'insp_ing' ||
                                data.creationViewStatus === 'reg' ||
                                data.creationViewStatus === 'reg_insp'
                              ) {
                                statusColor = 'green'
                              } else if (
                                data.creationViewStatus === 'temp' ||
                                data.creationViewStatus === 'insp_none' ||
                                data.creationViewStatus === 'reject'
                              ) {
                                statusColor = 'red'
                              }
                              if (data.adSetOrder === 1) {
                                return (
                                  <div className="box-group" key={data.id}>
                                    <div className="box-left">
                                      <span className="fz-12 fc-4">논타겟 광고세트</span>
                                    </div>
                                    <div className="box-right">
                                      <div className="box-txt">
                                        <div className="box-group">
                                          <span className={`badge ${statusColor}`}></span>
                                          <span className="fz-12 fc-2">
                                            {FbAdSetCreatinoStatusMap[data.creationViewStatus]}
                                          </span>
                                          <Link
                                            to="#!"
                                            className="ico-tooltip tooltip-f"
                                            id={`fbAdSet${data.adSetOrder}Tooltip_${props.order}`}
                                            title=""
                                          />
                                        </div>
                                        <div className="box-group">
                                          <span className="fz-12 fc-4">SNS 소재 ID</span>
                                          <span className="fz-12 fc-2">
                                            {data.snsAdId === undefined ? '-' : data.snsAdId}
                                          </span>
                                          {data.snsAdId === undefined ? (
                                            ''
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-secondary-txt btn-ico xsmall single tooltip-btn tooltip-f"
                                            >
                                              <i
                                                id={`snsAdId${data.adSetOrder}_${props.order}_${data.snsAdId}`}
                                                className="ico ico-copy"
                                              />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                      <div className="comp-switch">
                                        <input
                                          type="checkbox"
                                          id={`fbAdSetS${data.adSetOrder}_${props.order}_${data.id}`}
                                          checked={checkedInput1}
                                          readOnly={true}
                                        />
                                        <label htmlFor={`fbAdSetS${data.adSetOrder}_${props.order}_${data.id}`}>
                                          <i className="ico"></i>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              if (data.adSetOrder === 2) {
                                return (
                                  <div className="box-group" key={data.id}>
                                    <div className="box-left">
                                      <span className="fz-12 fc-4">리타겟 광고세트</span>
                                    </div>
                                    <div className="box-right">
                                      <div className="box-txt">
                                        <div className="box-group">
                                          <span className={`badge ${statusColor}`}></span>
                                          <span className="fz-12 fc-2">
                                            {FbAdSetCreatinoStatusMap[data.creationViewStatus]}
                                          </span>
                                          <Link
                                            to="#!"
                                            className="ico-tooltip tooltip-f"
                                            id={`fbAdSet${data.adSetOrder}Tooltip_${props.order}`}
                                            title=""
                                          />
                                        </div>
                                        <div className="box-group">
                                          <span className="fz-12 fc-4">SNS 소재 ID</span>
                                          <span className="fz-12 fc-2">
                                            {data.snsAdId === undefined ? '-' : data.snsAdId}
                                          </span>
                                          {data.snsAdId === undefined ? (
                                            ''
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-secondary-txt btn-ico xsmall single tooltip-btn tooltip-f"
                                            >
                                              <i
                                                id={`snsAdId${data.adSetOrder}_${props.order}_${data.snsAdId}`}
                                                className="ico ico-copy"
                                              />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                      <div className="comp-switch">
                                        <input
                                          type="checkbox"
                                          id={`fbAdSetS${data.adSetOrder}_${props.order}_${data.id}`}
                                          checked={checkedInput2}
                                          readOnly={true}
                                        />
                                        <label htmlFor={`fbAdSetS${data.adSetOrder}_${props.order}_${data.id}`}>
                                          <i className="ico"></i>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              return (
                                <div className="box-group" key={data.id}>
                                  <div className="box-left">
                                    <span className="fz-12 fc-4">리타겟 광고세트 (추가)</span>
                                  </div>
                                  <div className="box-right">
                                    <div className="box-txt">
                                      <div className="box-group">
                                        <span className={`badge ${statusColor}`}></span>
                                        <span className="fz-12 fc-2">
                                          {FbAdSetCreatinoStatusMap[data.creationViewStatus]}
                                        </span>
                                        <Link
                                          to="#!"
                                          className="ico-tooltip tooltip-f"
                                          id={`fbAdSet${data.adSetOrder}Tooltip_${props.order}`}
                                          title=""
                                        />
                                      </div>
                                      <div className="box-group">
                                        <span className="fz-12 fc-4">SNS 소재 ID</span>
                                        <span className="fz-12 fc-2">
                                          {data.snsAdId === undefined ? '-' : data.snsAdId}
                                        </span>
                                        {data.snsAdId === undefined ? (
                                          ''
                                        ) : (
                                          <button
                                            id={data.snsAdId}
                                            type="button"
                                            className="btn btn-secondary-txt btn-ico xsmall single tooltip-btn tooltip-f"
                                          >
                                            <i
                                              id={`snsAdId${data.adSetOrder}_${props.order}_${data.snsAdId}`}
                                              className="ico ico-copy"
                                            />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    <div className="comp-switch">
                                      <input
                                        type="checkbox"
                                        id={`fbAdSetS${data.adSetOrder}_${props.order}_${data.id}`}
                                        checked={checkedInput3}
                                        readOnly={true}
                                      />
                                      <label htmlFor={`fbAdSetS${data.adSetOrder}_${props.order}_${data.id}`}>
                                        <i className="ico"></i>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                )
              ) : null}
              {
                // 기타상태인 경우에만 검수이력 버튼을 출력
                campViewStatusParam !== null &&
                campViewStatusParam !== 'temp' &&
                campViewStatusParam !== 'complete' &&
                campViewStatusParam !== 'del_ready' &&
                campViewStatusParam !== 'del_complete' ? (
                  <button
                    type="button"
                    className="btn btn-primary-outline xsmall w-auto"
                    onClick={() => setShowCreationDataInspHisDialog(true)}
                  >
                    검수이력
                  </button>
                ) : null
              }
            </div>
            {campViewStatusParam !== null &&
            campViewStatusParam !== 'complete' &&
            campViewStatusParam !== 'del_ready' &&
            campViewStatusParam !== 'del_complete' &&
            !blockSubMenu ? (
              <div className="box-option">
                {campViewStatusParam !== null &&
                campViewStatusParam !== 'complete' &&
                campViewStatusParam !== 'del_ready' &&
                campViewStatusParam !== 'del_complete' &&
                campViewStatusParam !== 'temp' &&
                !blockSubMenu ? (
                  <button
                    type="button"
                    className="btn btn-primary xsmall w-auto"
                    value={statusCreationView}
                    onClick={creationStatusUpdate}
                  >
                    {statusChangeBtn}
                  </button>
                ) : null}
                <div className="comp-dropdown open-right">
                  <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                    <i className="ico ico-more"></i>
                  </Link>
                  <div className="dropdown-menu">
                    <ul className="opt-selectbox">
                      {previewMode ? (
                        <li className="opt-menu" id={`updateModeCreation_${props.order}`} value={props.order}>
                          <p className="fc-1">편집</p>
                        </li>
                      ) : (
                        <li className="opt-menu" id={`previewCreation_${props.order}`} value={props.order}>
                          <p className="fc-1">미리보기</p>
                        </li>
                      )}
                      {props.creationLinkData !== undefined &&
                      props.creationLinkData[0].snsAdId !== undefined &&
                      props.creationLinkData[0].snsApiOkYn !== undefined &&
                      props.creationLinkData[0].snsApiOkYn ? (
                        <li
                          className="opt-menu"
                          id={`facebookPreview_${props.order}`}
                          value={props.order}
                          data-snsadid={props.creationLinkData[0].snsAdId}
                        >
                          <p className="fc-1">페이스북 미리보기</p>
                        </li>
                      ) : null}
                      <li className="opt-menu" id={`copyCreation_${props.order}`} value={props.order}>
                        <p className="fc-1">복제</p>
                      </li>
                      <li className="opt-menu" id={`removeCreation_${props.order}`} value={props.order}>
                        <p className="fc-1">삭제</p>
                      </li>
                      <li className="opt-menu">
                        <p className="fc-1">소재ID : {props.creationData.id}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {previewMode ? (
            <div className="box-body" id={`creationBody_${props.order}`} key={props.order}>
              <div className="box-group flex-container">
                <div className="box-left">
                  <img src={Fb01} alt="" />
                </div>
                <div className="box-right">
                  <img src={Fb02} alt="" />
                </div>
              </div>
              <div className="box-group">
                <div className="box-left">
                  <img src={IcoWabiz} className="logo" alt="" />
                </div>
                <div className="box-right">
                  <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                  <p className="fz-12 fc-3 lh15">광고</p>
                </div>
              </div>
              <div className="box-group">
                {
                  <p
                    className={`fz-14 fc-2 lh15 ${catProhValidErrorAdDesc ? 'error' : ''}`}
                    dangerouslySetInnerHTML={{ __html: adDescText.replaceAll(/\n/g, '<br/>') }}
                  />
                }
              </div>
              {afList.length === 0 ? (
                <div className="box-group">
                  <div className="comp-no-data">
                    <div className="box-group">
                      <i className="ico ico-no-img"></i>
                      <p className="fz-14 fc-2">이미지 혹은 영상을 선택해주세요.</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="box-group">
                  <div className="comp-thumb">
                    <div className="txt-state mint">{previewFileDiv === 'VIDEO' ? '동영상' : '이미지'}</div>
                    {previewExt !== undefined ? <div className="txt-state blue">{previewExt.toUpperCase()}</div> : null}
                    {previewFileDiv === 'VIDEO' && previewExt !== 'gif' ? (
                      <video
                        autoPlay
                        controls
                        muted
                        loop
                        // style={{ width: '100%' }}
                      >
                        <source src={`${previewImage}`} />
                      </video>
                    ) : (
                      <img
                        src={`${previewImage}`}
                        // style={{ width: '100%' }}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="box-group flex-container">
                <div className="box-right">
                  <p className="fz-12 fc-3 lh15">
                    <i className="fz-12 fc-5">*</i> 이미지/영상 크기에 따라 미리보기에서 일부 표시되지 않으나 실제 광고
                    노출시에는 정상적으로 노출됩니다.
                  </p>
                </div>
              </div>
              <div className="box-group">
                <div className="box-row">
                  <span className="fz-12 fc-3 lh15">wadiz.kr</span>
                </div>
                <div className="box-row flex-container">
                  <div className="box-left">
                    <p
                      className={`fz-14 fc-2 lh15 ${catProhValidErrorAdTitle01 ? 'error' : ''}`}
                      dangerouslySetInnerHTML={{ __html: adTitle01Text }}
                    />
                  </div>
                  <div className="box-right">
                    <button type="button" className="btn btn-secondary-outline xsmall w-auto">
                      {clickBtnDivsMap.get(getValues('clickBtnDiv'))}
                    </button>
                  </div>
                </div>
                <div className="box-row">
                  <p
                    className={`fz-14 fc-2 lh15 ${catProhValidErrorAdTitle02 ? 'error' : ''}`}
                    dangerouslySetInnerHTML={{ __html: adTitle02Text }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="box-body">
              <div className="box-group flex-container">
                <div className="box-left">
                  <img src={Fb01} alt="" />
                </div>
                <div className="box-right">
                  <img src={Fb02} alt="" />
                </div>
              </div>
              <div className="box-group">
                <div className="box-left">
                  <img src={IcoWabiz} className="logo" alt="" />
                </div>
                <div className="box-right">
                  <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                  <p className="fz-12 fc-3 lh15">광고</p>
                </div>
              </div>
              <div className="box-group">
                <div className="input-group expand">
                  <div className="inner-input-group">
                    <textarea
                      className="tf-textarea"
                      placeholder="광고를 설명할 문구를 입력하세요 (선택)"
                      id={`adDesc_${props.order}`}
                      {...register('adDesc')}
                      onChange={(event) => checkByteEvent(event.currentTarget.id, event.currentTarget.value)}
                      onBlur={changeCreationData}
                    />
                  </div>
                  <p className="fz-12 fc-3">
                    <span className="fc-2" id={`adDesc_${props.order}_Byte`}>
                      0
                    </span>{' '}
                    / 2,000
                  </p>
                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                </div>
              </div>
              <div className="box-group txt-right">
                <button
                  type="button"
                  className="btn btn-secondary-txt btn-ico xsmall"
                  id={props.creationData.id}
                  onClick={(e: any) => dialogOpen(e.currentTarget.id)}
                >
                  <i className="ico ico-more"></i>슬라이드 추가
                </button>
              </div>
              {afList.length === 0 ? (
                <div className="box-group" onClick={() => dialogOpen(props.creationData.id)} role="presentation">
                  <div className="comp-no-data">
                    <div className="box-group">
                      <i className="ico ico-no-img"></i>
                      <p className="fz-14 fc-7">이미지 혹은 영상을 등록해주세요.</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="box-group">
                  <div
                    id="carousel-example-generic-01"
                    className="carousel slide"
                    data-ride="carousel"
                    data-interval="false"
                  >
                    <ol className="carousel-indicators">
                      {afList.map((file: any, index: number) => {
                        const makeLikey = `fileLiKey_${file.id}`
                        const active = index === 0 ? 'active' : ''
                        return (
                          <li
                            key={makeLikey}
                            id={makeLikey}
                            data-creationdataid={props.creationData.id}
                            data-target="#carousel-example-generic-01"
                            data-slide-to={index}
                            className={active}
                          >
                            {index + 1}
                          </li>
                        )
                      })}
                    </ol>
                    <div className="carousel-inner" role="listbox">
                      <i
                        className="ico Ico-trash"
                        data-creationdataid={props.creationData.id}
                        id={`creationFileDelete_${props.order}`}
                      ></i>
                      {afList.map((file: any, index: number) => {
                        const makeFilekey = `fileKey_${file.id}`
                        const fileIndex = `00${index + 1}`.slice(-2)
                        const active = index === 0 ? 'active' : ''
                        const itemClass = `item item${fileIndex} ${active}`
                        return (
                          <Link to="#" key={makeFilekey} className={itemClass}>
                            <div className="txt-state mint">
                              {file.afMeta.fileDiv === 'VIDEO' ? '동영상' : '이미지'}
                            </div>
                            <div className="txt-state blue">{file.afMeta.ext.toUpperCase()}</div>
                            {file.afMeta.fileDiv === 'VIDEO' && file.afMeta.ext !== 'gif' ? (
                              <video
                                autoPlay
                                controls
                                muted
                                loop
                                // style={{ width: '100%' }}
                              >
                                <source src={`${file.fileName}`} />
                              </video>
                            ) : (
                              <img
                                src={`${file.fileName}`}
                                // style={{ width: '100%' }}
                                alt=""
                              />
                            )}
                          </Link>
                        )
                      })}
                    </div>
                    {afList.length > 1 ? (
                      <>
                        <a
                          className="left carousel-control"
                          href="src/components/views/camp/make02/Make02Step03CreationFb#carousel-example-generic-01"
                          role="button"
                          data-slide="prev"
                        >
                          <span className="sr-only"></span>
                        </a>
                        <a
                          className="right carousel-control"
                          href="src/components/views/camp/make02/Make02Step03CreationFb#carousel-example-generic-01"
                          role="button"
                          data-slide="next"
                        >
                          <span className="sr-only"></span>
                        </a>
                      </>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="box-group flex-container">
                <div className="box-right">
                  <p className="fz-12 fc-3 lh15">
                    <i className="fz-12 fc-5">*</i> 이미지/영상 크기에 따라 미리보기에서 일부 표시되지 않으나 실제 광고
                    노출시에는 정상적으로 노출됩니다.
                  </p>
                </div>
              </div>
              <div className="box-group flex-container">
                <div className="box-left">
                  <span className="fz-12 fc-2">행동 유도 버튼</span>
                </div>
                <div className="box-right">
                  <select
                    className="select2-single xsmall w-150"
                    id={`fbActionButton_${props.order}`}
                    name="fbActionButton"
                  ></select>
                </div>
              </div>
              <div className="box-group">
                <div className="input-group expand">
                  <div className="inner-input-group">
                    <input
                      type="text"
                      className="tf-comm"
                      placeholder="광고 제목을 입력하세요(선택)"
                      {...register('adTitle01')}
                      id={`adTitle01_${props.order}`}
                      onBlur={changeCreationData}
                      onChange={(event) => checkByteEvent(event.currentTarget.id, event.currentTarget.value)}
                    />
                  </div>
                  <p className="fz-12 fc-3">
                    <span className="fc-2" id={`adTitle01_${props.order}_Byte`}>
                      0
                    </span>{' '}
                    / 255
                  </p>
                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                </div>
              </div>
              <div className="box-group">
                <div className="input-group expand">
                  <div className="inner-input-group">
                    <input
                      type="text"
                      className="tf-comm"
                      placeholder="광고 설명을 입력하세요 (선택)"
                      {...register('adTitle02')}
                      id={`adTitle02_${props.order}`}
                      onBlur={changeCreationData}
                      onChange={(event) => checkByteEvent(event.currentTarget.id, event.currentTarget.value)}
                    />
                  </div>
                  <p className="fz-12 fc-3">
                    <span className="fc-2" id={`adTitle02_${props.order}_Byte`}>
                      0
                    </span>{' '}
                    / 10,000
                  </p>
                  <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                </div>
              </div>
            </div>
          )}
          <div className="box-footer">
            <div className="box-group">
              <div className="border-top">
                <img src={Fb03} alt="" />
              </div>
            </div>
            <div className="box-group">
              <img src={Fb04} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default Make02Step03CreationFb;
