import { useCallback, useEffect, useRef, useState } from 'react'

export interface UseOutsideClickProps {
  callback: (event: Event) => void
  enabled?: boolean
}

export function useOutsideClickRef({ callback, enabled: enabledProp = true }: UseOutsideClickProps) {
  const [element, setElement] = useState<HTMLElement>()
  const [enabled, setEnabled] = useState(enabledProp)
  const ref = useCallback((el: HTMLElement) => setElement(el), [])
  const localStateRef = useRef({ callback: callback })
  const localState = localStateRef.current
  localState.callback = callback

  /**
   * React 18의 batch 기능으로 인해 enabled 활성시간을 다음 랜더링으로 지연시킴
   * 외부 버튼 클릭 -> enabled 설정 -> 렌더링 -> effect 호출 -> document에 이벤트 바인딩 -> 앞에서 연결한 click 이벤트 호출됨
   * */
  useEffect(() => {
    const timer = setTimeout(() => setEnabled(enabledProp), 0)
    return () => clearTimeout(timer)
  }, [enabledProp])

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (element && event.target instanceof HTMLElement && !element.contains(event.target) && localState.callback) {
        localState.callback(event)
      }
    }

    if (element && enabled) {
      document.addEventListener('click', handler)
    }

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [element, enabled])

  return ref
}

export default useOutsideClickRef
