import React, {
  Fragment, useEffect,
} from 'react';
import Guide02 from '../../../../assets/images/Img-Material-Guide-02.jpg'

const CreationFileUploadGdnGuide = () => {
  useEffect(() => {
    $('[name=guideDialogClose]').click(() => {
      $('[id^=creationFileUploadGuideDialog]').dialog('close');
    });
  }, []);
  return (
  <Fragment>
      <div className="dialog-body">
        <div className="wrap-section">
          <div className="box-body">
            <img src={Guide02} alt=""/>
          </div>
        </div>
      </div>
    <div className="dialog-footer">
      <button type="button" className="btn btn-secondary-outline large" name="guideDialogClose" >닫기</button>
    </div>
  </Fragment>);
};
export default CreationFileUploadGdnGuide;
