/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import cx from 'classnames'
import ReactModal from 'react-modal'

import { sendAppMessage2 } from '../../utils'

import { CloseIcon } from '../../waffle-icons'
import Button from '../Button'

import styles from './ConfirmModal.module.scss'
// import useSwipe from '../useSwipe'

const breakpoints = [
  ['lg', '1096'],
  ['md', '769'],
]
const overlayClassName = 'waffle__modal__overlay'
const overlayBackgroundColor = 'rgba(0, 0, 0, 0.2)'

export const ConfirmModal = ({
  title,
  message,
  positiveButtonLabel = '확인',
  negativeButtonLabel = '취소',
  positiveButtonProps = {},
  negativeButtonProps = {},
  buttonsDirection = 'horizontal',
  size = 'md',
  overlayClassName: overlayClassNameProp,
  overlayTransparent = false,
  zIndex,
  autoClose = true,
  closeClickOutside = true,
  closeWithHistoryBack = false,
  preventBodyScroll = false,
  showButtons = true,
  showCloseButton = true,
  cleanUp,
  onClose,
  onPositiveButtonClick,
  onNegativeButtonClick,
  overlayRef,
  fullHeight,
  className,
  headerClassName,
  contentClassName,
  footerClassName,
  children,
  ...props
}) => {
  const [contentEl, setContentEl] = useState()
  const [enableSwipe, setEnableSwipe] = useState(false)
  const [swiped, setSwiped] = useState(false)
  const headerRef = useRef < HTMLDivElement > null

  const getBreakPoint = () => {
    for (let i = 0; i < breakpoints.length; i++) {
      const [name, width] = breakpoints[i]
      if (window.matchMedia(`(min-width: ${width}px)`).matches) {
        return name
      }
    }
    return 'sm'
  }

  const targetSize = useMemo(() => {
    let targetSize
    const currentBreakPoint = getBreakPoint()
    if (size.constructor.name === 'Object') {
      targetSize = size[currentBreakPoint]
    } else {
      targetSize = size
    }
    if (!targetSize) {
      targetSize = 'sm' // 값을 입력하지 않은 경우 sm 적용
    }

    return targetSize
  }, [size])

  useEffect(() => {
    if (!preventBodyScroll) {
      return
    }
    document.body.style.overflow = 'hidden'
    return () => {
      // 모달이 여러 개 중첩된 경우 스크롤 활성화 안 함
      if (document.querySelectorAll(`.${overlayClassName}`).length <= 1) {
        document.body.style.overflow = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    sendAppMessage2('modal.opened')

    return () => sendAppMessage2('modal.closed')
  }, [])

  const getOverlayElements = () => {
    return Array.from(document.getElementsByClassName(overlayClassName))
  }

  const handleClose = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  const handlePositiveButtonClick = useCallback(() => {
    autoClose && handleClose()
    onPositiveButtonClick && onPositiveButtonClick()
  }, [onPositiveButtonClick])

  const handleNegativeButtonClick = useCallback(() => {
    autoClose && handleClose()
    onNegativeButtonClick && onNegativeButtonClick()
  }, [onNegativeButtonClick])

  const handleSwipe = useCallback(
    (direction) => {
      if (direction === 'bottom') {
        setSwiped(true)
      }
    },
    [handleClose]
  )

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (swiped) {
        handleClose()
      }
    }
    if (contentEl) {
      contentEl.addEventListener('transitionend', handleTransitionEnd)
    }

    ;() => {
      contentEl?.removeEventListener('transitionend', handleTransitionEnd)
    }
  }, [swiped, handleClose, contentEl])

  // useSwipe(headerRef.current, handleSwipe, 80, false, size === ('sheet' || 'sheetMd') && enableSwipe)

  useEffect(() => {
    const useHistoryBack = targetSize === 'full' && closeWithHistoryBack
    const { pathname } = window.location
    const handlePopState = () => {
      if (cleanUp && pathname !== window.location.pathname) {
        // 실제 url이 변경된 경우에만 실행
        cleanUp()
      }
      // full size인 경우에만 적용
      if (useHistoryBack) {
        handleClose()
      }
    }

    let id
    if (useHistoryBack) {
      id = Date.now()
      window.history.pushState(id, '', window.location.href)
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
      if (id && id === window.history.state) {
        window.history.back()
      }
    }
  }, [cleanUp, closeWithHistoryBack, handleClose])

  const getRootStyle = () => {
    return cx(styles['confirmModal'], {
      [styles['sizeMobile']]: targetSize === 'mobile',
      [styles['sizeSm']]: targetSize === 'sm',
      [styles['sizeMd']]: targetSize === 'md',
      [styles['sizeLg']]: targetSize === 'lg',
      [styles['sizeXl']]: targetSize === 'xl',
      [styles['sizeFull']]: targetSize === 'full',
      [styles['sizeSheet']]: targetSize === 'sheet',
      [styles['sizeSheetMD']]: targetSize === 'sheetMd',
      [styles['slideDown']]: swiped,
      [styles['overlayTransparent']]: overlayTransparent,
    })
  }

  // 최종 모달만 dimmed 적용
  const setOverlay = (overlayElement) => {
    if (overlayRef) {
      overlayRef(overlayElement)
    }

    if (overlayElement) {
      setEnableSwipe(true)
    } else {
      setEnableSwipe(false)
    }

    if (zIndex && overlayElement) {
      overlayElement.style.zIndex = zIndex
    }

    // 외부 영역 클릭 시 overlayElement 요소인 경우에만 닫기
    // 외부 영역의 모든 요소를 대상으로 할 경우 모달 내부에서 <Portal /> 형태로 사용한 컴포넌트 클릭 시 사라지는 사이드 이펙트 발생
    if (closeClickOutside && overlayElement) {
      overlayElement.addEventListener('click', (event) => {
        if (event.srcElement === overlayElement && onClose) {
          onClose()
        }
      })
    }
    const modalElements = getOverlayElements()
    modalElements.forEach((element, index) => {
      if (index === modalElements.length - 1) {
        element.style.backgroundColor = overlayBackgroundColor
      } else {
        element.style.backgroundColor = 'transparent'
      }
    })

    if (overlayElement && overlayTransparent) {
      overlayElement.style.backgroundColor = 'transparent'
    }
  }

  const setContent = (contentElement) => {
    if (contentElement) {
      setContentEl(contentElement)
    }
  }

  return (
    <ReactModal
      className={cx(getRootStyle(), className)}
      overlayClassName={cx(styles.overlay, overlayClassName, overlayClassNameProp)}
      appElement={window.document && window.document.body}
      overlayRef={setOverlay}
      contentRef={setContent}
      isOpen
      {...props}
    >
      <div ref={headerRef} className={cx(styles.header, headerClassName)}>
        {showCloseButton && (
          <div className={styles.closeIconRoot}>
            <button
              className={styles.closeIconWrapper}
              onClick={() => {
                size === ('sheet' || 'sheetMd') ? setSwiped(true) : handleClose()
              }}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>
        )}
        <div
          title={title || ''}
          className={cx({
            [styles.title]: title,
            [styles.titlePlaceholder]: !title && showCloseButton,
            [styles.showCloseButton]: showCloseButton,
          })}
        >
          {title}
        </div>
      </div>
      <div
        className={cx(styles.content, { [styles.hasTitle]: title, [styles.fullHeight]: fullHeight }, contentClassName)}
      >
        {children && children}
        {!children && <div className={styles.message}>{message}</div>}
      </div>
      {showButtons && (
        <div className={cx(styles.footer, footerClassName)}>
          <div className={cx(styles.buttonGroup, { [styles.buttonGroupVertical]: buttonsDirection === 'vertical' })}>
            {negativeButtonLabel !== null && (
              <Button
                size="lg"
                className={styles.negativeButton}
                onClick={handleNegativeButtonClick}
                {...negativeButtonProps}
              >
                {negativeButtonLabel}
              </Button>
            )}
            {positiveButtonLabel !== null && (
              <Button
                variant="contained"
                color="primary"
                size="lg"
                className={styles.positiveButton}
                onClick={handlePositiveButtonClick}
                {...positiveButtonProps}
              >
                {positiveButtonLabel}
              </Button>
            )}
          </div>
        </div>
      )}
    </ReactModal>
  )
}

export default React.memo(ConfirmModal)
