import React from 'react';
import classNames from 'classnames';
import FroalaWYSIWYGEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import type { MyComponentProps as FroalaWYSIWYGEditorProps } from 'react-froala-wysiwyg';

import config from './settings/config';

import './WYSIWYGEditorStory.scss';

export interface WYSIWYGEditorViewProps extends FroalaWYSIWYGEditorProps {
  model?: string;
}

const WYSIWYGEditorView = ({
  model,
  ...props
}: WYSIWYGEditorViewProps) => (
  <div className={classNames('wysiwyg-editor')}>
    <FroalaWYSIWYGEditorView
      config={config}
      model={model}
      {...props}
    />
  </div>
);

export default React.memo(WYSIWYGEditorView);
