import React, {
  Fragment, useContext, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import {
  AdminContext, CampIdContext, CampTabClickContext, CampViewStatusContext, ExistEditingContext,
} from './Make02Empty';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import Make02InspReqValidation from './Make02InspReqValidation';
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import AdUpdateDialog from "./AdUpdateDialog";
import CreationDataEditingValid from "./Make02CreationDataEditingValid";

interface fbAdSetForm{
    ok: boolean;
    campId:number;
    adSetId:number;
    genderDiv:string[];
    location:any[];
    minAge:string;
    maxAge:string;
    adSetExtYn:boolean;
}
interface  MdAdSetFormBefore {
  beforeGenderDiv:string[];
  beforeLocations:any[];
  beforeMinAge:string;
  beforeMaxAge:string;
}
interface fbAdSetData{
    ok: boolean;
    id:number;
    campId:number;
    adSetId:number;
    genderDiv:string;
    location:string;
    minAge:string;
    maxAge:string;
    age:string;
    adSetOrder:number;
    adSetExtYn:boolean;
    useYn:boolean;
}
interface fbAdSetDatas{
    ok: boolean;
    data:fbAdSetData;
}
interface fbAdSetDatas2{
    ok: boolean;
    data:fbAdSetData[];
}
interface geoTargetConstant{
    key:string;
    name:string;
    type:string;
    countryCode:string;
    countryName:string;
    region:string;
    regionId:number;
    supportsRegion:boolean;
    supportsCity:boolean;
}
interface geoTargetConstants{
    ok:boolean;
    data:geoTargetConstant[];
}
interface fbAdSetOnOffUpdate{
    ok:boolean;
    data:any;
    adSetId:number;
    useYn:boolean;
}
interface fbAdSetExtYnValid{
  ok:boolean;
  data:any;
  campId:number;
  adSetExtYn:boolean;
}
const select2DefaultOption = {
  width: '150',
  minimumResultsForSearch: Infinity,
};
const FbGenderDivMap:any = {
  ALL: '남/여',
  FEMALE: '여',
  MALE: '남',
}
let globalTimeout: string | number | NodeJS.Timeout | null | undefined = null;
const Make02Step02Fb = () => {
  const navigate = useNavigate();
  const locationRef = useRef<any>();
  const campInspFbStep02Ref = useRef<any>(null);
  const creationDataValidStep02Ref = useRef<any>(null);
  const { setTabClick } = useContext(CampTabClickContext);
  const defaultGenderDiv = ['MALE', 'FEMALE'];
  const { register, setValue, getValues } = useForm<fbAdSetForm>();
  const { campIdParam } = useContext(CampIdContext);
  const { adminParam } = useContext(AdminContext);
  const { campViewStatusParam } = useContext(CampViewStatusContext);
  const [locationSearchText, setLocationSearchText] = useState('');
  // 광고세트1 on/off
  const [checkedInput1, setCheckedInput1] = useState<any>(false);
  // 광고세트2 on/off
  const [checkedInput2, setCheckedInput2] = useState<any>(false);
  const [checkedInput3, setCheckedInput3] = useState<any>(false);
  // 로케이션 제어
  const [locationClick, setLocationClick] = useState<boolean>(false);
  const fbAdSetOnOffUpdateForm = useForm<fbAdSetOnOffUpdate>();

  // 머신러닝 초기화 알림
  const [adUpdateDialog, setAdUpdateDialog] = useState<boolean>(false);
  const [adUpdateDiv, setAdUpdateDiv] = useState<string>('');
  const [adUpdateBefore, setAdUpdateBefore] = useState<string>('');
  const [adUpdateAfter, setAdUpdateAfter] = useState<string>('');

  const MdAdSetBeforeForm = useForm<MdAdSetFormBefore>();

  const tabClickEvent = (value:any) => {
    setTabClick(value);
  };

  const clickModalOutside = (event:any) => {
    if (locationClick && !locationRef.current.contains(event.target)) {
      $('#compSearchDiv').removeClass('selected');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickModalOutside);

    return () => {
      document.removeEventListener('mousedown', clickModalOutside);
    };
  });
  const getGeoLists = async () => {
    const { data } = await ApiUtil2.get<geoTargetConstants>('/api/camp/make02/findFbGeoLists', { params: { query: locationSearchText, campId: campIdParam } });
    return data;
  };
  const getGeoTargetApi = useQuery(['getGeoLists'], getGeoLists, {
    enabled: false,
  })
  const getFbAdSetDatas = async () => {
    const { data } = await ApiUtil2.get<fbAdSetDatas>('/api/camp/make02/fbAdSetDataSearch', { params: { campId: campIdParam } });
    return data;
  };
  const getFbAdSetDatasApi = useQuery(['getFbAdSetDatas'], getFbAdSetDatas, {
    enabled: false,
  })

  // 기타상태 광고세트 useyn
  const getFbAdSetDatas2 = async () => {
    const { data } = await ApiUtil2.get<fbAdSetDatas2>('/api/camp/make02/fbAdSetDataSearch2', { params: { campId: campIdParam } });
    return data;
  };

  const getFbAdSetDatas2Api = useQuery(['getGdnAdSetDatas2'], getFbAdSetDatas2, {
    enabled: false,
  })

  // 광고세트 onoff 수정 처리
  const fbAdSetOnOffUpdateApi = () => {
    const {
      adSetId, useYn,
    } = fbAdSetOnOffUpdateForm.getValues();
    const result = ApiUtil.post<fbAdSetOnOffUpdate>('/api/camp/make02/fbAdSetOnOffUpdate', {
      adSetId, useYn, campId: campIdParam,
    });
    return result;
  };
  const fbAdSetOnOffUpdateEvent = useMutation(fbAdSetOnOffUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
        alertify.success(`광고 세트가 ${resp.data.data.useYn ? 'ON' : 'OFF'} 되었습니다.`);
      }
    },
  });

  const fbAdSetExtYnValidCheck = async () => {
    const { data } = await ApiUtil2.get<fbAdSetExtYnValid>('/api/camp/make02/fbAdSetExtYnValid', { params: { data: { campId: campIdParam, adSetExtYn: getValues('adSetExtYn') } } });
    return data;
  };

  const fbAdSetExtYnValidApi = useQuery(['fbAdSetExtYnValidCheck'], fbAdSetExtYnValidCheck, {
    enabled: false,
  })
  const fbAdSetUpdateApi = () => {
    const {
      campId, adSetId, genderDiv, location, minAge, maxAge, adSetExtYn,
    } = getValues();
    const result = ApiUtil2.post<fbAdSetForm>('/api/camp/make02/fbAdSetUpdate', {
      campId, adSetId, genderDiv, location, minAge, maxAge, adSetExtYn,
    });
    return result;
  };
  const fbAdSetUpdate = useMutation(fbAdSetUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        MdAdSetBeforeForm.setValue('beforeGenderDiv', getValues('genderDiv'));
        MdAdSetBeforeForm.setValue('beforeLocations', getValues('location'));
        MdAdSetBeforeForm.setValue('beforeMinAge', getValues('minAge'));
        MdAdSetBeforeForm.setValue('beforeMaxAge', getValues('maxAge'));
      }
    },
  });
  const changeDataUpdateEvent = () => {
    fbAdSetUpdate.mutate();
  };
  const adSetErrChange = (field:string) => {
    if (field === 'genderDiv') {
      const beforeGenderDivs = MdAdSetBeforeForm.getValues('beforeGenderDiv');
      setValue('genderDiv', beforeGenderDivs);
      beforeGenderDivs.forEach((value) => {
        $(`#${value}`).attr('checked', 'checked');
      });
    }else if(field === 'location'){
      const tagGroupQuery = '.tag-group';
      const tagGroups = document.querySelector(tagGroupQuery);
      if (tagGroups !== null) tagGroups.innerHTML ='';
      const beforeLocations = MdAdSetBeforeForm.getValues('beforeLocations');
      setValue('location', beforeLocations);
      beforeLocations.forEach((data:any) => {
        const countryId = data.key;
        const locationName = data.name;
        const locationType = data.type;
        const countryCode1 = data.countryCode;
        const countryName1 = data.countryName;
        const region1 = data.region;
        const regionId1 = data.regionId;
        const supportsRegion1 = data.supportsRegion;
        const supportsCity1 = data.supportsCity;
        if (tagGroups !== null) {
          tagGroups.innerHTML += `<li class="tag">${locationName}<i class="ico ico-delete" id="geoTarget_${countryId}||${locationName}||${locationType}"  data-countryCode="${countryCode1}" data-countryName="${countryName1}" data-region="${region1}" data-regionId="${regionId1}" data-supportsRegion ="${supportsRegion1}" data-supportsCity = "${supportsCity1}"></i></li>`;
        }
      });
    }else if(field === 'age'){

      const minAge = MdAdSetBeforeForm.getValues('beforeMinAge') === '65+' ? '65' : MdAdSetBeforeForm.getValues('beforeMinAge');
      const maxAge = MdAdSetBeforeForm.getValues('beforeMaxAge') === '65+' ? '65' : MdAdSetBeforeForm.getValues('beforeMaxAge');
      setValue('minAge', minAge);
      setValue('maxAge', maxAge);

      // age 값 처리
      if (minAge === '65+' && maxAge === '65+') {
        $('#minAgeSelect').val('65').trigger('change');
        $('#maxAgeSelect').append('<option value="empty"></option>');
        $('#maxAgeSelect').val('empty').trigger('change');
      } else if (minAge === '18' && maxAge === '18') {
        $('#maxAgeSelect').val(maxAge).trigger('change');
        $('#minAgeSelect').append('<option value="empty"></option>');
        $('#minAgeSelect').val('empty').trigger('change');
      } else {
        $('#minAgeSelect').val(minAge).trigger('change');
        $('#maxAgeSelect').val(maxAge).trigger('change');
        minAgeSelectEvent('maxAgeSelect', minAge);
        maxAgeSelectEvent('minAgeSelect', maxAge);
      }

    }
    targetDelEvent(true);
  }
  const fbGenderDivEvent = () => {
    const query = 'input[name=genderDiv]:checked';
    const checkeds = document.querySelectorAll(query);
    const checkedValues:any = [];
    if (checkeds.length === 0) {
      defaultGenderDiv.forEach((value) => {
        $(`#${value}`).attr('checked', 'checked');
      });
      setValue('genderDiv', defaultGenderDiv);
    } else {
      checkeds.forEach((el:any) => {
        checkedValues.push(el.getAttribute('value'));
      });
      setValue('genderDiv', checkedValues);
    }

    if((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete')){
      let beforeGenderDivArr:string[] = [];
      let afterGenderDivArr:string[] = [];

      MdAdSetBeforeForm.getValues('beforeGenderDiv').forEach((data:any) => {
        beforeGenderDivArr.push(FbGenderDivMap[data]);
      })
      getValues('genderDiv').forEach((data:any) => {
        afterGenderDivArr.push(FbGenderDivMap[data]);
      })

      setAdUpdateBefore(`성별: ${beforeGenderDivArr.join(' / ')}`);
      setAdUpdateAfter(`성별: ${afterGenderDivArr.join(' / ')}`);
      setAdUpdateDiv('genderDiv');
      setAdUpdateDialog(true);
    }else{
      changeDataUpdateEvent();
    }

  };
  // 지역 관련
  const targetDelEvent = (isAdd:boolean) => {
    $('[id^=geoTarget_]').on('click', (e:any) => {
      const locationIds: any[] = [];
      const tagDelQuery = '.tag';
      const tagsDel = document.querySelectorAll(tagDelQuery);
      if (tagsDel.length === 1) {
        alertify.error('최소 1개 이상의 지역이 입력되어야 합니다.');
      } else {
        tagsDel.forEach((del:any) => {
          if (e.currentTarget.id === del.childNodes[1].id) {
            del.remove();
          } else {
            const target = del.childNodes[1] as HTMLElement;
            const targetId = target.id.split('_')[1];
            const locationKey = targetId.split('||')[0];
            const locationName = targetId.split('||')[1];
            const locationType = targetId.split('||')[2];
            const locationCountryCode = target.dataset.countrycode;
            const locationCountryName = target.dataset.countryname;
            const locationRegion = target.dataset.region;
            const locationRegionId = target.dataset.regionid;
            const locationSupportsRegion = target.dataset.supportsregion;
            const locationSupportsCity = target.dataset.supportscity;
            const locationJson = {
              key: locationKey,
              name: locationName,
              type: locationType,
              countryCode: locationCountryCode,
              countryName: locationCountryName,
              region: locationRegion,
              regionId: locationRegionId,
              supportsRegion: locationSupportsRegion,
              supportsCity: locationSupportsCity,
            };
            locationIds.push(locationJson);
            setValue('location', locationIds);
          }
        });
        if((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' && isAdd )){
          let beforeLocationNameArr:string[] = [];
          let afterLocationNameArr:string[] = [];

          MdAdSetBeforeForm.getValues('beforeLocations').forEach((data) => {
            beforeLocationNameArr.push(data.name);
          })

          getValues('location').forEach((data) => {
            afterLocationNameArr.push(data.name);
          })

          setAdUpdateBefore(`지역: ${beforeLocationNameArr.join(', ')}`);
          setAdUpdateAfter(`지역: ${afterLocationNameArr.join(', ')}`);
          setAdUpdateDiv('location');
          setAdUpdateDialog(true);
        }else{
          changeDataUpdateEvent();
        }
      }
    });
  };
  const search = (searchValue:string) => {
    globalTimeout = null;
    setLocationSearchText(searchValue);
  };
  const locationFocusEvent = () => {
    $('#locationSearchTag').addClass('focus');
  };
  const locationBlurEvent = () => {
    $('#locationSearchTag').removeClass('focus');
  };
  const geoTargetSearchEvent = (event:any) => {
    const searchValue = event.currentTarget.value;
    if (globalTimeout != null) clearTimeout(globalTimeout);
    globalTimeout = setTimeout(() => search(searchValue), 1000);
  };
  const onChangeAdSet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const adSetId:any = e.target.id;
    const adSetIdValue:any = e.currentTarget.value;
    if (adSetId === 'fbAdSet1') {
      setCheckedInput1(checked);
      fbAdSetOnOffUpdateForm.setValue('useYn', checked);
    } else if (adSetId === 'fbAdSet2') {
      setCheckedInput2(checked);
      fbAdSetOnOffUpdateForm.setValue('useYn', checked);
    } else {
      setCheckedInput3(checked);
      fbAdSetOnOffUpdateForm.setValue('useYn', checked);
    }
    fbAdSetOnOffUpdateForm.setValue('adSetId', adSetIdValue);
    fbAdSetOnOffUpdateEvent.mutate();
  };
  const searchFbAdSet2 = () => {
    globalTimeout = null;
    getFbAdSetDatas2Api.refetch().then((r) => {
      if (r.isSuccess) {
        r.data.data.forEach((data) => {
          // 스위치 버튼 변경
          if (data !== null) {
            const { useYn } = data;
            if (data.adSetOrder === 1) {
              setCheckedInput1(useYn);
              $('#fbAdSet1').val(data.id);
            } else if (data.adSetOrder === 2) {
              setCheckedInput2(useYn);
              $('#fbAdSet2').val(data.id);
            } else if (data.adSetOrder !== null && data.adSetOrder === 3) {
              setCheckedInput3(data.useYn);
              $('#fbAdSet3').val(data.id);
              const fbAdSet3Html = document.getElementById('fbAdSet3Div') as HTMLElement;
              fbAdSet3Html.hidden = false;
            }
          }
        });
      }
    });
  };
  const fbAdSetSearch2Event = () => {
    if (globalTimeout != null) clearTimeout(globalTimeout);
    globalTimeout = setTimeout(() => searchFbAdSet2(), 1000);
  };
  const campStatusInit = () => {
    if (campViewStatusParam === 'complete' || campViewStatusParam === 'del_ready' || campViewStatusParam === 'del_complete'|| (!adminParam.isAdmin && adminParam.forAdmin)) {
      $('#MALE, #FEMALE, #minAgeSelect, #maxAgeSelect, #geoTargetSearchInput').attr('disabled', 'disabled');
    } else if (campViewStatusParam !== null && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete') {
      if (campViewStatusParam !== 'temp') {
        // 임시저장 및 종료상태가 아닐 경우
        $('#campStatusEtc').show();
        $('#fbAdset01Tooltip').tooltip({
          content: '<p class="fz-12 fc-0">사용자가 입력한 타겟 정보를 기반으로 생성되는 광고세트입니다.</p>',
        });
        $('#fbAdset02Tooltip').tooltip({
          content: '<p class="fz-12 fc-0">광고 효율 향상을 위해 와디즈에서 입력한 타겟 정보를 기반으로 생성되는 광고세트입니다.</p>',
        });
        $('#fbAdset03Tooltip').tooltip({
          content: '<p class="fz-12 fc-0">광고 효율 향상을 위해 와디즈의 데이터를 활용해 추가로 생성되는 광고세트입니다.</p>',
        });
        // 광고세트에 대한 정보세팅(useyn)
        fbAdSetSearch2Event();
      }
    }
  };
  const minAgeSelectEvent = (selectId:string, age:string) => {
    const options = $(`#${selectId}`).find('option').map(function () {
      return $(this).val();
    }).get();
    options.forEach((value) => {
      if (value !== 'empty' && Number(value) < Number(age)) {
        $(`#${selectId} > option[value=${value}]`).attr('disabled', 'true');
      } else {
        $(`#${selectId} > option[value=${value}]`).removeAttr('disabled');
      }
    });
    $(`#${selectId}`).select2(select2DefaultOption);
  };
  const maxAgeSelectEvent = (selectId:string, age:string) => {
    const options = $(`#${selectId}`).find('option').map(function () {
      return $(this).val();
    }).get();
    options.forEach((value) => {
      if (value !== 'empty' && Number(value) > Number(age)) {
        $(`#${selectId} > option[value=${value}]`).attr('disabled', 'true');
      } else {
        $(`#${selectId} > option[value=${value}]`).removeAttr('disabled');
      }
    });
    $(`#${selectId}`).select2(select2DefaultOption);
  };
  const searchFbAdSet = () => {
    getFbAdSetDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        setValue('campId', r.data.data.campId);
        setValue('adSetId', r.data.data.id);

        const genderDivs = [];

        if (r.data.data.genderDiv === 'ALL') {
          setValue('genderDiv', defaultGenderDiv);
          MdAdSetBeforeForm.setValue('beforeGenderDiv',defaultGenderDiv);
          defaultGenderDiv.forEach((value) => {
            $(`#${value}`).attr('checked', 'checked');
          });
        } else {
          genderDivs.push(r.data.data.genderDiv);
          setValue('genderDiv', genderDivs);
          MdAdSetBeforeForm.setValue('beforeGenderDiv', genderDivs);
          genderDivs.forEach((value) => {
            $(`#${value}`).attr('checked', 'checked');
          });
        }
        const locations = JSON.parse(r.data.data.location);
        const tagGroupQuery = '.tag-group';
        const tagGroups = document.querySelector(tagGroupQuery);
        setValue('location', locations);
        locations.forEach((data:any) => {
          const countryId = data.key;
          const locationName = data.name;
          const locationType = data.type;
          const countryCode1 = data.countryCode;
          const countryName1 = data.countryName;
          const region1 = data.region;
          const regionId1 = data.regionId;
          const supportsRegion1 = data.supportsRegion;
          const supportsCity1 = data.supportsCity;
          if (tagGroups !== null) tagGroups.innerHTML += `<li class="tag">${locationName}<i class="ico ico-delete" id="geoTarget_${countryId}||${locationName}||${locationType}"  data-countryCode="${countryCode1}" data-countryName="${countryName1}" data-region="${region1}" data-regionId="${regionId1}" data-supportsRegion ="${supportsRegion1}" data-supportsCity = "${supportsCity1}"></i></li>`;
        });
        MdAdSetBeforeForm.setValue('beforeLocations', locations);

        const age = r.data.data.age.split(',');
        setValue('minAge', age[0]);
        setValue('maxAge', age[1]);
        MdAdSetBeforeForm.setValue('beforeMinAge', age[0]);
        MdAdSetBeforeForm.setValue('beforeMaxAge', age[1]);
        // age 값 처리
        if (age[0] === '65+' && age[1] === '65+') {
          $('#minAgeSelect').val('65').trigger('change');
          $('#maxAgeSelect').append('<option value="empty"></option>');
          $('#maxAgeSelect').val('empty').trigger('change');
        } else if (age[0] === '18' && age[1] === '18') {
          $('#maxAgeSelect').val(age[1]).trigger('change');
          $('#minAgeSelect').append('<option value="empty"></option>');
          $('#minAgeSelect').val('empty').trigger('change');
        } else {
          const minAge = age[0] === '65+' ? '65' : age[0];
          const maxAge = age[1] === '65+' ? '65' : age[1];
          $('#minAgeSelect').val(minAge).trigger('change');
          $('#maxAgeSelect').val(maxAge).trigger('change');
          minAgeSelectEvent('maxAgeSelect', minAge);
          maxAgeSelectEvent('minAgeSelect', maxAge);
        }

        if (r.data.data.adSetExtYn) {
          $('#addAdSetUse').attr('checked', 'checked');
        }
        setValue('adSetExtYn', r.data.data.adSetExtYn);

        targetDelEvent(true);
        campStatusInit();
      }
    });
  };

  const addAdSetUseEvent = () => {
    const query = document.getElementById('addAdSetUse') as HTMLInputElement;
    const { checked } = query;
    setValue('adSetExtYn', checked);
    if (checked) {
      fbAdSetExtYnValidApi.refetch().then((r) => {
        if (r.isSuccess) {
          if (r.data.data) {
            changeDataUpdateEvent();
          } else {
            alertify.error('해당 프로젝트 카테고리에 해당하는 <br> 설정값이 없습니다. <br> 관리자에게 문의해주세요.');
            setValue('adSetExtYn', false);
            $('#addAdSetUse').prop('checked', false);
          }
        } else {
          alertify.error('해당 프로젝트 카테고리에 해당하는 설정값이 없습니다. <br> 관리자에게 문의해주세요.');
          setValue('adSetExtYn', false);
          $('#addAdSetUse').prop('checked', false);
        }
      });
    } else {
      setValue('adSetExtYn', checked);
      changeDataUpdateEvent();
    }
  };

  // 검수요청 밸리데이션
  const validCheckEvent = () => {
    campInspFbStep02Ref.current!.validCheckEvent();
  };

  useEffect(() => {
    if(campIdParam !== null){
      creationDataValidStep02Ref.current!.validCrationDataEditingApiEvent()
    }
  }, [campIdParam]);

  useEffect(() => {
    $('#locationTooltip').tooltip({
      content: '<p class="fz-12 fc-0">해당 지역에 거주하거나 위치한 사람들에게 광고를 표시합니다.</p>',
    });
    if (locationSearchText !== '') {
      getGeoTargetApi.refetch().then((r) => {
        if (r.isSuccess) {
          const optSelectBoxQuery = '#optSelectBox';
          const optSelectBoxs = document.querySelector(optSelectBoxQuery);
          // 검색한 지역이 없을 경우
          if (r.data.data.length === 0) {
            if (optSelectBoxs !== null) {
              optSelectBoxs.innerHTML = '<li class="comp-no-data">\n'
                              + '                  <div class="box-group">\n'
                              + '                      <i class="ico ico-no-search"></i>\n'
                              + '                      <p class="fz-14 fc-2">검색 결과가 없습니다.</p>\n'
                              + '                      <p class="fz-12 fc-3">검색어를 다시 입력하여 검색해주세요.</p>\n'
                              + '                  </div>\n'
                              + '              </li>';
            }
          } else {
            // 검색한 지역이 존재할 경우
            if (optSelectBoxs !== null) optSelectBoxs.innerHTML = '';
            r.data.data.forEach((geoTarget:geoTargetConstant) => {
              if (optSelectBoxs !== null) optSelectBoxs.innerHTML += `<li class="opt-menu" id="${geoTarget.key}||${geoTarget.name}||${geoTarget.type}" data-countryCode="${geoTarget.countryCode}" data-countryName="${geoTarget.countryName}" data-region="${geoTarget.region}" data-regionId="${geoTarget.regionId}" data-supportsRegion = "${geoTarget.supportsRegion}" data-supportsCity ="${geoTarget.supportsCity}"><p class="fc-1">${geoTarget.name}</p></li>`;
            });
          }
          $('#compSearchDiv').addClass('selected');
          setLocationClick(true);

          const addTargetEvent = (target:any) => {
            let isAdd = true;
            let isHigherLevel = false;
            const allTaglQuery = '.tag > i';
            const allTags = document.querySelectorAll(allTaglQuery);
            if (allTags.length === 20) {
              return;
            }
            const countryId = target.currentTarget.id.split('||')[0];
            const name = target.currentTarget.id.split('||')[1];
            const type = target.currentTarget.id.split('||')[2];
            const countryCode = target.currentTarget.dataset.countrycode;
            const countryName = target.currentTarget.dataset.countryname;
            const region1 = target.currentTarget.dataset.region;
            const regionId = target.currentTarget.dataset.regionid;
            const supportsRegion = target.currentTarget.dataset.supportsregion;
            const supportsCity = target.currentTarget.dataset.supportscity;
            allTags.forEach((tag:any) => {
              if (tag.id.split('||')[0].split('geoTarget_')[1] === countryId) {
                isAdd = false;
              }
            });
            if (isAdd) {
              const tagGroupQuery = '.tag-group';
              const tagGroups = document.querySelector(tagGroupQuery);
              if (tagGroups !== null) tagGroups.innerHTML += `<li class="tag">${name}<i class="ico ico-delete" id="geoTarget_${countryId}||${name}||${type}"  data-countryCode="${countryCode}" data-countryName="${countryName}" data-region="${region1}" data-regionId="${regionId}" data-supportsRegion ="${supportsRegion}" data-supportsCity = "${supportsCity}"></i></li>`;
              const addLocations = getValues('location');
              const locationKey = target.currentTarget.id.split('||')[0];
              const locationName = target.currentTarget.id.split('||')[1];
              const locationType = target.currentTarget.id.split('||')[2];
              const locationCountryCode = target.currentTarget.dataset.countrycode;
              const locationCountryName = target.currentTarget.dataset.countryname;
              const locationRegion = target.currentTarget.dataset.region;
              const locationRegionId = target.currentTarget.dataset.regionid;
              const locationSupportsRegion = target.currentTarget.dataset.supportsregion;
              const locationSupportsCity = target.currentTarget.dataset.supportscity;
              const locationJson = {
                key: locationKey,
                name: locationName,
                type: locationType,
                countryCode: locationCountryCode,
                countryName: locationCountryName,
                region: locationRegion,
                regionId: locationRegionId,
                supportsRegion: locationSupportsRegion,
                supportsCity: locationSupportsCity,
              };
              addLocations.push(locationJson);
              setValue('location', addLocations);
              targetDelEvent(true);
              if((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' )){
                let beforeLocationNameArr:string[] = [];
                let afterLocationNameArr:string[] = [];

                MdAdSetBeforeForm.getValues('beforeLocations').forEach((data) => {
                  beforeLocationNameArr.push(data.name);
                })

                getValues('location').forEach((data) => {
                  afterLocationNameArr.push(data.name);
                })

                setAdUpdateBefore(`지역: ${beforeLocationNameArr.join(', ')}`);
                setAdUpdateAfter(`지역: ${afterLocationNameArr.join(', ')}`);
                setAdUpdateDiv('location');
                setAdUpdateDialog(true);
              }else{
                changeDataUpdateEvent();
              }
            }
            // 지역 레벨 체크
            allTags.forEach((tag:any) => {
              if (type !== tag.id.split('||')[2]) { // 일단 타입이 다른경우
                if (type === 'country') {
                  isHigherLevel = true;
                  const delTag = document.getElementById(tag.id) as HTMLElement;
                  delTag.click();
                  // country 가 같은거 (상위 레벨)
                  // city의 regionId가 같은거 (하위 레벨)
                } else if (
                  (tag.id.split('||')[2] === 'country' && countryCode === tag.dataset.countrycode)
                    || (countryId === tag.dataset.regionid)
                    || (regionId === tag.id.split('||')[0].split('geoTarget_')[1])
                ) {
                  isHigherLevel = true;
                  const delTag = document.getElementById(tag.id) as HTMLElement;
                  delTag.click();
                }
              }
            });
            if (isHigherLevel) {
              alertify.error('지역은 국가, 행정구역, 도시 단위로 선택이 가능하며,'
                  + '선택된 지역 중 연관이 있는 지역이 있고 서로 단위가 다른 지역을 선택한 경우 나중에 선택한 지역만 출력 됩니다. <br> (단, 동일 단위의 지역이거나 연관이 없는 지역의 경우 다수 선택이 가능합니다.)<br>'
                  + 'ex) 대한민국 선택 후 부산 선택 시 부산만 출력, 수원 선택 후 경기도 선택 시 경기도만 출력');
            }
          };
          const optSelectBoxOptMenuQuery = '#optSelectBox > .opt-menu';
          const optSelectBoxOptMenus = document.querySelectorAll(optSelectBoxOptMenuQuery);
          optSelectBoxOptMenus.forEach((e) => {
            e.addEventListener('click', addTargetEvent);
          });
        }
      });
    } else {
      $('#compSearchDiv').removeClass('selected');
    }
  }, [locationSearchText]);
  useEffect(() => {
    searchFbAdSet();
    // 나이
    $('#minAgeSelect').select2({
      width: '150px',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e : any) => {
      if (e.params.data.id === '65') {
        $('#maxAgeSelect').append('<option value="empty"></option>');
        $('#maxAgeSelect').val('empty').trigger('change');
      } else {
        $("#maxAgeSelect option[value='empty']").remove();
      }
      minAgeSelectEvent('maxAgeSelect', e.params.data.id);
      setValue('minAge', e.params.data.id === '65' ? '65+' : e.params.data.id);
      if((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete')){
        const maxAge = getValues('maxAge') === '65' ? '65+' : getValues('maxAge');
        const beforeMinAge = MdAdSetBeforeForm.getValues('beforeMinAge')  === '65' ? '65+' : MdAdSetBeforeForm.getValues('beforeMinAge');
        const afterMinAge = getValues('minAge') === '65' ? '65+' : getValues('minAge');

        setAdUpdateBefore(`나이 : ${beforeMinAge} ~ ${maxAge}`);
        setAdUpdateAfter(`나이 : ${afterMinAge} ~ ${maxAge}`);
        setAdUpdateDiv('age');
        setAdUpdateDialog(true);
      }else{
        changeDataUpdateEvent();
      }
    });

    $('#maxAgeSelect').select2({
      width: '150px',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e : any) => {
      $("#minAgeSelect option[value='empty']").remove();
      if (e.params.data.id === '18') {
        $('#minAgeSelect').append('<option value="empty" id="empty"></option>');
        $('#minAgeSelect').val('empty').trigger('change');
      }
      maxAgeSelectEvent('minAgeSelect', e.params.data.id);
      setValue('maxAge', e.params.data.id === '65' ? '65+' : e.params.data.id);
      if((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete')){
        const minAge = getValues('minAge') === '65' ? '65+' : getValues('minAge');
        const beforeMaxAge = MdAdSetBeforeForm.getValues('beforeMaxAge')  === '65' ? '65+' : MdAdSetBeforeForm.getValues('beforeMaxAge');
        const afterMaxAge = getValues('maxAge') === '65' ? '65+' : getValues('maxAge');

        setAdUpdateBefore(`나이 : ${minAge} ~ ${beforeMaxAge}`);
        setAdUpdateAfter(`나이 : ${minAge} ~ ${afterMaxAge}`);
        setAdUpdateDiv('age');
        setAdUpdateDialog(true);
      }else{
        changeDataUpdateEvent();
      }
    });
    return () => {
      $('.tooltip').remove();
    };
  }, []);
  return (
    <Fragment>
      {
        adUpdateDialog ? <AdUpdateDialog errEvent = {adSetErrChange} updateEvent={changeDataUpdateEvent} setAdUpdateDialog ={setAdUpdateDialog} updateDiv = {adUpdateDiv} adUpdateBefore={adUpdateBefore} adUpdateAfter={adUpdateAfter}/> : null
      }
        <div className="comp-tab-content selected">
            <section className="wrap-section wrap-tbl">
                <div className="box-body">
                    <div className="tbl">
                        <dl className="vertical" id="campStatusEtc" style={{ display: 'none' }}>
                            <dd>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col col-4">
                                            <div className="form-group txt-center">
                                                <span className="comp-txt">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-14 fc-1">논타겟 광고세트</b>
                                                            <Link to="#" className="ico-tooltip tooltip-f" title="" id ="fbAdset01Tooltip"></Link>
                                                        </span>
                                                    </span>
                                                </span>
                                                <div className="comp-switch">
                                                    <input type="checkbox" id="fbAdSet1" checked={checkedInput1} onChange={onChangeAdSet} />
                                                        <label htmlFor="fbAdSet1">
                                                            <i className="ico"></i>
                                                        </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-4">
                                            <div className="form-group txt-center">
                                                <span className="comp-txt">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-14 fc-1">리타겟 광고세트</b>
                                                            <Link to="#" className="ico-tooltip tooltip-f" title="" id ="fbAdset02Tooltip"></Link>
                                                        </span>
                                                    </span>
                                                </span>
                                                <div className="comp-switch">
                                                    <input type="checkbox" id="fbAdSet2" checked={checkedInput2} onChange={onChangeAdSet} />
                                                        <label htmlFor="fbAdSet2">
                                                            <i className="ico"></i>
                                                        </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-4" id="fbAdSet3Div" hidden={true}>
                                            <div className="form-group txt-center">
                                                <span className="comp-txt">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-14 fc-1">리타겟 광고세트(추가)</b>
                                                            <Link to="#" className="ico-tooltip tooltip-f" title="" id = "fbAdset03Tooltip"></Link>
                                                        </span>
                                                    </span>
                                                </span>
                                                <div className="comp-switch">
                                                    <input type="checkbox" id="fbAdSet3" checked={checkedInput3} onChange={onChangeAdSet} />
                                                    <label htmlFor="fbAdSet3">
                                                            <i className="ico"></i>
                                                        </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <dl className="vertical" style={{ display: 'none' }}>
                            <dd>
                                <div className="form-group">
                                    <div className="comp-help">
                                        <ul className="help-list">
                                            <li className="list">
                                                <i className="ico ico-help"></i><span className="fz-14 fc-2">캠페인이 진행 된 이후 타겟 정보를 수정하면 광고 효율이 저하될 수 있습니다.</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <dl className="vertical column-two">
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        성별
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <div className="comp-checkbox txt-center">
                                        <input type="checkbox" id="MALE" {...register('genderDiv')} name='genderDiv' onChange={fbGenderDivEvent} value="MALE"/>
                                            <label htmlFor="MALE" className="label"> <i className="ico ico-male"></i></label>
                                            <label htmlFor="MALE">남성</label>
                                    </div>
                                    <div className="comp-checkbox txt-center">
                                        <input type="checkbox" id="FEMALE"{ ...register('genderDiv')} name='genderDiv' onChange={fbGenderDivEvent} value="FEMALE"/>
                                            <label htmlFor="FEMALE" className="label"> <i className="ico ico-female"></i></label>
                                            <label htmlFor="FEMALE">여성</label>
                                    </div>
                                </div>
                            </dd>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        지역
                                        <Link to="#" className="ico-tooltip tooltip-f" id='locationTooltip'></Link>
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group" ref={locationRef}>
                                    <div className="input-group comp-search expand">
                                        <div className="inner-input-group" id="compSearchDiv">
                                            <div className="search-tag selected focus" id="locationSearchTag">
                                                <ul className="tag-group">
                                                </ul>
                                                <div className="input-group">
                                                    <input type="text" className="tf-comm" id="geoTargetSearchInput"
                                                           placeholder="지역을 검색하여 추가해 주세요." onKeyUp={geoTargetSearchEvent} onFocus={locationFocusEvent} onBlur={locationBlurEvent}/>
                                                    <i className="ico ico-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="opt-selectbox" id="optSelectBox">
                                        </ul>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <dl className="vertical column-two">
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        나이
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <select className="select2-single" id="minAgeSelect">
                                         <option value="18">18</option>
                                         <option value="19">19</option>
                                         <option value="20">20</option>
                                         <option value="21">21</option>
                                         <option value="22">22</option>
                                         <option value="23">23</option>
                                         <option value="24">24</option>
                                         <option value="25">25</option>
                                         <option value="26">26</option>
                                         <option value="27">27</option>
                                         <option value="28">28</option>
                                         <option value="29">29</option>
                                         <option value="30">30</option>
                                         <option value="31">31</option>
                                         <option value="32">32</option>
                                         <option value="33">33</option>
                                         <option value="34">34</option>
                                         <option value="35">35</option>
                                         <option value="36">36</option>
                                         <option value="37">37</option>
                                         <option value="38">38</option>
                                         <option value="39">39</option>
                                         <option value="40">40</option>
                                         <option value="41">41</option>
                                         <option value="42">42</option>
                                         <option value="43">43</option>
                                         <option value="44">44</option>
                                         <option value="45">45</option>
                                         <option value="46">46</option>
                                         <option value="47">47</option>
                                         <option value="48">48</option>
                                         <option value="49">49</option>
                                         <option value="50">50</option>
                                         <option value="51">51</option>
                                         <option value="52">52</option>
                                         <option value="53">53</option>
                                         <option value="54">54</option>
                                         <option value="55">55</option>
                                         <option value="56">56</option>
                                         <option value="57">57</option>
                                         <option value="58">58</option>
                                         <option value="59">59</option>
                                         <option value="60">60</option>
                                         <option value="61">61</option>
                                         <option value="62">62</option>
                                         <option value="63">63</option>
                                         <option value="64">64</option>
                                         <option value="65">65+</option>
                                    </select>
                                    <span className="comp-txt">
                                        <span className="table">
                                            <span className="table-cell">
                                                <b className="fz-12 fc-1">~</b>
                                            </span>
                                        </span>
                                    </span>
                                    <select className="select2-single" id="maxAgeSelect">
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                        <option value="32">32</option>
                                        <option value="33">33</option>
                                        <option value="34">34</option>
                                        <option value="35">35</option>
                                        <option value="36">36</option>
                                        <option value="37">37</option>
                                        <option value="38">38</option>
                                        <option value="39">39</option>
                                        <option value="40">40</option>
                                        <option value="41">41</option>
                                        <option value="42">42</option>
                                        <option value="43">43</option>
                                        <option value="44">44</option>
                                        <option value="45">45</option>
                                        <option value="46">46</option>
                                        <option value="47">47</option>
                                        <option value="48">48</option>
                                        <option value="49">49</option>
                                        <option value="50">50</option>
                                        <option value="51">51</option>
                                        <option value="52">52</option>
                                        <option value="53">53</option>
                                        <option value="54">54</option>
                                        <option value="55">55</option>
                                        <option value="56">56</option>
                                        <option value="57">57</option>
                                        <option value="58">58</option>
                                        <option value="59">59</option>
                                        <option value="60">60</option>
                                        <option value="61">61</option>
                                        <option value="62">62</option>
                                        <option value="63">63</option>
                                        <option value="64">64</option>
                                        <option value="65">65+</option>
                                    </select>
                                </div>
                            </dd>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                    </span>
                                </div>
                            </dt>
                            <dd style={{ display: 'none' }}>
                                <div className="form-group">
                                    <div className="comp-checkbox txt-center">
                                        <input type="checkbox" id="addAdSetUse" onClick={addAdSetUseEvent} disabled={campViewStatusParam !== 'temp'}/>
                                            <label htmlFor="addAdSetUse" >와디즈 데이터를 활용한 추가 타겟팅세트 적용</label>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="box-footer">
                  <div className="box-left">
                    <button type="button" onClick= {() => navigate('/mngCamp/mngCamp')} className="btn btn-primary-outline">캠페인 목록</button>
                    {
                        (campViewStatusParam === 'temp' || campViewStatusParam === undefined)
                        && <button type="button" className="btn btn-primary" onClick={validCheckEvent}>검수요청</button>
                    }
                  </div>
                  <div className="box-right">
                    <button type="button" className="btn btn-secondary-outline" onClick={() => tabClickEvent('step01')}>이전</button>
                    <button type="button" className="btn btn-primary" onClick={() => tabClickEvent('step03')}>다음</button>
                  </div>
                </div>
                <Make02InspReqValidation ref={campInspFbStep02Ref}/>
                <CreationDataEditingValid ref={creationDataValidStep02Ref} />
            </section>
        </div>
    </Fragment>
  );
};
export default Make02Step02Fb;
