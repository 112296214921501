import React, {
    Fragment, useEffect, useRef, useState,
} from 'react';
import { Dialog } from 'rc-easyui';

const AdDivMAp:any = {
    campTime: '캠페인기간',
    genderDiv: '캠페인타겟',
    location: '캠페인타겟',
    minAge: '캠페인타겟',
    maxAge: '캠페인타겟',
}
const  AdUpdateDialog = (props:any) => {
    const dialogRef = useRef<any>();
    const [title, setTitle] = useState<string>('');

    const cancelEvent = () => {
        props.errEvent(props.updateDiv);
        props.setAdUpdateDialog(false);
    }

    const updateEvent = () => {
        props.updateEvent(props.updateDiv);
        props.setAdUpdateDialog(false);
    }

    useEffect(() => {
        setTitle(AdDivMAp[props.updateDiv]);
    })
    return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="wrap-section wrap-tbl">
                                <div className="box-body">
                                    <div className="tbl">
                                        <dl className="vertical">
                                            <dd>
                                                <div className="form-group">
                                                    <p className="comp-txt center">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-24 fc-1 fw-medium">{title}을 수정 하시겠습니까?</b>
                                                                </span>
                                                            </span>
                                                    </p>
                                                </div>
                                            </dd>
                                        </dl>
                                        <dl className="vertical">
                                            <dd>
                                                <div className="form-group">
                                                    <div className="comp-help white">
                                                        <ul className="help-list">
                                                            <li className="list">
                                                                <i className="ico ico-help"></i>
                                                                <span
                                                                    className="fz-14 fc-2">{title}이 아래와 같이 변경됩니다.</span>
                                                            </li>
                                                            <li className="list">
                                                                <span className="fz-14 fc-4 bullet">(기존) {props.adUpdateBefore}</span>
                                                            </li>
                                                            <li className="list">
                                                                <span className="fz-14 fc-4 bullet">(변경) <span
                                                                    className="fc-5">{props.adUpdateAfter}</span></span>
                                                            </li>
                                                            <li className="list">
                                                                <i className="ico ico-help"></i>
                                                                <span className="fz-14 fc-2">{title}을 변경할 경우 머신러닝이 다시 시작됩니다.</span>
                                                            </li>
                                                            <li className="list">
                                                                <span className="fz-14 fc-4 bullet">머신러닝에 대한 내용 <a className="txt-link" href="https://biz.wadiz.kr/makerCommon/faq#2983813489" target="_blank">FAQ</a>를 참고해 주세요.</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-secondary-outline large" onClick={cancelEvent} >취소
                            </button>
                            <button type="button" className="btn btn-primary large" onClick={updateEvent}>확인</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>
    )
}
export default AdUpdateDialog;