import React, {
  Fragment, useContext, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import {
  AdminContext, CampIdContext, CampTabClickContext, CampViewStatusContext,
} from './Make02Empty';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import Make02InspReqValidation from './Make02InspReqValidation';

interface gdnAdSetForm{
    ok: boolean;
    campId:number;
    adSetId:number;
    genderDiv:string[];
    age:string[];
    location:string[];
}

interface gdnAdSetData{
    ok: boolean;
    id:number;
    campId:number;
    adSetId:number;
    genderDiv:string;
    age:string;
    location:string;
    adSetOrder:number;
    useYn:boolean;
}
interface gdnAdSetDatas{
    ok: boolean;
    data:gdnAdSetData;
}
interface gdnAdSetDatas2{
    ok: boolean;
    data:gdnAdSetData[];
}
interface geoTargetConstant{
    name:string;
    canonicalName:string;
    countryCode:string;
    countryId:string;
    targetType:string;
    geoTargetResourceName:string;
}
interface geoTargetConstants{
    ok:boolean;
    data:geoTargetConstant[];
}

interface gdnAdSetOnOffUpdate{
    ok:boolean;
    adSetId:number;
    useYn:boolean;
}
let globalTimeout: string | number | NodeJS.Timeout | null | undefined = null;
const Make02Step02Gdn = () => {
  const campInspGdnStep02Ref = useRef<any>(null);
  const { setTabClick } = useContext(CampTabClickContext);
  const { campIdParam } = useContext(CampIdContext);
  const { campViewStatusParam } = useContext(CampViewStatusContext);
  const { adminParam } = useContext(AdminContext);
  // 광고세트1 on/off
  const [checkedInput1, setCheckedInput1] = useState<any>(false);
  // 광고세트2 on/off
  const [checkedInput2, setCheckedInput2] = useState<any>(false);
  const { register, setValue, getValues } = useForm<gdnAdSetForm>();
  const gdnAdSetOnOffUpdateForm = useForm<gdnAdSetOnOffUpdate>();
  const [countryName, setCountryName] = useState('');
  // const defaultLocation = ['2410_South Korea'];
  const defaultGenderDiv = ['MALE', 'FEMALE'];
  const defaultAgeRange = ['18_24', '25_34', '35_44', '45_54', '55_99'];
  // 지역 API
  const getGeoTargets = async () => {
    const { data } = await ApiUtil2.get<geoTargetConstants>('/api/camp/make02/findGeoTargetConstants', { params: { countryName } });
    return data;
  };

  const getGeoTargetApi = useQuery(['getGeoTargets'], getGeoTargets, {
    enabled: false,
  })
    // 광고세트 상세 데이터
  const getGdnAdSetDatas = async () => {
    const { data } = await ApiUtil2.get<gdnAdSetDatas>('/api/camp/make02/gdnAdSetDataSearch', { params: { campId: campIdParam } });
    return data;
  };

  const getGdnAdSetDatasApi = useQuery(['getGdnAdSetDatas'], getGdnAdSetDatas, {
    enabled: false,
  })
    // 기타상태 광고세트 useyn
  const getGdnAdSetDatas2 = async () => {
    const { data } = await ApiUtil2.get<gdnAdSetDatas2>('/api/camp/make02/gdnAdSetDataSearch2', { params: { campId: campIdParam } });
    return data;
  };

  const getGdnAdSetDatas2Api = useQuery(['getGdnAdSetDatas2'], getGdnAdSetDatas2, {
    enabled: false,
  })

  // 광고세트 onoff 수정 처리
  const gdnAdSetOnOffUpdateApi = () => {
    const {
      adSetId, useYn,
    } = gdnAdSetOnOffUpdateForm.getValues();
    const result = ApiUtil.post<gdnAdSetOnOffUpdate>('/api/camp/make02/gdnAdSetOnOffUpdate', {
      adSetId, useYn,
    });
    return result;
  };
  const gdnAdSetOnOffUpdateEvent = useMutation(gdnAdSetOnOffUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
      }
    },
  });
  // 수정 처리
  const gdnAdSetUpdateApi = () => {
    const {
      campId, adSetId, genderDiv, location, age,
    } = getValues();
    const result = ApiUtil2.post<gdnAdSetForm>('/api/camp/make02/gdnAdSetDataUpdate', {
      campId, adSetId, genderDiv, location, age,
    });
    return result;
  };
  const gdnAdSetUpdate = useMutation(gdnAdSetUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
      }
    },
  });

  const tabClickEvent = (value:any) => {
    setTabClick(value);
  };
  const changeDataUpdateEvent = () => {
    gdnAdSetUpdate.mutate();
  };
  const gdnGenderDivEvent = () => {
    const query = 'input[name=genderDiv]:checked';
    const checkeds = document.querySelectorAll(query);
    const checkedValues:any = [];
    if (checkeds.length === 0) {
      defaultGenderDiv.forEach((value) => {
        $(`#${value}`).attr('checked', 'checked');
      });
      setValue('genderDiv', defaultGenderDiv);
    } else {
      checkeds.forEach((el:any) => {
        checkedValues.push(el.getAttribute('value'));
      });
      setValue('genderDiv', checkedValues);
    }
    changeDataUpdateEvent();
  };
  const gdnAgeEvent = () => {
    const query = 'input[name=age]:checked';
    const checkeds = document.querySelectorAll(query);
    const checkedValues:any = [];
    if (checkeds.length === 0) {
      defaultAgeRange.forEach((value) => {
        $(`#${value}`).attr('checked', 'checked');
      });
      setValue('age', defaultAgeRange);
    } else {
      checkeds.forEach((el:any) => {
        checkedValues.push(el.getAttribute('value'));
      });
      setValue('age', checkedValues);
    }
    changeDataUpdateEvent();
  };
  const locationFocusEvent = () => {
    $('#locationSearchTag').addClass('focus');
  };
  const locationBlurEvent = () => {
    $('#locationSearchTag').removeClass('focus');
  };
  const searchFunc = (searchValue:string) => {
    globalTimeout = null;
    setCountryName(searchValue);
  };
  // 지역 검색 텍스트 입력 시 1초 딜레이 후 API 콜
  const geoTargetSearchEvent = (event:any) => {
    const searchValue = event.currentTarget.value;
    if (globalTimeout != null) clearTimeout(globalTimeout);
    globalTimeout = setTimeout(() => searchFunc(searchValue), 1000);
  };

  const targetDelEvent = () => {
    if (campViewStatusParam === 'complete' || (!adminParam.isAdmin && adminParam.forAdmin)) {
      return;
    }
    $('[id^=geoTarget_]').on('click', (e:any) => {
      const locationIds: any[] = [];
      const tagDelQuery = '.tag';
      const tagsDel = document.querySelectorAll(tagDelQuery);
      if (tagsDel.length === 1) {
        alertify.error('최소 1개 이상의 지역이 입력되어야 합니다.');
      } else {
        tagsDel.forEach((del:any) => {
          if (e.currentTarget.id === del.childNodes[1].id) {
            del.remove();
          } else {
            locationIds.push(del.childNodes[1].id.split('_')[1]);
            setValue('location', locationIds);
          }
        });
        changeDataUpdateEvent();
      }
    });
  };

  const onChangeAdSet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const adSetId:any = e.currentTarget.id;
    const adSetIdValue:any = e.currentTarget.value;
    if (adSetId === 'gdnAdSet1') {
      setCheckedInput1(checked);
    } else {
      setCheckedInput2(checked);
    }
    if (adSetIdValue !== 'on') {
      gdnAdSetOnOffUpdateForm.setValue('adSetId', adSetIdValue);
      gdnAdSetOnOffUpdateForm.setValue('useYn', checked);
      gdnAdSetOnOffUpdateEvent.mutate();
    }
  };
  const searchGdnAdSet2 = () => {
    globalTimeout = null;
    getGdnAdSetDatas2Api.refetch().then((r) => {
      if (r.isSuccess) {
        r.data.data.forEach((data) => {
          // 스위치 버튼 변경
          if (data !== null) {
            const { useYn } = data;
            if (data.adSetOrder === 1) {
              setCheckedInput1(useYn);
              $('#gdnAdSet1').val(data.id);
            } else {
              setCheckedInput2(useYn);
              $('#gdnAdSet2').val(data.id);
            }
          }
        });
      }
    });
  };
  const gdnAdSetSearch2Event = () => {
    if (globalTimeout != null) clearTimeout(globalTimeout);
    globalTimeout = setTimeout(() => searchGdnAdSet2(), 1000);
  };
  const campStatusInit = () => {
    // 상태가 종료이거나 메이커가 들어왔는데 어드민이 작성한 캠페인일 경우 상태에 상관없이 모두 disabled
    if (campViewStatusParam === 'complete' || (!adminParam.isAdmin && adminParam.forAdmin)) {
      $('#MALE, #FEMALE, #18_24, #25_34, #35_44, #45_54, #55_99, #geoTargetSearchInput').attr('disabled', 'disabled');
    } else if (campViewStatusParam !== null && campViewStatusParam !== 'complete') {
      if (campViewStatusParam !== 'temp') {
        // 임시저장 및 종료상태가 아닐 경우
        $('#campStatusEtc').show();
        $('#gdnAdset01Tooltip').tooltip({
          content: '<p class="fz-12 fc-0">사용자가 입력한 타겟 정보를 기반으로 생성되는 광고세트입니다.</p>',
        });
        $('#gdnAdset02Tooltip').tooltip({
          content: '<p class="fz-12 fc-0">광고 효율 향상을 위해 와디즈에서 입력한 타겟 정보를 기반으로 생성되는 광고세트입니다.</p>',
        });
        // 광고세트에 대한 정보세팅(useyn)
        gdnAdSetSearch2Event();
      }
    }
  };
  const searchGdnAdSet = () => {
    globalTimeout = null;
    getGdnAdSetDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        setValue('adSetId', r.data.data.id);
        const genderDivData = [];
        const ageRangeData = r.data.data.age.split(',');
        if (r.data.data.genderDiv === 'ALL') {
          setValue('genderDiv', defaultGenderDiv);
          defaultGenderDiv.forEach((value) => {
            $(`#${value}`).attr('checked', 'checked');
          });
        } else {
          genderDivData.push(r.data.data.genderDiv);
          setValue('genderDiv', genderDivData);
          genderDivData.forEach((value) => {
            $(`#${value}`).attr('checked', 'checked');
          });
        }
        setValue('age', ageRangeData);
        ageRangeData.forEach((value) => {
          $(`#${value}`).attr('checked', 'checked');
        });

        const locationData = r.data.data.location.split(',');
        const tagGroupQuery = '.tag-group';
        const tagGroups = document.querySelector(tagGroupQuery);
        setValue('location', locationData);
        locationData.forEach((data) => {
          const countryId = data.split('||')[0];
          const name = data.split('||')[1];
          if (tagGroups !== null) tagGroups.innerHTML += `<li class="tag">${name}<i class="ico ico-delete" id="geoTarget_${countryId}||${name}"></i></li>`;
        });

        targetDelEvent();
        // 캠페인 상태값에 따른 초기화
        campStatusInit();
      }
    });
  };
    // 타겟정보 데이터 가져오기
    // 데이터를 즉시 가져오게 되면 업데이트 이전에 가져오는 경우가 있어서 0.5초정도 늦게 가져온다
  const gdnAdSetSearchEvent = () => {
    if (globalTimeout != null) clearTimeout(globalTimeout);
    globalTimeout = setTimeout(() => searchGdnAdSet(), 1000);
  };
  // step02 에서 검수 체크
  const step2InspReqValidEvent = () => {
    campInspGdnStep02Ref.current!.inspValidEvent();
  };
  // 지역 검색 관련 이벤트 처리
  useEffect(() => {
    if (countryName !== '') {
      getGeoTargetApi.refetch().then((r) => {
        if (r.isSuccess) {
          const optSelectBoxQuery = '#optSelectBox';
          const optSelectBoxs = document.querySelector(optSelectBoxQuery);
          // 검색한 지역이 없을 경우
          if (r.data.data.length === 0) {
            if (optSelectBoxs !== null) {
              optSelectBoxs.innerHTML = '<li class="comp-no-data">\n'
                + '                  <div class="box-group">\n'
                + '                      <i class="ico ico-no-search"></i>\n'
                + '                      <p class="fz-14 fc-2">검색 결과가 없습니다.</p>\n'
                + '                      <p class="fz-12 fc-3">검색어를 다시 입력하여 검색해주세요.</p>\n'
                + '                  </div>\n'
                + '              </li>';
            }
          } else {
            // 검색한 지역이 존재할 경우
            if (optSelectBoxs !== null) optSelectBoxs.innerHTML = '';
            r.data.data.forEach((geoTarget:geoTargetConstant) => {
              if (optSelectBoxs !== null) optSelectBoxs.innerHTML += `<li class="opt-menu" id="${geoTarget.countryId}||${geoTarget.name}"><p class="fc-1">${geoTarget.name}</p></li>`;
            });
          }
          $('#compSearchDiv').addClass('selected');

          const addTargetEvent = (target:any) => {
            let isAdd = true;
            const allTagQuery = '.tag > i';
            const allTags = document.querySelectorAll(allTagQuery);
            if (allTags.length === 20) {
              return;
            }
            const countryId = target.currentTarget.id.split('||')[0];
            const name = target.currentTarget.id.split('||')[1];
            allTags.forEach((tag:any) => {
              if (tag.id.split('||')[0].split('_')[1] === `${countryId}`) {
                isAdd = false;
              }
            });
            if (isAdd) {
              const tagGroupQuery = '.tag-group';
              const tagGroups = document.querySelector(tagGroupQuery);
              if (tagGroups !== null) tagGroups.innerHTML += `<li class="tag">${name}<i class="ico ico-delete" id="geoTarget_${countryId}||${name}"></i></li>`;
              const addLocatinos = getValues('location');
              addLocatinos.push(target.currentTarget.id);
              setValue('location', addLocatinos);
              targetDelEvent();
              changeDataUpdateEvent();
            }
          };
          const optSelectBoxOptMenuQuery = '#optSelectBox > .opt-menu';
          const optSelectBoxOptMenus = document.querySelectorAll(optSelectBoxOptMenuQuery);
          optSelectBoxOptMenus.forEach((e) => {
            e.addEventListener('click', addTargetEvent);
          });
        }
      });
    }
  }, [countryName]);

  // 페이지 진입시 초기화 처리
  useEffect(() => {
    if (campIdParam !== null) {
      setValue('campId', campIdParam);
    }
    gdnAdSetSearchEvent();

    $('#gdnLocationTooltip').tooltip({
      content: '<p class="fz-12 fc-0">해당 지역에 거주하거나 위치한 사람들에게 광고를 표시합니다.</p>',
    });
    return () => {
      $('.tooltip').remove();
    };
  }, []);
  return (
    <Fragment>
        <div className="comp-tab-content selected">
          <section className="wrap-section wrap-tbl">
              <div className="box-body">
                  <div className="tbl">
                      <dl className="vertical" id="campStatusEtc" style={{ display: 'none' }}>
                          <dd>
                              <div className="container-fluid">
                                  <div className="row">
                                      <div className="col col-6">
                                          <div className="form-group txt-center">
                                                <span className="comp-txt">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-14 fc-1">논타겟 광고세트</b>
                                                            <Link to="#" className="ico-tooltip tooltip-f" id="gdnAdset01Tooltip" title=""></Link>
                                                        </span>
                                                    </span>
                                                </span>
                                              <div className="comp-switch">
                                                  <input type="checkbox" id="gdnAdSet1" checked={checkedInput1} onChange={onChangeAdSet} />
                                                  <label htmlFor="gdnAdSet1">
                                                      <i className="ico"></i>
                                                  </label>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col col-6">
                                          <div className="form-group txt-center">
                                                <span className="comp-txt">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-14 fc-1">리타겟 광고세트</b>
                                                            <Link to="#" className="ico-tooltip tooltip-f" id="gdnAdset02Tooltip" title=""></Link>
                                                        </span>
                                                    </span>
                                                </span>
                                              <div className="comp-switch">
                                                  <input type="checkbox" id="gdnAdSet2" checked={checkedInput2} onChange={onChangeAdSet} />
                                                  <label htmlFor="gdnAdSet2">
                                                      <i className="ico"></i>
                                                  </label>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </dd>
                      </dl>
                      <dl className="vertical" style={{ display: 'none' }}>
                          <dd>
                              <div className="form-group">
                                  <div className="comp-help">
                                      <ul className="help-list">
                                          <li className="list">
                                              <i className="ico ico-help"></i><span className="fz-14 fc-2">캠페인이 진행 된 이후 타겟 정보를 수정하면 광고 효율이 저하될 수 있습니다.</span>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </dd>
                      </dl>
                      <dl className="vertical column-two">
                          <dt>
                              <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        성별
                                        <i className="fz-12 fc-5">*</i>
                                    </span>
                              </div>
                          </dt>
                          <dd>
                              <div className="form-group">
                                  <div className="comp-checkbox txt-center">
                                      <input type="checkbox" id="MALE" value="MALE" {...register('genderDiv')} onChange={gdnGenderDivEvent}/>
                                      <label htmlFor="MALE" className="label"> <i className="ico ico-male"></i></label>
                                      <label htmlFor="MALE">남성</label>
                                  </div>
                                  <div className="comp-checkbox txt-center">
                                      <input type="checkbox" id="FEMALE" value="FEMALE" {...register('genderDiv')} onChange={gdnGenderDivEvent}/>
                                      <label htmlFor="FEMALE" className="label"> <i className="ico ico-female"></i></label>
                                      <label htmlFor="FEMALE">여성</label>
                                  </div>
                              </div>
                          </dd>
                          <dt>
                              <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        지역
                                        <Link to="#" className="ico-tooltip tooltip-f" id="gdnLocationTooltip" title=""></Link>
                                    </span>
                              </div>
                          </dt>
                          <dd>
                              <div className="form-group">
                                  <div className="input-group comp-search expand">
                                      <div className="inner-input-group" id="compSearchDiv">
                                          <div className="search-tag selected focus" id="locationSearchTag">
                                              <ul className="tag-group">
                                              </ul>
                                              <div className="input-group">
                                                  <input type="text" className="tf-comm" id="geoTargetSearchInput"
                                                         placeholder="지역을 검색하여 추가해 주세요." onKeyUp={geoTargetSearchEvent} onFocus={locationFocusEvent} onBlur={locationBlurEvent}/>
                                                  <i className="ico ico-search"></i>
                                              </div>
                                          </div>
                                      </div>
                                      <ul className="opt-selectbox" id="optSelectBox">
                                      </ul>
                                  </div>
                              </div>
                          </dd>
                      </dl>
                      <dl className="vertical">
                          <dt>
                              <div className="dt-inner">
                            <span className="fz-16 fc-1">
                                나이
                                <i className="fz-12 fc-5">*</i>
                            </span>
                              </div>
                          </dt>
                          <dd>
                              <div className="form-group">
                                  <div className="comp-checkbox txt-center">
                                      <input type="checkbox" id="18_24" value="18_24" {...register('age')} onChange={gdnAgeEvent}/>
                                      <label htmlFor="18_24" className="label"><span className="fz-14">18 - 24</span></label>
                                      <label htmlFor="18_24" className="single"> </label>
                                  </div>
                                  <div className="comp-checkbox txt-center">
                                      <input type="checkbox" id="25_34" value="25_34" {...register('age')} onChange={gdnAgeEvent}/>
                                      <label htmlFor="25_34" className="label"><span className="fz-14">25 - 34</span></label>
                                      <label htmlFor="25_34" className="single"> </label>
                                  </div>
                                  <div className="comp-checkbox txt-center">
                                      <input type="checkbox" id="35_44" value="35_44" {...register('age')} onChange={gdnAgeEvent}/>
                                      <label htmlFor="35_44" className="label"><span className="fz-14">35 - 44</span></label>
                                      <label htmlFor="35_44" className="single"> </label>
                                  </div>
                                  <div className="comp-checkbox txt-center">
                                      <input type="checkbox" id="45_54" value="45_54" {...register('age')} onChange={gdnAgeEvent}/>
                                      <label htmlFor="45_54" className="label"><span className="fz-14">45 - 54</span></label>
                                      <label htmlFor="45_54" className="single"> </label>
                                  </div>
                                  <div className="comp-checkbox txt-center">
                                      <input type="checkbox" id="55_99" value="55_99" {...register('age')} onChange={gdnAgeEvent}/>
                                      <label htmlFor="55_99" className="label"><span className="fz-14">55 - 99</span></label>
                                      <label htmlFor="55_99" className="single"> </label>
                                  </div>
                              </div>
                          </dd>
                      </dl>
                  </div>
              </div>
              <div className="box-footer">
                  <div className="box-left">
                      <Link to="/mngCamp/mngCamp" className="btn btn-primary-outline">캠페인 목록</Link>
                      {
                          (campViewStatusParam === 'temp' || campViewStatusParam === undefined)
                          && <button type="button" className="btn btn-primary" onClick={step2InspReqValidEvent}>검수요청</button>
                      }
                  </div>
                  <div className="box-right">
                      <button type="button" className="btn btn-secondary-outline" onClick={() => tabClickEvent('step01')}>이전</button>
                      <button type="button" className="btn btn-primary" onClick={() => tabClickEvent('step03')}>다음</button>
                  </div>
              </div>
              <Make02InspReqValidation ref={campInspGdnStep02Ref}/>
          </section>
      </div>

    </Fragment>
  );
};
export default Make02Step02Gdn;
