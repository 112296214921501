import React, {
  createContext,
  Fragment, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { Link } from 'react-router-dom';
import { ApiUtil2 } from '@biz/api'
import { useUsableCostQuery } from '@biz/query'
import {
  AdminContext,
  CampIdContext,
  CampTabClickContext, CampViewStatusContext, GdnCreationDataIdContext,
  // GdnCreationDataIdContext,
  GdnCreationFileUploadIdContext,
} from './Make02Empty';
import MasonryLayout from './MasonryLayout';
import CreationFileUploadGuide from './CreationFileUploadGuide'
import alertify from 'alertifyjs'

import Make02InspReqValidation from './Make02InspReqValidation'
import Make02Step03CreationItg, { itgCreationFormData } from './Make02Step03CreationItg'
import CreationFileUploadItg from './CreationFileUploadItg'

interface IFileTypes {
  uploadId: number
  fileId: number
  publicUrl: string
  afName: string
  fileName: string
  imageDiv: string
  object: File
}
interface CampData {
  ok: boolean
  campId: number
  campNm: string
  campGoalDiv: string
  campStartTime: string
  campEndTime: string
  campBudget: number
  adminCampYn: boolean
  mediaDiv4: string
  projectId: string
}
interface CampDatas {
  ok: boolean
  data: CampData
}
interface itgCreationData {
  ok: boolean
  id: number
  adTitle01: string
  adTitle02: string
  adDesc: string
  sortOrder: number
  clickBtnDiv: string
  preview: boolean
  afList: IFileTypes[]
  campBudget: number
  creationViewStatus: string
  adSetDatas: any
  clickBtnDivs: string[]
}
interface itgCreationLink {
  ok: boolean
  id: number
  adSetOrder: number
  creationViewStatus: string
  creationData: itgCreationData
}
interface itgCreationLinks {
  ok: boolean
  data: itgCreationLink[]
}
interface itgAdSetStatusData {
  ok: boolean
  id: number
  creationDataId: string
  creationViewStatus: string
  adSetOrder: number
  useYn: boolean
}
interface itgAdSetStatusDatas {
  ok: boolean
  data: itgAdSetStatusData[]
}
interface calculateBalance {
  ok: boolean
  data: any
  campId: number
  changeCampBudget: number
  cost: number
}
interface CampBizmoneyInfo {
  ok: boolean
  data: any
  currentCost: number
}
export const reloadCountContext = createContext({
  reloadCount: 0,
  setReloadCount: (value: any) => value,
})
let globalTimeout: string | number | NodeJS.Timeout | null | undefined = null
const Make02Step03Itg = () => {
  const campInspItgStep03Ref = useRef<any>(null)
  const { campIdParam } = useContext(CampIdContext)
  const { setTabClick } = useContext(CampTabClickContext)
  const { getValues, setValue } = useForm<itgCreationFormData>()
  const { setGdnFileUploadId } = useContext(GdnCreationFileUploadIdContext)
  const { gdnCreationDataId } = useContext(GdnCreationDataIdContext)
  const [campItgCreationForm, setCampItgCreationForm] = useState<any>([])
  const [makerFiles, setMakerFiles] = useState<any>([])
  const [reloadCount, setReloadCount] = useState<any>(0)
  const { campViewStatusParam } = useContext(CampViewStatusContext)
  const { adminParam } = useContext(AdminContext)
  const calculateBalanceForm = useForm<calculateBalance>()
  const { update: setUsableCostChange } = useUsableCostQuery()
  // 소재 추가 버튼 과클릭 방지 true 일때 소재추가 액션이 동작한다.
  const [isAddCreationBtn, setIsAddCreationBtn] = useState<any>(true)
  // 비즈머니
  const getBizmoney = async () => {
    const { data } = await ApiUtil2.get<CampBizmoneyInfo>('/api/camp/make02/getBizmoney')
    return data
  }
  const bizmoneyApi = useQuery(['getBizmoney'], getBizmoney, { enabled: false })

  const calculateBalanceApi = async () => {
    const { data } = await ApiUtil2.get<calculateBalance>('/api/camp/make02/calculateBalance', {
      params: { campId: campIdParam, changeCampBudget: calculateBalanceForm.getValues('changeCampBudget') },
    })
    return data
  }
  const calculateBalanceEvent = useQuery(['calculateBalanceApi'], calculateBalanceApi, {
    enabled: false,
  })
  const getItgCreationDatas = async () => {
    const { data } = await ApiUtil2.get<itgCreationLinks>('/api/camp/make02/fbCreationDataSearch', {
      params: { campId: campIdParam },
    })
    return data
  }
  const getFbCreationDatasApi = useQuery(['getItgCreationDatas'], getItgCreationDatas, {
    enabled: false,
  })

  const itgCreationLinkSearchApi = async () => {
    const { data } = await ApiUtil2.get<itgAdSetStatusDatas>('/api/camp/make02/fbCreationLinkSearch', {
      params: { campId: campIdParam },
    })
    return data
  }

  const itgCreationLinkSearchEvent = useQuery(['fbCreationLinkSearch'], itgCreationLinkSearchApi, {
    enabled: false,
  })

  // 등록 처리
  const itgCreationDataInsertApi = () => {
    const { campId, sortOrder, clickBtnDiv, isCampBudgetChange, changeCampBudget } = getValues()
    const result = ApiUtil2.post<itgCreationFormData>('/api/camp/make02/fbCreationDataInsert', {
      campId,
      sortOrder,
      clickBtnDiv,
      isCampBudgetChange,
      changeCampBudget,
    })
    return result
  }
  const itgCreationDataInsert = useMutation(itgCreationDataInsertApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        // 상단 비즈머니 업데이트
        if (getValues('isCampBudgetChange')) {
          bizmoneyApi.refetch().then((r) => {
            if (r.isSuccess) {
              setUsableCostChange(r.data.data.currentCost.toLocaleString())
            }
          })
        }
        setValue('isCampBudgetChange', false)
        // eslint-disable-next-line no-use-before-define
        setReComponent()
      }
    },
  })

  const getCampDatas = async () => {
    const { data } = await ApiUtil2.get<CampDatas>('/api/camp/make02/getCampInfo', { params: { campId: campIdParam } })
    return data
  }
  const getCampDatasApi = useQuery(['getCampDatas'], getCampDatas, {
    enabled: false,
  })
  // 행동유도버튼 데이터 가져오기
  const getClickBtnDiv = async () => {
    const { data } = await ApiUtil2.get('/api/camp/make02/fbClickBtnInfos', { params: { campId: campIdParam } })
    return data
  }
  const getClickBtnDivApi = useQuery(['getClickBtnDiv'], getClickBtnDiv, {
    enabled: false,
  })
  const tabClickEvent = (value: any) => {
    setTabClick(value)
  }

  // 등록
  const AddItgCreation = useCallback(() => <Fragment>{campItgCreationForm}</Fragment>, [campItgCreationForm])
  const itgCreationDataInsertEvent = (isReLoading: boolean) => {
    // 소재 추가 + 버튼 과다클릭 방지를 위한...
    setIsAddCreationBtn(false)
    setValue('sortOrder', campItgCreationForm.length + 1)
    setValue('clickBtnDiv', 'LEARN_MORE_FB')
    itgCreationDataInsert.mutate()

    if (isReLoading) {
      // eslint-disable-next-line no-use-before-define
      // setReComponent();
    }
  }
  // 삭제
  const removeCreationItgEvent = () => {
    // eslint-disable-next-line no-use-before-define
    setReComponent()
    // if (campItgCreationForm.length >= 1) {
    // }
  }
  const creationComponentRefresh = () => {
    // eslint-disable-next-line no-use-before-define
    setReComponent()
  }
  // 복사
  const copyCreationItgEvent = () => {
    if (campItgCreationForm.length >= 10) {
      alertify.error('소재는 10개까지 추가 가능합니다.')
      $('#itgAddCreationBtn').attr('disabled', 'disabled')
    } else {
      // eslint-disable-next-line no-use-before-define
      setReComponent()
    }
  }
  // 빈 소재 추가
  const addCreationItgEvent = () => {
    if (
      (campViewStatusParam !== null &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        adminParam.isAdmin &&
        adminParam.forAdmin) ||
      (campViewStatusParam !== null &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        !adminParam.isAdmin &&
        !adminParam.forAdmin) ||
      (campViewStatusParam !== 'complete' && !adminParam.isAdmin && !adminParam.forAdmin) ||
      (campViewStatusParam !== 'complete' && adminParam.isAdmin && !adminParam.forAdmin) ||
      (adminParam.isAdmin && adminParam.forAdmin)
    ) {
      if (campItgCreationForm.length >= 10) {
        alertify.error('소재는 10개까지 추가 가능합니다.')
        $('#itgAddCreationBtn').attr('disabled', 'disabled')
      } else if (getValues('campBudget') !== undefined) {
        // 기타상태일 경우 가져온 캠페인 예산을 가지고 소재 추가시 예산 체크한다.(비즈머니 체크는?)
        const chaBudget = (campItgCreationForm.length + 1) * 5000
        // 현재 설정된 예산체크 추가했을때 예산이 초과되면??
        if (getValues('campBudget') < chaBudget) {
          const changeCampBudgetTxt = (getValues('campBudget') + 5000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          const changeCampBudget = getValues('campBudget') + 5000
          calculateBalanceForm.setValue('changeCampBudget', changeCampBudget)
          calculateBalanceEvent.refetch().then((r) => {
            if (r.isSuccess) {
              alertify
                .confirm(
                  `소재당 1일 최소 5천원의 예산이 필요합니다. 1일 캠페인 예산을${changeCampBudgetTxt}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 비즈머니에서 ${r.data.data
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 (VAT포함)이 차감됩니다.`,
                  () => {
                    setValue('changeCampBudget', changeCampBudget)
                    setValue('isCampBudgetChange', true)
                    itgCreationDataInsertEvent(true)
                  }
                )
                .set({ labels: { cancel: '취소', ok: '확인' } })
                .setHeader('')
            }
          })
        } else {
          itgCreationDataInsertEvent(true)
        }
      } else {
        itgCreationDataInsertEvent(true)
      }
    }
  }
  // 컴포넌트 재생성
  const setReComponent = () => {
    if (globalTimeout != null) clearTimeout(globalTimeout)
    // eslint-disable-next-line no-use-before-define
    globalTimeout = setTimeout(() => searchItgCreation(), 200)
  }
  const setMakerFilesEvent = (files: any) => {
    setMakerFiles(files)
  }
  // step03 에서 검수 체크
  const step3InspReqValidEvent = () => {
    campInspItgStep03Ref.current!.inspValidEvent()
  }

  const dataLoad2 = (callback: any) => {
    const creationLinkDatas: any = []
    getCampDatasApi.refetch().then((rr) => {
      if (rr.isSuccess) {
        setValue('campBudget', rr.data.data.campBudget)
        itgCreationLinkSearchEvent.refetch().then((rr2) => {
          if (rr2.isSuccess) {
            rr2.data.data.forEach((data) => {
              creationLinkDatas.push(data)
            })
            callback(creationLinkDatas)
          }
        })
      }
    })
  }

  const allDataLoad = (creationLinkDatas: any) => {
    getFbCreationDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        // 페이스북 인스타그램은 행동 유도 버튼
        getClickBtnDivApi.refetch().then((rr) => {
          if (rr.isSuccess) {
            setValue('clickBtnDivs', rr.data.data)
            // 초기화
            const defaultDatas: itgCreationData[] = []
            r.data.data.map((data, index) => {
              const tempData = data.creationData
              tempData.creationViewStatus = data.creationViewStatus
              tempData.clickBtnDivs = getValues('clickBtnDivs')
              if (gdnCreationDataId !== null) {
                if (`${tempData.id}_` === `${gdnCreationDataId}_`) {
                  tempData.preview = false
                } else {
                  tempData.preview = true
                }
              } else if (r.data.data.length === index + 1) {
                tempData.preview = false
              } else {
                tempData.preview = true
              }
              if (
                (campViewStatusParam !== null &&
                  campViewStatusParam !== 'temp' &&
                  campViewStatusParam !== 'complete') ||
                (campViewStatusParam !== null &&
                  campViewStatusParam !== 'temp' &&
                  campViewStatusParam !== 'complete' &&
                  !adminParam.isAdmin &&
                  !adminParam.forAdmin)
              ) {
                const filterAdsetDatas = creationLinkDatas
                  .filter((d: any) => `${d.creationDataId}` === `${tempData.id}`)
                  .map((dd: any) => dd)
                tempData.adSetDatas = filterAdsetDatas
                tempData.campBudget = getValues('campBudget')
              }
              // 종료일 경우 모두 미리보기 모드로만...
              if (campViewStatusParam === 'complete' || (!adminParam.isAdmin && adminParam.forAdmin)) {
                tempData.preview = true
              }

              defaultDatas.push(tempData)
              return tempData
            })
            if (defaultDatas.length >= 10) {
              defaultDatas.sort((a, b) => b.sortOrder - a.sortOrder)
              const components = defaultDatas.map((data: any) => (
                <Make02Step03CreationItg
                  step3InspReqValidEvent={step3InspReqValidEvent}
                  setMakerFilesEvent={setMakerFilesEvent}
                  maxSize={defaultDatas.length}
                  creationComponentRefresh={creationComponentRefresh}
                  copyCreationItgEvent={copyCreationItgEvent}
                  creationData={data}
                  key={data.sortOrder}
                  order={data.sortOrder}
                  removeCreationItgEvent={removeCreationItgEvent}
                />
              ))
              setCampItgCreationForm(components)
              $('#itgAddCreationBtn').attr('disabled', 'disabled')
            } else {
              defaultDatas.sort((a, b) => b.sortOrder - a.sortOrder)
              const components = defaultDatas.map((data: any) => (
                <Make02Step03CreationItg
                  step3InspReqValidEvent={step3InspReqValidEvent}
                  setMakerFilesEvent={setMakerFilesEvent}
                  maxSize={defaultDatas.length}
                  creationComponentRefresh={creationComponentRefresh}
                  copyCreationItgEvent={copyCreationItgEvent}
                  creationData={data}
                  key={data.sortOrder}
                  order={data.sortOrder}
                  removeCreationItgEvent={removeCreationItgEvent}
                />
              ))
              setCampItgCreationForm(components)
              $('#itgAddCreationBtn').removeAttr('disabled')
            }
            setReloadCount(0)
            // 소재 추가 + 버튼 과다클릭 방지를 위한...
            setIsAddCreationBtn(true)
          }
        })
      }
    })
  }
  // 소재 초기 세팅
  const searchItgCreation = () => {
    globalTimeout = null
    let creationLinkDatas: any = []

    if (
      (campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete') ||
      (campViewStatusParam !== null &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        !adminParam.isAdmin &&
        !adminParam.forAdmin)
    ) {
      dataLoad2((callbackData: any) => {
        creationLinkDatas = callbackData
        allDataLoad(creationLinkDatas)
      })
    } else {
      allDataLoad(creationLinkDatas)
    }
  }
  useEffect(() => {
    if (reloadCount !== 0) {
      setReComponent()
    }
  }, [reloadCount])
  useEffect(() => {
    if (campIdParam !== null) {
      setValue('campId', campIdParam)
    }
    setReComponent()
    MasonryLayout()
    window.addEventListener('resize', MasonryLayout)
    if (campViewStatusParam === 'complete') {
      $('#creationAddBtn').hide()
    } else {
      ApiUtil2.get('/api/common/getUploadId').then((response) => {
        setGdnFileUploadId(response.data.data)
      })
    }
    return () => {
      // 여기서 다이얼로그 관련된것 destory(필수)
      const dialogCheck = $('#creationFileUploadItgDialog')
      const dialogCheck2 = $('#creationFileUploadGuideDialog')
      if (dialogCheck.length > 0) {
        $('#creationFileUploadItgDialog').dialog('destroy')
      }
      if (dialogCheck2.length > 0) {
        $('#creationFileUploadGuideDialog').dialog('destroy')
      }
      $('.tooltip').remove()
    }
  }, [])

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-masonry">
                    <div className="masonry-item" id="creationAddBtn" style={{ gridRowEnd: 'span 64' }}>
                      <div className="wrap-preview">
                        <div className="box-body">
                          <div className="box-group flex-container-center h-684">
                            {isAddCreationBtn ? (
                              <button
                                type="button"
                                className="btn btn-add"
                                id="itgAddCreationBtn"
                                onClick={addCreationItgEvent}
                              ></button>
                            ) : (
                              <button type="button" className="btn btn-add"></button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {campItgCreationForm.length > 0 ? <AddItgCreation /> : null}
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div id="creationFileUploadItgDialog" className="dialog" style={{ display: 'none' }}>
          <reloadCountContext.Provider value={{ reloadCount, setReloadCount }}>
            <CreationFileUploadItg makerFiles={makerFiles} />
          </reloadCountContext.Provider>
        </div>
        <div id="creationFileUploadGuideDialog" className="dialog" style={{ display: 'none' }}>
          <CreationFileUploadGuide />
        </div>
        <div className="box-footer">
          <div className="box-left">
            <Link to="/mngCamp/mngCamp" className="btn btn-primary-outline">
              캠페인 목록
            </Link>
            {(campViewStatusParam === 'temp' || campViewStatusParam === undefined) && (
              <button type="button" className="btn btn-primary" onClick={step3InspReqValidEvent}>
                검수요청
              </button>
            )}
          </div>
          <div className="box-right">
            <button type="button" className="btn btn-secondary-outline" onClick={() => tabClickEvent('step02')}>
              이전
            </button>
          </div>
        </div>
        <Make02InspReqValidation ref={campInspItgStep03Ref} />
      </section>
    </Fragment>
  )
}
export default Make02Step03Itg;
