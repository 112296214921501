import React, {forwardRef, useEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {PlainObject} from "select2";
import {useForm} from "react-hook-form";
import _ from "lodash";
import { useMutation } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'



const CreationChatGptForm = (props : any, ref : any) => {
  const { hisData } = props;
  const adminInspStatusSelect = useRef<HTMLSelectElement>(null);
  const [memoLength, setMemoLength] = useState<number>(0);

  const { register, setValue, getValues } = useForm();
  const [ adminInspDivDesc, setAdminInspDivDesc ] = useState('유형');

  const textCheckRef = useRef<HTMLInputElement>(null);
  const imgCheckRef = useRef<HTMLInputElement>(null);
  const movieCheckRef = useRef<HTMLInputElement>(null);
  const slideCheckRef = useRef<HTMLInputElement>(null);

  const clearMemo = () => {
    setValue('adminMemo', '');
    setMemoLength(0);
  }

  const checkAdminInspDiv = (() => {
    let dropdownTxt = '유형';
    let checkedCnt = 0;
    let checkedArr = [];

    if (slideCheckRef.current!!.checked) {
      dropdownTxt = '슬라이드';
      checkedCnt++;
      checkedArr.push('slide');
    }

    if (movieCheckRef.current!!.checked) {
      dropdownTxt = '영상';
      checkedCnt++;
      checkedArr.push('movie');
    }

    if (imgCheckRef.current!!.checked) {
      dropdownTxt = '이미지';
      checkedCnt++;
      checkedArr.push('img');
    }

    if (textCheckRef.current!!.checked) {
      dropdownTxt = '텍스트';
      checkedCnt++;
      checkedArr.push('text');
    }

    setAdminInspDivDesc(`${dropdownTxt}${ checkedCnt > 1 ? ` 외 ${ checkedCnt }` : '' }`)
    setValue('adminInspDiv', checkedArr.reverse().join(','));
  });

  const renderGptInstStatus = ((chatGptStatus : any) => {
    switch (chatGptStatus) {
      case 'ing':
        return <div className="txt-state blue">검수중</div>
      case 'error':
        return <div className="txt-state black">실패</div>
      case 'ok':
        return <div className="txt-state mint">승인</div>
      case 'fail':
        return <div className="txt-state red">반려</div>
      default :
        return <div className="txt-state blue">검수중</div>
    }
  });

  const postAdminGptMemo = useMutation((formData : any) => ApiUtil2.post(`/api/mngAd/creation/chatGptHis/saveMemo`, formData), {
    onSuccess: (resp) => {
      alertify.success('정상적으로 저장 되었습니다.');
    },
  });

  const saveAdminGptMemo = () => {
    const formData = {
      ...getValues(),
    };

    if (_.isEmpty(formData.adminMemo)) {
      alertify.error('검수 결과 사유를 입력해 주신 후 다시 시도해 주세요.');
      return;
    }

    if (_.isEmpty(formData.adminInspStatus) || _.isEmpty(formData.adminInspDiv)) {
      alertify.error('검수 결과와 검수 소재 유형을 선택하신 후 다시 시도해 주세요.');
      return;
    }

    postAdminGptMemo.mutate(formData);
  };

  useEffect(() => {
    setValue('creationDataGptHisId', hisData.creationDataGptHisId);
    setValue('adminInspStatus', _.isEmpty(hisData.adminInspStatus) ? '' : hisData.adminInspStatus);

    if (!_.isEmpty(hisData.adminInspStatus)) adminInspStatusSelect.current!!.value = hisData.adminInspStatus;
    if (!_.isEmpty(hisData.adminMemo)) {
      setValue('adminMemo', hisData.adminMemo);
      setMemoLength(hisData.adminMemo.length);
    }

    if (!_.isEmpty(hisData.adminInspDiv)) {
      setValue('adminInspDiv', hisData.adminInspDiv);
      const adminInspDivs = hisData.adminInspDiv.split(',');

      _.each(adminInspDivs, (v : string) => {
        switch(v) {
          case 'text':
            textCheckRef.current!!.checked = true;
            break;
          case 'img':
            imgCheckRef.current!!.checked = true;
            break;
          case 'movie':
            movieCheckRef.current!!.checked = true;
            break;
          case 'slide':
            slideCheckRef.current!!.checked = true;
            break;
        }
      });

      checkAdminInspDiv();
    } else {
      setValue('adminInspDiv', '');
    }

    $(adminInspStatusSelect.current as PlainObject).select2({
      width: '100px',
      minimumResultsForSearch: Infinity,
      dropdownCssClass : 'small',
      placeholder: '검수결과'
    }).on('select2:select', (e : any) => {
      setValue('adminInspStatus', e.params.data.id);
    });
  }, []);

  return (
    <div className="wrap-section wrap-tbl" ref={(e) => (ref(e))}>
      <div className="box-header">
        <div className="box-tit">
          <h2 className="fz-20 fc-1 fw-bold">chatGPT</h2>
          {
            renderGptInstStatus(hisData.gptInspStatus)
          }
        </div>
        <div className="box-option">
          <h3 className="fz-12 fc-3">최종 { hisData.gptInspStatus == 'ing' || hisData.gptInspStatus == 'error' ? '전송' : '수신' }일시 : { hisData.gptInspStatus == 'ing' || hisData.gptInspStatus == 'error' ? dayjs(hisData.regTime).format('YYYY-MM-DD HH:mm:ss') : dayjs(hisData.completeTime).format('YYYY-MM-DD HH:mm:ss') }</h3>
        </div>
      </div>
      <div className="box-body">
        <div className="tbl">
          <dl className="vertical">
            <dd>
              <div className="form-group">
                  <span className="comp-txt">
                      <span className="table">
                          <span className="table-cell">
                              <b className="fz-14 fc-2" dangerouslySetInnerHTML={{__html: _.isEmpty(hisData.gptInspContent) ? (hisData.gptInspStatus == 'ok' ? '검수한 모든 항목이 승인 되었습니다.' : '') : hisData.gptInspContent.replace(/\\n/g, '<br/>').replace(/\n/g, '<br/>')}}>
                                </b>
                          </span>
                      </span>
                  </span>
              </div>
            </dd>
          </dl>
          <dl className="vertical">
            <dt>
              <div className="dt-inner flex-container">
                <div className="box-left">
                  <span className="fz-16 fc-1">광고사업팀</span>
                </div>
                <div className="box-right">
                  <select className="select2-single small" { ...register('adminInspStatus') } ref={adminInspStatusSelect}>
                    <option></option>
                    <option value="ok">승인</option>
                    <option value="fail">반려</option>
                    <option value="ing">검수중</option>
                  </select>
                  <div className="comp-dropdown select2 w-150">
                    <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                      <div className="box-left">
                        <span className="fz-14">{adminInspDivDesc}</span>
                      </div>
                      <div className="box-right">
                        <i className="ico ico-arrow"></i>
                      </div>
                    </a>
                    <div className="dropdown-menu expand">
                      <ul className="opt-selectbox">
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input type="checkbox" id={`txt_chk_${props.creationDataId}`} onChange={checkAdminInspDiv} value={`text`} ref={textCheckRef}/>
                            <label htmlFor={`txt_chk_${props.creationDataId}`}>텍스트</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input type="checkbox" id={`img_chk_${props.creationDataId}`} onChange={checkAdminInspDiv} value={`img`} ref={imgCheckRef}/>
                            <label htmlFor={`img_chk_${props.creationDataId}`}>이미지</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input type="checkbox" id={`movie_chk_${props.creationDataId}`} onChange={checkAdminInspDiv} value={`movie`} ref={movieCheckRef}/>
                            <label htmlFor={`movie_chk_${props.creationDataId}`}>영상</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input type="checkbox" id={`slide_chk_${props.creationDataId}`} onChange={checkAdminInspDiv} value={`slide`} ref={slideCheckRef}/>
                            <label htmlFor={`slide_chk_${props.creationDataId}`}>슬라이드</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <button type="button" className="btn btn-primary small" onClick={saveAdminGptMemo}>저장</button>
                </div>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <div className="input-group small expand">
                  <div className="inner-input-group">
                    <textarea className="tf-textarea" placeholder="해당 소재를 검수하신 결과 및 사유를 입력해 주세요." maxLength={100} {...register('adminMemo') } onChange={(e) => setMemoLength(e.target.value.length)}></textarea>
                    <i className="ico ico-delete" onMouseDown={clearMemo}></i>
                  </div>
                  <p className="fz-12 fc-3"><span className="fc-2">{memoLength}</span> / 100</p>
                  <p className="fz-12"><i className="ico"></i>체크 / 에러 문구 내용 영역</p>
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(CreationChatGptForm);
