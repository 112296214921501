import React, {
  ChangeEvent,
  useCallback,
  Fragment, useEffect, useContext, useState, forwardRef, useImperativeHandle,
} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil2, ApiUtil3, uploadUrl } from '@biz/api'
import { IFileTypes } from './MultiFileUploaderGgLogo'
import { CampIdContext, GdnCreationDataIdContext, GdnCreationFileUploadIdContext } from '../camp/make02/Make02Empty'
import { FileValidationCreationGg } from './FileGgCreationValidator'
// eslint-disable-next-line no-unused-vars
import { reloadCountContext } from '../camp/make02/Make02Step03Gdn'

interface uploadFileForm {
  ok: boolean
  creationDataId: any
  campId: any
  uploadFile: IFileTypes | any
}
export interface uploadFiles {
  ok: boolean
  data: IFileTypes[] | any
}
let uploadId: number
let submitFile: any = []
const FileUploadGgCreation = (props: any, ref: any) => {
  const [imageUrl, setImageUrl] = useState<any>()
  const [uploadFileId, setUploadFileId] = useState<any>()
  const [afId, setAfId] = useState<any>(null)
  const { gdnFileUploadId } = useContext(GdnCreationFileUploadIdContext)
  const { campIdParam } = useContext(CampIdContext)
  const { gdnCreationDataId } = useContext(GdnCreationDataIdContext)

  const { setReloadCount } = useContext(reloadCountContext)

  const { setValue, getValues } = useForm<uploadFileForm>()
  const gdnCreationUploadFileForm = useForm<IFileTypes>()

  const gdnCreationDataFileSearchApi = async () => {
    const { data } = await ApiUtil2.get<uploadFiles>('/api/camp/make02/gdnCreationDataFileSearch', {
      params: { creationDataId: gdnCreationDataId },
    })
    return data
  }

  const gdnCreationDataFileSearch = useQuery(['gdnCreationDataFileSearchApi'], gdnCreationDataFileSearchApi, {
    enabled: false,
  })
  // 파일 등록 처리
  const gdnCreationDataFileInsertApi = () => {
    const { uploadFile, campId } = getValues()
    const result = ApiUtil.post<uploadFileForm>('/api/camp/make02/gdnCreationDataFileInsert', {
      creationDataId: gdnCreationDataId,
      uploadFile,
      campId,
    })
    return result
  }
  const gdnCreationDataFileInsert = useMutation(gdnCreationDataFileInsertApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 컴포넌트를 리로딩 하기 위한 값 세팅
        setReloadCount(1)
        // props.fileListEvent();
      }
    },
  })
  // 미리보기 파일 등록 처리
  const gdnCreationDataFilePreviewInsertApi = () => {
    const result = ApiUtil.post<uploadFileForm>('/api/camp/make02/gdnCreationDataFilePreviewInsert', {
      afId,
      campId: campIdParam,
      creationDataId: gdnCreationDataId,
    })
    return result
  }
  const gdnCreationDataFilePreviewInsert = useMutation(gdnCreationDataFilePreviewInsertApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 컴포넌트를 리로딩 하기 위한 값 세팅
        setReloadCount(1)
      }
    },
  })

  const uploadInit = () => {
    $('#gdnCreationFileUpload').val('')
    setImageUrl(null)
    setAfId(null)
    // 좌측 파일 보기 초기화
    // setGdnMakerCreationFiles([]);
    submitFile = []
  }
  // 여기서 최종 데이터 등록
  const creationFileUpload = (file: any, imageDiv: string, width: number, height: number, fileSize: number) => {
    if (uploadId !== null) {
      ApiUtil3.post('/api/file/success', { uploadId, fileId: uploadFileId }).then((successRes) => {
        if (successRes.status === 200) {
          gdnCreationUploadFileForm.setValue('uploadId', uploadId)
          gdnCreationUploadFileForm.setValue('fileId', uploadFileId)
          gdnCreationUploadFileForm.setValue('publicUrl', imageUrl)
          gdnCreationUploadFileForm.setValue('afName', file.name)
          gdnCreationUploadFileForm.setValue('fileName', file.name)
          gdnCreationUploadFileForm.setValue('imageDiv', imageDiv)
          gdnCreationUploadFileForm.setValue('width', width)
          gdnCreationUploadFileForm.setValue('height', height)
          gdnCreationUploadFileForm.setValue('fileSize', fileSize)
          setValue('creationDataId', gdnCreationDataId)
          setValue('uploadFile', gdnCreationUploadFileForm.getValues())
          setValue('campId', campIdParam)
          gdnCreationDataFileInsert.mutate()
        }
      })
    }
  }
  const fileUploadFirst = (file: any) => {
    ApiUtil3.post('/api/file/uploadReq', {
      uploadId,
      originFileName: file.name,
      myS3Upload: 'creationGg',
    }).then((res) => {
      if (res.status === 200) {
        const { presignUrl, publicUrl, fileId } = res.data.data
        const commonLoader = document.querySelector('.loader.file2')
        if (commonLoader !== null) commonLoader.classList.remove('none')

        uploadUrl(presignUrl, file)
          .then((uploadRes) => {
            if (uploadRes.status === 200) {
              setImageUrl(publicUrl)
              setUploadFileId(fileId)
              submitFile = file
            }
          })
          .finally(() => {
            if (commonLoader !== null) commonLoader.classList.add('none')
          })
      }
    })
  }
  const onChangeFiles = useCallback((e: ChangeEvent<HTMLInputElement> | any): void => {
    let selectFiles: File[] = []
    if (e.type === 'drop') {
      selectFiles = e.dataTransfer.files
      if (selectFiles.length > 15) {
        alertify.error('소재 이미지는 최대 15개까지 업로드 가능합니다.')
        selectFiles = []
        return
      }
    } else {
      selectFiles = e.target.files
      if (selectFiles.length > 15) {
        alertify.error('소재 이미지는 최대 15개까지 업로드 가능합니다.')
        selectFiles = []
        return
      }
    }
    // 업로드 후 파일 체크???
    // eslint-disable-next-line no-restricted-syntax
    for (const file of selectFiles) {
      setImageUrl(null)
      setAfId(null)
      fileUploadFirst(file)
    }
  }, [])
  // 미리보기 이미지 클릭시 셋팅
  const previewFileSelect = (selectAfFileId: any, selectFileUrl: any) => {
    uploadInit()
    setImageUrl(selectFileUrl)
    setAfId(selectAfFileId)
  }
  const currentCreationFileCnt = (callback: any) => {
    gdnCreationDataFileSearch.refetch().then((r) => {
      if (r.isSuccess) {
        // setCurrentFileRegCnt(r.data.data.length);
        callback(r.data.data.length)
      }
    })
  }
  const fileCheckUpload = () => {
    if (imageUrl !== null && afId === null) {
      FileValidationCreationGg(
        submitFile,
        (finalCheck: boolean, file: any, imageDiv: string, width: number, height: number, fileSize: number) => {
          if (finalCheck) {
            // 현재 등록된 파일 개수 체크
            currentCreationFileCnt((fileCnt: any) => {
              if (fileCnt >= 15) {
                alertify.error('소재 이미지는 최대 15개까지 업로드 가능합니다.')
                return
              }
              creationFileUpload(file, imageDiv, width, height, fileSize)
              uploadInit()
              $('#creationFileUploadGdnDialog').dialog('close')
              // $('.dialog').dialog('destroy');
            })
          }
        }
      )
    } else if (imageUrl !== null && afId !== null) {
      // 현재 등록된 파일 개수 체크
      currentCreationFileCnt((fileCnt: any) => {
        if (fileCnt >= 15) {
          alertify.error('소재 이미지는 최대 15개까지 업로드 가능합니다.')
          return
        }
        gdnCreationDataFilePreviewInsert.mutate()
        uploadInit()
        $('#creationFileUploadGdnDialog').dialog('close')
        // $('.dialog').dialog('destroy');
      })
    } else {
      alertify.error('선택된 이미지 파일이 없습니다.')
    }
  }
  // 부모컴포넌트에서 자식 컴포넌트의 함수를 실행하기 위한 forwardRef와 useImperativeHandle을 사용
  useImperativeHandle(ref, () => ({
    uploadInit,
    fileCheckUpload,
    previewFileSelect,
  }))

  useEffect(() => {
    // 이미지 보여주기 초기화
    if (imageUrl !== undefined && imageUrl !== null) {
      $('#emptyImage').hide()
      $('#showImage').show()
    } else {
      $('#emptyImage').show()
      $('#showImage').hide()
    }
  }, [imageUrl])
  useEffect(() => {
    uploadInit()
    if (gdnFileUploadId !== null) {
      uploadId = gdnFileUploadId
      $('#gdnCreationFileUpload').on('change', onChangeFiles)
    }
  }, [gdnFileUploadId])
  return (
    <Fragment>
      <div className="form-group" id="emptyImage">
        <div className="comp-thumb expand">
          <div className="comp-no-data">
            <div className="box-group">
              <i className="ico ico-no-img"></i>
              <p className="fz-18 fc-2">
                이미지를 선택해주세요.<Link to="#!" className="ico-tooltip tooltip-f" title=""></Link>
              </p>
              <p className="fz-14 fc-7">정사각형 이미지와 가로 이미지가 각각 1장 이상씩 등록되어야 합니다.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group" id="showImage" style={{ display: 'none' }}>
        <div className="comp-thumb expand">
          <img src={imageUrl} alt="" />
        </div>
      </div>
      <div className="form-group">
        <div className="comp-file-upload expand">
          <input type="file" id="gdnCreationFileUpload" accept="image/jpeg, image/jpg, image/png, image/gif" />
          <i className="ico ico-file"></i>
          <input type="text" className="tf-comm" placeholder="파일을 선택하세요." readOnly={true} />
          <label className="btn" htmlFor="gdnCreationFileUpload">
            파일 선택
          </label>
        </div>
      </div>
    </Fragment>
  )
}

export default forwardRef(FileUploadGgCreation);
