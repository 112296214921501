import { useQuery, useQueryClient } from '@tanstack/react-query'

export const useUsableCostQuery = () => {
  const queryKey = ['@biz/query/frame/UsableCost']
  const queryClient = useQueryClient()
  return {
    ...useQuery({
      queryKey,
      queryFn: () => 0,
    }),
    update: (num: string) => queryClient.setQueryData(queryKey, num),
  }
}
