// 화면 레이아웃 자동 맞춤
const MasonryLayout = () => {
  const masonry = document.querySelector('.container-masonry');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const containerMasonryStyle = getComputedStyle(masonry);
  const columnGap = parseInt(
    containerMasonryStyle.getPropertyValue('column-gap'),
    10,
  );
  const autoRows = parseInt(
    containerMasonryStyle.getPropertyValue('grid-auto-rows'),
    10,
  );
  document.querySelectorAll('.masonry-item').forEach((elt:any) => {
    // eslint-disable-next-line no-param-reassign
    elt.style.gridRowEnd = `span ${Math.ceil(
      elt.querySelector('.wrap-preview').scrollHeight / autoRows
            + columnGap / autoRows,
    )}`;
  });
};

export default MasonryLayout;
