import React, {
  createContext,
  Fragment, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { Link } from 'react-router-dom';
import { useUsableCostQuery } from '@biz/query'
import {
  AdminContext,
  CampIdContext,
  CampTabClickContext, CampViewStatusContext,
  GdnCreationDataIdContext,
  GdnCreationFileUploadIdContext,
} from './Make02Empty';
import Make02Step03CreationGdn, { gdnCreationFormData } from './Make02Step03CreationGdn';
import MasonryLayout from './MasonryLayout';
import CreationFileUploadGdn from './CreationFileUploadGdn';
import CreationFileUploadGdnGuide from './CreationFileUploadGdnGuide';
import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'
import { IFileTypes } from '../../fileUploader/MultiFileUploaderGgLogo';
import alertify from 'alertifyjs'
import Make02InspReqValidation from './Make02InspReqValidation'

interface CampData {
  ok: boolean
  campId: number
  campNm: string
  campGoalDiv: string
  campStartTime: string
  campEndTime: string
  campBudget: number
  adminCampYn: boolean
  mediaDiv4: string
  projectId: string
}
interface CampDatas {
  ok: boolean
  data: CampData
}
interface gdnCreationData {
  ok: boolean
  id: number
  adTitle01: string
  adTitle02: string
  adDesc: string
  sortOrder: number
  clickBtnDiv: string
  creationViewStatus: string
  adSetDatas: any // 기타 상태일 경우 소재별 상태에 대한 데이터(최대 2개)
  preview: boolean
  afList: IFileTypes[]
  campBudget: number
}
interface gdnCreationLink {
  ok: boolean
  id: number
  adSetOrder: number
  creationViewStatus: string
  creationData: gdnCreationData
}
interface gdnCreationLinks {
  ok: boolean
  data: gdnCreationLink[]
}

interface gdnAdSetStatusData {
  ok: boolean
  id: number // creation_link_id
  creationDataId: string
  creationViewStatus: string
  adSetOrder: number
  useYn: boolean
}
interface gdnAdSetStatusDatas {
  ok: boolean
  data: gdnAdSetStatusData[]
}
interface calculateBalance {
  ok: boolean
  data: any
  campId: number
  changeCampBudget: number
  cost: number
}
interface CampBizmoneyInfo {
  ok: boolean
  data: any
  currentCost: number
}
export const reloadCountContext = createContext({
  reloadCount: 0,
  setReloadCount: (value: any) => value,
})
let globalTimeout: string | number | NodeJS.Timeout | null | undefined = null
const Make02Step03Gdn = () => {
  const campInspGdnStep03Ref = useRef<any>(null)
  const { campIdParam } = useContext(CampIdContext)
  const { setTabClick } = useContext(CampTabClickContext)
  const { setGdnFileUploadId } = useContext(GdnCreationFileUploadIdContext)
  const [campGdnCreationForm, setCampGdnCreationForm] = useState<any>([])
  const { getValues, setValue } = useForm<gdnCreationFormData>()
  const [makerFiles, setMakerFiles] = useState<any>([])
  const [reloadCount, setReloadCount] = useState<any>(0)
  const { gdnCreationDataId } = useContext(GdnCreationDataIdContext)
  const { campViewStatusParam } = useContext(CampViewStatusContext)
  const { adminParam } = useContext(AdminContext)
  const calculateBalanceForm = useForm<calculateBalance>()
  const { update: setUsableCostChange } = useUsableCostQuery()
  // 소재 추가 버튼 과클릭 방지 true 일때 추가 액션이 동작한다.
  const [isAddCreationBtn, setIsAddCreationBtn] = useState<any>(true)
  // 비즈머니
  const getBizmoney = async () => {
    const { data } = await ApiUtil.get<CampBizmoneyInfo>('/api/camp/make02/getBizmoney')
    return data
  }
  const bizmoneyApi = useQuery(['getBizmoney'], getBizmoney, { enabled: false })

  const calculateBalanceApi = async () => {
    const { data } = await ApiUtil2.get<calculateBalance>('/api/camp/make02/calculateBalance', {
      params: { campId: campIdParam, changeCampBudget: calculateBalanceForm.getValues('changeCampBudget') },
    })
    return data
  }
  const calculateBalanceEvent = useQuery(['calculateBalanceApi'], calculateBalanceApi, {
    enabled: false,
  })
  const getGdnCreationDatas = async () => {
    const { data } = await ApiUtil2.get<gdnCreationLinks>('/api/camp/make02/gdnCreationDataSearch', {
      params: { campId: campIdParam },
    })
    return data
  }

  const getGdnCreationDatasApi = useQuery(['getGdnCreationDatas'], getGdnCreationDatas, {
    enabled: false,
  })
  // 기타 상태일 경우 소재 상태에 대한 데이터 gdnCreationLinkSearch
  const gdnCreationLinkSearchApi = async () => {
    const { data } = await ApiUtil2.get<gdnAdSetStatusDatas>('/api/camp/make02/gdnCreationLinkSearch', {
      params: { campId: campIdParam },
    })
    return data
  }

  const gdnCreationLinkSearchEvent = useQuery(['gdnCreationLinkSearch'], gdnCreationLinkSearchApi, {
    enabled: false,
  })
  // 등록 처리
  const gdnCreationDataInsertApi = () => {
    const { campId, sortOrder, clickBtnDiv, isCampBudgetChange, changeCampBudget } = getValues()
    const result = ApiUtil.post<gdnCreationFormData>('/api/camp/make02/gdnCreationDataInsert', {
      campId,
      sortOrder,
      clickBtnDiv,
      isCampBudgetChange,
      changeCampBudget,
    })
    return result
  }
  const gdnCreationDataInsert = useMutation(gdnCreationDataInsertApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        // 상단 비즈머니 업데이트
        if (getValues('isCampBudgetChange')) {
          bizmoneyApi.refetch().then((r) => {
            if (r.isSuccess) {
              setUsableCostChange(r.data.data.currentCost.toLocaleString())
            }
          })
        }
        setValue('isCampBudgetChange', false)
        // eslint-disable-next-line no-use-before-define
        setReComponent()
      }
    },
  })

  // 캠페인 조회
  const getCampDatas = async () => {
    const { data } = await ApiUtil2.get<CampDatas>('/api/camp/make02/getCampInfo', { params: { campId: campIdParam } })
    return data
  }
  const getCampDatasApi = useQuery(['getCampDatas'], getCampDatas, {
    enabled: false,
  })

  const tabClickEvent = (value: any) => {
    setTabClick(value)
  }
  // 소재 등록 관련 컴포넌트
  const AddGdnCreation = useCallback(() => <Fragment>{campGdnCreationForm}</Fragment>, [campGdnCreationForm])
  // 소재 추가시 이벤트
  const gdnCreationDataInsertEvent = (isReLoading: boolean) => {
    // 추가시 디폴트값
    setValue('sortOrder', campGdnCreationForm.length + 1)
    setValue('clickBtnDiv', 'MORE_DETAIL')
    gdnCreationDataInsert.mutate()
    if (isReLoading) {
      // 추가 제거시에만 리로딩???
      // eslint-disable-next-line no-use-before-define
      // setReComponent();
    }
  }
  // 소재 삭제 이벤트
  const creationComponentRefresh = () => {
    // eslint-disable-next-line no-use-before-define
    setReComponent()
  }
  // 소재 복사 이벤트
  const copyCreationGdnEvent = () => {
    // eslint-disable-next-line no-use-before-define
    setReComponent()
  }
  // 빈 소재 추가 이벤트
  const addCreationGdnEvent = () => {
    if (
      (campViewStatusParam !== null &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        adminParam.isAdmin &&
        adminParam.forAdmin) ||
      (campViewStatusParam !== null &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        !adminParam.isAdmin &&
        !adminParam.forAdmin) ||
      (campViewStatusParam !== 'complete' && !adminParam.isAdmin && !adminParam.forAdmin) ||
      (campViewStatusParam !== 'complete' && adminParam.isAdmin && !adminParam.forAdmin) ||
      (adminParam.isAdmin && adminParam.forAdmin)
    ) {
      if (campGdnCreationForm.length >= 10) {
        $('#gdnAddCreationBtn').attr('disabled', 'disabled')
      } else if (getValues('campBudget') !== undefined) {
        // 기타상태일 경우 가져온 캠페인 예산을 가지고 소재 추가시 예산 체크한다.(비즈머니 체크는?)
        const chaBudget = (campGdnCreationForm.length + 1) * 5000
        // 현재 설정된 예산체크 추가했을때 예산이 초과되면??
        if (getValues('campBudget') < chaBudget) {
          const changeCampBudgetTxt = (getValues('campBudget') + 5000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          const changeCampBudget = getValues('campBudget') + 5000
          calculateBalanceForm.setValue('changeCampBudget', changeCampBudget)
          calculateBalanceEvent.refetch().then((r) => {
            if (r.isSuccess) {
              alertify
                .confirm(
                  `소재당 1일 최소 5천원의 예산이 필요합니다. 1일 캠페인 예산을${changeCampBudgetTxt}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 비즈머니에서 ${r.data.data
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 (VAT포함)이 차감됩니다.`,
                  () => {
                    setValue('changeCampBudget', changeCampBudget)
                    setValue('isCampBudgetChange', true)
                    gdnCreationDataInsertEvent(true)
                  }
                )
                .set({ labels: { cancel: '취소', ok: '확인' } })
                .setHeader('')
            }
          })
        } else {
          gdnCreationDataInsertEvent(true)
        }
      } else {
        gdnCreationDataInsertEvent(true)
      }
    }
  }
  // 컴포넌트 재생성
  const setReComponent = () => {
    // eslint-disable-next-line no-use-before-define
    gdnCreationDataSearchEvent()
  }
  // 메이커가 등록한 모든 소재에 대한 정보 세팅(Make02Step03CreationGdn.tsx 에서)
  const setMakerFilesEvent = (files: any) => {
    setMakerFiles(files)
  }

  // step03 에서 검수 체크
  const step3InspReqValidEvent = () => {
    campInspGdnStep03Ref.current!.inspValidEvent()
  }

  const dataLoad2 = (callback: any) => {
    const creationLinkDatas: any = []
    getCampDatasApi.refetch().then((rr) => {
      if (rr.isSuccess) {
        setValue('campBudget', rr.data.data.campBudget)
        gdnCreationLinkSearchEvent.refetch().then((rr2) => {
          if (rr2.isSuccess) {
            rr2.data.data.forEach((data) => {
              creationLinkDatas.push(data)
            })
            callback(creationLinkDatas)
          }
        })
      }
    })
  }

  const allDataLoad = (creationLinkDatas: any) => {
    getGdnCreationDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        // 초기화
        const defaultDatas: gdnCreationData[] = []
        // setCampGdnCreationForm([]);
        r.data.data.map((data, index) => {
          const tempData = data.creationData
          tempData.creationViewStatus = data.creationViewStatus
          // 수정 선택된 소재ID에 대해 미리보기 false
          if (gdnCreationDataId !== null) {
            if (`${tempData.id}_` === `${gdnCreationDataId}_`) {
              tempData.preview = false
            } else {
              tempData.preview = true
            }
          } else if (r.data.data.length === index + 1) {
            tempData.preview = false
          } else {
            tempData.preview = true
          }
          if (
            (campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete') ||
            (campViewStatusParam !== null &&
              campViewStatusParam !== 'temp' &&
              campViewStatusParam !== 'complete' &&
              !adminParam.isAdmin &&
              !adminParam.forAdmin)
          ) {
            const filterAdsetDatas = creationLinkDatas
              .filter((d: any) => `${d.creationDataId}` === `${tempData.id}`)
              .map((dd: any) => dd)
            tempData.adSetDatas = filterAdsetDatas // 광고세트별 소재 정보
            tempData.campBudget = getValues('campBudget') // 캠페인 예산 정보
          }
          // 종료일 경우 모두 미리보기 모드로만...
          if (campViewStatusParam === 'complete' || (!adminParam.isAdmin && adminParam.forAdmin)) {
            tempData.preview = true
          }
          defaultDatas.push(tempData)
          return tempData
        })

        if (defaultDatas.length >= 10) {
          const components = defaultDatas.map((data: any) => (
            <Make02Step03CreationGdn
              step3InspReqValidEvent={step3InspReqValidEvent}
              setMakerFilesEvent={setMakerFilesEvent}
              maxSize={defaultDatas.length}
              creationComponentRefresh={creationComponentRefresh}
              copyCreationGdnEvent={copyCreationGdnEvent}
              creationData={data}
              key={data.sortOrder}
              order={data.sortOrder}
            />
          ))
          setCampGdnCreationForm(components)
          $('#gdnAddCreationBtn').attr('disabled', 'disabled')
        } else {
          const components = defaultDatas.map((data: any) => (
            <Make02Step03CreationGdn
              step3InspReqValidEvent={step3InspReqValidEvent}
              setMakerFilesEvent={setMakerFilesEvent}
              maxSize={defaultDatas.length}
              creationComponentRefresh={creationComponentRefresh}
              copyCreationGdnEvent={copyCreationGdnEvent}
              creationData={data}
              key={data.sortOrder}
              order={data.sortOrder}
            />
          ))
          setCampGdnCreationForm(components)
          $('#gdnAddCreationBtn').removeAttr('disabled')
        }
        setReloadCount(0)
        // 소재 추가 + 버튼 과다클릭 방지를 위한...
        setIsAddCreationBtn(true)
      }
    })
  }
  const searchGdnCreation = () => {
    globalTimeout = null
    // 소재 상태에 대한 데이터 가져오기
    let creationLinkDatas: any = []
    if (
      (campViewStatusParam !== null &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        !adminParam.isAdmin &&
        !adminParam.forAdmin) ||
      (campViewStatusParam !== null &&
        campViewStatusParam !== 'temp' &&
        campViewStatusParam !== 'complete' &&
        adminParam.isAdmin &&
        !adminParam.forAdmin)
    ) {
      dataLoad2((callbackData: any) => {
        creationLinkDatas = callbackData
        allDataLoad(creationLinkDatas)
      })
    } else {
      allDataLoad(creationLinkDatas)
    }
  }
  // 모든 소재 데이터 가져오기 이벤트
  const gdnCreationDataSearchEvent = () => {
    if (globalTimeout != null) clearTimeout(globalTimeout)
    globalTimeout = setTimeout(() => searchGdnCreation(), 200)
  }

  // 파일업로드 후 등록된 파일을 보여주기 위한 부분
  useEffect(() => {
    if (reloadCount !== 0) {
      setReComponent()
    }
  }, [reloadCount])
  useEffect(() => {
    if (campIdParam !== null) {
      setValue('campId', campIdParam)
    }
    setReComponent()
    MasonryLayout()
    window.addEventListener('resize', MasonryLayout)

    if (campViewStatusParam === 'complete') {
      $('#creationAddBtn').hide()
    } else {
      ApiUtil.get('/api/common/getUploadId').then((response) => {
        setGdnFileUploadId(response.data.data)
      })
    }
    return () => {
      // 여기서 다이얼로그 관련된것 destory(필수)
      const dialogCheck = $('#creationFileUploadGdnDialog')
      const dialogCheck2 = $('#creationFileUploadGuideDialog')
      if (dialogCheck.length > 0) {
        $('#creationFileUploadGdnDialog').dialog('destroy')
      }
      if (dialogCheck2.length > 0) {
        $('#creationFileUploadGuideDialog').dialog('destroy')
      }
      $('.tooltip').remove()
    }
  }, [])

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-masonry">
                    <div className="masonry-item" id="creationAddBtn" style={{ gridRowEnd: 'span 64' }}>
                      <div className="wrap-preview">
                        <div className="box-body">
                          <div className="box-group flex-container-center h-600">
                            {isAddCreationBtn ? (
                              <button
                                type="button"
                                className="btn btn-add"
                                id="gdnAddCreationBtn"
                                onClick={addCreationGdnEvent}
                              ></button>
                            ) : (
                              <button type="button" className="btn btn-add"></button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {campGdnCreationForm.length > 0 ? <AddGdnCreation /> : null}
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        {/* {파일업로드 다이얼로그} */}
        <div id="creationFileUploadGdnDialog" className="dialog" style={{ display: 'none' }}>
          <reloadCountContext.Provider value={{ reloadCount, setReloadCount }}>
            <CreationFileUploadGdn makerFiles={makerFiles} />
          </reloadCountContext.Provider>
        </div>
        {/* {소재제작 가이드 다이얼로그} */}
        <div id="creationFileUploadGuideDialog" className="dialog" style={{ display: 'none' }}>
          <CreationFileUploadGdnGuide />
        </div>
        <div className="box-footer">
          <div className="box-left">
            <Link to="/mngCamp/mngCamp" className="btn btn-primary-outline">
              캠페인 목록
            </Link>
            {(campViewStatusParam === 'temp' || campViewStatusParam === undefined) && (
              <button type="button" className="btn btn-primary" onClick={step3InspReqValidEvent}>
                검수요청
              </button>
            )}
          </div>
          <div className="box-right">
            <button type="button" className="btn btn-secondary-outline" onClick={() => tabClickEvent('step02')}>
              이전
            </button>
          </div>
        </div>
        <Make02InspReqValidation ref={campInspGdnStep03Ref} />
      </section>
    </Fragment>
  )
}
export default Make02Step03Gdn;
