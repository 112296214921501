import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import LeftMenu from './LeftMenu/LeftMenu';

export const LnbLayout = () => {
  const url = useLocation();
  const [title, setTitle] = useState('');

  const HeaderTitle = () => {
    if (title === '') return <Fragment></Fragment>;
    return (
      <Fragment>
        <div className='content-header'>
          <div className='box-tit'>
            <h2 className='fz-36 fc-1 fw-bold'>{title}</h2>
          </div>
        </div>
      </Fragment>
    );
  };

  useEffect(() => {
    if (
      url.pathname === '/' ||
      url.pathname === '/main' ||
      url.pathname === '/admin/main'
    ) {
      $('.wrap').addClass('home');
    } else {
      $('.wrap').removeClass('home');
    }
  }, [url.pathname]);
  return (
    <Fragment>
      <div className='wrap'>
        <Header />
        <div className='content'>
          <LeftMenu />
          <div className='inner-content'>
            <div className='content-body'>
              {/* 추가 메뉴는 MenuRouters에... */}
              {/* <MenuRouters/> */}
              <Outlet context={setTitle} />
            </div>
            <footer className='content-footer'>
              <Footer />
            </footer>
          </div>
        </div>
        <div className='loader none'>
          <div className='stage'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
          </div>
          <p className='txt-loader'>LOADING</p>
        </div>
        <div className='loader file none'>
          <div className='stage'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
          </div>
          <p className='txt-loader'>파일 정보를 변환 중 입니다.</p>
        </div>
        {/* 페이스북 동영상 대량 파일 소재 업로드시 로딩바 */}
        <div className='loader file2 none'>
          <div className='stage'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
          </div>
          <p className='txt-loader'>파일을 업로드 중입니다.</p>
        </div>
        {/* 검수 요청시 로딩 화면 내 텍스트 추가 */}
        <div className='loader inspReq none'>
          <div className='stage'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
          </div>
          <p className='txt-loader'>
            요청을 처리하고 있습니다. 잠시만 기다려주시기 바랍니다.
          </p>
        </div>
      </div>
    </Fragment>
  );
};
