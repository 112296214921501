import React, {
    Fragment, useRef,
} from 'react';
import { Dialog } from 'rc-easyui';
const  CampBudgetReduceDialog = (props:any) => {
    const dialogRef = useRef<any>();

    const cancelEvent = () => {
        props.searchCamp();
        props.setCampBudgetReduceDialog(false);
    }

    const updateEvent = () => {
        props.setValue('campBudgetChange' ,true);
        props.campUpdate.mutate();
        props.setCampBudgetReduceDialog(false);
    }
    return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
                      <div className="dialog">
                            <div className="dialog-body">
                                <div className="wrap-section wrap-tbl">
                                    <div className="box-body">
                                        <div className="tbl">
                                            <dl className="vertical">
                                                <dd>
                                                    <div className="form-group">
                                                        <p className="comp-txt center">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-20 fc-5 txt-center">[ {props.campNm} ]</b>
                                                                    <b className="fz-20 fc-1 fw-medium">캠페인 예산을 수정하시겠습니까?</b>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </dd>
                                            </dl>
                                            <dl className="vertical">
                                                <dd>
                                                    <div className="form-group">
                                                        <p className="comp-txt">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-14 fc-2">메이커님의 현재 캠페인의 광고 소진 비용을 확인하셨나요?</b>
                                                                    <b className="fz-14 fc-2">캠페인 예산 감액 시 아래와 같은 경우 광고 <span className="fc-7">노출 매체로부터 감액 요청이 거절</span>될 수 있습니다.</b>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="comp-help white">
                                                            <ul className="help-list">
                                                                <li className="list">
                                                                    <i className="ico ico-help"></i>
                                                                    <span className="fz-14 fc-2">변경하시고자 하는 캠페인의 예산이 현재 소진 금액보다 적거나 비슷한 경우</span>
                                                                </li>
                                                                <li className="list">
                                                                    <span className="fz-14 fc-4 bullet">매체와의 연동 딜레이로 인해 비즈센터의 금액보다 더 많이 쓰여졌을 수 있습니다.</span>
                                                                </li>
                                                                <li className="list">
                                                                    <i className="ico ico-help"></i>
                                                                    <span className="fz-14 fc-2">캠페인 총 광고기간 * 소재개수를 계산하여, 최소 소재당 일일예산이 충족되지 않는 경우 </span>
                                                                </li>
                                                                <li className="list">
                                                                    <span className="fz-14 fc-4 bullet">일별 소재 1개당 최소 예산인 5천 원 이상</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="comp-txt">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-14 fc-2">캠페인 예산은 <span className="fc-7">현재 소진된 금액보다 30% 이상 금액 혹은 소진된 금액이 없을 시 캠페인 최소 예산 이상 금액</span>으로만 감액이 가능합니다.</b>
                                                                    <b className="fz-14 fc-2">(현재 소진된 금액 : <span className="fc-5">{props.rptTotalCost.toLocaleString()}</span> 원 / 감액 가능한 예산 : <span className="fc-5">{props.reducedBudget.toLocaleString()}</span> 원)</b>
                                                                    <b className="fz-14 fc-2">감액 실패 시 알림으로 안내드리며, 메이커님의 원치 않으시는 예산 소진을 최소화하기 위해</b>
                                                                    <b className="fz-14 fc-7">캠페인이 자동 OFF 처리되며, 이전 설정하신 예산으로 자동 변경됩니다.</b>
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </dd>
                                            </dl>
                                            <dl className="vertical total">
                                                <dd>
                                                    <div className="form-group">
                                                            <p className="fz-18 fc-1 lh20">
                                                                현재 예산 <span className="fc-5">{props.beforeCampBudgetTotal.toLocaleString()}</span> 원에서
                                                            </p>
                                                            <p className="fz-18 fc-1 lh20">
                                                                예산 <span className="fc-5">{props.afterCampBudgetTotal.toLocaleString()}</span> 원으로 변경하시겠습니까?
                                                            </p>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dialog-footer">
                                <button type="button" className="btn btn-secondary-outline large" onClick={cancelEvent}>취소</button>
                                <button type="button" className="btn btn-primary large" onClick={updateEvent}>수정</button>
                            </div>
                      </div>
                </Dialog>
            </form>
        </Fragment>
    )
}
export default CampBudgetReduceDialog;