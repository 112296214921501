import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useQuery, useQueryClient } from '@tanstack/react-query' // try next react-query;
import { ApiUtil } from '@biz/api'
import {
  useLoginStatusQuery,
  useAlertUnreadCountQuery,
  useUsableCostQuery,
  useRoleQuery,
  useShadowLoginQuery,
} from '@biz/query'
import { loginForm } from '../../AdminLoginPage/Login'
import alertify from 'alertifyjs'
import ShadowLoginList from './ShadowLoginList'

export interface ShadowList {
  id: number
  memberName: string
}

// 쉐도우 로그인 권한 가진 관리자
let isAdminShadow = false
// 쉐도우 로그인 권한없는 관리자
let isAdmin = false
let isMgr = false

// 강제로 로그인 화면으로 이동시 로그아웃 처리
export const initHeader = () => {
  ApiUtil.get<loginForm>('/api/common/logout').then((response) => {
    if (response.data) {
      isAdminShadow = false
      isAdmin = false
      isMgr = false
      $('#shadowDiv').hide()
      $('#loginButton').show()
      $('#logoutButton').hide()
      $('#menuList').find('li').not('.one-depth').attr('class', '')
      $('#menuList').find('li').not('.two-depth').removeClass('active')
      // 로그아웃시 localStorage 정보 삭제
      localStorage.removeItem('user')
      localStorage.removeItem('shadow')
      localStorage.removeItem('shadowRole')
    }
  })
}
const getShadowLoginList = async () => {
  const { data } = await ApiUtil.get<ShadowList[]>('/api/shadowLogin/shadowLoginList')
  return data
}
const Header = () => {
  // const { data } = useLoginCheckQuery()
  const { data, refetch: refetchAlertUnreadCount } = useAlertUnreadCountQuery()
  const unreadBellAlertCnt = data || 0
  const { data: usableCostChange } = useUsableCostQuery()
  const { data: loginStatus, update: setLoginStatus } = useLoginStatusQuery()
  const { data: auth, update: setAuth } = useRoleQuery()
  const { update: setShadowLogin } = useShadowLoginQuery()

  const [isShadowLogout, setIsShadowLogout] = useState(false)
  const [shadowMakerList, setShadowMakerList] = useState<any>()
  const url = useLocation()
  const navigate = useNavigate()

  // 권한 체크를 위해 로그인 여부를 확인
  useEffect(() => {
    // 로그인체크를 하지 않은 url
    if (url.pathname === '/common/login') {
      // 여기서 우측 헤더 부분 숨김 처리
      $('#userInfoDiv').hide()
      // localStorage.setItem('adminLoginPath', url.pathname);
      return
    }
    $('#userInfoDiv').show()
  }, [url.pathname])

  const queryClient = useQueryClient()
  const logoutSuccess = () => {
    isAdminShadow = false
    isAdmin = false
    isMgr = false
    $('#shadowDiv').hide()
    $('#loginButton').show()
    $('#logoutButton').hide()
    $('#menuList').find('li').not('.one-depth').attr('class', '')
    $('#menuList').find('li').not('.two-depth').removeClass('active')
    // 로그 아웃시 메뉴 관련 쿼리를 invalidateQueries를 사용하여 재호출한다.
    queryClient.invalidateQueries(['menus'])
    queryClient.invalidateQueries(['subMenus'])
  }
  const initLogin = () => {
    $('#loginButton').show()
    $('#logoutButton').hide()
  }

  // 화면 로딩이 빠를경우 쉐도우 로그인 목록을 가져오기 전 리스트를 만들어버려서 refetch 로 데이터 호출
  const resShadowLoginList = useQuery(['shadowLoginList'], getShadowLoginList, { enabled: false })
  const [memberNm, setMemberNm] = useState('')
  // 로그인 유저 정보를 localStorage에서 확인
  const info = localStorage.getItem('user')
  const shadowInfo = localStorage.getItem('shadow')

  useEffect(() => {
    initLogin()
    if (info !== null) {
      const userInfo = JSON.parse(info)
      if (userInfo.ok) {
        setLoginStatus(true)
        if (userInfo.data.authorities.indexOf('ROLE_SHADOW') >= 0) {
          isAdminShadow = true
          $('#shadowDiv').show()
          // 쉐도우 로그인 목록 데이터를 가져온다.
          resShadowLoginList.refetch().then((r) => {
            if (r.isSuccess) {
              setShadowMakerList(r.data)
            }
          })
        } else if (userInfo.data.authorities.indexOf('ROLE_ADMIN_ACTION') >= 0) {
          isAdmin = true
          $('#shadowDiv').hide()
        }
        if (userInfo.data.roleGroup === 'USER_MGR') {
          isMgr = true
        }
        setMemberNm(userInfo.data.memberName)
      }
    } else {
      setLoginStatus(false)
      logoutSuccess()
    }
  }, [isAdminShadow])

  const logout = () => {
    ApiUtil.get<loginForm>('/api/common/logout').then((response) => {
      if (response.data) {
        alertify.alert('로그아웃', '로그아웃성공', () => {
          setLoginStatus(false)
          const user = localStorage.getItem('user')
          const userInfo = user === null ? null : JSON.parse(user)
          if (userInfo !== null && userInfo.data !== undefined) {
            // 로그아웃시 localStorage 정보 삭제
            localStorage.removeItem('user')
            localStorage.removeItem('shadow')
            localStorage.removeItem('shadowRole')
            navigate('/common/login')
          }
          logoutSuccess()
          // 종알임을 위한 로그인 플래그 false 전환
        })
      }
    })
  }

  const logoutFormaker = () => {
    location.href = '/api/common/logout'
  }

  const [makerNm, setMakerNm] = useState(shadowInfo !== null ? JSON.parse(shadowInfo).memberName : '메이커 로그인')
  const [shadowSearchText, setShadowSearch] = useState('')
  const shadowLogoutEvent = () => {
    ApiUtil.get<ShadowList>('/api/shadowLogin/shadowLogout').then((response) => {
      if (response.data) {
        alertify.alert('쉐도우 로그아웃', '쉐도우 로그아웃 성공')
        localStorage.removeItem('shadow')
        localStorage.removeItem('shadowRole')
        setMakerNm('메이커 로그인')
        setIsShadowLogout(true)
        // const shadowLoginList = document.querySelectorAll('[id^=shadowLoginList]');
        // shadowLoginList.forEach((shadowLoginListInfo:any) => {
        //   if (shadowLoginListInfo.classList.contains('selected')) {
        //     shadowLoginListInfo.classList.remove('selected');
        //   }
        // });
        setShadowSearch('')
        $('#shadowLogoutBtn').hide()
        $('#searchText').val('')
        // 쉐도우 로그아웃 성공시 메뉴리스트 재갱신
        queryClient.invalidateQueries(['menus'])
        queryClient.invalidateQueries(['subMenus'])
        setShadowLogin(false)
        setAuth('ROLE_ADMIN')
        navigate('/')
      }
    })
  }
  useEffect(() => {
    if (info != null) {
      if (JSON.parse(info).ok) {
        if (url.pathname !== '/common/login') {
          /** 쉐도로그인 하면 쉐도 계정 알림 개수로 */
          refetchAlertUnreadCount()
          // loginCheckEvent()
        }
      }
    }
  }, [makerNm])
  /** 비즈머니 잔액 표기 및 자동 충전 여부 */
  const [usableCost, setUsableCost] = useState<any>('')
  const [atptActYn, setAtptActYn] = useState(false)
  const usableCostFunction = () => {
    ApiUtil.get('/api/bzm/status/usableCost').then((resp) => {
      const bzm: any = resp.data.data
      setUsableCost(Number(bzm).toLocaleString())
    })
  }
  const atptActYnFunction = () => {
    ApiUtil.get('/api/bzm/common/findAutoChargeInfo').then((resp) => {
      setAtptActYn(resp.data.data.atptActYn)
    })
  }

  /** 일반 메이커가 로그인 했을 경우 (쉐도우 로그인 X) */
  useEffect(() => {
    if (!isAdminShadow && !isMgr && loginStatus) {
      // 로그인했을때만 호출
      // 로그인 url이 아닐때만 실행
      if (url.pathname !== '/common/login') {
        usableCostFunction()
        atptActYnFunction()
      }
    }
  }, [])

  useEffect(() => {
    setUsableCost(usableCostChange)
  }, [usableCostChange])

  /** 어드민인데 쉐도우 로그인 한 경우 쉐도우 로그인 성공시 호출 */
  const makerHeaderInfoEvent = () => {
    if (url.pathname !== '/common/login') {
      refetchAlertUnreadCount()
      usableCostFunction()
      atptActYnFunction()
      if (shadowInfo !== null) {
        const shadowInfo2 = JSON.parse(shadowInfo)
        setMakerNm(shadowInfo2.memberName)
      }
    }
  }
  useEffect(() => {
    if (loginStatus) {
      if (info !== null) {
        const userInfo2 = JSON.parse(info)
        if (userInfo2.ok) {
          // 메이커가 아닌 관리자로 로그인했을 경우에만
          if (userInfo2.data.authorities.indexOf('ROLE_USER_MAKER') < 0) {
            // 화면 리프레시 일경우 처리
            if (shadowInfo !== null) {
              // 로그인 url이 아닐때만 실행
              if (url.pathname !== '/common/login') {
                makerHeaderInfoEvent()
                if (shadowInfo !== null) {
                  const shadowInfo2 = JSON.parse(shadowInfo)
                  setMakerNm(shadowInfo2.memberName)
                  // 로그인되어 있는 메이커가 현재 화면의 메이커와 다를경우 홈으로 이동
                  if (makerNm !== shadowInfo2.memberName) {
                    navigate('/')
                  }
                }
                // 다른브라우저에서 관리자로 로그인되어 있고(쉐도우 로그아웃 상태) 현재 브라우저가 메이커로 쉐도우 로그인되어 있을 경우
                // 메인 경로가 아닌경우 홈으로 이동
                if (url.pathname !== '/main' && auth === 'ROLE_ADMIN') {
                  navigate('/')
                }
                setShadowLogin(true)
                setAuth('ROLE_USER_MAKER')
              }
            } else {
              setMakerNm('메이커 로그인')
              refetchAlertUnreadCount()
              // 다른 브라우저에서 메이커로 쉐도우 로그인 되어 있고 현재 브라우저가 관리자로 쉐도우 로그아웃 되어 있을 경우
              // 메인 경로가 아닌경우 홈으로 이동
              if (url.pathname !== '/main' && auth === 'ROLE_USER_MAKER') {
                navigate('/')
              }
              setShadowLogin(false)
              setAuth('ROLE_ADMIN')
            }
            queryClient.invalidateQueries(['menus'])
            queryClient.invalidateQueries(['subMenus'])
          }
        }
      }
    }
  }, [shadowInfo])

  return (
    <header className="header">
      <h1 className="logo">{url.pathname === '/common/login' ? <Link to="#"></Link> : <Link to="/main"></Link>}</h1>
      <div className="user-info" id="userInfoDiv">
        <div className="name">
          <span className="fz-14 fc-1 fw-medium">{memberNm}</span>
        </div>
        {
          isAdminShadow || isAdmin ? (
            <div className="name">
              <Link to="#" onClick={logout}>
                <span className="fz-14 fc-4">관리자 로그아웃</span>
              </Link>
            </div>
          ) : null
          //  <div className="name">
          //     <Link to="/common/login"><span className="fz-14 fc-1 fw-medium">로그인 임시</span></Link>
          // </div>
        }
        {
          loginStatus && !isAdmin && !isAdminShadow ? (
            <div className="name">
              <Link to="#" onClick={logoutFormaker}>
                <span className="fz-14 fc-4">메이커 로그아웃</span>
              </Link>
            </div>
          ) : null
          //  <div className="name">
          //     <Link to="/common/login"><span className="fz-14 fc-1 fw-medium">로그인 임시</span></Link>
          // </div>
        }
        <Link className="notify" to="/makerCommon/bellAlert">
          <i className="ico i-16 ico-notify"></i>
          {unreadBellAlertCnt > 0 ? <span className="badge">{unreadBellAlertCnt}+</span> : null}
        </Link>
        {(isAdminShadow && shadowInfo !== null) || (isMgr && shadowInfo !== null) || (!isAdminShadow && !isMgr) ? (
          <Link className="biz-money" to="/bzm/charge">
            {atptActYn ? (
              <span className="auto-charge on">
                자동충전 <span className="eng">ON</span>
              </span>
            ) : (
              <span className="auto-charge off">
                자동충전 <span className="eng">OFF</span>
              </span>
            )}
            <span className="fz-14 fc-1 fw-medium" id="bzm">
              비즈머니
            </span>
            <span className="fz-14 fc-2" id="usableCost">
              ₩{usableCost}
            </span>
          </Link>
        ) : null}
        {isAdminShadow === true && (
          <div className="shadow comp-dropdown" id="shadowDiv">
            <Link className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" to="#">
              <i className="ico i-24 ico-shadow"></i>
              <span className="fz-14 fc-2 fw-medium"> {makerNm} </span>
            </Link>
            <div className="dropdown-menu">
              <div className="box-body">
                <div className="input-group comp-search">
                  <div className="inner-input-group selected">
                    <input
                      type="text"
                      className="tf-comm"
                      placeholder="힌트 텍스트"
                      id="searchText"
                      onChange={(e) => {
                        setShadowSearch(e.target.value)
                      }}
                    />
                    <i className="ico ico-search" role="presentation"></i>
                  </div>
                  {isAdminShadow ? (
                    <ul className="opt-selectbox" id="makerListScroll">
                      {shadowMakerList !== undefined ? (
                        <ShadowLoginList
                          items={shadowMakerList}
                          isAdmin={isAdminShadow}
                          setMakerNm={setMakerNm}
                          shadowSearchText={shadowSearchText}
                          isShadowLogout={isShadowLogout}
                          setIsShadowLogout={setIsShadowLogout}
                          makerHeaderInfoEvent={makerHeaderInfoEvent}
                        ></ShadowLoginList>
                      ) : null}
                    </ul>
                  ) : null}
                </div>
              </div>
              <div className="box-footer">
                <button
                  type="button"
                  className="btn btn-tertiary-mint expand"
                  onClick={shadowLogoutEvent}
                  id="shadowLogoutBtn"
                  style={{ display: shadowInfo !== null ? '' : 'none' }}
                >
                  메이커 로그아웃
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}
export default Header
