import type { Config } from 'froala-editor'
import { COLOR } from '../../styles'

const config: Partial<Config> = {
  attribution: false,
  // aviaryKey: 'e4048848d16d4959a9e3384bf8369299',
  aviaryOptions: {
    language: 'ko',
    tools: 'orientation,crop,resize',
    theme: 'dark',
  },
  charCounterCount: true,
  colorsHEXInput: false,
  colorsStep: 4,
  colorsText: [
    'REMOVE',
    COLOR.GREY_500,
    COLOR.MINT_300,
    COLOR.BLUE_300,
    COLOR.RGBA_LIGHT_100,
    COLOR.GREY_700,
    COLOR.MINT_600,
    COLOR.BLUE_600,
    'none',
    COLOR.GREY_900,
    COLOR.MINT_800,
    COLOR.BLUE_800,
  ].map((value) => (value.includes('#') ? value.toUpperCase() : value)),
  events: {},
  key: 'SDB17hD9C4D6C5F3C3gKTRe2CG1PGe1DESAe1Kg1EBC1Pe2TKoF4I4B3A9A3B5F4B2A3D3==',
  height: 600,
  heightMin: 400,
  highlighterButtons: ['unhighlight', 'highlightMintColor', 'highlightYellowColor'],
  htmlAllowedTags: [
    'a',
    'b',
    'blockquote',
    'br',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'iframe',
    'img',
    'li',
    'mark',
    'ol',
    'p',
    'span',
    'strong',
    'table',
    'tbody',
    'td',
    'th',
    'thead',
    'tr',
    'u',
    'ul',
  ],
  htmlUntouched: true,
  imageAddNewLine: true,
  imageAllowedTypes: ['jpg', 'jpeg', 'gif', 'png', 'webp'],
  imageCORSProxy: '',
  imageDefaultWidth: '',
  imageEditButtons: [
    'imageReplace',
    'imageAlign',
    'imageCaption',
    '|',
    'imageLink',
    'linkOpen',
    'linkRemove',
    '|',
    'imageDisplay',
    'imageRemove',
  ],
  imageMaxSize: 1024 * 1024 * 3,
  imageOutputSize: false,
  imagePaste: true,
  imagePasteProcess: true,
  imageResizeWithPercent: false, // BUG: true인 경우 캡션 설정 시 style 속성이 없으면 오류 발생
  imageRoundPercent: true,
  imageUploadMethod: 'POST',
  language: 'ko',
  linkAlwaysBlank: true,
  linkAutoPrefix: 'https://',
  linkConvertEmailAddress: false,
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
  linkInsertButtons: ['linkBack'],
  linkList: [],
  listAdvancedTypes: false,
  paragraphFormat: {
    H1: '대제목 1',
    H2: '대제목 2',
    H3: '대제목 3',
    H4: '소제목',
    N: '본문',
  },
  paragraphFormatSelection: true,
  pasteAllowedStyleProps: ['text-align'],
  pasteDeniedAttrs: ['id'],
  pasteDeniedTags: ['table', 'td'],
  pastePlain: false,
  pluginsEnabled: [
    'align',
    'charCounter',
    'colors',
    'draggable',
    'file',
    // 'highlighter',
    // 'image',
    'link',
    'lists',
    'paragraphFormat',
    'quickInsert',
    // 'quote',
    'table',
    'url',
    // 'video',
    'wordPaste',
  ],
  quoteButtons: ['quoteDefault', 'quoteLine', 'unquote'],
  tableColors: [
    'REMOVE',
    COLOR.GREY_500,
    COLOR.MINT_300,
    COLOR.BLUE_300,
    COLOR.RGBA_LIGHT_100,
    COLOR.GREY_700,
    COLOR.MINT_600,
    COLOR.BLUE_600,
    'none',
    COLOR.GREY_900,
    COLOR.MINT_800,
    COLOR.BLUE_800,
  ].map((value) => (value.includes('#') ? value.toUpperCase() : value)),
  tableColorsButtons: ['tableBack'],
  tableColorsStep: 4,
  tableEditButtons: [
    'tableHeader',
    'tableRows',
    'tableColumns',
    'tableCells',
    'tableCellBackground',
    'tableCellHorizontalAlign',
    'tableRemove',
  ],
  tableInsertHelper: true,
  toolbarButtons: [
    [
      'paragraphFormat',
      'bold',
      'underline',
      'strikeThrough',
      'quote',
      'textColor',
      // 'highlighter',
      'align',
      'formatOL',
      'formatUL',
      'insertHR',
      'insertLink',
      // 'insertImage',
      // 'insertVideo',
      'insertTable',
    ],
  ],
  toolbarInline: false,
  toolbarSticky: false,
  tooltips: false,
  videoAllowedProviders: ['vimeo', 'youtube'],
  videoEditButtons: ['videoReplace', 'videoDisplay', 'videoRemove'],
  videoInsertButtons: ['videoBack', 'videoByURL'],
  videoUpload: false,
}

export default config
