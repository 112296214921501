/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';
import FroalaWYSIWYGEditor from 'react-froala-wysiwyg';
import type { MyComponentProps as FroalaWYSIWYGEditorProps } from 'react-froala-wysiwyg';
import classNames from 'classnames';
import { LANGUAGE } from 'froala-editor';
import type { Config, WYSIWYGEditor as WYSIWYGEditorType } from 'froala-editor';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';

import { toast } from 'react-toastify'

import defaultConfig from './settings/config';
import './settings/icons';
import './settings/language';

// import './plugins/highlighter';
// import './plugins/quote';

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'

import './WYSIWYGEditor.scss';
import './WYSIWYGEditorStory.scss';

export interface WYSIWYGEditorProps extends Omit<FroalaWYSIWYGEditorProps, 'config'> {
  config?: Partial<Config>
  value?: string
  isError?: boolean
  isReadOnly?: boolean
  onChange?: (value: string) => void
  'aria-invalid'?: string
  'aria-required'?: string
}

const WYSIWYGEditor = ({
  config: additionalConfig = {},
  value,
  isError,
  isReadOnly,
  onChange,
  ...props
}: WYSIWYGEditorProps) => {
  let temporaryFileName = ''
  const [froalaEditor, setFroalaEditor] = useState<WYSIWYGEditorType>()

  const mergedConfig: Partial<Config> = {
    ...defaultConfig,
    ...additionalConfig,
  }

  const replaceHTTPToHTTPS = async (editor: WYSIWYGEditorType) => {
    const { events, html } = editor
    const parser = new DOMParser()
    const parsedHTML = parser.parseFromString(html.get(), 'text/html')
    const body = parsedHTML.querySelector('body') as HTMLBodyElement
    const images = Array.from(body.querySelectorAll('img')).filter((image) => image.src.startsWith('http://'))

    if (images.length > 0) {
      images.forEach((image) => {
        image.src = image.src.replace(/^http:\/\//i, 'https://')
      })

      html.set(body.innerHTML)
      events.trigger('contentChanged', [], false)
    }
  }

  const setVideoOuterHTML = (editor: WYSIWYGEditorType) => {
    const { events, html, opts: options } = editor
    const parser = new DOMParser()
    const parsedHTML = parser.parseFromString(html.get(), 'text/html')
    const body = parsedHTML.querySelector('body') as HTMLBodyElement
    const iframes = Array.from(body.querySelectorAll('iframe')).filter(
      (iframe) =>
        iframe.parentElement &&
        iframe.parentElement.classList.contains('fr-video') === false &&
        options['videoAllowedProviders'].some((provider: string) => iframe.src.includes(provider))
    )

    if (iframes.length > 0) {
      iframes.forEach((iframe) => {
        iframe.width = '640'
        iframe.height = '360'
        iframe.outerHTML = `<span class="fr-video fr-deletable fr-fvc fr-dvb fr-draggable" contenteditable="false" draggable="true">${iframe.outerHTML}</span>`
      })

      html.set(body.innerHTML)
      events.trigger('contentChanged', [], false)
    }
  }

  const config = {
    ...defaultConfig,
    ...additionalConfig,
    events: {
      'html.get': function (html: string) {
        return html.replace(/id="isPasted"/g, '')
      },
      keyup: function (event: KeyboardEvent) {
        const editor = this as unknown as WYSIWYGEditorType

        // https://github.com/froala/wysiwyg-editor/issues/4545
        if (editor.helpers.isIOS() && event.key === 'Backspace') {
          editor.cursor.backspace()
          event.preventDefault()
        }
      },

      ...additionalConfig.events,

      initialized: function () {
        const editor = this as unknown as WYSIWYGEditorType

        const { imageAllowedTypes, imageMaxSize } = mergedConfig

        if (imageAllowedTypes && imageMaxSize) {
          LANGUAGE['ko'].translation['Drop image'] = `${imageMaxSize / 1024 / 1024}MB 이하의<br />${imageAllowedTypes
            .join(', ')
            .toUpperCase()} 파일`
        }

        if (additionalConfig.events?.initialize) {
          additionalConfig.events.initialize.bind(editor)()
        }

        setFroalaEditor(editor)
      },
      contentChanged: function () {
        const editor = this as unknown as WYSIWYGEditorType

        replaceHTTPToHTTPS(editor)
        setVideoOuterHTML(editor)

        if (additionalConfig.events?.contentChanged) {
          additionalConfig.events.contentChanged.bind(editor)()
        }
      },
      'image.beforeUpload': function (files: FileList) {
        const editor = this as unknown as WYSIWYGEditorType
        const file = files[0]

        if (file instanceof File) {
          temporaryFileName = file.name
        } else {
          // FIXME: Blob 이미지 복사/붙여넣기 시 Base64 PNG 파일로 변환하는 문제
          temporaryFileName = 'UNABLE_TO_UPLOAD'
          toast('이 이미지는 붙여넣기할 수 없어요. 내용을 저장한 후 다시 시도해 주세요.', { type: 'warning' })

          return true
        }

        if (mergedConfig['imageMaxSize'] && file.size > mergedConfig['imageMaxSize']) {
          editor.image.hideProgressBar(false)
          toast(
            `${
              mergedConfig['imageMaxSize'] / 1024 / 1024
            }MB 이하의 이미지만 업로드할 수 있어요. 파일 용량을 확인해 주세요.`,
            { type: 'warning' }
          )

          return false
        }

        return true
      },
      'image.inserted': function (images: HTMLImageElement[]) {
        const editor = this as unknown as WYSIWYGEditorType
        const image = images[0]
        const { width, height, naturalWidth, naturalHeight } = image

        if (temporaryFileName === 'UNABLE_TO_UPLOAD') {
          image.remove()
          editor.popups.hideAll()

          return
        }

        image.alt = temporaryFileName
        image.setAttribute('data-width', String(width))
        image.setAttribute('data-height', String(height))
        image.setAttribute('data-natural-width', String(naturalWidth))
        image.setAttribute('data-natural-height', String(naturalHeight))

        if (additionalConfig.events?.['image.inserted']) {
          additionalConfig.events['image.inserted'].bind(editor)(images)
        }
      },
    },
  }

  useEffect(() => {
    if (froalaEditor) {
      if (isReadOnly) {
        froalaEditor.edit.off()
      } else {
        froalaEditor.edit.on()
      }
    }
  }, [froalaEditor, isReadOnly])

  return (
    <div
      className={classNames('wysiwyg-editor', {
        'is-error': isError || (props['aria-invalid'] === 'true' && props['aria-required'] === 'true'),
      })}
    >
      <FroalaWYSIWYGEditor config={config} model={value} {...props} onModelChange={onChange} />
    </div>
  )
}

export default React.memo(WYSIWYGEditor);
