import { forwardRef, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { uniqueId } from 'lodash-es'

import { ReactComponent as HelpIcon } from '@common/svg/help.svg'
import { ReactComponent as HelpOIcon } from '@common/svg/helpOutline.svg'

import useOutsideClickRef from '../useOutsideClickRef'

import TooltipMessageBox from './TooltipMessageBox'

import styles from './Tooltip.module.scss'

export type Placement = 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end'

export interface Props {
  /** 툴팁 아이디 */
  id?: string

  /** 툴팁 라벨 */
  label?: string

  /** label 블라인드 텍스트 처리 **/
  isBlindText?: boolean

  /** 툴팁 표시 위치 */
  placement?: Placement

  /** 마우스 오버 시 툴팁 표시 여부 (터치 환경은 적용 안 함) */
  hover?: boolean

  className?: string

  children: React.ReactNode

  /** 메시지 박스 넓이 **/
  messageWidth?: number
}

export const Tooltip = forwardRef<HTMLButtonElement, Props>(
  ({ children, className, hover = false, id, isBlindText, label, messageWidth, placement = 'bottom-start' }, ref) => {
    const [buttonElement, setButtonElement] = useState<HTMLButtonElement>()
    const iconRef = useRef<HTMLSpanElement>(null)
    const messageBoxRef = useRef<HTMLDivElement>(null)
    const rootRef = useOutsideClickRef({
      callback: () => setButtonElement(undefined),
    })

    const isHover = useMemo(() => typeof window !== 'undefined' && !('ontouchstart' in window) && hover, [hover])

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      if (event.type === 'mouseenter' && !isHover) return

      if (iconRef) {
        setButtonElement(buttonElement ? undefined : event.currentTarget)
      }
    }
    const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
      if (event && event.type === 'mouseleave' && !isHover) return
      setButtonElement(undefined)
    }

    const uid = useMemo(() => {
      if (id) {
        return id
      }
      return uniqueId('Tooltip_')
    }, [id])

    const isOpen = Boolean(buttonElement)

    return (
      <div ref={rootRef as React.LegacyRef<HTMLDivElement>}>
        <button
          ref={ref}
          type="button"
          className={cx(styles.button, className, { [styles.isOpen]: isOpen })}
          aria-describedby={uid}
          onClick={handleOpen}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        >
          <span className={cx(styles.label, { [styles.blindText]: isBlindText })}>{label}</span>
          <span ref={iconRef} className={styles.helpIconWrap}>
            <HelpOIcon className={styles.helpOutlineIcon} />
            <HelpIcon className={styles.helpIcon} />
          </span>
        </button>
        <TooltipMessageBox
          button={buttonElement}
          id={uid}
          isOpen={isOpen}
          messageWidth={messageWidth}
          placement={placement}
          refObject={messageBoxRef}
          target={iconRef ? iconRef.current : null}
        >
          {typeof children === 'function' ? (children as Function)() : children}
        </TooltipMessageBox>
      </div>
    )
  }
)

export default Tooltip
