import { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useUsableCostQuery } from '@biz/query'
import alertify from 'alertifyjs'
import GeneralChargeDialog from '../../bizmoney/GeneralChargeDialog'

interface MdCampData {
  ok: boolean
  campId: number
  project2Id: string
  campStartTime: string
  campEndTime: string
  campBudgetTotal1: number
  campPeriod: number
  campBudgetExp: number // 예상 캠페인 비용
  wadizFee: number
  campBuff: number
  campBudgetTotalExp: number // 예상 총 비용
  campBudgetTotalVat: number
  campBudgetTotal2: number // 최종 예치금
}
export interface MdCampDatas {
  ok: boolean
  data: MdCampData
}
export interface CampInspReqForm {
  ok: boolean
  data: any
  campId: number
  project2Id: string
  intervalValid: boolean
  resultMsg: string
}
interface CampBizmoneyInfo {
  ok: boolean
  data: any
  currentCost: number
}
const CampAdReqDialog = (props: any) => {
  const navigate = useNavigate()
  const [campPeriod, setCampPeriod] = useState(0)
  const [campStartDate, setCampStartDate] = useState('')
  const [campEndDate, setCampEndDate] = useState('')
  // const [campBudget, setCampBudget] = useState('');
  const [campBudgetTotal1, setCampBudgetTotal1] = useState('')
  const [campBugetWadizFee, setCampBugetWadizFee] = useState('')
  const [campBugetBuff, setCampBugetBuff] = useState('')
  const [campBudgetTotalExp, setCampBudgetTotalExp] = useState('')
  const [campBudgetTotal2, setCampBudgetTotal2] = useState(0)
  const [campBudgetTotalVat, setCampBudgetTotalVat] = useState(0)
  // 비즈머니 or 후불머니
  const [money, setMoney] = useState(0)
  const [isClicked, setIsClicked] = useState(false)
  const [isLoadingOk, setIsLoadingOk] = useState(false)
  const [isMoneyLoadingOk, setIsMoneyLoadingOk] = useState(false)
  const { setValue, getValues } = useForm<CampInspReqForm>()
  const { update: setUsableCostChange } = useUsableCostQuery()
  const getCampDatas = async () => {
    const { data } = await ApiUtil2.get<MdCampDatas>('/api/camp/make02/getCampInfo2', {
      params: { campId: props.campId, payType: props.payType },
    })
    return data
  }

  const getCampDatasApi = useQuery(['getCampDatas'], getCampDatas, {
    enabled: false,
  })

  // 비즈머니
  const getMoney = async () => {
    const { data } = await ApiUtil.get<CampBizmoneyInfo>('/api/camp/make02/getMoneyInfo', {
      params: { campId: props.campId, payType: props.payType },
    })
    return data
  }
  const moneyApi = useQuery(['getMoney'], getMoney, { enabled: false })

  const moneyApiEvent = () => {
    moneyApi.refetch().then((r) => {
      if (r.isSuccess) {
        setMoney(r.data.data.currentCost)
        $('#campAdReqDialog').dialog({ width: 900, title: '', closeOnEscape: true })
      }
      setIsMoneyLoadingOk(true)
    })
  }
  const commonLoader = document.querySelector('.loader.inspReq')
  const campInspReqApi = () => {
    const payType = props.payType
    const { campId, project2Id } = getValues()
    const result = ApiUtil.post<CampInspReqForm>('/api/camp/make02/inspReq', {
      campId,
      payType,
      project2Id,
    })

    return result
  }

  const campInspReqApiEvent = useMutation(campInspReqApi, {
    onSuccess: (resp) => {
      if (commonLoader !== null) commonLoader.classList.add('none')
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        if (props.payType === 'BIZ_MONEY')
          setUsableCostChange(`${(Number(money) - Number(campBudgetTotal2)).toLocaleString()}`)
        // 캠페인 종료시간 밸리데이션 여부에 따라서 분기됌. (36시간)
        if (resp.data.data.intervalValid) alertify.success(resp.data.data.resultMsg)
        else alertify.warning(resp.data.data.resultMsg)

        $('#campAdReqDialog').dialog('close')
        navigate('/mngCamp/mngCamp')
      }
    },
    onError: () => {
      if (commonLoader !== null) commonLoader.classList.add('none')
    },
  })
  // 충전 주체와 사용 주체가 같은지 판별한다.
  const useNoCheckApi = async () => {
    if (commonLoader !== null) commonLoader.classList.remove('none')
    const { data } = await ApiUtil.get('/api/camp/make02/userNoCheck', { params: { campId: props.campId } })
    return data
  }
  const useNoCheckApiApiEvent = useQuery(['useNoCheckApi'], useNoCheckApi, {
    enabled: false,
  })
  const searchCamp = () => {
    getCampDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        setValue('campId', r.data.data.campId)
        setValue('project2Id', r.data.data.project2Id)
        if (r.data.data.campStartTime != null) {
          setCampStartDate(dayjs(r.data.data.campStartTime).format('YYYY-MM-DD'))
        }

        if (r.data.data.campEndTime != null) {
          setCampEndDate(dayjs(r.data.data.campEndTime).format('YYYY-MM-DD'))
        }
        if (r.data.data.campPeriod != null) {
          setCampPeriod(r.data.data.campPeriod)
        }

        if (r.data.data.campBudgetTotal1 != null) {
          // const budget = r.data.data.campBudgetTotal1;
          const budgetTotal = r.data.data.campBudgetTotal1
          const budgetWadizFee = r.data.data.wadizFee
          const budgetBuff = r.data.data.campBuff
          const budgetTotalExp = r.data.data.campBudgetTotalExp
          const balance = r.data.data.campBudgetTotal2
          // setCampBudget(budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
          setCampBudgetTotal1(budgetTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
          setCampBugetWadizFee(budgetWadizFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
          setCampBugetBuff(budgetBuff.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
          setCampBudgetTotalExp(budgetTotalExp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
          setCampBudgetTotalVat(r.data.data.campBudgetTotalVat)
          setCampBudgetTotal2(balance)

          setIsLoadingOk(true)
        }
      }
    })
  }

  // const init = () => {
  //   searchCamp();
  //   /* 보유 비즈머니 */
  // };
  const inspReqEvent = () => {
    if (props.payType === 'BIZ_MONEY') {
      if (money < campBudgetTotal2) {
        alertify.error('보유 비즈머니가 부족합니다. <br> 비즈머니를 먼저 충전해주세요')
        return
      }
      useNoCheckApiApiEvent.refetch().then((r) => {
        if (r.isSuccess) {
          if (!r.data.data) {
            alertify
              .confirm('충전 주체와 사용 주체가 다른 경우 와디즈는 불이익에 대하여 책임지지 않습니다.', () => {
                campInspReqApiEvent.mutate()
              })
              .set({ labels: { cancel: '취소', ok: '확인' } })
              .setHeader('선택한 프로젝트의 사업자번호와 비즈머니를 충전한 사업자번호가 다릅니다.')
          } else {
            campInspReqApiEvent.mutate()
          }
        }
      })
    } else {
      campInspReqApiEvent.mutate()
    }
  }
  useEffect(() => {
    if (isLoadingOk && !isMoneyLoadingOk) {
      moneyApiEvent()
    }
  })
  useEffect(() => {
    $('#campAdReqBizDialog').click(() => {
      setIsClicked(true)
    })

    $(document).on('click', '#campAdReqdialogClose', () => {
      $('#campAdReqDialog').dialog('close')
      props.setCampInspValidOk(false)
    })
    if (props.campId != null) {
      //init();
      searchCamp()
    }
    const inspBtnReq = document.getElementById('inspReqBtn') as HTMLElement
    inspBtnReq.addEventListener('click', inspReqEvent)
    return () => {
      props.setCampInspValidOk(false)
      $('#campAdReqDialog').dialog('destroy')
    }
  }, [])
  useEffect(() => {
    $('#campBuffTooltip').tooltip({
      content:
        '<p class="fz-12 fc-0">캠페인 보증금은 예산을 초과하여 광고가 집행되는 경우를 위한 예비 금액입니다. 광고비가 초과 발생하지 않을 경우 캠페인 종료 이후 반환됩니다.</p>',
    })
    $('#campBuffTooltip').click((e) => {
      e.preventDefault()
    })
    return () => {
      $('.tooltip').remove()
    }
  }, [])
  return (
    <Fragment>
      {isClicked === true ? <GeneralChargeDialog isClicked={isClicked} setIsClicked={setIsClicked} /> : null}

      <form>
        <div id="campAdReqDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt center">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-28 fc-1 fw-medium">와디즈에 광고 진행을 요청합니다.</b>
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className="form-group">
                        <p className="comp-txt center">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-12 fc-3">
                                <i className="fz-12 fc-5">*</i>소재 검수를 통과하면 캠페인 시작일부터 SNS 매체에 광고를
                                노출합니다.
                              </b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">캠페인 기간</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">
                                {campPeriod}일 ({campStartDate} ~ {campEndDate})
                              </b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    {/*<dt>*/}
                    {/*    <div className="dt-inner">*/}
                    {/*                        <span className="fz-16 fc-1">*/}
                    {/*                            1일 캠페인 예산*/}
                    {/*                        </span>*/}
                    {/*    </div>*/}
                    {/*</dt>*/}
                    {/*<dd>*/}
                    {/*    <div className="form-group">*/}
                    {/*    <span className="comp-txt">*/}
                    {/*                            <span className="table">*/}
                    {/*                                <span className="table-cell">*/}
                    {/*                                    <b className="fz-14 fc-2">{campBudget} 원</b>*/}
                    {/*                                </span>*/}
                    {/*                            </span>*/}
                    {/*                        </span>*/}
                    {/*    </div>*/}
                    {/*</dd>*/}
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">캠페인 총 예산</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{campBudgetTotal1} 원</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                  </dl>
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">와디즈 수수료</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{campBugetWadizFee} 원</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">
                          캠페인 보증금
                          <Link to="#" className="ico-tooltip tooltip-f" title="" id="campBuffTooltip"></Link>
                        </span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{campBugetBuff} 원</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">예상 총 비용</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{campBudgetTotalExp} 원</b>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-12 fc-3 lh20">
                                <i className="fz-12 fc-5">*</i>실제 비용은 SNS 매체에서 발생한 금액에 따라 변경됩니다.
                              </b>
                              <b className="fz-12 fc-3 lh20">
                                <i className="fz-12 fc-5">*</i>잔액은 캠페인 종료 후 반환됩니다.
                              </b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl className="vertical total">
                    <dd>
                      <div className="form-group">
                        <div className="box-right">
                          <div className="box-group">
                            <span className="fz-14 fc-1">
                              {props.payType === 'BIZ_MONEY' ? '비즈머니 잔액' : '후불머니 사용가능 잔액'}
                            </span>
                            <span className="fz-14 fc-2">{money.toLocaleString()} 원</span>
                          </div>
                          {props.payType === 'BIZ_MONEY' ? (
                            <button type="button" className="btn btn-primary large w-200" id="campAdReqBizDialog">
                              비즈머니 충전
                            </button>
                          ) : null}
                        </div>
                        <div className="box-left">
                          <span className="fz-18 fc-1">
                            ‘확인’ 버튼을 누르면 <span className="fc-5">{campBudgetTotal2.toLocaleString()}</span> 원이
                            결제됩니다.
                          </span>
                          <p className="fz-12 fc-3 txt-center">
                            <i className="fz-12 fc-5">*</i>(10% 부가세 금액{' '}
                            <span className="fc-5">{campBudgetTotalVat.toLocaleString()}</span> 원)
                          </p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-secondary-outline large" id="campAdReqdialogClose">
              취소
            </button>
            <button type="button" className="btn btn-primary large" id="inspReqBtn">
              확인
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}
export default CampAdReqDialog;
