import React, { Fragment, useEffect } from 'react';

const NoDataTemplate = () => {
  useEffect(() => {
    const noDataGrid = (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
    const noTreeDataGrid2 = (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any);
    const noDataTempleteArea = (document.querySelector<HTMLElement>('.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper')! as any);
    if (noDataGrid !== null) {
      noDataGrid.style.height = '548px';
    }
    if (noTreeDataGrid2 !== null) {
      noTreeDataGrid2.style.height = '548px';
    }
    if (noDataTempleteArea !== null) {
      noDataTempleteArea.style.height = '548px';
    }

    return () => {
      if (noDataGrid !== null) noDataGrid.style.height = '';
      if (noTreeDataGrid2 !== null) noTreeDataGrid2.style.height = '';
      if (noDataTempleteArea !== null) noDataTempleteArea.style.height = '';
    }
  }, []);
  return (<Fragment>
        <div className="comp-no-data">
            <div className="box-group">
                <i className="ico ico-no-data"></i>
                <p className="fz-18 fc-2">
                    데이터가 존재하지 않습니다.
                </p>
            </div>
        </div>
    </Fragment>);
};

export default NoDataTemplate;
