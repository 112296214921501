import {Fragment, useEffect, useRef, useState} from "react";
import { useNavigate } from 'react-router'
import { useOutletContext } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import {useForm} from "react-hook-form";
import alertify from 'alertifyjs'
import _ from "lodash";
import { ApiUtil2 } from '@biz/api'


export interface catInspConfig{
  catCodeStr?: string,
  catCode: string,
  desc: string,
  catInspDiv: string,
  catProhKwd?: string,
}

const CategoryInspMng = () => {
  const setTitle = useOutletContext<any>();
  const navigate = useNavigate();
  const [cateDiv, setCateDiv] = useState([]);
  const [markCateDiv, setMakrCateDiv] = useState('COMMON');
  const localCateDivs = useRef<any>({});
  const prevLocalCateDivs = useRef<any>({});

  const {
    getValues, setValue, register, watch
  } = useForm<catInspConfig>();

  useEffect(() => {
    setTitle('카테고리 관리');
    return () => {
      setTitle('');
    };
  }, []);

  const getCate = async () => {
    // COMMON 은 공통 적용을 위한 분류로서, '공통'이라는 카테고리가 따로 존재하지는 않음
    const { data } = await ApiUtil2.get(`/api/mngAd/creation/categoryInspMng/getCateDiv`);
    return data;
  };

  const getCateDiv = useQuery(['getCateDiv', 0], getCate, {
    enabled: false,
  });

  const saveLocalCategory = () => {
    if (typeof getValues('catCode') != 'undefined' && getValues('catCode') != null)
      localCateDivs.current[getValues('catCode')] = getValues();
  };

  const saveCategoryDiv = useMutation((formData : any) => ApiUtil2.post(`/api/mngAd/creation/categoryInspMng/saveCateInspConfig`, formData), {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 저장되었습니다.', 2, () => {
          navigate('/mngAd/creation');
        });
      }
    }
  });

  const cancelEvent = () => {
    let isChange = false;
    Object.entries(localCateDivs.current).map((e : any, idx : any) => {
      if (typeof prevLocalCateDivs.current[e[0]] == 'undefined') {
        isChange = true;
        return;
      } else if (prevLocalCateDivs.current[e[0]].catInspDiv != e[1].catInspDiv || prevLocalCateDivs.current[e[0]].catProhKwd != e[1].catProhKwd) {
        isChange = true;
        return;
      }
    });

    if (isChange) {
      alertify.confirm('변경하신 내용을 저장하지 않고 이전 화면으로 이동할까요?', () => {
        window.location.href = '/mngAd/creation';
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('변경하신 내용이 있습니다.');
    } else {
      window.location.href = '/mngAd/creation';
    }
  };

  const save = () => {
    const formData = Object.values(localCateDivs.current);
    saveCategoryDiv.mutate(formData);
  }

  useEffect(() => {
    if (!_.isEmpty($('#kwdTag')[0].innerHTML)) destroyTagging();

    if (typeof localCateDivs.current[markCateDiv] == 'undefined') {
      ApiUtil2.get(`/api/mngAd/creation/categoryInspMng/getCateInspConfig/${markCateDiv}`)
        .then((categoryInspConfig) => {
          const { data } = categoryInspConfig.data;

          setValue('catCode', data.catCode);
          setValue('desc', data.desc);
          setValue('catInspDiv', data.catInspDiv);
          setValue('catProhKwd', data.catProhKwd);

          localCateDivs.current[markCateDiv] = data;
          prevLocalCateDivs.current[markCateDiv] = data;

          createTagging();
        });
    } else {
      setValue('catCode', localCateDivs.current[markCateDiv].catCode);
      setValue('desc', localCateDivs.current[markCateDiv].desc);
      setValue('catInspDiv', localCateDivs.current[markCateDiv].catInspDiv);
      setValue('catProhKwd', localCateDivs.current[markCateDiv].catProhKwd);
      createTagging();
    }


  }, [markCateDiv]);

  const destroyTagging = (() => {
    $('#kwdTag').tagging('destroy');
    $('#kwdTag').off('add:after');
    $('#kwdTag').off('remove:after');
  });

  const createTagging = (() => {
    $('#kwdTag').tagging({
      'edit-on-delete': false,
      'pre-tags-separator': '|',
      'no-duplicate-text': '키워드는 중복하여 등록하실 수 없습니다.',
      'no-duplicate-callback': function (msg:string) {
        alertify.error(msg[0]);
      },
      'placeholder' : '키워드를 입력해 주세요.'
    });

    if (_.isEmpty(localCateDivs.current[markCateDiv].catProhKwd)) {
      $('#kwdTag').tagging('reset');
    } else {
      $('#kwdTag').tagging('refresh', localCateDivs.current[markCateDiv].catProhKwd);
    }

    $('#kwdTag').on('add:after', (el :any, text :any, tagging :any) => {
      const { tags } = tagging;
      const tagTexts : any[] = [];

      if (text.indexOf('\|') != -1) {
        $('#kwdTag').tagging('remove', text.replaceAll('\|', '\\|'));
        $('#kwdTag').tagging('add', text.replaceAll('\|', ''));
        return;
      }

      let isCommonKwd = false;

      if (markCateDiv == 'COMMON') {
        _.each(localCateDivs.current, (cateDiv : any) => {
          if (cateDiv.catCode == 'COMMON') return;
          const idx = cateDiv.catProhKwd.split('|').indexOf(text);

          if (idx != -1) {
            let kwdArr = cateDiv.catProhKwd.split('|');
            kwdArr.splice(idx, 1);
            localCateDivs.current[cateDiv.catCode].catProhKwd = kwdArr.join('|');
          }
        });
      } else {
        if (localCateDivs.current['COMMON'].catProhKwd.split('|').indexOf(text) != -1) {
          alertify.error('공통 카테고리에 등록된 키워드 입니다.');
          $('#kwdTag').tagging('remove', text);
          isCommonKwd = true;
        }
      }

      if (isCommonKwd) return;

      _.each(tags, (tag : any) => {
        tagTexts.push(tag.pure_text);
      });

      setValue('catProhKwd', tagTexts.length == 0 ? '' : tagTexts.join('|'));
    });

    $('#kwdTag').on('remove:after', (el :any, text :any, tagging :any) => {
      const { tags } = tagging;
      const tagTexts : any[] = [];

      _.each(tags, (tag : any) => {
        tagTexts.push(tag.pure_text);
      });

      setValue('catProhKwd', tagTexts.length == 0 ? '' : tagTexts.join('|'));
    });
  });

  useEffect(() => {
    saveLocalCategory();
  }, [watch('catInspDiv'), watch(('catProhKwd'))]);


  useEffect(() => {
    getCateDiv.refetch().then((response) => {
      const { data } = response.data;
      setCateDiv(data.enumMap);

      _.each(data.catInspConfigs, (cateConfig : any) => {
        localCateDivs.current[cateConfig.catCode] = cateConfig;
        prevLocalCateDivs.current[cateConfig.catCode] = cateConfig;
      });

    });

    return () => {
      destroyTagging();
    }
  }, []);

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-fluid">
                    <div className="row flex-container">
                      <div className="col col-4">
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">카테고리 목록</h2>
                            </div>
                          </div>
                          <div className="box-body h-500 scroll-y">
                            <table className="tbl group-list">
                              <tbody>
                                {
                                  Object.entries(cateDiv).map((e, idx) => (
                                    <tr key={e[0]}>
                                      <td className={markCateDiv==e[0] ? "selected" : ""} onClick={() => (setMakrCateDiv(e[0]))}>
                                        <span className="fz-16 fc-3">{e[1]}</span>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col col-8">
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">카테고리 설정</h2>
                            </div>
                          </div>
                          <div className="box-body">
                            <div className="tbl">
                              <dl>
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        카테고리명
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <span className="comp-txt">
                                        <span className="table">
                                            <span className="table-cell">
                                                <b className="fz-14 fc-2">{ getValues('desc') }</b>
                                            </span>
                                        </span>
                                    </span>
                                  </div>
                                </dd>
                              </dl>
                              <dl style={{display : markCateDiv == 'COMMON' ? 'none' : 'flex' }}>
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        카테고리 검수 구분
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="comp-radio">
                                      <input type="radio" id="catInspDiv_kwd" value="kwd" {...register('catInspDiv')}/>
                                        <label htmlFor="catInspDiv_kwd">일반</label>
                                    </div>
                                    <div className="comp-radio">
                                      <input type="radio" id="catInspDiv_must" value="must" {...register('catInspDiv' )}/>
                                        <label htmlFor="catInspDiv_must">검수필요</label>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl>
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        검수필요 키워드 등록
                                    </span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="tf-comm expand h-274" id="kwdTag"></div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-right">
            <button type="button" className="btn btn-secondary-outline" onClick={cancelEvent}>취소</button>
            <button type="button" className="btn btn btn-primary" onClick={save}>저장</button>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default CategoryInspMng;
