import React, {
    createContext, Fragment, /* useContext, */ useEffect, useState,
} from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query' // try next react-query;
/* https://wadiz.atlassian.net/browse/BIZ-51
 위 지라 요청에 따라 아래 campAdmin, shadowLoginContext 임시 주석 처리
 */
// import CampAdmin from './CampAdmin';
import Make02Reouter from './Make02Router';
import { ApiUtil2 } from '@biz/api'

interface MdCampData {
    ok: boolean;
    id:number;
    campId:number;
    campNm: string;
    campGoalDiv: string;
    campStartTime: string;
    campEndTime: string;
    campBudget: number;
    adminCampYn: boolean;
    mediaDiv4:string;
    projectId:string;
    projectFundingDiv: string;
    projectNm: string;
    campViewStatus:string;
    useYn:boolean;
    creationDataCnt:number;
}
interface MdCampDatas {
    ok: boolean;
    data:MdCampData;
}
export const CampTabClickContext = createContext({
  tabClick: '',
  setTabClick: (value: any) => value,
});
export const CampMediaDiv4Context = createContext({
  mediaDiv4Param: 'FBITG',
  setMediaDiv4Param: (value: any) => value,
});

export const CampIdContext = createContext({
  campIdParam: null,
  setCampIdParam: (value: any) => value,
});

export const ProjectIdContext = createContext({
  projectIdParam: null,
  setProjectIdParam: (value: any) => value,
});

export const CampViewStatusContext = createContext({
  campViewStatusParam: null,
  setCampViewStatusParam: (value: any) => value,
});

export const GdnCreationFileUploadIdContext = createContext({
  gdnFileUploadId: null,
  setGdnFileUploadId: (value: any) => value,
});

export const GdnCreationDataIdContext = createContext({
  gdnCreationDataId: null,
  setGdnCreationDataId: (value: any) => value,
});
export const GdnMakerCreationFilesContext = createContext({
  gdnMakerCreationFiles: [],
  setGdnMakerCreationFiles: (value: any) => value,
});
export const CampFbCreationContext = createContext({
  campFbCreationForm: [],
  setCampFbCreationForm: (value: any) => value,
});
export const CampItgCreationContext = createContext({
  campItgCreationForm: [],
  setCampItgCreationForm: (value: any) => value,
});
export const AdminContext = createContext({
  adminParam: { isAdmin: false, forAdmin: false },
  setAdminParam: (value: any) => value,
});

export const CampValidDataContext = createContext({
  campValidData: null,
  setCampValidData: (value: any) => value,
});
export const CatProhValidDataContext = createContext({
    catProhValidData: null,
    setCatProhValidData: (value: any) => value,
})
export const CatProhUpdateYnContext = createContext({
    catProhUpdateYn: false,
    setCatProhUpdateYn: (value: any) => value,
})
export const AdminCampYnChangeContext = createContext({
  adminCampYnChangeCnt: 0,
  setAdminCampYnChangeCnt: (value: any) => value,
});
// 캠페인 수정 상태 추가
export const CampUpdateModeContext = createContext({
  campUpdateMode: false,
  setCampUpdateMode: (value: any) => value,
});
// 편집중인 소재 있는지 체크함
export const ExistEditingContext = createContext({
    existEditing: false,
    setExistEditing: (value: any) => value,
});
const Make02Empty = (props:any) => {
  const navigate = useNavigate()
  const url = useLocation()
  const { campId, step, mediaDiv4, adminCampYn, campViewStatus, projectId } = useParams()

  const shadowInfo = localStorage.getItem('shadow')
  /* https://wadiz.atlassian.net/browse/BIZ-51
    위 지라 요청에 따라 shadowLogin useContext 임시 주석 처리
    */

  const setTitle = useOutletContext<any>()
  const [tabClick, setTabClick] = useState<any>(step === undefined ? 'step01' : step)
  const [existEditing, setExistEditing] = useState<boolean>(false)
  const [mediaDiv4Param, setMediaDiv4Param] = useState(mediaDiv4 === undefined ? 'FBITG' : mediaDiv4)
  const [campIdParam, setCampIdParam] = useState<any>(campId === undefined ? null : campId)
  const [projectIdParam, setProjectIdParam] = useState<any>(projectId === null ? null : projectId)
  const [campFbCreationForm, setCampFbCreationForm] = useState([])
  const [campItgCreationForm, setCampItgCreationForm] = useState([])
  const [gdnFileUploadId, setGdnFileUploadId] = useState<any>(null)
  const [adminParam, setAdminParam] = useState<any>({
    isAdmin: shadowInfo !== null,
    forAdmin: adminCampYn === undefined ? false : String(adminCampYn) === 'true',
  })
  const [gdnMakerCreationFiles, setGdnMakerCreationFiles] = useState([])
  const [gdnCreationDataId, setGdnCreationDataId] = useState<any>(null)
  const [campViewStatusParam, setCampViewStatusParam] = useState<any>(
    campViewStatus === undefined ? null : campViewStatus
  )
  const [campValidData, setCampValidData] = useState<any>(null)
  const [catProhValidData, setCatProhValidData] = useState<any>(null)
  const [catProhUpdateYn, setCatProhUpdateYn] = useState<any>(false)
  const [adminCampYnChangeCnt, setAdminCampYnChangeCnt] = useState<any>(0)
  const [campUpdateMode, setCampUpdateMode] = useState<any>(campId !== undefined)
  const [pageLoad, setPageLoad] = useState<any>(false)

  // 캠페인 조회
  const getCampDatas = async () => {
    const { data } = await ApiUtil2.get<MdCampDatas>('/api/camp/make02/getCampInfo', { params: { campId } })
    return data
  }

  const getCampDatasApi = useQuery(['getCampDatas'], getCampDatas, {
    enabled: false,
  })
  useEffect(() => {
    if (campId !== undefined) {
      setTitle('캠페인 관리')
      setCampIdParam(campId)
      setTabClick(step)
      getCampDatasApi.refetch().then((r) => {
        if (r.isSuccess) {
          if (step === undefined) {
            setTabClick('step01')
          }
          setAdminParam({ isAdmin: shadowInfo !== null, forAdmin: r.data.data.adminCampYn })
          setCampIdParam(r.data.data.id)
          setCampViewStatusParam(r.data.data.campViewStatus)
          setPageLoad(true)
          navigate(`/mngCamp/mngCamp/mng/${campId}`)
        }
      })
    } else if (url.pathname.includes('/mngCamp/mngCamp')) {
      navigate('/mngCamp/mngCamp')
    } else {
      setTitle('새 캠페인 만들기')
      setCampUpdateMode(false)
      setCampIdParam(null)
      setProjectIdParam(null)
      setTabClick('')
      setPageLoad(true)
      setCampValidData(null)
      setCampViewStatusParam(null)
    }
  }, [props.mode])

  return (
    <Fragment>
      <CampTabClickContext.Provider value={{ tabClick: tabClick, setTabClick }}>
        <CampMediaDiv4Context.Provider value={{ mediaDiv4Param, setMediaDiv4Param }}>
          <CampIdContext.Provider value={{ campIdParam, setCampIdParam }}>
            <ProjectIdContext.Provider value={{ projectIdParam, setProjectIdParam }}>
              <AdminContext.Provider value={{ adminParam, setAdminParam }}>
                <CampFbCreationContext.Provider value={{ campFbCreationForm, setCampFbCreationForm }}>
                  <CampItgCreationContext.Provider value={{ campItgCreationForm, setCampItgCreationForm }}>
                    <GdnCreationFileUploadIdContext.Provider value={{ gdnFileUploadId, setGdnFileUploadId }}>
                      <GdnMakerCreationFilesContext.Provider
                        value={{ gdnMakerCreationFiles, setGdnMakerCreationFiles }}
                      >
                        <GdnCreationDataIdContext.Provider value={{ gdnCreationDataId, setGdnCreationDataId }}>
                          <CampViewStatusContext.Provider value={{ campViewStatusParam, setCampViewStatusParam }}>
                            <CampValidDataContext.Provider value={{ campValidData, setCampValidData }}>
                              <AdminCampYnChangeContext.Provider
                                value={{ adminCampYnChangeCnt, setAdminCampYnChangeCnt }}
                              >
                                <CampUpdateModeContext.Provider value={{ campUpdateMode, setCampUpdateMode }}>
                                  <CatProhValidDataContext.Provider value={{ catProhValidData, setCatProhValidData }}>
                                    <CatProhUpdateYnContext.Provider value={{ catProhUpdateYn, setCatProhUpdateYn }}>
                                      <ExistEditingContext.Provider value={{ existEditing, setExistEditing }}>
                                        {/* https://wadiz.atlassian.net/browse/BIZ-51
                                                                    위 지라 요청에 따라 아래 campAdmin.tsx 임시 주석 처리
                                                                */}
                                        {/* {pageLoad && shadowLogin ? <CampAdmin/> : null} */}
                                        {/* <CampTab/> */}
                                        {pageLoad ? <Make02Reouter mode={props.mode} /> : null}
                                      </ExistEditingContext.Provider>
                                    </CatProhUpdateYnContext.Provider>
                                  </CatProhValidDataContext.Provider>
                                </CampUpdateModeContext.Provider>
                              </AdminCampYnChangeContext.Provider>
                            </CampValidDataContext.Provider>
                          </CampViewStatusContext.Provider>
                        </GdnCreationDataIdContext.Provider>
                      </GdnMakerCreationFilesContext.Provider>
                    </GdnCreationFileUploadIdContext.Provider>
                  </CampItgCreationContext.Provider>
                </CampFbCreationContext.Provider>
              </AdminContext.Provider>
            </ProjectIdContext.Provider>
          </CampIdContext.Provider>
        </CampMediaDiv4Context.Provider>
      </CampTabClickContext.Provider>
    </Fragment>
  )
};
export default Make02Empty;
