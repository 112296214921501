import React, { Fragment } from 'react';
import {
  Link,
  Outlet,
} from 'react-router-dom';

const ErrorLayout = () => (
    <Fragment>
        <div className="wrap error">
            <header className="header">
                <h1 className="logo">
                    <Link to="#"></Link>
                </h1>
            </header>
            <div className="content">
                <div className="inner-content">
                    <div className="content-body">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
);
export default ErrorLayout;
