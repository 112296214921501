import alertify from 'alertifyjs'

const errMsgRatio = () => {
  alertify.error('1:1, 4:5, 1.91:1, 1.77:1 규격의 이미지/영상 첨부만 가능합니다.')
}

/**
 * 소재 비율 밸리데이션
 * 참고 : https://wadiz.atlassian.net/browse/BIZ-395?focusedCommentId=164991&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-164991
 * 1:1,4:5
 * 비율을 소수 3자리에서 반올림해서 1.91+-0.03 이 된다면 통과
 * 비율을 소수 3자리에서 반올림해서 1.77+-0.03 이 된다면 통과
 * */
export const ratioCheck = (ratio: number) => {
  const validRatios = [1 / 1, 4 / 5]
  const tRatio1: number = 1.91
  const tRatio2: number = 1.77

  if (
    !validRatios.includes(ratio) &&
    Math.abs(ratio - tRatio1) > 0.03 &&
    ratio !== tRatio1 &&
    Math.abs(ratio - tRatio2) > 0.03 &&
    ratio !== tRatio2
  ) {
    errMsgRatio()
    return false
  }
  return true
}
