import { useQuery, type QueryClient } from '@tanstack/react-query'
import { loginLocalStorage, fetchLoginCheck, type LoginCheckType, type AuthorityType } from '@biz/api'
import { setLoginStatus, getLoginStatus } from '../useLoginStatusQuery'
import { setRoleGroup } from '../useRoleGroupQuery'
import { setRole } from '../useRoleQuery'
import { setMemberId } from '../useMemberIdQuery'
import { setShadowLogin } from '../useShadowLoginQuery'
import { loadMakerInfo } from '../useMakerInfoQuery'

const replaceToDevUrl = (url: string) =>
  url.replace(/(redirect_uri=)[^&]*/, `redirect_uri=${encodeURIComponent('http://localhost:3000/callback/wadiz/api')}`)
type ResponseDataType = { ok: boolean; data: LoginCheckType | string }
const queryKey = ['@biz/query/auth/LoginCheck']
const makeQueryOption = (enableRedirect: boolean) => ({
  queryKey,
  queryFn: () =>
    fetchLoginCheck().then((res: ResponseDataType) => {
      const { ok, data: redirectURL } = res as { ok: boolean; data: string }
      if (enableRedirect && ok === false) {
        window.location.href = import.meta.env.DEV ? replaceToDevUrl(redirectURL) : redirectURL
      }
      return res
    }),
  enabled: !loginLocalStorage.isExistUser(),
})

export const useLoginCheckQuery = () => useQuery(makeQueryOption(false))
export const loadLoginCheck = (queryClient: QueryClient) => async () => {
  const enableRedirect = await getLoginStatus(queryClient)()
  const loginData = await queryClient.fetchQuery(makeQueryOption(enableRedirect))
  setLoginStatus(queryClient)(loginData.ok)
  if (!loginData.ok) {
    return loginData
  }

  const userInfo = loginLocalStorage.getUserInfo()
  setRoleGroup(queryClient)(userInfo.roleGroup)

  const { shadowInfo, shadowRoleInfo } = loginLocalStorage.getShadowInfo()
  if (shadowInfo !== null && shadowRoleInfo !== null) {
    setMemberId(JSON.parse(shadowInfo).id)
    setRole(queryClient)('ROLE_USER_MAKER')
    setShadowLogin(queryClient)(shadowRoleInfo.indexOf('ROLE_ADMIN_ACTION') >= 0)
    return loginData
  }

  if (userInfo !== null) {
    if (userInfo.authorities === undefined) {
      return loginData
    }
    if (userInfo.authorities.indexOf('ROLE_USER_MAKER') >= 0) {
      setRole(queryClient)('ROLE_USER_MAKER')
      await loadMakerInfo(queryClient)()
    } else {
      setRole(queryClient)('ROLE_ADMIN')
    }
    setMemberId(userInfo.id)
    return loginData
  }

  await loadMakerInfo(queryClient)()

  return loginData
}
