import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';
import CampTab from './CampTab';
import { CampIdContext } from './Make02Empty';

const Make02Reouter = (props:any) => {
  const [pageLoad, setPageLoad] = useState<any>(false);
  const { setCampIdParam } = useContext(CampIdContext);
  useEffect(() => {
    if (props.mode === 'insert') {
      setCampIdParam(null);
    }
    setPageLoad(true);
  }, [props.mode, pageLoad]);
  return (
      <Fragment>
        {pageLoad ? <CampTab/> : null}
      </Fragment>
  );
};
export default Make02Reouter;
