import React, {createContext, Fragment, useEffect, useRef, useState,} from 'react';
import {useOutletContext} from 'react-router-dom';
import _ from 'lodash';
import { ApiUtil2 } from '@biz/api'
import { ICreationData } from './CreationDetailItg'
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import PreviewImgDialog from './PreviewImgDialog';

export const GdnCreationDataIdContext = createContext({
  creationDataId: 'step01',
  setCreationDataId: (value: any) => value,
});

const CreationDetailGdn = (props: any) => {
  const setTitle = useOutletContext<any>();
  const [creationDataId, setCreationDataId] = useState<any>(null);
  const [creationData, setCreationData] = useState<ICreationData[]>([]);
  const chunked = _.chunk(creationData, 3);
  const [fileData, setFileData] = useState<any[]>([]);
  const campIdRef = useRef<number>();
  const creationDataIdRef = useRef<number>();

  const getPrevData = async () => {
    ApiUtil2.get<ICreationData>('/api/mngAd/creation/detail', { params: { data: { campId: props.campId } } }).then((resp) => {
      setCreationData(resp.data.data);
    });
  };

  //소재반려 다이얼로그 추가
  const rejectClickEvent = (campId:any, id: any) => {
    campIdRef.current = campId
    creationDataIdRef.current = id
    setCreationDataId(id);
    $('#returnDialog').dialog({ width: 400, title: '소재 반려사유를 입력하세요.', closeOnEscape: true });
    $('#returnDialog').dialog('open');
  };

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1);
  };

  const confirmError = (msg: string) => {
    errorEvent(msg);
  };

  const confirmClickEvent = (id: any) => {
    alertify.confirm('', () => {
      ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
        id, //creationDataId
        campId: props.campId,
        status: 'ok',
      }).then(() => {
        getPrevData();
      });
    }).set({ labels: { cancel: '취소', ok: '승인' } })
      .setHeader('해당 소재를 검수 승인하시겠습니까?');
  };

  const getAf = async (id:any) => {
    ApiUtil2.post('/api/mngAd/creation/getFiles', { refId: id }).then((resp) => {
      // eslint-disable-next-line no-use-before-define
      if (resp.data.data.length !== 0) {
        setFileData(resp.data.data);
      }
    });
  };

  const event = (id: any) => {
    setCreationDataId(id);
    getAf(id);
    $('#previewDialog').dialog({ width: 1200, title: '이미지 미리보기', closeOnEscape: true });
    $('#previewDialog').dialog('open');
  };

  useEffect(() => {
    setTitle('소재 검수');
    getPrevData();

    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    $('#closePreview').click(() => {
      creationDataIdRef.current = 0
      setCreationDataId(0);
      setFileData([]);
      $('#previewDialog').dialog('close');
    });
    return () => {
      $('#previewDialog').dialog('destroy');
    };
  }, []);

  useEffect(() => {
    $('#closeBtn').click(() => {
      creationDataIdRef.current = 0
      setCreationDataId(0);
      campIdRef.current = 0
      $('#returnDialog').dialog('close');
    });
    return () => {
      $('#returnDialog').dialog('destroy');
    };
  }, []);

  useEffect(() => {
    $('#okBtn').click(() => {
      if($('#msg').val() === ''){
        confirmError('반려사유를 작성해주세요')
        return;
      }
      ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
        id: $('#creationDataId').val(),
        campId: $('#campId').val(),
        status: 'fail',
        msg: $('#msg').val(),
      }).then(() => {
        getPrevData();
        $('#returnDialog').dialog('close');
      });
    });
  }, []);

  return (
        <Fragment>
            <GdnCreationDataIdContext.Provider value={{ creationDataId, setCreationDataId }}>
                <PreviewImgDialog fileData={fileData}/>
            </GdnCreationDataIdContext.Provider>
                {
                    chunked.map((a: any, k: any) => (
                        <section className="wrap-section wrap-tbl" key={k}>
                          <div className="box-body">
                            <div className="tbl">
                              <dl className="vertical">
                                <dd >
                                    <div className="form-group">
                                        <div className="container-fluid" id="cardContent">
                                            <div className="row">
                                                {
                                                    a.map((aa: any, kk: any) => <div className="col col-4" key={kk}>
                                                        <div className="wrap-preview">
                                                            <div className="box-header">
                                                                <div className="box-tit">
                                                                    <span
                                                                        className="fz-14 fc-1">소재ID: {aa.creationData.id}</span>
                                                                </div>
                                                                {
                                                                    aa.creationData.wabizInspStatus === 'ing'
                                                                      ? <div className="box-option"
                                                                               id={`option${aa.creationData.id}`}>
                                                                            <button type="button"
                                                                                    className="btn btn-danger xsmall w-auto"
                                                                                    onClick={() => {
                                                                                      rejectClickEvent(aa.campId, aa.creationData.id);
                                                                                    }}>반려
                                                                            </button>
                                                                            <button type="button"
                                                                                    className="btn btn-primary xsmall w-auto"
                                                                                    onClick={() => {
                                                                                      confirmClickEvent(aa.creationData.id);
                                                                                    }}>승인
                                                                            </button>
                                                                        </div>
                                                                      : null
                                                                }
                                                                {
                                                                    aa.creationData.wabizInspStatus === 'ok' || aa.creationData.wabizInspStatus === 'fail'
                                                                      ? <div className="box-option">
                                                                            <button
                                                                                type="button"
                                                                                className={`btn ${aa.creationData.wabizInspStatus === 'ok' ? 'btn-primary-outline' : 'btn-danger-outline'} xsmall w-auto`}
                                                                                onClick={() => {
                                                                                  if (aa.creationData.wabizInspStatus === 'ok') rejectClickEvent(aa.campId, aa.creationData.id);
                                                                                  if (aa.creationData.wabizInspStatus === 'fail') confirmClickEvent(aa.creationData.id);
                                                                                }}>{aa.creationData.wabizInspStatus === 'ok' ? '승인' : '반려'}
                                                                            </button>
                                                                        </div>
                                                                      : null
                                                                }
                                                            </div>
                                                            <div className="box-body">
                                                                <div className="box-group">
                                                                    <img
                                                                        src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Google-01.png"
                                                                        alt="img1"/>
                                                                </div>
                                                                <div className="box-group">
                                                                    <div className="box-left">
                                                                        <img
                                                                            src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-60x17.png"
                                                                            alt="img1"/>
                                                                    </div>
                                                                </div>
                                                                <div className="box-group">
                                                                    <p className="fz-14 fc-1 fw-medium lh15"> {aa.adTitle01 === '' ? '' : aa.adTitle01}</p>
                                                                </div>
                                                                <div className="box-group">
                                                                    <p className="fz-14 fc-7 fw-medium lh15">{aa.adTitle02 === '' ? '' : aa.adTitle02}</p>
                                                                </div>
                                                                <div className="box-group">
                                                                    {
                                                                        aa.af.length === 0
                                                                          ? <div className="comp-no-data">
                                                                                <div className="box-group">
                                                                                    <i className="ico ico-no-img"></i>
                                                                                    <p className="fz-14 fc-2">이미지
                                                                                        혹은 영상을
                                                                                        선택해주세요.</p>
                                                                                </div>
                                                                            </div>
                                                                          : <div className="comp-thumb">
                                                                                <button type="button"
                                                                                        onClick={() => event(aa.creationData.id)}>
                                                                                  {aa.af[0].afMeta.fileDiv === 'VIDEO' && aa.af[0].afMeta.ext !== 'gif'
                                                                                      // eslint-disable-next-line jsx-a11y/media-has-caption
                                                                                      ?
                                                                                      <>
                                                                                        <div className="txt-state mint">동영상</div>
                                                                                        <div className="txt-state blue">{aa.af[0].afMeta.ext.toUpperCase()}</div>
                                                                                        <video
                                                                                            autoPlay
                                                                                            controls
                                                                                            muted
                                                                                            loop
                                                                                            style={{ width: '100%' }}
                                                                                        >
                                                                                          <source src={`${aa.af[0].fileName}`}/>
                                                                                        </video>
                                                                                      </>
                                                                                      :
                                                                                      <>
                                                                                        <div className="txt-state mint">이미지</div>
                                                                                        <div className="txt-state blue">{aa.af[0].afMeta.ext.toUpperCase()}</div>
                                                                                        <img
                                                                                            src={`${aa.af[0].fileName}`}
                                                                                            style={{ width: '100%' }}
                                                                                            alt="img22"/>
                                                                                      </>
                                                                                  }
                                                                                </button>
                                                                            </div>

                                                                    }
                                                                </div>
                                                                <div className="box-group">
                                                                    <p className="fz-12 fc-4 lh15">{aa.adDesc === '' ? '' : aa.adDesc}</p>
                                                                </div>
                                                                <div className="box-group flex-container">
                                                                    <div className="box-left">
                                                                                <span
                                                                                    className="fz-12 fc-1">와디즈</span>
                                                                    </div>
                                                                    <div className="box-right">
                                                                        <button type="button"
                                                                                className="btn btn-secondary-outline xsmall w-auto">{aa.clickBtnDiv}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </section>
                    ))
                }
        </Fragment>
  );
};

export default CreationDetailGdn;
