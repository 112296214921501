import { Typography, Tabs, type TabsProps } from 'antd';
import { Breadcrumb } from '../Breadcrumb';
import { AntdSection } from '../AntdSection';
import { DailyListTab } from './DailyListTab';
import { AdListTab } from './AdListTab';

const { Title } = Typography;

const tabItems: TabsProps['items'] = [
  {
    key: 'daily',
    label: '일자별 내역 보기',
    children: <DailyListTab />,
  },
  {
    key: 'ad',
    label: '광고별 내역 보기',
    children: <AdListTab />,
  },
];

export const SalesDpPush = () => (
  <AntdSection>
    <Breadcrumb path={['리포트 관리', 'DP/PUSH : 매출 리포트']} />
    <Title level={2} style={{ marginTop: 24 }}>
      {'DP/PUSH : 매출 리포트'}
    </Title>
    <Tabs items={tabItems} style={{ marginTop: 40 }} />
  </AntdSection>
);
