import React, {Fragment, useContext, useEffect, useRef} from 'react';
import {Dialog} from 'rc-easyui';
import {CatProhUpdateYnContext} from "./Make02Empty";

const CatProhKwdDialog = (props: any) => {
    const dialogRef = useRef<any>();
    const { setCatProhUpdateYn } = useContext(CatProhUpdateYnContext);

    const modifyEvent = () => {
        props.setIsCatProhKwdDialog(false);
        if(props.tabClickEvent !== undefined && props.tabClickEvent !== null) props.tabClickEvent('step03');
        setCatProhUpdateYn(true);
    }
    const ignoreInspReqEvent = () => {
        if(props.showSelfCheck) {
            props.setIsCatProhKwdDialog(false);
            props.setSelfCheckDialog(true);
        } else {
            props.inspValidEvent();
        }
    }
    return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{width: '600px'}}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="wrap-section wrap-tbl">
                                <div className="tbl">
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <p className="comp-txt center">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-18 fc-1 fw-medium txt-center">작성하신 소재에 수정이 필요한 문구가 있습니다.</b>
                                                            <b className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>[ {props.category} ] 카테고리는 <span className="fc-7">아래 기재된 단어 사용 시, 반려 사유</span>가 될 수 있습니다.</b>
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <div className="comp-help white">
                                                    <ul className="help-list">
                                                        <li className="list">
                                                            <i className="ico ico-help"></i>
                                                            <span className="fz-14 fc-2">{props.catProhKwds.join(' , ')}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="dialog-footer">
                                <button type="button" className="btn btn-secondary-outline" onClick={modifyEvent}>수정하기</button>
                                <button type="button" className="btn btn-primary" onClick={ignoreInspReqEvent}>무시하고 검수 요청</button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>

    );
};
export default CatProhKwdDialog;
