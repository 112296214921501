import alertify from 'alertifyjs'

/*
파일 관련 각종 밸리데이션
 */
const maxFileSize = 5242880
export const FileGgCreationValidator = (file: any) => {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/jpg' ||
    file.type === 'image/png' ||
    file.type === 'image/gif'
  ) {
    // 성공
  } else {
    alertify.error('다음의 확장자만 허용합니다.<br/>JPEG, JPG, PNG, GIF')
    return false
  }
  if (file.size > maxFileSize) {
    alertify.error('업로드 가능한 용량을 초과하였습니다.<br/>이미지 파일의 최대 크기는 5,120KB 입니다.')
    return false
  }

  return true
}

const errMsgSizeMin = () => {
  alertify.error(
    '이미지가 최소 픽셀사이즈보다 작습니다.<br/><br/>정사각형 최소 사이즈:300 x 300 픽셀<br/>가로형 최소 사이즈:600 x 314 픽셀'
  )
}
const errMsg = () => {
  alertify.error(
    '이미지의 사이즈가 규격에 맞지 않습니다.<br/><br/>가로형 (1.91:1)<br/>권장 1200 x 628 픽셀<br/>최소 600 x 314 픽셀<br/><br/>정사각형(1:1)<br/>권장 1200 x 1200 픽셀<br/>최소 300 x 300 픽셀'
  )
}
export const FileGgCreationSizeCheck = (file: any, callback: any) => {
  const image = new Image()

  const URL = window.URL || window.webkitURL
  const objectUrl = URL.createObjectURL(file)
  let width = 0
  let height = 0
  image.onload = function () {
    URL.revokeObjectURL(objectUrl)
    width = image.width
    height = image.height
    let imageDiv = ''
    if (width === height) {
      // 1:1 정사각형으로 판단
      if (width < 300 || height < 300) {
        errMsgSizeMin()
        callback(false)
        return
      }
      // if (width > 1200 || height > 1200) {
      //   errMsg();
      //   callback(false);
      //   return;
      // }
      imageDiv = 'SQUARE' // 정사각형
    } else if (Math.round((width / height) * 100) / 100 === 1.91) {
      // 가로 모드
      if (width < 600 || height < 314) {
        errMsgSizeMin()
        callback(false)
        return
      }
      // if (width > 1200 || height > 628) {
      //   errMsg();
      //   callback(false);
      //   return;
      // }
      imageDiv = 'NOT_SQUARE' // 가로모드 로고
    } else {
      errMsg()
      callback(false)
      return
    }
    URL.revokeObjectURL(objectUrl)
    callback(true, imageDiv, width, height, file.size)
  }
  image.src = objectUrl
}
export const FileValidationCreationGg = (file: any, callback: any) => {
  // eslint-disable-next-line no-restricted-syntax
  FileGgCreationSizeCheck(
    file,
    (checkStep1: boolean, imageDiv: string, width: number, height: number, fileSize: number) => {
      if (!checkStep1) {
        callback(false)
        return
      }
      const checkStep2 = FileGgCreationValidator(file)
      if (!checkStep2) {
        callback(false)
      }
      callback(true, file, imageDiv, width, height, fileSize)
    }
  )
}
