import React, {
  Fragment, useCallback, useContext, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useUsableCostQuery } from '@biz/query'
import Itg01 from '../../../../assets/images/Img-Insta-01.png'
import Itg02 from '../../../../assets/images/Img-Insta-02.png'
import Itg03 from '../../../../assets/images/Img-Insta-03.png'
import Itg04 from '../../../../assets/images/Img-Insta-04.png'
import IcoWabiz from '../../../../assets/images/Ico-logo-wadiz-48x48.png'
import MasonryLayout from './MasonryLayout'
import { AdminContext, CampIdContext, CampViewStatusContext, GdnCreationDataIdContext } from './Make02Empty'
import { gdnCreationFormData } from './Make02Step03CreationGdn'
import { IFileTypes } from '../../fileUploader/MultiFileUploaderGgLogo'
import { uploadFiles } from '../../fileUploader/FileUploaderGgCreation'
import alertify from 'alertifyjs'
import { getByteFunction } from '../../common/CommonFunc'
import { fbCreationFormData } from './Make02Step03CreationFb'
import { CampInspValids } from './Make02'

export interface itgCreationFormData {
  ok:boolean;
  campId:number;
  id:number;
  adDesc:string;
  sortOrder:number;
  clickBtnDiv:string;
  creationViewStatus:string;
  adSetDatas:any;
  preview:boolean;
  campBudget:number;
  isCampBudgetChange:boolean;
  changeCampBudget:number;
  clickBtnDivs: string[];
}
interface itgCreationFileForm {
  afId:number;
}

const itgClickBtnDivMap:any = {
  APPLY_NOW: '지금신청하기',
  RESERVE_NOW_FB: '지금 예약하기',
  DOWNLOAD_FB: '다운로드',
  GET_A_COUPON: '쿠폰 받기',
  LEARN_MORE_FB: '더 알아보기',
  ORDER_NOW: '지금 주문하기',
  REQUEST_RESERVATION: '예약 요청하기',
  SHOPPING_NOW: '지금 쇼핑하기',
  JOIN_FB: '가입하기',
  MORE_VIDEO: '동영상 더 보기',
  BUY_2: '구매하기',
  USE_THE_APP: '앱 사용하기',
  BUY_1: '구매하기',
};
const ItgAdSetCreatinoStatusMap:any = {
  temp: '등록대기',
  insp_none: '검수전',
  insp_ing: '검수중',
  reg: '등록완료 프로젝트',
  reg_insp: '재검수중',
  reject: '반려',
  stop_creation_off: '중단(소재OFF)',
  stop_ad_set_off: '중단(광고세트OFF)',
  stop_camp_off: '중단(캠페인OFF)',
  stop_camp_range: '중단(캠페인기간)',
};
const ItgAdSetCreatinoStatusTooltipMap:any = {
  insp_none: '아직 와디즈에 검수를 요청하지 않은 소재입니다. 우측 상단의 ‘검수요청‘ 버튼을 눌러 검수를 시작하세요',
  insp_ing: '와디즈에서 검수중입니다.',
  reg: '검수를 통과하였습니다.',
  reg_insp: '변경 소재를 와디즈에서 검수중입니다.',
  reject: '소재가 반려되었습니다. 소재 변경 후 재검수를 요청하세요. <br> * SNS 매체에서 반려되어 재검수 신청 후에도 장시간 반려상태가 유지되면 관리자에게 문의하세요.',
  stop_creation_off: '소재가 OFF되었습니다.',
  stop_ad_set_off: '광고세트가 OFF되었습니다.',
  stop_camp_off: '캠페인이 OFF되었습니다.',
  stop_camp_range: '프로젝트 기간이 변경되었습니다. 캠페인 기간을 재설정해주세요.',
};
interface itgAdSetCreationOnOffUpdate{
  ok:boolean;
  creationLinkId:number;
  useYn:boolean;
}
interface calculateBalance{
  ok:boolean;
  data:any;
  campId:number;
  changeCampBudget:number;
  cost:number;
}
interface CampBizmoneyInfo {
  ok: boolean;
  data:any;
  currentCost: number;
}
const Make02Step03CreationItg = (props:any) => {
  const { campIdParam } = useContext(CampIdContext);
  const { register, setValue, getValues } = useForm<gdnCreationFormData>();
  const { campViewStatusParam } = useContext(CampViewStatusContext);
  const { adminParam } = useContext(AdminContext);
  const { setGdnCreationDataId } = useContext(GdnCreationDataIdContext);
  const [previewMode, setPreviewMode] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>();
  const [previewFileDiv, setPreviewFileDiv] = useState<string>('IMG');
  const [previewExt, setPreviewExt] = useState<string>();
  const [creationStatusColor, setCreationStatusColor] = useState<any>('green');
  const [statusChangeBtn, setStatusChangeBtn] = useState<any>('변경요청');
  const [statusCreationView, setStatusCreationView] = useState<any>('');
  const [afList, setAfList] = useState<IFileTypes[]>([]);
  const [clickBtnDivsMap, setClickBtnDivsMap] = useState<any>(new Map());

  const itgCreationFileInfo = useForm<itgCreationFileForm>();
  // 각 광고세트별 소재 onoff
  const itgAdSetCreationOnOffUpdateForm = useForm<itgAdSetCreationOnOffUpdate>();
  // 광고세트1 on/off
  const [checkedInput1, setCheckedInput1] = useState<boolean>(false);
  // 광고세트2 on/off
  const [checkedInput2, setCheckedInput2] = useState<boolean>(false);
  // 광고세트3 on/off
  const [checkedInput3, setCheckedInput3] = useState<boolean>(false);

  const calculateBalanceForm = useForm<calculateBalance>();
  const { update: setUsableCostChange } = useUsableCostQuery()
  // 비즈머니
  const getBizmoney = async () => {
    const { data } = await ApiUtil.get<CampBizmoneyInfo>('/api/camp/make02/getBizmoney');
    return data;
  };
  const bizmoneyApi = useQuery(['getBizmoney'], getBizmoney, { enabled: false })

  const makerItgCreationFileList = async () => {
    const { data } = await ApiUtil2.get<uploadFiles>('/api/camp/make02/makerFbCreationFileList', {});
    return data;
  };

  const makerItgCreationFileListApi = useQuery(['makerFbCreationFileList'], makerItgCreationFileList, {
    enabled: false,
  })

  const dialogOpen = (refId:any) => {
    // 소재 creationDataId 세팅
    setGdnCreationDataId(refId);
    makerItgCreationFileListApi.refetch().then((r) => {
      if (r.isSuccess) {
        // 메이커 파일 데이터를 세팅
        props.setMakerFilesEvent(r.data.data);
      }
    });
    $('#creationFileUploadItgDialog').dialog({ width: 1200, title: '', closeOnEscape: true });
    $('#creationFileUploadItgDialog').dialog('open');
  };
  // 수정
  const itgCreationDataUpdateApi = () => {
    const {
      campId, id, adDesc, sortOrder, clickBtnDiv,
    } = getValues();
    const result = ApiUtil.post<fbCreationFormData>('/api/camp/make02/fbCreationDataUpdate', {
      campId, id, adDesc, sortOrder, clickBtnDiv,
    });
    return result;
  };
  const itgCreationDataUpdate = useMutation(itgCreationDataUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 수정
      }
    },
  });
  // 삭제
  const itgCreationDataDeleteApi = () => {
    const {
      campId, id,
    } = getValues();
    const result = ApiUtil2.post<fbCreationFormData>('/api/camp/make02/fbCreationDataDelete', {
      campId, id,
    });
    return result;
  };
  const itgCreationDataDelete = useMutation(itgCreationDataDeleteApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 삭제
        props.removeCreationItgEvent(props.order);
      }
    },
  });
  // 카피 처리
  const itgCreationDataCopyApi = () => {
    const {
      campId, id, adDesc, sortOrder, clickBtnDiv, isCampBudgetChange, changeCampBudget,
    } = getValues();
    const result = ApiUtil2.post<fbCreationFormData>('/api/camp/make02/fbCreationDataInsert', {
      campId, id, adDesc, sortOrder, clickBtnDiv, isCampBudgetChange, changeCampBudget,
    });
    return result;
  };
  const itgCreationDataCopy = useMutation(itgCreationDataCopyApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 카피
        // 상단 비즈머니 업데이트
        if (getValues('isCampBudgetChange')) {
          bizmoneyApi.refetch().then((r) => {
            if (r.isSuccess) {
              setUsableCostChange(r.data.data.currentCost.toLocaleString());
            }
          });
        }
        setValue('isCampBudgetChange', false);
        props.creationComponentRefresh();
      }
    },
  });
  // 소재 파일 삭제 처리
  const itgCreationFileDeleteApi = () => {
    const {
      afId,
    } = itgCreationFileInfo.getValues();
    const result = ApiUtil.post<any>('/api/camp/make02/fbCreationFileDelete', {
      afId,
    });
    return result;
  };
  const itgCreationFileDelete = useMutation(itgCreationFileDeleteApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
        props.creationComponentRefresh();
      }
    },
  });

  const itgAdSetCreationOnOffUpdateApi = () => {
    const {
      creationLinkId, useYn,
    } = itgAdSetCreationOnOffUpdateForm.getValues();
    const result = ApiUtil.post<itgAdSetCreationOnOffUpdate>('/api/camp/make02/fbAdSetCreationOnOffUpdate', {
      creationLinkId, useYn, campId: campIdParam,
    });
    return result;
  };
  const itgAdSetOnOffCreationUpdateEvent = useMutation(itgAdSetCreationOnOffUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
      }
    },
  });
  const calculateBalanceApi = async () => {
    const { data } = await ApiUtil2.get<calculateBalance>('/api/camp/make02/calculateBalance', { params: { campId: campIdParam, changeCampBudget: calculateBalanceForm.getValues('changeCampBudget') } });
    return data;
  };
  const calculateBalanceEvent = useQuery(['calculateBalanceApi'], calculateBalanceApi, {
    enabled: false,
  })
  const itgCreationLinkStatusUpdateReqApi = () => {
    const {
      id,
    } = getValues();
    const result = ApiUtil.post<itgAdSetCreationOnOffUpdate>('/api/camp/make02/fbCreationLinkStatusUpdateReq', {
      id, campId: campIdParam,
    });
    return result;
  };
  const itgCreationLinkStatusUpdateReqEvent = useMutation(itgCreationLinkStatusUpdateReqApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
        props.creationComponentRefresh();
      }
    },
  });

  const removeItgCreationComponent = () => {
    if (props.maxSize > 1) {
      alertify.confirm('해당 광고를 삭제하시겠습니까?', () => {
        itgCreationDataDelete.mutate();
      }).set({ labels: { cancel: '취소', ok: '삭제' } }).setHeader('');
    }
  };
  const updateItgCreationComponent = (event:any) => {
    if (event.currentTarget.id === `creationBody_${props.order}` || event.currentTarget.id === `updateModeCreation_${props.order}`) {
      setPreviewMode(false);
      setValue('preview', false);
      setGdnCreationDataId(getValues('id'));
    } else {
      setPreviewMode(true);
      setValue('preview', true);
    }
  };
  const previewItgCreationComponent = (event:any) => {
    if (event.currentTarget.value === props.order) {
      setPreviewMode(true);
      setValue('preview', true);
    }
  };
  const copyItgCreationComponent = () => {
    if (props.maxSize < 10) {
      setValue('sortOrder', props.maxSize + 1);
      // 기타 상태일 경우
      if (props.creationData.campBudget !== undefined && campViewStatusParam !== undefined && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete') {
        const chaBudget = (props.maxSize + 1) * 5000;
        // 현재 설정된 예산체크 추가했을때 예산이 초과되면??
        if (props.creationData.campBudget < chaBudget) {
          const changeCampBudgetTxt = (props.creationData.campBudget + 5000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          const changeCampBudget = props.creationData.campBudget + 5000;
          calculateBalanceForm.setValue('changeCampBudget', changeCampBudget);
          calculateBalanceEvent.refetch().then((r) => {
            if (r.isSuccess) {
              alertify.confirm(`소재당 1일 최소 5천원의 예산이 필요합니다. 1일 캠페인 예산을${changeCampBudgetTxt}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 비즈머니에서 ${r.data.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 (VAT포함)이 차감됩니다.`, () => {
                setValue('changeCampBudget', changeCampBudget);
                setValue('isCampBudgetChange', true);
                itgCreationDataCopy.mutate();
              }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
            }
          });
        } else {
          itgCreationDataCopy.mutate();
        }
      } else {
        itgCreationDataCopy.mutate();
      }
    } else {
      alertify.error('소재는 10개까지 추가 가능합니다.');
    }
  };
  const changeCreationData = () => {
    itgCreationDataUpdate.mutate();
  };

  // 등록된 소재 이미지 삭제 이벤트
  const creationFileDeleteEvent = (fileId:any) => {
    itgCreationFileInfo.setValue('afId', fileId);
    itgCreationFileDelete.mutate();
  };
  const setEvent = () => {
    if (campViewStatusParam === 'complete' || (!adminParam.isAdmin && adminParam.forAdmin)) {
      return;
    }
    $(`#removeCreation_${props.order}`).off('click').on('click', removeItgCreationComponent);
    $(`#copyCreation_${props.order}`).off('click').on('click', copyItgCreationComponent);
    $(`#previewCreation_${props.order}`).off('click').on('click', previewItgCreationComponent);
    $('[id^=creationBody_]').on('click', updateItgCreationComponent);
    $('[id^=updateModeCreation_]').on('click', updateItgCreationComponent);
  };

  const checkByteEvent = useCallback((id:any, value:any) => {
    const checkByteId = id;
    const checkByteValue = value;
    const registerName = id.split('_')[0];
    const byteTextHtml = document.getElementById(`${checkByteId}_Byte`) as HTMLInputElement;
    const maxLength = 2000;

    setValue(registerName, value);
    if (getByteFunction(checkByteValue) > maxLength) {
      alertify.alert('입력 최대치 도달', '더 이상 텍스트를 추가 할 수 없습니다.');
      return false;
    }
    byteTextHtml.innerText = getByteFunction(checkByteValue).toString();
    return true;
  }, []);

  // 소재별 검수 요청
  const creationInspValidData = async () => {
    const { data } = await ApiUtil2.get<CampInspValids>('/api/camp/make02/creationInspValid', { params: { campId: campIdParam, creationDataId: getValues('id') } });
    return data;
  };
  const creationInspValidDataApi = useQuery(['creationInspValidData'], creationInspValidData, {
    enabled: false,
  })
  const creationStatusUpdate = (e:any) => {
    const status = e.currentTarget.value;
    let statusChangeMsg = '';
    if (status === 'stop_creation_off' || status === 'stop_ad_set_off' || status === 'stop_camp_off' || status === 'stop_camp_range') {
      statusChangeMsg = '소재 변경을 신청합니다.<br/>중단 사유가 해제되야 노출이 가능합니다.';
    } else if (status === 'temp' || status === 'insp_none' || status === 'reject') {
      statusChangeMsg = '소재 검수를 신청합니다.';
    } else {
      statusChangeMsg = '소재 변경을 신청합니다.<br/>검수를 통과하면 신규 소재로 대체됩니다.';
    }
    alertify.confirm(statusChangeMsg, () => {
      if (afList.length === 0) {
        alertify.error('이미지를 추가해주세요.');
        return;
      }
      // 개별 검수 요청 로직 추가
      creationInspValidDataApi.refetch().then((r) => {
        if (r.isSuccess) {
          if (r.data.data.creationCnt !== r.data.data.creationCorrectCnt) {
            alertify.error(`소재의 검수가 통과되지 않았습니다. <br/>소재ID : ${getValues('id')}`);
          } else {
            itgCreationLinkStatusUpdateReqEvent.mutate();
          }
        }
      });
    }).set({ labels: { cancel: '취소', ok: '신청' } }).setHeader('');
  };

  $('.tooltip-btn').tooltip({
    content:'<p class="fz-12 fc-0">클립보드에 복사</p>'
  });

  const handleCopyClipBoard = (id: string) => {
    const dumTA = document.createElement('input');
    document.body.appendChild(dumTA);
    dumTA.value = id;
    dumTA.select();
    document.execCommand('copy');
    dumTA.remove();
    alertify.success('소재ID를 복사하였습니다.');
  };

  useEffect(() => {
    // 최초 진입시 모든 데이터 전달함
    if (campIdParam !== null) {
      setValue('campId', campIdParam);
    }
    setValue('id', props.creationData.id);
    setValue('adDesc', props.creationData.adDesc);
    setValue('sortOrder', props.creationData.sortOrder);
    setValue('clickBtnDiv', props.creationData.clickBtnDiv);
    setValue('creationViewStatus', props.creationData.creationViewStatus);
    setValue('preview', props.creationData.preview);
    setValue('adSetDatas', props.creationData.adSetDatas);
    setValue('clickBtnDivs', props.creationData.clickBtnDivs);
    setAfList(props.creationData.afList);
    setPreviewMode(props.creationData.preview);
    setStatusCreationView(props.creationData.creationViewStatus);
  }, []);

  useEffect(() => {
    $('.tooltip-btn > i').on('click',function (e:any){
      e.preventDefault();
      e.stopPropagation();
      const id = $(this).prop('id');
      handleCopyClipBoard(id);
    })

    // 광고세트별 소재상태에 대한 부분처리
    if (getValues('adSetDatas') !== undefined) {
      getValues('adSetDatas').filter((f:any) => `${f.creationDataId}` === `${props.creationData.id}`).forEach((data:any) => {
        $(`#dropdown_${props.order}`).on('click', (event) => {
          // 클릭시 드랍다운 창이 닫히기 때문에 해당 부분 처리
          event.stopPropagation();
        });
        // 여기서 소재별 on/off 이벤트 처리
        $(`#itgAdSetS${data.adSetOrder}_${props.order}_${data.id}`).on('change', (e) => {
          const { checked }:any = e.currentTarget;
          const adSetOrder:any = e.currentTarget.id.split('_')[0];
          const creationLinkId:any = e.currentTarget.id.split('_')[2];

          const msg = checked ? 'ON' : 'OFF';
          if (adSetOrder === 'itgAdSetS1') {
            setCheckedInput1(checked);
            alertify.success(`논타겟 광고세트 소재가 ${msg} 되었습니다.`);
          } else if (adSetOrder === 'itgAdSetS2') {
            setCheckedInput2(checked);
            alertify.success(`리타겟 광고세트 소재가 ${msg} 되었습니다.`);
          } else {
            setCheckedInput3(checked);
            alertify.success(`리타겟 광고세트(추가) 소재가 ${msg} 되었습니다.`);
          }
          itgAdSetCreationOnOffUpdateForm.setValue('creationLinkId', creationLinkId);
          itgAdSetCreationOnOffUpdateForm.setValue('useYn', checked);
          itgAdSetOnOffCreationUpdateEvent.mutate();
        });
        if (data.adSetOrder === 1) {
          setCheckedInput1(data.useYn);
        } else {
          setCheckedInput2(data.useYn);
        }
        const toolTipText = ItgAdSetCreatinoStatusTooltipMap[data.creationViewStatus];
        if (toolTipText !== undefined) {
          $(`#itgAdSet${data.adSetOrder}Tooltip_${props.order}`).tooltip({
            content: `<p class="fz-12 fc-0">${toolTipText}</p>`,
          });
        }
        // 소재 상태에 따른 색상 및 버튼 명칭 빨강 > 주황 > 녹색 순
        if (data.creationViewStatus === 'temp' || data.creationViewStatus === 'insp_none' || data.creationViewStatus === 'reject') {
          setCreationStatusColor('red');
          setStatusChangeBtn('검수요청');
          setStatusCreationView(data.creationViewStatus);
          return;
        }
        if (data.creationViewStatus === 'stop_creation_off' || data.creationViewStatus === 'stop_ad_set_off' || data.creationViewStatus === 'stop_camp_off' || data.creationViewStatus === 'stop_camp_range') {
          setCreationStatusColor('orange');
          setStatusChangeBtn('변경요청');
          setStatusCreationView(data.creationViewStatus);
        }
        setStatusCreationView(data.creationViewStatus);
      });
    }
  }, [getValues('adSetDatas')]);

  useEffect(() => {
    if (previewMode !== undefined && !props.creationData.clickBtnDivs !== undefined) {
      const clickBtnDivInfos: any[] = [];
      const tempArray = new Map(clickBtnDivsMap);
      props.creationData.clickBtnDivs.forEach((data:string) => {
        clickBtnDivInfos.push({ id: data, text: itgClickBtnDivMap[data] });
        tempArray.set(data, itgClickBtnDivMap[data]);
      });
      setClickBtnDivsMap(tempArray);
      $(`#itgActionButton_${props.order}`).select2({
        width: '150px',
        minimumResultsForSearch: Infinity,
        data: clickBtnDivInfos,
      }).on('select2:select', (e) => {
        setValue('clickBtnDiv', e.params.data.id);
        changeCreationData();
      });
      $(`#itgActionButton_${props.order}`).val(getValues('clickBtnDiv')).trigger('change');
      setEvent();
      MasonryLayout();
    }
    if (afList.length > 0) {
      afList.forEach((file, index) => {
        if (index === 0) {
          setPreviewImage(file.fileName);
          setPreviewFileDiv(file.afMeta.fileDiv);
          setPreviewExt(file.afMeta.ext);
        }
      });
    }
    // 파일 이미지 휴지통 버튼 클릭
    $(`#creationFileDelete_${props.order}`).on('click', () => {
      const imageActive = document.querySelectorAll('li.active');
      imageActive.forEach((list) => {
        if (list.id.startsWith('fileLiKey_')) {
          const delAfId = list.id.split('_')[1];
          creationFileDeleteEvent(delAfId);
        }
      });
    });
    if (getValues('adDesc') !== undefined) {
      $(`#adDesc_${props.order}_Byte`).text(getByteFunction(getValues('adDesc')));
    }
  }, [previewMode]);

  return (
      <Fragment>
        <div className="masonry-item" style={{ gridRowEnd: 'span 90' }}>
          <div className="wrap-preview">
            <div className="box-header">
              <div className="box-tit">
                {
                  (campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete')
                    ? <div className="comp-dropdown">
                        <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                          <span className="fz-12 fc-1 txt-underline">소재상태</span>
                          <span className={`badge ${creationStatusColor}`}></span>
                        </Link>
                        <div className="dropdown-menu" id={`dropdown_${props.order}`}>
                          <div className="opt-setting">
                            {getValues('adSetDatas') !== undefined
                              ? getValues('adSetDatas').map((data:any) => {
                                let statusColor = 'orange';
                                if (data.creationViewStatus === 'insp_ing' || data.creationViewStatus === 'reg' || data.creationViewStatus === 'reg_insp') {
                                  statusColor = 'green';
                                } else if (data.creationViewStatus === 'temp' || data.creationViewStatus === 'insp_none' || data.creationViewStatus === 'reject') {
                                  statusColor = 'red';
                                }
                                if (data.adSetOrder === 1) {
                                  return (
                                        <div className="box-group" key={data.id}>
                                          <div className="box-left">
                                    <span
                                        className="fz-12 fc-4">논타겟 광고세트</span>
                                          </div>
                                          <div className="box-right">
                                            <div className="box-txt">
                                              <div className="box-group">
                                                <span className={`badge ${statusColor}`}></span>
                                                <span
                                                    className="fz-12 fc-2">{ItgAdSetCreatinoStatusMap[data.creationViewStatus]}</span>
                                                <Link to="#!" className="ico-tooltip tooltip-f"
                                                      id={`fbAdSet${data.adSetOrder}Tooltip_${props.order}`}
                                                      title=""/>
                                              </div>
                                              <div className="box-group">
                                                <span className="fz-12 fc-4">SNS 소재 ID</span>
                                                <span className="fz-12 fc-2">{data.snsAdId === undefined ? '-' : data.snsAdId}</span>
                                                {data.snsAdId === undefined ?
                                                    '' : <button type="button"
                                                                 className="btn btn-secondary-txt btn-ico xsmall single tooltip-btn tooltip-f">
                                                      <i id={data.snsAdId} className="ico ico-copy"/>
                                                    </button>
                                                }
                                              </div>
                                            </div>
                                            <div className="comp-switch">
                                              <input type="checkbox"
                                                     id={`itgAdSetS${data.adSetOrder}_${props.order}_${data.id}`}
                                                     checked={checkedInput1}
                                                     readOnly={true}/>
                                              <label htmlFor={`itgAdSetS${data.adSetOrder}_${props.order}_${data.id}`}>
                                                <i className="ico"></i>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                  );
                                } if (data.adSetOrder === 2) {
                                  return (
                                        <div className="box-group" key={data.id}>
                                          <div className="box-left">
                                    <span
                                        className="fz-12 fc-4">리타겟 광고세트</span>
                                          </div>
                                          <div className="box-right">
                                            <div className="box-txt">
                                              <div className="box-group">
                                                <span className={`badge ${statusColor}`}></span>
                                                <span
                                                    className="fz-12 fc-2">{ItgAdSetCreatinoStatusMap[data.creationViewStatus]}</span>
                                                <Link to="#!" className="ico-tooltip tooltip-f"
                                                      id={`fbAdSet${data.adSetOrder}Tooltip_${props.order}`}
                                                      title=""/>
                                              </div>
                                              <div className="box-group">
                                                <span className="fz-12 fc-4">SNS 소재 ID</span>
                                                <span className="fz-12 fc-2">{data.snsAdId === undefined ? '-' : data.snsAdId}</span>
                                                {data.snsAdId === undefined ?
                                                    '' : <button type="button"
                                                                 className="btn btn-secondary-txt btn-ico xsmall single tooltip-btn tooltip-f">
                                                      <i id={data.snsAdId} className="ico ico-copy"/>
                                                    </button>
                                                }
                                              </div>
                                            </div>
                                            <div className="comp-switch">
                                              <input type="checkbox"
                                                     id={`itgAdSetS${data.adSetOrder}_${props.order}_${data.id}`}
                                                     checked={checkedInput2}
                                                     readOnly={true}/>
                                              <label htmlFor={`itgAdSetS${data.adSetOrder}_${props.order}_${data.id}`}>
                                                <i className="ico"></i>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                  );
                                }
                                return (
                                      <div className="box-group" key={data.id}>
                                        <div className="box-left">
                                    <span
                                        className="fz-12 fc-4">리타겟 광고세트 (추가)</span>
                                        </div>
                                        <div className="box-right">
                                          <div className="box-txt">
                                            <div className="box-group">
                                              <span className={`badge ${statusColor}`}></span>
                                              <span
                                                  className="fz-12 fc-2">{ItgAdSetCreatinoStatusMap[data.creationViewStatus]}</span>
                                              <Link to="#!" className="ico-tooltip tooltip-f"
                                                    id={`fbAdSet${data.adSetOrder}Tooltip_${props.order}`}
                                                    title=""/>
                                            </div>
                                            <div className="box-group">
                                              <span className="fz-12 fc-4">SNS 소재 ID</span>
                                              <span className="fz-12 fc-2">{data.snsAdId === undefined ? '-' : data.snsAdId}</span>
                                              {data.snsAdId === undefined ?
                                                  '' : <button type="button"
                                                               className="btn btn-secondary-txt btn-ico xsmall single tooltip-btn tooltip-f">
                                                    <i id={data.snsAdId} className="ico ico-copy"/>
                                                  </button>
                                              }
                                            </div>
                                          </div>
                                          <div className="comp-switch">
                                            <input type="checkbox"
                                                   id={`itgAdSetS${data.adSetOrder}_${props.order}_${data.id}`}
                                                   checked={checkedInput3}
                                                   readOnly={true}/>
                                            <label htmlFor={`itgAdSetS${data.adSetOrder}_${props.order}_${data.id}`}>
                                              <i className="ico"></i>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                );
                              })
                              : null }
                          </div>
                        </div>
                      </div>
                    : null
                }
              </div>
                {
                  (campViewStatusParam !== null && campViewStatusParam !== 'complete')
                    ? <div className="box-option">
                        { ((campViewStatusParam !== 'complete' && campViewStatusParam !== null && campViewStatusParam !== 'temp'))
                          ? <button type="button" className="btn btn-primary xsmall w-auto" value={statusCreationView} onClick={creationStatusUpdate}>
                                    {statusChangeBtn}
                                </button>
                          : null}
                            <div className="comp-dropdown open-right">
                                <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                                    <i className="ico ico-more"></i>
                                </Link>
                                <div className="dropdown-menu">
                                    <ul className="opt-selectbox">
                                        {previewMode
                                          ? <li className="opt-menu" id={`updateModeCreation_${props.order}`} value={props.order}>
                                                <p className="fc-1">편집</p>
                                            </li>
                                          : <li className="opt-menu" id={`previewCreation_${props.order}`} value={props.order}>
                                                <p className="fc-1">미리보기</p>
                                            </li>}
                                        <li className="opt-menu" id={`copyCreation_${props.order}`} value={props.order}>
                                            <p className="fc-1">복제</p>
                                        </li>
                                        <li className="opt-menu" id={`removeCreation_${props.order}`} value={props.order}>
                                            <p className="fc-1">삭제</p>
                                        </li>
                                        <li className="opt-menu">
                                            <p className="fc-1">소재ID : {props.creationData.id}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    : null
                }
            </div>
            {previewMode
              ? <div className="box-body" id={`creationBody_${props.order}`} key={props.order}>
                  <div className="box-group flex-container">
                    <div className="box-left">
                      <img src={Itg01} alt=""/>
                    </div>
                    <div className="box-right">
                      <img src={Itg02} alt=""/>
                    </div>
                  </div>
                  <div className="box-group">
                    <div className="box-left">
                      <img src={IcoWabiz} className="logo" alt=""/>
                    </div>
                    <div className="box-right">
                      <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                      <p className="fz-12 fc-3 lh15">광고</p>
                    </div>
                  </div>
                  {afList.length === 0 ? <div className="box-group">
                        <div className="comp-no-data">
                          <div className="box-group">
                            <i className="ico ico-no-img"></i>
                            <p className="fz-14 fc-2">이미지 혹은 영상을 선택해주세요.</p>
                          </div>
                        </div>
                      </div>
                    : <div className="box-group">
                        <div className="comp-thumb">
                          {
                            previewFileDiv === 'VIDEO' && previewExt !== 'gif'
                                ?
                                <>
                                  <div className="txt-state mint">동영상</div>
                                  {
                                    previewExt !== undefined ? <div className="txt-state blue">{previewExt.toUpperCase()}</div> : null
                                  }
                                  <video
                                      autoPlay
                                      controls
                                      muted
                                      loop
                                      style={{ width: '100%' }}
                                  >
                                    <source src={`${previewImage}`}/>
                                  </video>
                                </>
                                :
                                <>
                                  <div className="txt-state mint">이미지</div>
                                  {
                                    previewExt !== undefined ? <div className="txt-state blue">{previewExt.toUpperCase()}</div> : null
                                  }
                                  <img
                                      src={`${previewImage}`}
                                      // style={{ width: '100%' }}
                                      alt=""/>
                                </>
                          }
                        </div>
                      </div>
                  }
                  <div className="box-group flex-container">
                    <div className="box-right">
                      <p className="fz-12 fc-3 lh15"><i className="fz-12 fc-5">*</i> 이미지/영상 크기에 따라 미리보기에서 일부 표시되지 않으나 실제 광고 노출시에는 정상적으로 노출됩니다.</p>
                    </div>
                  </div>
                  <div className="box-group">
                    <div className="box-row flex-container">
                      <div className="box-left">
                        <span className="fz-14 fc-1 fw-medium lh15">{clickBtnDivsMap.get(getValues('clickBtnDiv'))}</span>
                      </div>
                      <div className="box-right">
                        <i className="ico ico-arrow"></i>
                      </div>
                    </div>
                    <div className="box-row border-top">
                      <img src={Itg03} alt=""/>
                    </div>
                  </div>
                  <div className="box-group">
                    <p className="fz-14 fc-1 fw-medium lh15 edit">
                      {
                        getValues('adDesc') !== ''
                          ? getValues('adDesc').split('\n').map((data, index) => (
                            // eslint-disable-next-line react/jsx-key
                            <p className="fz-14 fc-2 lh15 edit" key={index}>
                              {data}
                              <br />
                            </p>
                          )) : ''
                      }
                    </p>
                  </div>
                </div>
              : <div className="box-body">
                  <div className="box-group flex-container">
                    <div className="box-left">
                      <img src={Itg01} alt=""/>
                    </div>
                    <div className="box-right">
                      <img src={Itg02} alt=""/>
                    </div>
                  </div>
                  <div className="box-group">
                    <div className="box-left">
                      <img src={IcoWabiz} className="logo" alt=""/>
                    </div>
                    <div className="box-right">
                      <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                      <p className="fz-12 fc-3 lh15">광고</p>
                    </div>
                  </div>
                  <div className="box-group txt-right">
                    <button type="button" className="btn btn-secondary-txt btn-ico xsmall" id={props.creationData.id} onClick={(e:any) => dialogOpen(e.currentTarget.id)}><i
                        className="ico ico-more"></i>슬라이드
                      추가
                    </button>
                  </div>
                  {afList.length === 0 ? <div className="box-group" onClick={() => dialogOpen(props.creationData.id)} role="presentation">
                        <div className="comp-no-data">
                          <div className="box-group">
                            <i className="ico ico-no-img"></i>
                            <p className="fz-14 fc-7">이미지 혹은 영상을 등록해주세요.</p>
                          </div>
                        </div>
                      </div>
                    : <div className="box-group">
                        <div id="carousel-example-generic-01" className="carousel slide" data-ride="carousel" data-interval="false">
                          <ol className="carousel-indicators">
                            {afList.map((file:any, index:number) => {
                              const makeLikey = `fileLiKey_${file.id}`;
                              const active = index === 0 ? 'active' : '';
                              return (
                                  <li key={makeLikey} id={makeLikey} data-target="#carousel-example-generic-01" data-slide-to={index} className={active}>{index + 1}</li>
                              );
                            })}
                          </ol>
                          <div className="carousel-inner" role="listbox">
                            <i className="ico Ico-trash" id={`creationFileDelete_${props.order}`}></i>
                            {afList.map((file:any, index:number) => {
                              const makeFilekey = `fileKey_${file.id}`;
                              const fileIndex = (`00${index + 1}`).slice(-2);
                              const active = index === 0 ? 'active' : '';
                              const itemClass = `item item${fileIndex} ${active}`;
                              return (
                                  <Link to="#" key={makeFilekey} className={itemClass}>
                                    {
                                      file.afMeta.fileDiv === 'VIDEO' && file.afMeta.ext !== 'gif'
                                          ? <>
                                            <div className="txt-state mint">동영상</div>
                                            <div className="txt-state blue">{file.afMeta.ext.toUpperCase()}</div>
                                            <video
                                                autoPlay
                                                controls
                                                muted
                                                loop
                                                style={{ width: '100%' }}
                                            >
                                              <source src={`${file.fileName}`}/>
                                            </video>
                                          </>
                                          :
                                          <>
                                            <div className="txt-state mint">이미지</div>
                                            <div className="txt-state blue">{file.afMeta.ext.toUpperCase()}</div>
                                            <img
                                                src={`${file.fileName}`}
                                                // style={{ width: '100%' }}
                                                alt=""/>
                                          </>
                                    }
                                  </Link>
                              );
                            })}
                          </div>
                          {afList.length > 1
                            ? <><a className="left carousel-control" href="src/components/views/camp/make02/Make02Step03CreationItg#carousel-example-generic-01" role="button"
                                   data-slide="prev">
                                <span className="sr-only"></span>
                              </a><a className="right carousel-control" href="src/components/views/camp/make02/Make02Step03CreationItg#carousel-example-generic-01" role="button"
                                     data-slide="next">
                                <span className="sr-only"></span>
                              </a></>
                            : null
                          }
                        </div>
                      </div>
                  }
                  <div className="box-group flex-container">
                    <div className="box-right">
                      <p className="fz-12 fc-3 lh15"><i className="fz-12 fc-5">*</i> 이미지/영상 크기에 따라 미리보기에서 일부 표시되지 않으나 실제 광고 노출시에는 정상적으로 노출됩니다.</p>
                    </div>
                  </div>
                  <div className="box-group">
                    <div className="box-row flex-container">
                      <div className="box-left">
                        <span className="fz-12 fc-2">행동 유도 버튼</span>
                      </div>
                      <div className="box-right">
                        <select className="select2-single xsmall w-150" id={`itgActionButton_${props.order}`} name="itgActionButton">
                        </select>
                      </div>
                    </div>
                    <div className="box-row border-top">
                      <img src={Itg03} alt=""/>
                    </div>
                  </div>
                  <div className="box-group">
                    <div className="input-group expand">
                      <div className="inner-input-group">
                        <textarea className="tf-textarea" placeholder="광고를 설명할 문구를 입력하세요(선택)" {...register('adDesc')} id={`adDesc_${props.order}`} onBlur={changeCreationData} onChange={(event) => checkByteEvent(event.currentTarget.id, event.currentTarget.value)} />
                      </div>
                      <p className="fz-12 fc-3"><span className="fc-2" id={`adDesc_${props.order}_Byte`} >0</span> / 2,000</p>
                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                    </div>
                  </div>
                </div>
            }
            <div className="box-footer">
              <img src={Itg04} alt=""/>
            </div>
          </div>
        </div>
      </Fragment>
  );
};
export default Make02Step03CreationItg;
