import React, { Fragment, useEffect } from 'react';
import ReactHtmlParser from 'html-react-parser';

const FooterDialog = (props:any) => {
  useEffect(() => {
    $('#closeFooter').on('click', () => {
      $('#advApiRegDialog').dialog('close');
      props.setIsClicked(false);
    });
    return () => {
      $('#advApiRegDialog').dialog('destroy');
      props.setIsClicked(false);
    };
  }, []);

  return (
        <Fragment>
            <form>
                <div className="form-group">
                    <div id="advApiRegDialog" className="dialog" style={{ display: 'none' }}>
                        <div className="dialog-body">
                            <section className="wrap-section wrap-tbl wrap-terms">
                                <div className="box-header">
                                    <div className="box-tit">
                                        <h2 className="fz-20 fc-1 fw-bold">{props.systemDataDiv === 'PRIVATE_DATA' ? '개인정보 처리방침' : '광고서비스 이용약관' }</h2>
                                        <h3 className="fz-12 fc-3">{props.regTime}</h3>
                                    </div>
                                </div>
                                <div className="box-body" id="bs">
                                    <div className="tbl">
                                        <dl className="vertical">
                                            <dd>
                                                <div className="form-group">
                                                    <ul className="inner-content-terms">
                                                        <li>
                                                            <span className="fz-14">{ReactHtmlParser(props.privateText)}</span>
                                                            <span className="fz-14">{ReactHtmlParser(props.termsText)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-secondary-outline large" id="closeFooter">닫기</button>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};
export default FooterDialog;
