import React, {
  useEffect, Fragment, useRef, useState, useMemo, useCallback,
} from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import Pagination from '../../common/Pagination';
import NoDataTemplate from '../../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'
import FbDialog2 from './FbDialog2'
import ItgDialog2 from './ItgDialog2'
import GdnDialog2 from './GdnDialog2'
import { ApiUtil } from '@biz/api'
import SingleSelect2 from '../../accAdjust/SingleSelect2';
import {ICampListDetailSearchForm, IComponents2} from './MngAdCampListUtil';
import RangeDatePicker2 from '../../mngMd/RangeDatePicker2';
import { IComponents } from '../../accAdjust/AccAdjustTargetUtil';

interface IFileTypes {
    uploadId: number;
    fileId: number;
    publicUrl: string;
    afName: string;
    fileName: string;
    imageDiv: string;
    object: File;
}
interface ICampInfoCreation {
     creationDataId:number;
     creationLinkId:number;
     creationViewStatusDesc:string;
     clickBtnDiv:string;
     adTitle01:string;
     adTitle02:string;
     adDesc:string;
     sortOrder:number;
     mediaDiv4:string;
     afList:IFileTypes[];
}
interface ICampInfo {
    ok: boolean;
    data:any;
    campId:number;
    campNm: string;
    campStartTime: string;
    campEndTime: string;
    campViewStatusDesc:string;
    actYn: boolean;
    delYn: boolean;
    projectId:string;
    projectNm:string;
    memberName:string;
    makerId:number;
    makerLoginId:string;
    mediaDiv4Desc:string;
    mediaDiv4:string;
    adSetId:number;
    genderDiv:string[];
    location:any[];
    age:string;
    creations:ICampInfoCreation[];
    rptStartDate: string;
    rptEndDate: string;
    adSetTargetDiv: string;
}
const nodataRenderer = (props: ICellRendererParams) => (props.value === undefined || props.value === null || props.value === '' ? '-' : props.value);
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setCtrRenderer = (props: ICellRendererParams) => (props.data.ctr === undefined || props.data.ctr === 0 ? '-' : `${props.data.ctr}%`);
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setConvRateRenderer = (props: ICellRendererParams) => (props.data.convRate === undefined || props.data.convRate === 0 ? '-' : `${props.data.convRate}%`);
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setRoasRenderer = (props: ICellRendererParams) => (props.data.roas === undefined || props.data.roas === 0 ? '-' : `${Math.floor(props.data.roas)}%`);
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setCostPerConvRenderer = (props: ICellRendererParams) => (props.data.costPerConv === undefined || props.data.costPerConv === 0 ? '-' : `${props.data.costPerConv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);

const MngAdCampListDetail = () => {
  const setTitle = useOutletContext<any>();
  const { campId } = useParams();
  const { rptStartDate } = useParams();
  const { rptEndDate } = useParams();
  const navigate = useNavigate();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [row, setRow] = useState<any[]>();
  const { setValue, getValues } = useForm<ICampInfo>();
  const [campPerd, setCampPerd] = useState<string>('-');
  const [showFbDialog, setShowFbDialog] = useState<boolean>(false);
  const [showItgDialog, setShowItgDialog] = useState<boolean>(false);
  const [showGdnDialog, setShowGdnDialog] = useState<boolean>(false);
  // grid 요소
  const dialogOpen = (value: any) => {
    if (value.mediaDiv4 === 'FB' || value.mediaDiv4 === 'FBITG') setShowFbDialog(true);
    if (value.mediaDiv4 === 'ITG') setShowItgDialog(true);
    if (value.mediaDiv4 === 'GDN') setShowGdnDialog(true);
  };
  const fileIconClickEvent = (data:any) => {
    setRow(data);
    dialogOpen(data);
  };
  const updateItem = (id:any, res:any) => {
    const itemsToUpdate: any[] = [];
        gridRef.current!.api!.forEachNode((rowNode) => {
          const { data } = rowNode;
          if (data.id === id) {
            data.frequency = res === 0 ? '-' : res.toFixed(1);
          }
          itemsToUpdate.push(data);
        });
        gridRef.current!.api!.applyTransaction({ update: itemsToUpdate })!;
  };

  // 프리퀀시
  /** 프리퀀시 개별클릭 */
  const confirmFrequency = (e: any) => {
    // eslint-disable-next-line no-nested-ternary
    ApiUtil.get('/api/mngAd/camp/detailFrequency', {
      params: {
        creationLinkId: e.creationLinkId,
      },
    }).then((resp) => {
      updateItem(e.id, resp.data.data);
    });
  };

  const updateFrequencies = (rptData:any) => {
    if (rptData.length === 0) return;
    const itemsToUpdate: any[] = [];
        gridRef.current!.api!.forEachNode((rowNode, index) => {
          const { data } = rowNode;
          const { frequency } = rptData[index];
          data.frequency = frequency === 0 ? '-' : frequency.toFixed(1);
          // data.frequency = '-';
          itemsToUpdate.push(data);
        });
        gridRef.current!.api!.applyTransaction({ update: itemsToUpdate })!;
        $('#frequencyAll').prop('disabled', false);
  };
    const detailGridOld: IComponents2 = {
        searchUrl: '/api/mngAd/camp/list',
        colDefs: [
            {
                headerName: '미리보기',
                cellRenderer: (params: any) => {
                    const spanId = `creation_${params.data.creationDataId}`;
                    return <span id={spanId} onClick={() => fileIconClickEvent(params.data)} role={'presentation'}><i className="far fa-file"></i><span className="filename"></span></span>;
                },
            },
            {
                field: 'adTitle01', headerName: '소재명', minWidth: 200, cellRenderer: nodataRenderer,
            },
            { field: 'creationDataId', headerName: '소재ID' },
            { field: 'creationViewStatusDesc', headerName: '소재상태' },
        ],
        callback: (data: ICampListDetailSearchForm) => {
            const param = {
                campId: data.campId,
            };
            return param;
        },
    };
  const detailGrid: IComponents = {
    searchUrl: '/api/mngAd/camp/detailCreationsInfo',
    colDefs: [
      {
        headerName: '미리보기',
        cellRenderer: (params: any) => {
          const spanId = `creation_${params.data.creationDataId}`;
          return <span id={spanId} onClick={() => fileIconClickEvent(params.data)} role={'presentation'}><i className="far fa-file"></i><span className="filename"></span></span>;
        },
      },
      {
        field: 'adTitle01', headerName: '소재명', minWidth: 200, cellRenderer: nodataRenderer,
      },
      { field: 'creationDataId', headerName: '소재ID' },
      { field: 'creationViewStatusDesc', headerName: '소재상태' },
      {
        field: 'ctr', headerName: '클릭률(CTR)', cellRenderer: setCtrRenderer, minWidth: 150,
      },
      {
        field: 'convRate', headerName: '전환율', cellRenderer: setConvRateRenderer, minWidth: 150,
      },
      {
        field: 'costPerConv', headerName: '전환당 비용', cellRenderer: setCostPerConvRenderer, minWidth: 150,
      },
      {
        field: 'roas', headerName: '수익률(ROAS)', cellRenderer: setRoasRenderer, minWidth: 150,
      },
      {
        field: 'frequency',
        headerName: '게재 빈도',
        headerClass: 'ag-center-aligned-header',
        cellClass: 'ag-center-aligned-cell',
        minWidth: 150,
        // eslint-disable-next-line consistent-return
        cellRenderer: (params: any) => {
          if (params.data.frequency !== undefined) {
            return params.data.frequency === 0 ? '-' : params.data.frequency;
          }
          if (params.data.frequency === undefined || params.data.frequency) {
            // eslint-disable-next-line react/prop-types
            return <a id={`f-${params.data.id}`} className="txt-link frequency" href="#javascript" onClick={() => confirmFrequency(params.data)}>보기</a>;
          }
        },
      },
    ],
    searchTypes: [{ id: 'all', text: '선택하세요.', selected: true }, { id: 'nontarget', text: '논타겟' }, { id: 'retarget', text: '리타겟' }],
    callback: (data: ICampListDetailSearchForm) => {
      const param = {
        campId, rptStartDate: data.rptStartDate, rptEndDate: data.rptEndDate, adSetTargetDiv: data.adSetTargetDiv,
      };
      return param;
    },
  };
  const [columnDefs] = useState<IComponents>(detailGrid);
  const [columnDefsOld] = useState<IComponents2>(detailGridOld);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  }), []);

  /** 프리퀀시 전체보기 */
  const onFrequencyReady = useCallback(() => {
    $('#frequencyAll').prop('disabled', true);
    ApiUtil.get('/api/mngAd/camp/detailFrequencyAll', {
      params: {
        data: columnDefs.callback(getValues()),
      },
    }).then((resp) => {
      updateFrequencies(resp.data.data);
    });
  }, []);
  const loadDataCreation = useCallback(() => {
    ApiUtil2.get(columnDefs.searchUrl, {
      params: {
        data: columnDefs.callback(getValues()),
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
  const updateRptDate = (start:Dayjs, end:Dayjs) => {
    const startDate = start.format('YYYYMMDD');
    const endDate = end.format('YYYYMMDD');
    setValue('rptStartDate', startDate);
    setValue('rptEndDate', endDate);
    if (getValues('rptStartDate') !== undefined && rowData !== undefined) loadDataCreation();
  };
  const updateAdSetTargetDiv = (value: any) => {
    setValue('adSetTargetDiv', value);
    if (rowData !== undefined) loadDataCreation();
  };

  const changePagination = () => {
        childRef!.current!.onPaginationChanged();
  };
    // 캠페인 조회
  const getCampDatas = async () => {
    const { data } = await ApiUtil2.get<ICampInfo>('/api/mngAd/camp/detail/campListDetail', { params: { campId } });
    return data;
  };
  const getCampDatasApi = useQuery(['getCampDatas'], getCampDatas, {
    enabled: false,
  })
  const searchDetailInfo = () => {
    getCampDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        setValue('campId', r.data.data.campId);
        setValue('campNm', r.data.data.campNm);
        setValue('campStartTime', r.data.data.campStartTime);
        setValue('campEndTime', r.data.data.campEndTime);
        setCampPerd(`${dayjs(r.data.data.campStartTime).format('YYYY.MM.DD')} ~  ${dayjs(r.data.data.campEndTime).format('YYYY.MM.DD')}`);
        setValue('campViewStatusDesc', r.data.data.campViewStatusDesc);
        setValue('actYn',r.data.data.actYn);
        setValue('delYn',r.data.data.delYn);
        setValue('memberName', r.data.data.memberName);
        setValue('makerLoginId', r.data.data.makerLoginId);
        if (r.data.data.projectId !== undefined) {
          setValue('projectId', r.data.data.projectId);
          setValue('projectNm', r.data.data.projectNm);
        }
        setValue('mediaDiv4', r.data.data.mediaDiv4);
        setValue('mediaDiv4Desc', r.data.data.mediaDiv4Desc);
        setValue('genderDiv', r.data.data.genderDiv);
        setValue('location', r.data.data.location);
        setValue('age', r.data.data.age);
      }
    });
  };
  const goPrevEvent = () => {
    navigate('/mngAd/camp');
  };
  useEffect(() => {
    setTitle('캠페인 조회');
    return () => {
      setTitle('');
    };
  });
  useEffect(() => {
    if (campId !== undefined && getValues('campId') === undefined) {
      searchDetailInfo();
      loadDataCreation();
    }
  });
    /** 관리자 전용으로 소재 효율 볼 수 있게 세팅 (ctrl + alt + shift + o) */
    // const keys: boolean[] = [];
    // let keyDown = false;
    // const keysPressed = (e:any) => {
    //     if (keyDown) return;
    //     keys[e.keyCode] = true;
    //     if (keys[17] && keys[16] && keys[18] && keys[79]) {
    //         keyDown = true;
    //         if (!adminMode) {
    //             setAdminMode(true);
    //         } else {
    //             setAdminMode(false);
    //         }
    //         keyDown = false;
    //     }
    // };
    // const keysReleased = (e:any) => {
    //     keys[e.keyCode] = false;
    // };
    // $(document).bind('keydown', (e) => {
    //     keysPressed(e);
    // });
    // $(document).bind('keyup', (e) => {
    //     keysReleased(e);
    // });

    return (
        <Fragment>
            <div className="wrap-section wrap-tbl">
                <div className="box-header">
                    <div className="box-tit">
                        <button type="button" className="btn btn-secondary-outline" onClick={goPrevEvent}>이전 목록으로</button>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="vertical">
                            <dd>
                                <div className="form-group">
                                    <div className="wrap-section wrap-tbl">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">캠페인 정보</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                캠페인명
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('campNm') || '-'} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                캠페인 ID
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('campId') || '-'} disabled/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                기간
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={campPerd} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                상태
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('actYn')? getValues('campViewStatusDesc') : "삭제"} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                메이커명
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('memberName') || '-'} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                메이커 ID
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('makerLoginId') || '-'} disabled/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                프로젝트명
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('projectNm') || '-'} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                프로젝트 ID
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('projectId') || '-'} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap-section wrap-tbl">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">타겟 정보</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                노출 매체
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('mediaDiv4Desc') || '-'} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                타겟 성별
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('genderDiv') || '-'} disabled/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                타겟 지역
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('location') || '-'} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                            <span className="fz-16 fc-1">
                                                                타겟 나이
                                                            </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" value={getValues('age') || '-'} disabled/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            {
                <section className="wrap-section wrap-datagrid">
                        <div className="box-header">
                            <div className="box-tit">
                                <div className="filter-tit">
                                    <p className="fz-12 fc-2">대상 세트</p>
                                </div>
                                <div className="box-filter">
                                    <SingleSelect2 data={detailGrid.searchTypes} selectEvent={updateAdSetTargetDiv} clss={'w-150'} />
                                </div>
                                <div className="filter-tit">
                                    <p className="fz-12 fc-2">성과조회기간  <Link to="#" className="ico-tooltip tooltip-f" title="" id="rptPerdTooltip"></Link></p>
                                </div>
                                <div className="box-filter">
                                    <RangeDatePicker2 changeEvent={updateRptDate} option={{ startDate: dayjs(rptStartDate), endDate: dayjs(rptEndDate) }}/>
                                </div>
                            </div>
                            <div className="box-option">
                                <button id="frequencyAll" type="button" className="btn btn-primary-outline" onClick={onFrequencyReady} disabled={rowData === undefined}>게재빈도 전체보기</button>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="ag-grid">
                                <div className="ag-grid-inner">
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={rowData}
                                        columnDefs={columnDefs.colDefs}
                                        defaultColDef={defaultColDef}
                                        rowHeight={48}
                                        pagination={true}
                                        paginationPageSize={10}
                                        suppressPaginationPanel={true}
                                        onPaginationChanged={changePagination}
                                        domLayout={'autoHeight'}
                                        noRowsOverlayComponent={NoDataTemplate}
                                    ></AgGridReact>
                                </div>
                            </div>
                        </div>
                        <div className="box-footer">
                            <Pagination gridRef={gridRef} ref={childRef}/>
                        </div>
                        {
                            showFbDialog ? <FbDialog2 row={row} setShowFbDialog={setShowFbDialog}/> : null
                        }
                        {
                            showItgDialog ? <ItgDialog2 row={row} setShowItgDialog={showItgDialog}/> : null
                        }
                        {
                            showGdnDialog ? <GdnDialog2 row={row} setShowGdnDialog={showGdnDialog}/> : null
                        }
                    </section>
            }

        </Fragment>
  );
};
export default MngAdCampListDetail;
