import { Fragment, useState } from 'react'
import { ApiUtil2 } from '@biz/api'
import FooterDialog from '../../views/community/systemData/FooterDialog'
import { ISystemData } from '../../views/community/systemData/SystemData'

const Footer = () => {
  const [isClicked, setIsClicked] = useState(false)
  const [privateText, setPrivateText] = useState<string>('')
  const [termsText, setTermsText] = useState<string>('')
  const [systemDataDiv, setSystemDataDiv] = useState<string>('')
  const [regTime, setRegTime] = useState<string>('')

  const getRecentPrivateData = () => {
    ApiUtil2.get<ISystemData>('/api/makerCommon/setting/getRecentPrivateData').then((resp) => {
      $('#advApiRegDialog').dialog({ width: 1200, title: '개인정보 처리방침', closeOnEscape: true })
      const temp = resp.data.data.text
      setRegTime(resp.data.data.regTime)
      setSystemDataDiv(resp.data.data.systemDataDiv)
      setTermsText('')
      setPrivateText(temp)
      $('#advApiRegDialog').dialog('open')
    })
  }

  const getRecentTermsData = () => {
    ApiUtil2.get<ISystemData>('/api/makerCommon/setting/getRecentTermsData').then((resp) => {
      $('#advApiRegDialog').dialog({ width: 1200, title: '광고 서비스 이용약관', closeOnEscape: true })
      const temp = resp.data.data.text
      setRegTime(resp.data.data.regTime)
      setSystemDataDiv(resp.data.data.systemDataDiv)
      setPrivateText('')
      setTermsText(temp)
      $('#advApiRegDialog').dialog('open')
    })
  }

  return (
    <Fragment>
      <div className="box-left">
        <ul className="company-info">
          <li>
            <span className="fz-13">와디즈㈜</span>
          </li>
          <li>
            <span className="fz-13">대표이사 : 신혜성</span>
          </li>
          <li>
            <span className="fz-13">사업자등록번호 : 258-87-01370</span>
          </li>
          <li>
            <span className="fz-13">주소 : 경기 성남시 분당구 판교로 242 PDC A동 402호</span>
          </li>
        </ul>
      </div>
      <div className="box-right f-right">
        {isClicked ? (
          <FooterDialog
            setIsClicked={setIsClicked}
            privateText={privateText}
            termsText={termsText}
            regTime={regTime}
            systemDataDiv={systemDataDiv}
          />
        ) : null}
        <ul className="footer-menu">
          <li>
            <a
              href="#javascript"
              className="fz-13"
              onClick={() => {
                setIsClicked(true)
                getRecentTermsData()
              }}
            >
              이용 약관
            </a>
          </li>
          <li>
            <a
              href="#javascript"
              className="fz-13"
              onClick={() => {
                setIsClicked(true)
                getRecentPrivateData()
              }}
            >
              개인정보 처리방침
            </a>
          </li>
          {/* fixme. 디자인 깨짐 */}
          {/* <li>
                        <a href="#javascript" className="fz-13">1:1 문의하기</a>
                    </li> */}
        </ul>
      </div>
    </Fragment>
  )
}
export default Footer
