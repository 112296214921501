import { Breadcrumb as BreadcrumbAntd } from 'antd';
import { ReactComponent as ChevronRight } from './chevronRight.svg';

type BreadcrumbProps = {
  path: string[];
};
export const Breadcrumb: React.FC<BreadcrumbProps> = ({ path }) => (
  <BreadcrumbAntd
    items={path.map((p) => ({ title: p }))}
    separator={
      <ChevronRight
        style={{ fill: '#868E96', width: '16px', height: '22px' }}
      />
    }
  />
);
