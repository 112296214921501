import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Dialog} from "rc-easyui";
import {AgGridReact} from "ag-grid-react";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from "../common/NoDataTemplate";
export interface IPomInquiryProcess {
    projectNm: string;
    histories:[];
    data: any;
}
const PomInquiryProcessDialog = (props:any) => {
    const dialogRef = useRef<any>();
    const [projectNm, setProjectNm] = useState('');
    const [histories, setHistories] = useState([]);
    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'updateTime',
            headerName: '상태 변경 일시',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',

        },
        {
            field: 'status',
            headerName: '처리 상태',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'comment',
            headerName: '사유',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 200,
            cellRenderer: (props: ICellRendererParams) => {
                const pomInquiryHisId = props.data.pomInquiryHisId;
                const comment = props.data.comment !== ('' || null || undefined) ? props.data.comment : '-';
                const tooltip = <div id={pomInquiryHisId}
                                     style={{textOverflow: 'ellipsis',
                                         whiteSpace: 'nowrap',
                                         overflow: 'hidden',}}>{comment}</div>
                $('#'+`${pomInquiryHisId}`).tooltip({
                    content: '<p class="fz-12 fc-0">'+comment+'</p>'
                });
                return (
                    tooltip
                )
            },
        },
    ]);
    const onGridReady = () => {
        ApiUtil2.get<IPomInquiryProcess>('/api/pom/pomInquiry/process?pomInquiryId='+props.pomInquiryId
        ).then((resp) => {
            setProjectNm(resp.data.data.projectNm);
            setHistories(resp.data.data.histories);
        });
    };
    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(()=>{
        onGridReady();
    }, [])

    useEffect(() => {
        /** 다이얼로그 */
        $(document).on('click', '#cancelBtn', () => {
            props.setIsPomInquiryProcessDialogOpened(false);
        });
    }, []);
    return (
        <Fragment>
            <form>
                <Dialog title="처리 상태" modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    선택 항목
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <p className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-4">{projectNm}</b>
                                                    </span>
                                                </span>
                                                </p>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                                <div className="wrap-section wrap-tbl">
                                    <div className="box-header">
                                        <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        히스토리
                                    </span>
                                        </div>
                                    </div>
                                    <div className="box-body" style={{maxHeight: 500, overflow: 'auto'}}>
                                        <div className="ag-grid">
                                            <div className="ag-grid-inner">
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={histories}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={defaultColDef}
                                                    rowHeight={48}
                                                    suppressPaginationPanel={true}
                                                    domLayout={'autoHeight'}
                                                />
                                                { histories === undefined ? <NoDataTemplate/> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-primary" id={'cancelBtn'}>확인</button>
                    </div>
                </Dialog>
            </form>
        </Fragment>
    );
};

export default PomInquiryProcessDialog;
