import React from 'react';

export function setRef<T = Element>(ref: React.RefCallback<T> | React.MutableRefObject<T | null> | null, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/utils/useForkRef.js
// https://react-hooks.org/docs/use-fork-ref
export function useForkRef<T = Element>(
  refA: React.RefCallback<T> | React.MutableRefObject<T | null> | null,
  refB: React.RefCallback<T> | React.MutableRefObject<T | null> | null,
) {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior
   */
  return React.useMemo(() => {
    if (refA === null && refB === null) {
      return null;
    }
    return (refValue: T) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}

export default useForkRef;
