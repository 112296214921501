import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PomStatusTab from './PomStatusTab'
import { useOutletContext } from 'react-router'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from '../common/NoDataTemplate'
import Pagination from '../common/Pagination'
import PomInquiryDetailDialog from './PomInquiryDetailDialog'
import PomInquiryModificationDialog from './PomInquiryModificationDialog'
import PomInquiryProcessDialog from './PomInquiryProcessDialog'

export interface IPomInquiryResp {
  regTime: string
  userNo: string
  projectNm: string
  status: string
  comment: string
  data: any[]
}

const PomInquiry = () => {
  /** 다이얼로그 플래그 */
  const [isPomInquiryDetailDialogOpened, setIsPomInquiryDetailDialogOpened] = useState<boolean>(false)
  const [isPomInquiryModificationDialogOpened, setIsPomInquiryModificationDialogOpened] = useState<boolean>(false)
  const [isPomInquiryProcessDialogOpened, setIsPomInquiryProcessDialogOpened] = useState<boolean>(false)
  const [pomInquiryId, setPomInquiryId] = useState<number>()

  const setTitle = useOutletContext<any>()
  const childRef = useRef<any>(null)
  const gridRef = useRef<AgGridReact>(null)
  const statusRef = useRef(null)
  const [rowData, setRowData] = useState<any[]>()
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: '등록 일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'userNo',
      headerName: '사업자등록번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'projectNm',
      headerName: '프로젝트명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: (props: any) => {
        return (
          <span>
            <a
              onClick={() => {
                setPomInquiryId(props.data.pomInquiryId)
                setIsPomInquiryDetailDialogOpened(true)
              }}
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              {props.data.projectNm}
            </a>
          </span>
        )
      },
    },
    {
      field: 'status',
      headerName: '처리 상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: (props: any) => {
        const status = props.data.status
        statusRef.current = status
        const render = (text: string) => (
          <span>
            <a
              onClick={() => {
                setPomInquiryId(props.data.pomInquiryId)
                setIsPomInquiryProcessDialogOpened(true)
              }}
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              {text}
            </a>
          </span>
        )
        if (statusRef.current === 'PERMITTED') return render('승인')
        if (statusRef.current === 'REJECTED') return render('반려')
        if (statusRef.current === 'WAITING') return render('대기')
        if (statusRef.current === 'HOLDING') return render('보류')
      },
    },
    {
      field: 'comment',
      headerName: '사유',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: (props: any) => {
        const project2Id = props.data.project2Id
        const comment = props.data.comment
        const tooltip = (
          <div id={project2Id} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {comment !== ('' || null || undefined) ? comment : '-'}
          </div>
        )
        $('#' + `${project2Id}`).tooltip({
          content: '<p class="fz-12 fc-0">' + comment === ('' || null) ? comment : '-' + '</p>',
        })
        return tooltip
      },
    },
    {
      field: 'function',
      headerName: '기능',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: (props: any) => {
        const functionHandler = () => {
          setPomInquiryId(props.data.pomInquiryId)
          setIsPomInquiryModificationDialogOpened(true)
        }
        if (props.data.status === 'HOLDING') {
          return (
            <button className="btn btn-primary xsmall" onClick={functionHandler}>
              수정
            </button>
          )
        }
        return (
          <button className="btn btn-primary xsmall" disabled={false} onClick={functionHandler}>
            수정
          </button>
        )
      },
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )

  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  const onGridReady = useCallback(() => {
    ApiUtil2.get<IPomInquiryResp>('/api/pom/pomInquiry/list').then((resp) => {
      setRowData(resp.data.data)
    })
  }, [])
  useEffect(() => {
    setTitle('한도관련 문의 내역')
    onGridReady()
    return () => {
      setTitle('')
    }
  }, [])

  return (
    <Fragment>
      <PomStatusTab />
      {isPomInquiryDetailDialogOpened ? (
        <PomInquiryDetailDialog
          setIsPomInquiryDetailDialogOpened={setIsPomInquiryDetailDialogOpened}
          pomInquiryId={pomInquiryId}
        />
      ) : null}
      {isPomInquiryModificationDialogOpened ? (
        <PomInquiryModificationDialog
          setIsPomInquiryModificationDialogOpened={setIsPomInquiryModificationDialogOpened}
          pomInquiryId={pomInquiryId}
          onGridReady={onGridReady}
        />
      ) : null}
      {isPomInquiryProcessDialogOpened ? (
        <PomInquiryProcessDialog
          setIsPomInquiryProcessDialogOpened={setIsPomInquiryProcessDialogOpened}
          pomInquiryId={pomInquiryId}
        />
      ) : null}
      <div className="comp-tab-content selected">
        <section className="wrap-section wrap-datagrid">
          <div className="box-body">
            <div className="ag-grid">
              <div className="ag-grid-inner">
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowHeight={48}
                  pagination={true}
                  paginationPageSize={10}
                  suppressPaginationPanel={true}
                  onPaginationChanged={changePagination}
                  domLayout={'autoHeight'}
                  noRowsOverlayComponent={NoDataTemplate}
                ></AgGridReact>
              </div>
            </div>
          </div>
          <div className="box-footer">
            <Pagination gridRef={gridRef} ref={childRef} />
          </div>
          {rowData === undefined ? <NoDataTemplate /> : null}
        </section>
      </div>
    </Fragment>
  )
}

export default PomInquiry
