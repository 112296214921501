import React, {Fragment, useContext, useEffect, useRef, useState,} from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';
import {useMutation, useQuery} from '@tanstack/react-query' // try next react-query;
import {PlainObject} from 'select2';
import NumberFormat from 'react-number-format';
import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useUsableCostQuery } from '@biz/query'
import {
  AdminCampYnChangeContext,
  AdminContext,
  CampIdContext,
  CampMediaDiv4Context,
  CampTabClickContext,
  CampValidDataContext,
  CampViewStatusContext,
  GdnCreationDataIdContext,
  ProjectIdContext,
} from './Make02Empty'
import alertify from 'alertifyjs'
import Make02InspReqValidation from './Make02InspReqValidation'
import CampProjectSelectDialog from './CampProjectSelectDialog'
import CampBudgetReduceDialog from './CampBudgetReduceDialog'
import { useNavigate } from 'react-router'
import AdUpdateDialog from './AdUpdateDialog'
import CreationDataEditingValid from './Make02CreationDataEditingValid'
import Make02SelfCheckDialog from './Make02SelfCheckDialog'

interface MdCamp {
  ok: boolean
  campId: number
  campNm: string
  campGoalDiv: string
  campStartTime: string
  campEndTime: string
  // beforeCampStartTime: string;
  // beforeCampEndTime: string;
  campBudgetTotal1: number
  adminCampYn: boolean
  mediaDiv4: string
  projectId: string
  projectFundingDiv: string
  useYn: boolean
  campBudgetChange: boolean
  cost: number
  field: string
  wadizProjectNmText: string
  campUpdateDiv: string
  payType: string
  // beforeProjectId:string;
}
interface MdCampData {
  ok: boolean
  campId: number
  campNm: string
  campGoalDiv: string
  campStartTime: string
  campEndTime: string
  campBudgetTotal1: number
  adminCampYn: boolean
  mediaDiv4: string
  campViewStatus: string
  useYn: boolean
  creationDataCnt: number
  projectId: string
  projectFundingDiv: string
  projectNm: string
  projectDiv: string
  adPossStartTime: string
  adPossEndTime: string
  rcsPostTime: string
  rcsEndTime: string
  payType: string
}

interface MdCampBefore {
  beforeCampStartTime: string
  beforeCampEndTime: string
  beforeProjectId: string
  beforeProjectFundingDiv: string
  beforeProjectAdPossStartDate: string
  beforeProjectAdPossEndDate: string
  beforeProjectRcsStartDate: string
  beforeProjectRcsEndDate: string
}
interface MdCampDatas {
  ok: boolean
  data: MdCampData
}
export interface Project {
  id: string
  projectNm: string
  projectDiv: string
  adPossStartDate: string
  adPossEndDate: string
  rcsPostDate: string
  rcsEndDate: string
  adPossStartTime: string
  adPossEndTime: string
  rcsPostTime: string
  rcsEndTime: string
  userNo: string
  bizDiv: string
}

interface CampInspValid {
  ok: boolean
  projectValid: boolean
  campPeriodValid: boolean
  campBudgetValid: boolean
  creationCnt: number
  creationCorrectCnt: number
  campDepFees: number
  depBufRate: number
  minCampBudgetTotal1: number
}
export interface CampInspValids {
  ok: boolean
  data: CampInspValid
}
export interface PomValid {
  ok: boolean
  data: boolean
}
interface campOnOffUpdate {
  ok: boolean
  data: any
  campId: number
  useYn: boolean
}
const CampViewStatusMap: any = {
  temp: '임시저장',
  insp_req: '검수요청',
  invalid: '수정필요',
  wait: '진행대기',
  ing_update: '진행(수정필요)',
  ing_insp: '진행(검수중)',
  ing: '진행',
  stop_creation_off: '중단(소재OFF)',
  stop_camp_off: '중단(캠페인OFF)',
  stop_camp_range: '중단(캠페인기간)',
  complete: '종료',
}
const CampViewStatusTooltipMap: any = {
  insp_req: '검수가 요청된 상태입니다.',
  invalid: '일부 수정이 필요한 소재가 있습니다.',
  wait: '모든 소재가 정상이며 시작일이 도래하면 캠페인이 진행됩니다.',
  ing_update: '캠페인이 정상적으로 진행 중이나 일부 수정이 필요한 소재가 있습니다.',
  ing_insp: '캠페인이 정상적으로 진행 중이며 변경된 소재를 검수하고 있습니다.',
  ing: '캠페인이 정상적으로 진행 중입니다.',
  stop_creation_off: '모든 소재 혹은 모든 광고세트가 OFF되어 캠페인이 중단되었습니다.',
  stop_camp_off: '캠페인이 OFF되어 캠페인이 중단되었습니다.',
  stop_camp_range: '프로젝트 기간이 변경되었습니다. 캠페인 기간을 재설정해주세요.',
  complete: '캠페인이 종료되었습니다.',
}
interface calculateBalance {
  ok: boolean
  data: any
  campId: number
  changeCampBudget: number
  cost: number
  rptTotalCost: number
  adSpendCost: number
  reducedBudget: number
  afterCampBudgetTotal: number
  additionalCharge: boolean
}
interface CampBizmoneyInfo {
  ok: boolean
  data: any
  currentCost: number
}
const Make02 = () => {
  const navigate = useNavigate()
  const { setMediaDiv4Param } = useContext(CampMediaDiv4Context)
  const { campIdParam, setCampIdParam } = useContext(CampIdContext)
  const { adminParam, setAdminParam } = useContext(AdminContext)
  const { projectIdParam, setProjectIdParam } = useContext(ProjectIdContext)
  const { setTabClick } = useContext(CampTabClickContext)
  const { campValidData, setCampValidData } = useContext<any>(CampValidDataContext)
  const { register, setValue, getValues } = useForm<MdCamp>()
  const MdCampBeforeForm = useForm<MdCampBefore>()
  const { campViewStatusParam, setCampViewStatusParam } = useContext(CampViewStatusContext)
  const [campName, setCampName] = useState('')
  // const [campPeriod, setCampPeriod] = useState('');
  const [campGoalConversionActive, setCampGoalConversionActive] = useState(true)
  const [campGoalLinkClicksActive, setCampGoalLinkClicksActive] = useState(false)
  // const [campBudgetTxt, setCampBudgetText] = useState('10,000');
  // const [campBudgetTotal, setCampBudgetTotal] = useState('20,000');
  const [wadizFundingText, setWadizFundingText] = useState('')
  const [wadizFundingPerdText, setWadizFundingPerdText] = useState<string>('')
  const [beforeCampBudget, setBeforeCampBudget] = useState<any>(0)
  const [campViewStatusDescription, setCampViewStatusDescription] = useState('')
  const [useYnChecked, setUseYnChecked] = useState(false)
  const [creationDataCnt, setCreationDataCnt] = useState(0)
  // const [projectFundingDiv2, setProjectFundingDiv2] = useState('');
  const [projectSelectDialog, setProjectSelectDialog] = useState(false)
  const campOnOffUpdateForm = useForm<campOnOffUpdate>()
  const projectSelect = useRef<HTMLSelectElement>(null)
  const campInspRef = useRef<any>(null)
  const creationDataValidRef = useRef<any>(null)
  const { adminCampYnChangeCnt, setAdminCampYnChangeCnt } = useContext(AdminCampYnChangeContext)
  const { update: setUsableCostChange } = useUsableCostQuery()
  const { setGdnCreationDataId } = useContext(GdnCreationDataIdContext)
  const [projectAdPossStartDate, setProjectAdPossStartDate] = useState<any>(null)
  const [projectAdPossEndDate, setProjectAdPossEndDate] = useState<any>(null)
  const [projectRcsStartDate, setProjectRcsStartDate] = useState<any>(null)
  const [projectRcsEndDate, setProjectRcsEndDate] = useState<any>(null)
  const [campInfoRenderingOk, setCampInfoRenderingOk] = useState<boolean>(false)
  const [projectInfoRenderingOk, setProjectInfoRenderingOk] = useState<boolean>(false)
  const [projectDivFormShow, setProjectDivFormShow] = useState<boolean>(false)
  const [projectPerdFormShow, setProjectPerdFormShow] = useState<boolean>(false)
  const [initOk, setInitOk] = useState<boolean>(false)
  // 프로젝트 관련
  const [projectItems, setProjectItems] = useState<Project[]>([])
  const [projectNm, setProjectNm] = useState('')
  const [projectDivSelected, setProjectDivSelected] = useState<string>('')
  const [projectValidErrYn, setProjectValidErrYn] = useState<boolean>(false)
  const [projectDisabledYn, setProjectDisabledYn] = useState<boolean>(false)

  const [campBudgetReduceDialog, setCampBudgetReduceDialog] = useState<boolean>(false)
  const [rptTotalCost, setRptTotalCost] = useState<number>(0)
  const [reducedBudget, setReducedBudget] = useState<number>(0)
  const [afterCampBudgetTotal, setAfterCampBudgetTotal] = useState<number>(0)
  const [beforeCampBudgetTotal, setBeforeBudgetTotal] = useState<number>(0)

  // 머신러닝 초기화 알림
  const [adUpdateDialog, setAdUpdateDialog] = useState<boolean>(false)
  const [adUpdateDiv, setAdUpdateDiv] = useState<string>('campTime')
  const [adUpdateBefore, setAdUpdateBefore] = useState<string>('')
  const [adUpdateAfter, setAdUpdateAfter] = useState<string>('')

  const projectHandler = (project: Project): void => {
    setValue('projectId', project.id)
    setProjectIdParam(project.id)
    setProjectNm(project.projectNm)
    setProjectDivSelected(project.projectDiv)
    if (project.projectDiv === 'FUNDING') {
      setProjectSelectDialog(true)
      setProjectAdPossStartDate(project.adPossStartTime)
      setProjectAdPossEndDate(project.adPossEndTime)
      setProjectRcsStartDate(project.rcsPostTime)
      setProjectRcsEndDate(project.rcsEndTime)
    } else {
      setValue('projectFundingDiv', 'NON')
      setWadizFundingText('')
      setProjectDivFormShow(false)
      setProjectAdPossStartDate(project.adPossStartTime)
      setProjectAdPossEndDate(project.adPossEndTime)
      campUpdateEvent('project')
      const startTime =
        project.adPossStartTime === null || project.adPossStartTime === undefined
          ? '미설정'
          : dayjs(project.adPossStartTime).format('YY-MM-DD hh:mm A')
      const endTime =
        project.adPossEndTime === null || project.adPossEndTime === undefined
          ? '미설정'
          : dayjs(project.adPossEndTime).format('YY-MM-DD hh:mm A')

      setWadizFundingPerdText(`${startTime} ~ ${endTime}`)
      setProjectPerdFormShow(true)
    }
    // const campPeriodTableHtml = document.getElementById('campPeriod_table') as HTMLElement;
    // campPeriodTableHtml.style.display = 'block';
    //
    // const campBudgetTotalTextDivHtml = document.getElementById('campBudgetTotalTextDiv') as HTMLElement;
    // campBudgetTotalTextDivHtml.style.display = 'block';

    ///$('#projectVaildForm').hide();
    setCampValidData(null)
    setProjectValidErrYn(false)
    const projectValidGroup = document.getElementById('projectVaildGroup') as HTMLElement
    projectValidGroup.style.display = 'none'
    if (projectValidGroup.className.includes('negative')) {
      projectValidGroup.classList.remove('negative')
    }
  }

  const ProjectSelectBox = () => (
    <div className="dropdown-menu expand">
      <ul
        className="opt-selectbox"
        onClick={(e: any) => e.stopPropagation()}
        onKeyUp={(e: any) => e.handleKeyUp}
        role="presentation"
      >
        {projectItems.map((item, index) => (
          <li
            className="opt-menu"
            key={item.id}
            data-type={item.projectDiv}
            data-startdate={item.adPossStartTime}
            data-enddate={item.adPossEndTime}
            data-rcsstartdate={item.rcsPostTime}
            data-rcsenddate={item.rcsEndTime}
          >
            <i className={item.projectDiv === 'STORE' ? 'ico ico-store' : 'ico ico-funding'}></i>
            <span className="fz-12 fw-bold">{item.projectDiv === 'STORE' ? '스토어' : '펀딩'}</span>
            <span className="fz-14 fc-2" onClick={() => projectHandler(item)}>
              {item.projectNm}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
  // 캠페인 조회
  const getCampDatas = async () => {
    const campId = getValues('campId') === undefined ? campIdParam : getValues('campId')
    const { data } = await ApiUtil2.get<MdCampDatas>('/api/camp/make02/getCampInfo', { params: { campId } })
    return data
  }
  const getCampDatasApi = useQuery(['getCampDatas'], getCampDatas, {
    enabled: false,
  })
  // 비즈머니 or 후불머니
  const getMoney = async () => {
    const campId = getValues('campId') === undefined ? campIdParam : getValues('campId')
    const { data } = await ApiUtil.get<CampBizmoneyInfo>('/api/camp/make02/getMoneyInfo', {
      params: { campId: campId, payType: getValues('payType') },
    })
    return data
  }
  const bizmoneyApi = useQuery(['getBizmoney'], getMoney, { enabled: false })

  // 수정 API
  const campUpdateApi = () => {
    const {
      campId,
      campNm,
      campGoalDiv,
      campStartTime,
      campEndTime,
      campBudgetTotal1,
      adminCampYn,
      mediaDiv4,
      projectId,
      projectFundingDiv,
      useYn,
      campBudgetChange,
      cost,
      field,
    } = getValues()
    const result = ApiUtil2.post<MdCamp>('/api/camp/make02/campUpdate', {
      campId,
      campNm,
      campGoalDiv,
      campStartTime,
      campEndTime,
      campBudgetTotal1,
      adminCampYn,
      mediaDiv4,
      projectId,
      projectFundingDiv,
      useYn,
      campBudgetChange,
      cost,
      field,
    })
    return result
  }

  // 캠페인 예산 관련 업데이트 및 취소 시 원래 캠페인 예산 원복 처리
  const campErrChange = (fieldValue: any) => {
    if (fieldValue === 'campBudget') {
      $('#campBudget').val(beforeCampBudget.toLocaleString())
      setValue('campBudgetTotal1', beforeCampBudget)
      // let campBudgetTotalText = '0';
      // if (beforeCampBudget !== '') {
      //   campBudgetTotalText = (Number(beforeCampBudget) * Number(campPeriod)).toLocaleString();
      //   setCampBudgetText(beforeCampBudget.toLocaleString());
      // }
      // setCampBudgetText(beforeCampBudget.toLocaleString());
      // setCampBudgetTotal(campBudgetTotalText);
    } else if (fieldValue === 'campTime') {
      $('#datepickerTime').data('daterangepicker').setStartDate(MdCampBeforeForm.getValues('beforeCampStartTime'))
      setValue('campStartTime', MdCampBeforeForm.getValues('beforeCampStartTime'))
      $('#datepickerTime').data('daterangepicker').setEndDate(MdCampBeforeForm.getValues('beforeCampEndTime'))
      setValue('campEndTime', MdCampBeforeForm.getValues('beforeCampEndTime'))
      const changeStart = $('#datepickerTime').data('daterangepicker').startDate
      const changeEnd = $('#datepickerTime').data('daterangepicker').endDate

      const startDays = dayjs(dayjs(changeStart).format('YYYY-MM-DD'))
      const endDays = dayjs(dayjs(changeEnd).format('YYYY-MM-DD'))
      const campPeriodData = Number(endDays.diff(startDays, 'day', false) + Number(1))
      // setCampPeriod(campPeriodData.toString());

      // const campBudgetTotalText = (Number(getValues('campBudget')) * Number(campPeriodData)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // if (getValues('campBudget') != null) setCampBudgetTotal(campBudgetTotalText);
    } else if (fieldValue === 'project') {
      $(projectSelect.current as PlainObject)
        .val(MdCampBeforeForm.getValues('beforeProjectId'))
        .trigger('change')
      setWadizFundingText(wadizFundingText)
      // $('#projectDivForm').show();
      setProjectDivFormShow(true)
      setProjectPerdFormShow(true)
      if (
        MdCampBeforeForm.getValues('beforeProjectFundingDiv') === 'NON' ||
        MdCampBeforeForm.getValues('beforeProjectFundingDiv') === 'FUNDING_ING'
      ) {
        // eslint-disable-next-line no-use-before-define
        // commonCampDateValidation(MdCampBeforeForm.getValues('beforeProjectFundingDiv'), MdCampBeforeForm.getValues('beforeProjectAdPossStartDate'), MdCampBeforeForm.getValues('beforeProjectAdPossEndDate'), true);
      } else if (MdCampBeforeForm.getValues('beforeProjectFundingDiv') === 'FUNDING_OPEN') {
        // eslint-disable-next-line no-use-before-define
        // commonCampDateValidation(MdCampBeforeForm.getValues('beforeProjectFundingDiv'), MdCampBeforeForm.getValues('beforeProjectRcsStartDate'), MdCampBeforeForm.getValues('beforeProjectRcsEndDate'), true);
      }
      $('#datepickerTime').data('daterangepicker').setEndDate(MdCampBeforeForm.getValues('beforeCampEndTime'))
      setValue('campEndTime', MdCampBeforeForm.getValues('beforeCampEndTime'))
      $('#datepickerTime').data('daterangepicker').setStartDate(MdCampBeforeForm.getValues('beforeCampStartTime'))
      setValue('campStartTime', MdCampBeforeForm.getValues('beforeCampStartTime'))
    }
  }
  const campUpdate = useMutation(campUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        // 상단 비즈머니 업데이트
        if (getValues('campBudgetChange') && getValues('payType') === 'BIZ_MONEY') {
          bizmoneyApi.refetch().then((r) => {
            if (r.isSuccess) {
              setUsableCostChange(r.data.data.currentCost.toLocaleString())
            }
          })
        }
        setBeforeCampBudget(getValues('campBudgetTotal1'))
        setValue('campBudgetChange', false)
        // 업데이트가 프로젝트일 경우
        if (getValues('campUpdateDiv') === 'project') {
          // eslint-disable-next-line no-use-before-define
          // commonChangeDateEvent();
          MdCampBeforeForm.setValue('beforeProjectId', getValues('projectId'))
        }
        MdCampBeforeForm.setValue('beforeProjectAdPossStartDate', projectAdPossStartDate)
        MdCampBeforeForm.setValue('beforeProjectAdPossEndDate', projectAdPossEndDate)
        MdCampBeforeForm.setValue('beforeProjectRcsStartDate', projectRcsStartDate)
        MdCampBeforeForm.setValue('beforeProjectRcsEndDate', projectRcsEndDate)
        MdCampBeforeForm.setValue('beforeProjectFundingDiv', getValues('projectFundingDiv'))
        MdCampBeforeForm.setValue('beforeCampStartTime', getValues('campStartTime'))
        MdCampBeforeForm.setValue('beforeCampEndTime', getValues('campEndTime'))
      }
      searchCamp()
    },
    onError: (error: any) => {
      if (getValues('campUpdateDiv') === 'project') {
        campErrChange('project')
      } else if (getValues('campUpdateDiv') === 'campTime') {
        campErrChange('campTime')
      } else if (getValues('campUpdateDiv') === 'campBudget') {
        campErrChange('campBudget')
        setValue('campBudgetChange', false)
      }
      // 서버에서의 공통 벨리데이션에서 에러가 발생시 포커싱과 에러 메시지 처리
      // convertedMsg : "캠페인 이름이 입력되어야 합니다"
      // field : "campNm"
      // 위와 같이 에러 관련 정보가 배열로 담겨 오는 부분 사용
      error?.data.data.errors.forEach((errInfo: any) => {
        ;(document.querySelector<HTMLElement>(`#${errInfo.field}`)! as any).focus()
      })
    },
  })
  // on Off 처리
  const campOnOffUpdateApi = () => {
    const { campId, useYn } = campOnOffUpdateForm.getValues()
    const result = ApiUtil2.post<campOnOffUpdate>('/api/camp/make02/campOnOffUpdate', {
      campId,
      useYn,
    })
    return result
  }
  const campOnOffUpdateEvent = useMutation(campOnOffUpdateApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 리프레시
        alertify.success(`캠페인이 ${resp.data.data.useYn ? 'ON' : 'OFF'} 되었습니다.`)
      }
    },
  })
  const campUpdateCheckApi = async () => {
    const { data } = await ApiUtil2.get<calculateBalance>('/api/camp/make02/campUpdateCheck', {
      params: {
        campId: campIdParam,
        campStartTime: getValues('campStartTime'),
        campEndTime: getValues('campEndTime'),
        campBudget: getValues('campBudgetTotal1'),
        campUpdateDiv: getValues('campUpdateDiv'),
      },
    })
    return data
  }
  const campUpdateCheckApiEvent = useQuery(['calculateBalanceApi'], campUpdateCheckApi, {
    enabled: false,
  })

  const campUpdateEvent = (field: string) => {
    // 필드값을 추가 업데이트가 중복으로 나오는 이슈 때문에
    // field가 project일 경우 campUpdate.mutate() 완료 후 날짜 선택 함수 호출
    setValue('campUpdateDiv', field)
    setValue('field', field)
    let isMsgAlertShow = false
    // 임시저장 or 종료 or 삭제 상태일때
    if (
      campViewStatusParam === null ||
      campViewStatusParam === 'complete' ||
      campViewStatusParam === 'del_ready' ||
      campViewStatusParam === 'del_complete' ||
      campViewStatusParam === 'temp'
    ) {
      const startDays = dayjs(dayjs(MdCampBeforeForm.getValues('beforeCampStartTime')).format('YYYY-MM-DD'))
      const endDays = dayjs(dayjs(MdCampBeforeForm.getValues('beforeCampEndTime')).format('YYYY-MM-DD'))

      const period = Number(endDays.diff(startDays, 'day', false) + Number(1))
      const minPrice = creationDataCnt * 5000 * period
      if (getValues('campBudgetTotal1') >= minPrice) {
        const campBudgetValidGroupHtml = document.getElementById('campBudgetValidGroup') as HTMLElement
        // const campBudgetTextHtml = document.getElementById('campBudgetValidText') as HTMLElement;
        if (campBudgetValidGroupHtml.className.includes('negative')) {
          campBudgetValidGroupHtml.classList.remove('negative')
          // campBudgetTextHtml.innerText = '';
        }
      }
      campUpdate.mutate()
      return
    }
    // 기타 상태일때
    if (field !== 'campBudget') {
      campUpdate.mutate()
      return
    }

    campUpdateCheckApiEvent.refetch().then((r) => {
      if (r.isSuccess) {
        let msg = ''
        if (field === 'campBudget') {
          if (r.data.data.cost >= 0) {
            // 총예산을 이전값보다 증가 시킬 때
            // 추가 과금이 필요할 때
            if (r.data.data.additionalCharge) {
              msg = `캠페인의 총 예산을 <br> ${beforeCampBudget.toLocaleString().toLocaleString()} 원 에서  ${getValues(
                'campBudgetTotal1'
              ).toLocaleString()} 원 으로 <br> 변경 하시겠습니까? <br><br> 증액하시는 예산으로 인해 <br> 예치금에  ${Math.abs(
                r.data.data.cost
              ).toLocaleString()} 원이 추가로 <br>결제됩니다. (VAT 포함 금액)`
            } else {
              // 추가 과금이 필요 없을 때.
              msg = `캠페인 총예산이 ${beforeCampBudget.toLocaleString().toLocaleString()} 원 에서  ${getValues(
                'campBudgetTotal1'
              ).toLocaleString()} 원으로 변경됩니다.`
            }
            isMsgAlertShow = true
          } else {
            if (r.data.data.reducedBudget !== undefined) {
              setRptTotalCost(r.data.data.rptTotalCost)
              setReducedBudget(r.data.data.reducedBudget)
              setAfterCampBudgetTotal(r.data.data.afterCampBudgetTotal)
              setBeforeBudgetTotal(beforeCampBudget)
              setCampBudgetReduceDialog(true)
            } else {
              msg = `캠페인 총예산이 ${beforeCampBudget.toLocaleString().toLocaleString()} 원 에서  ${getValues(
                'campBudgetTotal1'
              ).toLocaleString()} 원으로 변경됩니다. <br><br><span class="fz-12 fc-2"><i class="fz-12 fc-5">*</i>  단, SNS광고의 예산을 변경 시 연동 딜레이로 인해 실제 사용한 비용이 변경하신 예산보다 <br>많은 경우 일일 예산이 조정될 수 있습니다.</span><br><br><span class="fz-12 fc-2"><i class="fz-12 fc-5">*</i>  예산 변경으로 예치금 이하의 금액이 소진되면 잔여 예치금은 캠페인 종료 후 D+4일에 반환됩니다.</span>`
              isMsgAlertShow = true
            }
          }
        } else {
          campUpdate.mutate()
        }

        // if (field === 'campTime') {
        //   if(r.data.data.reducedBudget !== undefined) {
        //     setRptTotalCost(r.data.data.rptTotalCost);
        //     setReducedBudget(r.data.data.reducedBudget);
        //     setAfterCampBudgetTotal(r.data.data.afterCampBudgetTotal);
        //     setBeforeBudgetTotal(beforeCampBudgetTotal);
        //     setCampBudgetReduceDialog(true);
        //     isMsgAlertShow = false;
        //   } else {
        //     msg = `캠패인 기간이 ${dayjs(MdCampBeforeForm.getValues('beforeCampStartTime')).format('YYYY.MM.DD')} ~ ${dayjs(MdCampBeforeForm.getValues('beforeCampEndTime')).format('YYYY.MM.DD')} 에서 ${dayjs(getValues('campStartTime')).format('YYYY.MM.DD')} ~ ${dayjs(getValues('campEndTime')).format('YYYY.MM.DD')} 로 변경됩니다.<br><br><span class="fz-12 fc-2"><i class="fz-12 fc-5">*</i>  단, SNS광고의 예산을 변경 시 연동 딜레이로 인해 실제 사용한 비용이 변경하신 예산보다 <br>많은 경우 일일 예산이 조정될 수 있습니다.</span><br><br><span class="fz-12 fc-2"><i class="fz-12 fc-5">*</i>  예산 변경으로 예치금 이하의 금액이 소진되면 잔여 예치금은 캠페인 종료 후 D+4일에 반환됩니다.</span>`
        //   }
        // }

        if (!isMsgAlertShow) return

        alertify
          .confirm(
            msg,
            () => {
              setValue('campBudgetChange', true)
              setValue('cost', r.data.data.cost)
              campUpdate.mutate()
            },
            () => {
              // 취소시 캠페인 예산 이전 값으로 업데이트
              campErrChange(field)
            }
          )
          .set({ labels: { cancel: '취소', ok: '확인' } })
          .setHeader(field === 'campBudget' ? '캠페인 비용 변경 확인' : '캠페인 기간 변경 확인')
      } else {
        // 에러시 캠페인 예산 이전 값으로 업데이트
        campErrChange(field)
      }
    })
  }
  const commonChangeDateEvent = () => {
    // 날짜가 변경될 경우 텍스트 이벤트 처리
    const start = $('#datepickerTime').data('daterangepicker').startDate
    const end = $('#datepickerTime').data('daterangepicker').endDate
    const campStartTime = start.format('YYYYMMDDHHmm')
    const campEndTime = end.format('YYYYMMDDHHmm')
    setValue('campStartTime', campStartTime)
    setValue('campEndTime', campEndTime)
    campUpdateEvent('campTime')
    $('#datepickerTimeSpan').text($('#datepickerTime').data('daterangepicker').chosenLabel)
    // $('#campPeriod_table').show();
    const campPeriodTableHtml = document.getElementById('campPeriod_table') as HTMLElement
    campPeriodTableHtml.style.display = 'block'
    const startDays = dayjs(dayjs(start).format('YYYY-MM-DD'))
    const endDays = dayjs(dayjs(end).format('YYYY-MM-DD'))
    const campPeriodData = Number(endDays.diff(startDays, 'day', false) + Number(1))
    // setCampPeriod(campPeriodData.toString());
    const campBudgetTotalText = (Number(getValues('campBudgetTotal1')) * Number(campPeriodData))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    // if (getValues('campBudget') != null) setCampBudgetTotal(campBudgetTotalText);
  }

  const campStatusInit = () => {
    const campGoalConversionHtml = document.getElementById('campGoal_conversion') as HTMLElement
    const campGoalLinkClicksHtml = document.getElementById('campGoal_linkClicks') as HTMLElement
    if (
      campViewStatusParam === 'complete' ||
      campViewStatusParam === 'del_ready' ||
      campViewStatusParam === 'del_complete' ||
      (!adminParam.isAdmin && adminParam.forAdmin)
    ) {
      const campNmHtml = document.getElementById('campNm') as HTMLElement
      campNmHtml.setAttribute('disabled', 'true')
      // projectSelectHtml.setAttribute('disabled', 'true');
      setProjectDisabledYn(true)
    } else if (
      campViewStatusParam !== null &&
      campViewStatusParam !== 'complete' &&
      campViewStatusParam !== 'del_ready' &&
      campViewStatusParam !== 'del_complete'
    ) {
      // 임시 저장 상태가 아닐경우
      if (campViewStatusParam !== 'temp') {
        setCampViewStatusDescription(CampViewStatusMap[campViewStatusParam])
        const toolTipText = CampViewStatusTooltipMap[campViewStatusParam]
        $('#campViewStatusTooltip').tooltip({
          content: `<p class="fz-12 fc-0">${toolTipText}</p>`,
        })

        // 기타 상태일 경우 와디즈 프로젝트 선택 disabled
        setProjectDisabledYn(true)
        campGoalConversionHtml.setAttribute('disabled', 'true')
        if (campGoalLinkClicksHtml !== null) campGoalLinkClicksHtml.setAttribute('disabled', 'true')
      }
    }
  }
  const searchCamp = () => {
    getCampDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        if (r.data.data.payType !== null) {
          setValue('payType', r.data.data.payType)
        }
        if (r.data.data.adminCampYn != null) {
          setValue('adminCampYn', r.data.data.adminCampYn)
          // 여기서 setAdminParam 하면 F5 새로고침시 데이터가 이상하게 들어가서 CampAdmin에서 처리함
          // setAdminParam({ isAdmin: shadowLogin, forAdmin: r.data.data.adminCampYn });
        }
        if (r.data.data.campNm != null) {
          setValue('campNm', r.data.data.campNm)
          setCampName(r.data.data.campNm ? r.data.data.campNm : '')
        }
        setWadizFundingText('')
        const projectVaildForm = document.getElementById('projectVaildForm') as HTMLElement
        setProjectDivFormShow(false)
        // $('#projectDivForm').hide();
        if (r.data.data.projectId != null) {
          setValue('projectId', r.data.data.projectId)
          MdCampBeforeForm.setValue('beforeProjectId', r.data.data.projectId)
          setValue('projectFundingDiv', r.data.data.projectFundingDiv)
          MdCampBeforeForm.setValue('beforeProjectFundingDiv', r.data.data.projectFundingDiv)
          // setProjectFundingDiv2(r.data.data.projectFundingDiv);
          setProjectIdParam(r.data.data.projectId)
          if (r.data.data.projectFundingDiv !== 'NON') {
            setWadizFundingText(
              r.data.data.projectFundingDiv === 'FUNDING_OPEN'
                ? `[오픈 예정] ${r.data.data.projectNm}`
                : `[펀딩 진행중] ${r.data.data.projectNm}`
            )
            // $('#projectDivForm').show();
            //  projectDiv.style.display = 'block';
            setProjectDivFormShow(true)
          }
          let startTime = ''
          let endTime = ''
          if (r.data.data.projectFundingDiv === 'FUNDING_OPEN') {
            startTime =
              r.data.data.rcsPostTime === null || r.data.data.rcsPostTime === undefined
                ? '미설정'
                : dayjs(r.data.data.rcsPostTime).format('YY-MM-DD hh:mm A')
            endTime =
              r.data.data.rcsEndTime === null || r.data.data.rcsEndTime === undefined
                ? '미설정'
                : dayjs(r.data.data.rcsEndTime).format('YY-MM-DD hh:mm A')
          } else {
            startTime =
              r.data.data.adPossStartTime === null || r.data.data.adPossStartTime === undefined
                ? '미설정'
                : dayjs(r.data.data.adPossStartTime).format('YY-MM-DD hh:mm A')
            endTime =
              r.data.data.adPossEndTime === null || r.data.data.adPossEndTime === undefined
                ? '미설정'
                : dayjs(r.data.data.adPossEndTime).format('YY-MM-DD hh:mm A')
          }
          setWadizFundingPerdText(`${startTime} ~ ${endTime}`)
          setProjectPerdFormShow(true)
          setProjectIdParam(r.data.data.projectId)
          setProjectNm(r.data.data.projectNm)
          setProjectDivSelected(r.data.data.projectDiv)
          // 벨리데이션 체크로 인한 에러 메시지 정상화 처리
          projectVaildForm.style.display = 'none'

          setProjectValidErrYn(false)
          const projectValidGroup = document.getElementById('projectVaildGroup') as HTMLElement
          projectValidGroup.style.display = 'none'
          if (projectValidGroup.className.includes('negative')) {
            projectValidGroup.classList.remove('negative')
          }

          setValue('wadizProjectNmText', r.data.data.projectNm)
        }

        if (r.data.data.mediaDiv4 != null) {
          const mediaDivRadio = document.getElementById(`radio_${r.data.data.mediaDiv4}`) as HTMLElement
          mediaDivRadio.setAttribute('checked', 'true')
          setValue('mediaDiv4', r.data.data.mediaDiv4)
          setMediaDiv4Param(r.data.data.mediaDiv4)
        }
        setAdminParam({ isAdmin: adminParam.isAdmin, forAdmin: r.data.data.adminCampYn })
        if (r.data.data.campGoalDiv === 'CONVERSIONS') {
          setCampGoalConversionActive(true)
          setCampGoalLinkClicksActive(false)
        } else {
          setCampGoalConversionActive(false)
          setCampGoalLinkClicksActive(true)
        }
        setValue('campGoalDiv', r.data.data.campGoalDiv)
        if (r.data.data.campEndTime != null) {
          $('#datepickerTime').data('daterangepicker').setEndDate(r.data.data.campEndTime)
          setValue('campEndTime', r.data.data.campEndTime)
          MdCampBeforeForm.setValue('beforeCampEndTime', r.data.data.campEndTime)
        }
        if (r.data.data.campStartTime != null) {
          $('#datepickerTime').data('daterangepicker').setStartDate(r.data.data.campStartTime)
          setValue('campStartTime', r.data.data.campStartTime)
          MdCampBeforeForm.setValue('beforeCampStartTime', r.data.data.campStartTime)
        }
        const startDays = dayjs(dayjs(r.data.data.campStartTime).format('YYYY-MM-DD'))
        const endDays = dayjs(dayjs(r.data.data.campEndTime).format('YYYY-MM-DD'))
        const periodData = Number(endDays.diff(startDays, 'day', false) + Number(1))
        // setCampPeriod(periodData.toString());
        if (r.data.data.campBudgetTotal1 != null) {
          setValue('campBudgetTotal1', r.data.data.campBudgetTotal1)
          setBeforeCampBudget(r.data.data.campBudgetTotal1)
          const minPrice = r.data.data.creationDataCnt * 5000
          if (r.data.data.campBudgetTotal1 >= minPrice) {
            const campBudgetValidGroupHtml = document.getElementById('campBudgetValidGroup') as HTMLElement
            const campBudgetTextHtml = document.getElementById('campBudgetValidText') as HTMLElement
            if (campBudgetValidGroupHtml.className.includes('negative')) {
              campBudgetValidGroupHtml.classList.remove('negative')
              campBudgetTextHtml.innerText = ''
            }
          }
        }

        if (r.data.data.campViewStatus != null) {
          setCampViewStatusParam(r.data.data.campViewStatus)
        }

        if (r.data.data.useYn != null) {
          setValue('useYn', r.data.data.useYn)
          setUseYnChecked(r.data.data.useYn)
        }

        if (r.data.data.creationDataCnt != null) {
          setCreationDataCnt(r.data.data.creationDataCnt)
        }
        // 예산 관련 세팅 추가
        // 종료일 세팅이 제대로 안되는데 왜 안되는지 아래에서 달력 시작일 ~ 종료일 세팅을 한번 더 해준다.위에서 하고 있는데...
        $('#datepickerTime').data('daterangepicker').setStartDate(r.data.data.campStartTime)
        $('#datepickerTime').data('daterangepicker').setEndDate(r.data.data.campEndTime)
        const campStartTime = $('#datepickerTime').data('daterangepicker').startDate
        const campEndTime = $('#datepickerTime').data('daterangepicker').endDate
        const startDays2 = dayjs(dayjs(campStartTime).format('YYYY-MM-DD'))
        const endDays2 = dayjs(dayjs(campEndTime).format('YYYY-MM-DD'))
        const campPeriodData = Number(endDays2.diff(startDays2, 'day', false) + Number(1))
        // setCampPeriod(campPeriodData.toString());
        // const campBudgetTotalText = (Number(getValues('campBudget')) * Number(campPeriodData)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // setCampBudgetTotal(campBudgetTotalText);
        // setCampBudgetText(Number(getValues('campBudget')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        campStatusInit()
      }
      setCampInfoRenderingOk(true)
    })
  }
  const insertCamp = async () => {
    ApiUtil2.post('/api/camp/make02/insertCamp').then((resp) => {
      setCampIdParam(resp.data.data.id)
      setCampName(resp.data.data.campNm)
      setValue('campId', resp.data.data.id)
      searchCamp()
    })
  }
  const getProjects = async () => {
    const { data } = await ApiUtil2.get<Project[]>('/api/camp/make02/getWadizProject', {
      params: { campId: campIdParam },
    })
    return data
  }
  const getProjectsData = useQuery(['getProjects'], getProjects, {
    enabled: false,
  })

  // 프로젝트 선택 날짜 벨리데이션
  // const commonCampDateValidation = (projectDiv:any, startTime:any, endTime:any, reloadData:any) => {
  //   $('#datepickerTime').data('daterangepicker').minDate = false;
  //   $('#datepickerTime').data('daterangepicker').maxDate = false;
  //   const defaultToday = dayjs().format('YYYYMMDD');
  //   const defaultTomorrow = dayjs().startOf('day').subtract(-2, 'days').format('YYYYMMDD');
  //   const defaultHh = dayjs().format('HH');
  //   const defaultMm = dayjs().format('mm');
  //   let limitStartTime = '';
  //   if (startTime !== 'undefined' && startTime !== undefined) {
  //     if (defaultToday <= startTime) {
  //       limitStartTime = `${startTime}00`;
  //     } else {
  //       limitStartTime = `${defaultToday}00`;
  //     }
  //   } else {
  //     limitStartTime = `${defaultToday}00`;
  //   }
  //   if (!reloadData) {
  //     // 우선 날짜 강제 세팅
  //     $('#datepickerTime').data('daterangepicker').setEndDate(dayjs(defaultTomorrow + defaultHh + defaultMm));
  //     $('#datepickerTime').data('daterangepicker').setStartDate(dayjs(defaultToday + defaultHh + defaultMm));
  //     if (startTime !== 'undefined' && startTime !== undefined) {
  //       if (defaultToday <= startTime) {
  //         const changeEndTime = dayjs(startTime).startOf('day').subtract(-2, 'days').format('YYYYMMDD');
  //         $('#datepickerTime').data('daterangepicker').setEndDate(dayjs(changeEndTime + defaultHh + defaultMm));
  //         $('#datepickerTime').data('daterangepicker').setStartDate(dayjs(startTime + defaultHh + defaultMm));
  //       }
  //     } else {
  //       $('#datepickerTime').data('daterangepicker').setStartDate(dayjs(defaultToday + defaultHh + defaultMm));
  //     }
  //     if (endTime === 'undefined' || endTime === undefined) {
  //       $('#datepickerTime').data('daterangepicker').setEndDate(dayjs(defaultTomorrow + defaultHh + defaultMm));
  //     } else {
  //       $('#datepickerTime').data('daterangepicker').setEndDate(dayjs(defaultTomorrow + defaultHh + defaultMm));
  //     }
  //   }
  //   $('#datepickerTime').data('daterangepicker').minDate = dayjs(dayjs(limitStartTime));
  //   if (endTime !== undefined && endTime !== 'undefined') {
  //     const limitEndTime = `${endTime}2350`;
  //     $('#datepickerTime').data('daterangepicker').maxDate = dayjs(dayjs(limitEndTime));
  //   } else {
  //     $('#datepickerTime').data('daterangepicker').maxDate = false;
  //   }
  //
  //   // if (campViewStatusParam === 'ing' || campViewStatusParam === 'ing_update' || campViewStatusParam === 'ing_insp' || campViewStatusParam === 'stop_creation_off' || campViewStatusParam === 'stop_camp_off' || campViewStatusParam === 'stop_camp_range') {
  //   //   // 임시저장이 아닐때 해당 케이스에서는 최소날짜를 시작일로 지정한다.
  //   //   $('#datepickerTime').data('daterangepicker').minDate = dayjs(dayjs(getValues('campStartTime')));
  //   // }
  //   // 오늘날짜와 캠페인 시작일을 체크
  //   const startDays = dayjs(dayjs(defaultToday).format('YYYY-MM-DD'));
  //   const endDays = dayjs(dayjs(getValues('campStartTime')).format('YYYY-MM-DD'));
  //   const campPeriodData = Number(endDays.diff(startDays, 'day', false));
  //   if (campPeriodData < 0) {
  //     $('#datepickerTime').data('daterangepicker').minDate = dayjs(dayjs(getValues('campStartTime')));
  //   }
  //
  //   const changeStart = $('#datepickerTime').data('daterangepicker').startDate;
  //   const changeEnd = $('#datepickerTime').data('daterangepicker').endDate;
  //   const campStartTime = changeStart.format('YYYYMMDDHHmm');
  //   const campEndTime = changeEnd.format('YYYYMMDDHHmm');
  //   setValue('campStartTime', campStartTime);
  //   setValue('campEndTime', campEndTime);
  // };
  const getMakerProjects = () => {
    getProjectsData.refetch().then((r) => {
      if (r.isSuccess) {
        setProjectItems(r.data)
        // const campPeriodTableHtml = document.getElementById('campPeriod_table') as HTMLElement;
        // const campBudgetTotalTextDivHtml = document.getElementById('campBudgetTotalTextDiv') as HTMLElement;
        // campPeriodTableHtml.style.display = 'block';
        // campBudgetTotalTextDivHtml.style.display = 'block';
      }
      setProjectInfoRenderingOk(true)
    })
  }
  const tabClickEvent = (value: any) => {
    if (projectIdParam === 'null' || projectIdParam === null || projectIdParam === '' || projectIdParam === undefined) {
      alertify.error('광고를 집행할 프로젝트를 먼저 선택해주시기 바랍니다.')
    } else {
      setTabClick(value)
    }
  }
  const mediaDiv4ClickEvent = (event: any) => {
    const mediaDiv4Value = event.currentTarget.value
    event.preventDefault()
    alertify
      .confirm(
        '광고 노출 매체를 변경하시는 경우<br/>기존에 등록하신 타겟팅 및 소재 정보들이<br/>초기화 됩니다. 변경하시겠습니까?',
        () => {
          setValue('mediaDiv4', mediaDiv4Value)
          setMediaDiv4Param(mediaDiv4Value)
          // 소재 관련 부분땜에 여기서 초기화처리
          setGdnCreationDataId(null)
          // 1일 캠페인 예산 벨리데이션 에러 문구 삭제
          const campBudgetValidGroupHtml = document.getElementById('campBudgetValidGroup') as HTMLElement
          const campBudgetTextHtml = document.getElementById('campBudgetValidText') as HTMLElement
          if (campBudgetValidGroupHtml.className.includes('negative')) {
            campBudgetValidGroupHtml.classList.remove('negative')
            campBudgetTextHtml.innerText = ''
          }
          campUpdateEvent('mediaDiv4')
        }
      )
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('기존 입력 정보가 사라집니다.')
  }
  const updateCampNameEvent = (event: any) => {
    const updateCampName = event.currentTarget.value
    setValue('campNm', updateCampName)
    campUpdateEvent('campName')
  }
  const updateCampGoalEvent = (event: any) => {
    if (adminParam.isAdmin && adminParam.forAdmin && campViewStatusParam === 'temp') {
      const updateCampGoalId = event.currentTarget.id
      let campGoal = 'CONVERSIONS'
      if (updateCampGoalId === 'campGoal_linkClicks') campGoal = 'LINK_CLICKS'
      setValue('campGoalDiv', campGoal)
      if (campGoal === 'CONVERSIONS') {
        setCampGoalConversionActive(true)
        setCampGoalLinkClicksActive(false)
      } else {
        setCampGoalConversionActive(false)
        setCampGoalLinkClicksActive(true)
      }
      campUpdateEvent('campGoal')
    }
  }
  const updateCampBudgetEvent = (e: any) => {
    if (getValues('campBudgetTotal1') !== Number(e.target.value.replaceAll(',', ''))) {
      setValue('campBudgetTotal1', Number(e.target.value.replaceAll(',', '')))
      campUpdateEvent('campBudget')
    }
  }
  // const campBudgetCalculateEvent = (event:any) => {
  //   setValue('campBudget', event.currentTarget.value === '' ? '' : event.currentTarget.value.replaceAll(',', ''));
  //   const campDailyBudget = event.currentTarget.value === '' ? 0 : event.currentTarget.value.replaceAll(',', '');
  //   let campBudgetTotalText = '0';
  //   if (campDailyBudget !== '') {
  //     campBudgetTotalText = (Number(campDailyBudget) * Number(campPeriod)).toLocaleString();
  //     setCampBudgetText(campDailyBudget.toLocaleString());
  //   }
  //   setCampBudgetText(event.currentTarget.value.toLocaleString());
  //   setCampBudgetTotal(campBudgetTotalText);
  // };
  const onChangeOnOff = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    const campId: any = campIdParam
    setUseYnChecked(checked)
    campOnOffUpdateForm.setValue('useYn', checked)
    campOnOffUpdateForm.setValue('campId', campId)
    campOnOffUpdateEvent.mutate()
  }
  useEffect(() => {
    if (campValidData !== null && campValidData !== undefined) {
      if (!campValidData.projectValid) {
        setProjectValidErrYn(true)
        const projectValidGroup = document.getElementById('projectVaildGroup') as HTMLElement
        const projectVaildForm = document.getElementById('projectVaildForm') as HTMLElement
        projectValidGroup.style.display = 'block'
        projectVaildForm.style.display = 'block'
        // $('#projectVaildForm').show();
        if (!projectValidGroup.className.includes('negative')) {
          projectValidGroup.className += ' negative'
        }
      }

      if (!campValidData.campPeriodValid) {
        const campPeriodVaildGroupHtml = document.getElementById('campPeriodVaildGroup') as HTMLElement
        campPeriodVaildGroupHtml.style.display = 'block'

        const campPeriodVaildFormHtml = document.getElementById('campPeriodVaildForm') as HTMLElement
        campPeriodVaildFormHtml.style.display = 'block'

        // $('#campPeriodVaildGroup').show();
        // $('#campPeriodVaildForm').show();
        const campPeriodHtml = document.getElementById('campPeriodVaildGroup') as HTMLElement
        if (!campPeriodHtml.className.includes('negative')) {
          campPeriodHtml.className += ' negative'
        }
      }
      if (!campValidData.campBudgetValid) {
        // const minPrice = campValidData.creationCnt * 5000 * campPeriodData;
        const campBudgetValidGroupHtml = document.getElementById('campBudgetValidGroup') as HTMLElement
        const campBudgetTextHtml = document.getElementById('campBudgetValidText') as HTMLElement
        if (!campBudgetValidGroupHtml.className.includes('negative')) {
          campBudgetValidGroupHtml.className += ' negative'
          campBudgetTextHtml.innerText = `현재 ${campValidData.creationCnt}개 소재로 ${campValidData.minCampBudgetTotal1
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 이상을 입력하세요`
        } else {
          campBudgetTextHtml.innerText = `현재 ${campValidData.creationCnt}개 소재로 ${campValidData.minCampBudgetTotal1
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 이상을 입력하세요`
        }
      }
    } else {
      const campPeriodVaildFormHtml = document.getElementById('campPeriodVaildForm') as HTMLElement
      campPeriodVaildFormHtml.style.display = 'none'

      setProjectValidErrYn(false)
      const projectValidGroup = document.getElementById('projectVaildGroup') as HTMLElement
      projectValidGroup.style.display = 'none'
      if (projectValidGroup.className.includes('negative')) {
        projectValidGroup.classList.remove('negative')
      }
    }
  })

  useEffect(() => {
    if (wadizFundingText !== '') {
      // $('#projectDivForm').show();
      setProjectDivFormShow(true)
      setProjectPerdFormShow(true)
    }
  })
  const validCheckEvent = () => {
    campInspRef.current!.validCheckEvent()
  }
  // 프로젝트 선택 다이얼로그에서 projectFundingDiv 업데이트가 되지 않는 이슈로 인해
  // 다이얼로그에서 해당 함수를 호출 후 캠페인 업데이트
  const projectFundingDivUpdateEvent = (fundingDiv: string) => {
    setValue('projectFundingDiv', fundingDiv)
    // if (fundingDiv === 'FUNDING_ING') {
    //   commonCampDateValidation(fundingDiv, projectAdPossStartDate, projectAdPossEndDate, false);
    // } else {
    //   commonCampDateValidation(fundingDiv, projectRcsStartDate, projectRcsEndDate, false);
    // }
    campUpdateEvent('project')
  }
  useEffect(() => {
    // 데이터피커 관련
    $('#datepickerTime').daterangepicker(
      {
        opens: 'left',
        ranges: {
          // '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day').subtract(59, 'm')],
          // '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days')],
          // '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')
          //   .subtract(59, 'm')],
          // '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)
          //   .subtract(59, 'm')],
          // '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
          // '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
          '5일': [dayjs().startOf('day').subtract(5, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
        },
        timePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 10,
        // maxDate: dayjs().startOf('day').add(7, 'd'),
        locale: {
          format: 'YY-MM-DD HH:mm A',
          separator: ' ~ ',
        },
        startDate: dayjs(),
        endDate: dayjs().add(5, 'd'),
      },
      (start: any, end: any) => {
        const campStartTime = start.format('YYYYMMDDHHmm')
        const campEndTime = end.format('YYYYMMDDHHmm')
        setValue('campStartTime', campStartTime)
        setValue('campEndTime', campEndTime)
        // if (campViewStatusParam === 'ing' || campViewStatusParam === 'ing_update' || campViewStatusParam === 'ing_insp' || campViewStatusParam === 'stop_creation_off' || campViewStatusParam === 'stop_camp_off' || campViewStatusParam === 'stop_camp_range') {
        //   if (campStartTime !== MdCampBeforeForm.getValues('beforeCampStartTime')) {
        //     alertify.error('시작일을 변경할 수 없습니다.');
        //     setValue('campStartTime', MdCampBeforeForm.getValues('beforeCampStartTime'));
        //     setValue('campEndTime', MdCampBeforeForm.getValues('beforeCampEndTime'));
        //     $('#datepickerTime').data('daterangepicker').setEndDate(MdCampBeforeForm.getValues('beforeCampEndTime'));
        //     $('#datepickerTime').data('daterangepicker').setStartDate(MdCampBeforeForm.getValues('beforeCampStartTime'));
        //     return;
        //   }
        // }
        if (
          campViewStatusParam !== null &&
          campViewStatusParam !== 'temp' &&
          campViewStatusParam !== 'complete' &&
          campViewStatusParam !== 'del_ready' &&
          campViewStatusParam !== 'del_complete'
        ) {
          setAdUpdateBefore(
            `${dayjs(MdCampBeforeForm.getValues('beforeCampStartTime')).format('YYYY.MM.DD HH:mm')} ~ ${dayjs(
              MdCampBeforeForm.getValues('beforeCampEndTime')
            ).format('YYYY.MM.DD HH:mm')}`
          )
          setAdUpdateAfter(`${start.format('YYYY.MM.DD HH:mm')} ~ ${end.format('YYYY.MM.DD HH:mm')}`)
          setAdUpdateDiv('campTime')
          setAdUpdateDialog(true)
        } else {
          campUpdateEvent('campTime')
        }
        $('#datepickerTimeSpan').text($('#datepickerTime').data('daterangepicker').chosenLabel)
        // $('#campPeriod_table').show();
        // const campPeriodTableHtml = document.getElementById('campPeriod_table') as HTMLElement;
        // campPeriodTableHtml.style.display = 'block';

        const startDays = dayjs(dayjs(start).format('YYYY-MM-DD'))
        const endDays = dayjs(dayjs(end).format('YYYY-MM-DD'))
        const campPeriodData = Number(endDays.diff(startDays, 'day', false) + Number(1))
        // setCampPeriod(campPeriodData.toString());
        const campBudgetTotalText = (Number(getValues('campBudgetTotal1')) * Number(campPeriodData))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        // if (getValues('campBudget') != null) setCampBudgetTotal(campBudgetTotalText);
      }
    )
    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      // $('.datepicker-range').remove();
      const daterangepickerCheck = document.querySelector<HTMLElement>('.daterangepicker')! as any
      if (daterangepickerCheck !== null) {
        daterangepickerCheck.remove()
      }
    }
  }, [])

  useEffect(() => {
    // 관리자 전용 상태 변경 체크 초기화
    setAdminCampYnChangeCnt(0)
    if (campIdParam === null) {
      insertCamp()
    }
    // 툴팁
    $('#mdTooltip').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">광고를 노출할 매체를 선택해주세요. </p><p class="fz-12 fc-0">페이스북/인스타그램’을 선택하시면 한번의 등록으로 <br> 페이스북 및 인스타그램에 동시 노출됩니다.</p>',
    })

    $('#campBudgetTooltip').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">캠페인 기간 동안 사용하기 희망하시는 예산을 <br> 입력합니다. </p><p class="fz-12 fc-0">기입하시는 금액을 기반으로 캠페인 전체 기간 동안<br> 효율적으로 광고비를 사용하게 됩니다.</p><p class="fz-12 fc-0">소재 1개당 1일 최소 5천원 이상의 예산이 필요합니다.</p>',
    })

    $('#campGoalTooltip_conversion').tooltip({
      content: '<p class="fz-12 fc-0">전환 최대화 및 전환당비용 최소화를 목표로 캠페인이 진행됩니다.</p>',
    })

    $('#campGoalTooltip_linkClicks').tooltip({
      content: '<p class="fz-12 fc-0">클릭 최대화 및 클릭당비용 최소화를 목표로 캠페인이 진행됩니다.</p>',
    })
    $('#campGoalTooltip_linkClicks2').tooltip({
      content: '<p class="fz-12 fc-0">클릭 최대화 및 클릭당비용 최소화를 목표로 캠페인이 진행됩니다.</p>',
    })
    // 모두 디폴트 세팅된 이무 캠페인 조회
    if (campIdParam !== null) {
      setValue('campId', campIdParam)
      searchCamp()
    }
    if (!adminParam.forAdmin) {
      if (campGoalLinkClicksActive) {
        setCampGoalConversionActive(true)
        setCampGoalLinkClicksActive(false)
      }
    }
    return () => {
      $('.tooltip').remove()
      $('.select2-single expand').select2('destroy')
    }
  }, [])
  // CampAdmin에서 관리자 전용 등록 관련 액션 발생할 경우 캠페인 초기화 후 데이터 다시 로드
  // Make02Service upadteAdminCampYn 에 캠페인 데이터 초기화 관련 소스 추가
  useEffect(() => {
    if (campIdParam !== null) {
      setValue('campId', campIdParam)
      if (adminCampYnChangeCnt > 0) {
        searchCamp()
        // 관리자 여부에 따라서 프로젝트 필터링 조건이 다름
        getMakerProjects()
      }
    }
  }, [adminParam.forAdmin])

  useEffect(() => {
    if (campIdParam !== null) {
      creationDataValidRef.current!.validCrationDataEditingApiEvent()
    }
  }, [campIdParam])

  useEffect(() => {
    if (campIdParam !== null) {
      getMakerProjects()
    }
  }, [campIdParam])
  useEffect(() => {
    if (initOk) return
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.remove('none')
    if (campInfoRenderingOk && projectInfoRenderingOk && commonLoader !== null) {
      commonLoader.classList.add('none')
      setInitOk(true)
    }
  })
  return (
    <Fragment>
      {adUpdateDialog ? (
        <AdUpdateDialog
          errEvent={campErrChange}
          updateEvent={campUpdateEvent}
          setAdUpdateDialog={setAdUpdateDialog}
          updateDiv={adUpdateDiv}
          adUpdateBefore={adUpdateBefore}
          adUpdateAfter={adUpdateAfter}
        />
      ) : null}
      {projectSelectDialog ? (
        <CampProjectSelectDialog
          projectRcsStartDate={projectRcsStartDate}
          projectRcsEndDate={projectRcsEndDate}
          projectAdPossStartDate={projectAdPossStartDate}
          projectAdPossEndDate={projectAdPossEndDate}
          projectFundingDivUpdateEvent={projectFundingDivUpdateEvent}
          setProjectSelectDialog={setProjectSelectDialog}
          setWadizFundingText={setWadizFundingText}
          setWadizFundingPerdText={setWadizFundingPerdText}
          projectNm={projectNm}
        />
      ) : null}
      {campBudgetReduceDialog ? (
        <CampBudgetReduceDialog
          setCampBudgetReduceDialog={setCampBudgetReduceDialog}
          campNm={getValues('campNm')}
          beforeCampBudgetTotal={beforeCampBudgetTotal}
          afterCampBudgetTotal={afterCampBudgetTotal}
          rptTotalCost={rptTotalCost}
          reducedBudget={reducedBudget}
          campUpdate={campUpdate}
          searchCamp={searchCamp}
          setValue={setValue}
        />
      ) : null}
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            {(campViewStatusParam !== null &&
              campViewStatusParam !== 'temp' &&
              campViewStatusParam !== 'complete' &&
              campViewStatusParam !== 'del_ready' &&
              campViewStatusParam !== 'del_complete' &&
              adminParam.isAdmin &&
              adminParam.forAdmin) ||
            (campViewStatusParam !== null &&
              campViewStatusParam !== 'temp' &&
              campViewStatusParam !== 'complete' &&
              campViewStatusParam !== 'del_ready' &&
              campViewStatusParam !== 'del_complete' &&
              adminParam.isAdmin &&
              !adminParam.forAdmin) ||
            (campViewStatusParam !== null &&
              campViewStatusParam !== 'temp' &&
              campViewStatusParam !== 'complete' &&
              campViewStatusParam !== 'del_ready' &&
              campViewStatusParam !== 'del_complete' &&
              !adminParam.isAdmin &&
              !adminParam.forAdmin) ? (
              <dl className="vertical">
                <dd>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col col-6">
                        <div className="form-group txt-center">
                          <span className="comp-txt">
                            <span className="table">
                              <span className="table-cell">
                                <b className="fz-14 fc-1">캠페인 상태</b>
                                <Link to="#" className="ico-tooltip tooltip-f" id="campViewStatusTooltip"></Link>
                              </span>
                            </span>
                          </span>
                          <span className="comp-txt">
                            <span className="table">
                              <span className="table-cell">
                                <b className="fz-14 fc-2">{campViewStatusDescription}</b>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="col col-6">
                        <div className="form-group txt-center">
                          <span className="comp-txt">
                            <span className="table">
                              <span className="table-cell">
                                <b className="fz-14 fc-1">캠페인 ON/OFF</b>
                              </span>
                            </span>
                          </span>
                          <div className="comp-switch">
                            <input type="checkbox" id="useYnChk" checked={useYnChecked} onChange={onChangeOnOff} />
                            <label htmlFor="useYnChk">
                              <i className="ico"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
            ) : null}
            {!adminParam.isAdmin && adminParam.forAdmin ? (
              <dl className="vertical">
                <dd>
                  <div className="form-group">
                    <span className="comp-txt">
                      <span className="table">
                        <span className="table-cell">
                          <b className="fz-14 fc-2">
                            와디즈에서 광고 대행을 받는 프로젝트입니다. 수정은 담당자에게 문의하세요.
                          </b>
                        </span>
                      </span>
                    </span>
                  </div>
                </dd>
              </dl>
            ) : null}
            <dl className="vertical column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">
                    캠페인명
                    <span className="fz-12 fc-5">[필수]</span>
                  </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input
                        type="text"
                        id="campNm"
                        className="tf-comm"
                        placeholder="힌트 텍스트"
                        defaultValue={campName}
                        {...register('campNm')}
                        onBlur={updateCampNameEvent}
                      />
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">
                    캠페인 목표
                    <span className="fz-12 fc-5">[필수]</span>
                  </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div
                    role="presentation"
                    className={`txt-state ${campGoalConversionActive ? 'blue' : 'black'} large`}
                    {...register('campGoalDiv')}
                    id="campGoal_conversion"
                    onClick={updateCampGoalEvent}
                  >
                    전환
                    <Link to="#" className="ico-tooltip" id="campGoalTooltip_conversion"></Link>
                  </div>
                  {adminParam.isAdmin === true && adminParam.forAdmin === true ? (
                    <div
                      role="presentation"
                      className={`txt-state ${campGoalLinkClicksActive ? 'blue' : 'black'}  large`}
                      {...register('campGoalDiv')}
                      id="campGoal_linkClicks"
                      onClick={updateCampGoalEvent}
                      hidden={adminParam.isAdmin === true && adminParam.forAdmin === true}
                    >
                      {' '}
                      트래픽
                      <Link to="#" className="ico-tooltip" id="campGoalTooltip_linkClicks"></Link>
                    </div>
                  ) : null}
                  {!adminParam.isAdmin && adminParam.forAdmin && getValues('campGoalDiv') === 'LINK_CLICKS' ? (
                    <div role="presentation" className={'txt-state blue large'}>
                      {' '}
                      트래픽
                      <Link to="#" className="ico-tooltip" id="campGoalTooltip_linkClicks2"></Link>
                    </div>
                  ) : null}
                </div>
              </dd>
            </dl>
            <dl className="vertical column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">
                    와디즈 프로젝트 선택
                    <span className="fz-12 fc-5">[필수]</span>
                  </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div
                    className={`comp-dropdown select2 expand selected ${projectValidErrYn ? 'error' : ''} ${
                      projectDisabledYn ? 'disabled' : ''
                    }`}
                  >
                    <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      <div className="box-left">
                        <i
                          className={
                            projectDivSelected === ''
                              ? ''
                              : projectDivSelected === 'STORE'
                              ? 'ico ico-store'
                              : 'ico ico-funding'
                          }
                        ></i>
                        <span className="fz-12 fw-bold">
                          {projectDivSelected === '' ? '' : projectDivSelected === 'STORE' ? '스토어' : '펀딩'}
                        </span>
                        <span className="fz-14">{projectNm}</span>
                      </div>
                      <div className="box-right">
                        <i className="ico ico-arrow"></i>
                      </div>
                    </a>
                    <ProjectSelectBox />
                  </div>
                </div>
                <div className="form-group" id="projectVaildForm" style={{ display: 'none' }}>
                  <div className="input-group" id="projectVaildGroup">
                    <p className="fz-12">캠페인을 집행할 프로젝트를 선택하세요</p>
                  </div>
                </div>
                <div className="form-group" id="projectDivForm">
                  {projectDivFormShow ? (
                    <p className="comp-txt">
                      <span className="table" id="wadizProject_table">
                        <span className="table-cell">
                          <b className="fz-12 fc-3" id="wadizProject_text">
                            {projectPerdFormShow && '•'}
                            {wadizFundingText}
                          </b>
                        </span>
                      </span>
                    </p>
                  ) : null}
                  {projectPerdFormShow ? (
                    <p className="comp-txt">
                      <span className="table" id="wadizProject_table">
                        <span className="table-cell" style={{ position: 'relative' }}>
                          <b className="fz-12 fc-3" id="wadizProjectPerd_text">
                            {projectDivFormShow && '•'}
                            프로젝트 기간 : {wadizFundingPerdText}
                          </b>
                        </span>
                      </span>
                    </p>
                  ) : null}
                </div>
              </dd>

              <Fragment>
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">
                      캠페인 기간
                      <span className="fz-12 fc-5"> [필수]</span>
                    </span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <div
                      id="datepickerTimeDiv"
                      className={`comp-datepicker ${
                        projectIdParam === null || projectIdParam === 'null' || projectIdParam === undefined
                          ? 'disabled'
                          : ''
                      } ${
                        campViewStatusParam === 'complete' ||
                        campViewStatusParam === 'del_ready' ||
                        campViewStatusParam === 'del_complete' ||
                        (!adminParam.isAdmin && adminParam.forAdmin)
                          ? 'disabled'
                          : ''
                      }`}
                    >
                      <div className="inner-datepicker">
                        <i className="ico ico-calendar"></i>
                        <input
                          type="text"
                          className="tf-comm datepicker-range time"
                          id="datepickerTime"
                          readOnly
                          disabled={
                            projectIdParam === null ||
                            projectIdParam === 'null' ||
                            projectIdParam === undefined ||
                            campViewStatusParam === 'complete' ||
                            campViewStatusParam === 'del_ready' ||
                            campViewStatusParam === 'del_complete' ||
                            (!adminParam.isAdmin && adminParam.forAdmin)
                          }
                        />
                        <span className="fz-12 fc-2 daterangepicker_span" id="datepickerTimeSpan">
                          사용자 설정
                        </span>
                      </div>
                    </div>
                    <p className="comp-txt">
                      <span className="table">
                        <span className="table-cell" style={{ position: 'relative', paddingTop: 16 }}>
                          <span style={{ display: 'flex' }}>
                            <span
                              style={{
                                display: 'flex',
                                height: '20px',
                                width: '24px',
                                padding: '0 4px',
                                marginRight: '4px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '10px',
                                lineHeight: '150%',
                                color: '#00C4C4',
                                background: '#E7F9F9',
                                borderRadius: '4px',
                              }}
                            >
                              {'TIP'}
                            </span>
                            <span style={{ color: '#495957', fontSize: '14px' }}>
                              {'캠페인 기간을 최소 5일 이상으로 설정해야 성과를 높일 수 있어요!'}
                            </span>
                          </span>
                          <b className="fz-12 fc-3">
                            {'• 와디즈 타겟 광고는 광고 성과를 높이기 위해 머신러닝 기술을 사용합니다.'}
                          </b>
                          <b className="fz-12 fc-3">
                            {'• 머신러닝은 5일이 지나야 최적화된 타겟 유저를 찾을 수 있어요.'}
                          </b>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div className="form-group" id="campPeriodVaildForm">
                    <div className="input-group" id="campPeriodVaildGroup">
                      <p className="fz-12">캠페인을 집행할 기간을 선택하세요</p>
                    </div>
                  </div>
                  {/*<div className="form-group" id="campPeriod_table" style={{ display: 'none' }}>*/}
                  {/*  <p className="comp-txt" >*/}
                  {/*                            <span className="table">*/}
                  {/*                                <span className="table-cell">*/}
                  {/*                                    <b className="fz-12 fc-3" id="campPeriod_text">총 {campPeriod}일 동안 광고가 게재됩니다.</b>*/}
                  {/*                                </span>*/}
                  {/*                            </span>*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                </dd>
              </Fragment>
            </dl>

            <dl className="vertical column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">
                    광고 노출 매체
                    <span className="fz-12 fc-5">[필수]</span>
                    <Link to="#" className="ico-tooltip tooltip-f" title="" id="mdTooltip"></Link>
                  </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div className="comp-radio block">
                    <input
                      type="radio"
                      id="radio_FBITG"
                      value="FBITG"
                      {...register('mediaDiv4')}
                      onClick={mediaDiv4ClickEvent}
                      disabled={campViewStatusParam !== 'temp' || (!adminParam.isAdmin && adminParam.forAdmin)}
                    />
                    <label htmlFor="radio_FBITG">페이스북/인스타그램</label>
                  </div>
                  <div className="comp-radio none">
                    <input
                      type="radio"
                      id="radio_FB"
                      value="FB"
                      {...register('mediaDiv4')}
                      onClick={mediaDiv4ClickEvent}
                      disabled={campViewStatusParam !== 'temp' || (!adminParam.isAdmin && adminParam.forAdmin)}
                    />
                    <label htmlFor="radio_FB">페이스북</label>
                  </div>
                  <div className="comp-radio none">
                    <input
                      type="radio"
                      id="radio_ITG"
                      value="ITG"
                      {...register('mediaDiv4')}
                      onClick={mediaDiv4ClickEvent}
                      disabled={campViewStatusParam !== 'temp' || (!adminParam.isAdmin && adminParam.forAdmin)}
                    />
                    <label htmlFor="radio_ITG">인스타그램</label>
                  </div>
                  <div className="comp-radio none">
                    <input
                      type="radio"
                      id="radio_GDN"
                      value="GDN"
                      {...register('mediaDiv4')}
                      onClick={mediaDiv4ClickEvent}
                      disabled
                    />
                    <label htmlFor="radio_GDN">구글 디스플레이 배너</label>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">
                    캠페인 총 예산
                    <span className="fz-12 fc-5"> [필수]</span>
                    <Link to="#" className="ico-tooltip tooltip-f" title="" id="campBudgetTooltip"></Link>
                  </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div className="input-group " id="campBudgetValidGroup">
                    <div className="inner-input-group">
                      <NumberFormat
                        className="tf-comm tf-unit"
                        thousandSeparator={true}
                        id="campBudget"
                        disabled={
                          projectIdParam === null ||
                          projectIdParam === 'null' ||
                          projectIdParam === undefined ||
                          campViewStatusParam === 'complete' ||
                          campViewStatusParam === 'del_ready' ||
                          campViewStatusParam === 'del_complete' ||
                          (!adminParam.isAdmin && adminParam.forAdmin)
                        }
                        placeholder="힌트 텍스트"
                        {...register('campBudgetTotal1')}
                        value={getValues('campBudgetTotal1')}
                        onBlur={updateCampBudgetEvent}
                      />
                      <span className="fz-14 fc-1">원</span>
                    </div>
                    <p className="fz-12" id="campBudgetValidText"></p>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-left">
            <button type="button" onClick={() => navigate('/mngCamp/mngCamp')} className="btn btn-primary-outline">
              캠페인 목록
            </button>
            {(campViewStatusParam === 'temp' || campViewStatusParam === undefined) && (
              <button type="button" className="btn btn-primary" onClick={validCheckEvent}>
                검수요청
              </button>
            )}
          </div>
          <div className="box-right">
            <button type="button" className="btn btn-primary" onClick={() => tabClickEvent('step02')}>
              다음
            </button>
          </div>
        </div>
        <Make02InspReqValidation ref={campInspRef} />
        <CreationDataEditingValid ref={creationDataValidRef} />
      </section>
    </Fragment>
  )
}

export default Make02;
