import React, {
  Fragment, useRef,
} from 'react';
import { Dialog } from 'rc-easyui';
import dayjs from 'dayjs';
import alertify from 'alertifyjs'

const CreationDataUpdateConfirmDialog = (props:any) => {
  const dialogRef = useRef<any>();

  const cancelEvent = () => {
      props.setUpdateConfirmDialog(false);
  };

  const updateModeEvent = () => {
      props.setUpdateConfirmDialog(false);
      props.previewModeChange();
      props.setEditMode(true);
  };
  return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '650px' }}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="wrap-section wrap-tbl">
                                <div className="box-body">
                                    <div className="tbl">
                                        <dl className="vertical">
                                            <dd>
                                                <div className="form-group">
                                                    <p className="comp-txt center">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-24 fc-1 fw-medium">소재를 수정 하시겠습니까?</b>
                                                                </span>
                                                            </span>
                                                    </p>
                                                </div>
                                            </dd>
                                        </dl>
                                        <dl className="vertical">
                                            <dd>
                                                <div className="form-group">
                                                    <p className="comp-txt">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-14 fc-4">수정이 완료된 이후에는 “변경요청” 버튼을 눌러주세요.</b>
                                                                </span>
                                                            </span>
                                                    </p>
                                                    <p className="comp-txt">
                                                            <span className="table">
                                                               <span className="table-cell">
                                                                    <b className="fz-14 fc-4">'변경요청'을 진행하지 않은 경우 수정된 소재 정보가 SNS 매체에 반영되지 않습니다.</b>
                                                                </span>
                                                            </span>
                                                    </p>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-secondary-outline" onClick={cancelEvent}>취소</button>
                            <button type="button" className="btn btn-primary" onClick={updateModeEvent}>수정하기</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>

  );
};
export default CreationDataUpdateConfirmDialog;
