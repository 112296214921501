import React from 'react';
import cx from 'classnames';

import styles from './HelperMessage.module.scss';

export interface HelperMessageProps {
  children?: string | React.ReactNode;
  isError?: boolean | 'error';
  className?: string;
}

export const HelperMessage = ({
  className,
  children,
  isError,
}: HelperMessageProps) => {
  const classes = cx(
    styles.helperMessage,
    {
      [styles.error]: !!isError,
    },
    className,
  );
  return (
    <em className={classes}>
      {children}
    </em>
  );
};

export default React.memo(HelperMessage);
