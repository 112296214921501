import React, { Fragment, useEffect, useState } from 'react';
import { Tooltip } from 'rc-easyui';

const AccAdjustInfoDet = (props:any) => {
  const [adjust, setAdjust] = useState<any>(undefined);
  const [adjustTarget, setAdjustTarget] = useState<any>(undefined);

  const comma = (str: any) => {
    if (str === undefined) return '';
    const str2 = String(str);
    return str2.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  };

  const uncomma = (str: any) => {
    if (str === undefined) return '';
    const str2 = String(str);
    return str2.replace(/[^\d]+/g, '');
  };

  useEffect(() => {
    // initialize
    // window.console.table(adjust);
    // window.console.table(adjustTarget);
  }, []);

  useEffect(() => {
    setAdjust({ ...adjust, ...props.adjust });
  }, [props.adjust]);

  useEffect(() => {
    if (adjust === undefined) {
      // nothing to do.
    } else if (adjust.plus != null) {
      setAdjustTarget({ ...adjustTarget, ...adjust.plus });
    } else if (adjust.minus != null) {
      setAdjustTarget({ ...adjustTarget, ...adjust.minus });
    }
  }, [adjust]);

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-header">
          <h2 className="fz-20 fc-1 fw-bold">임의조정 내역</h2>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl>
              <dt>
                <div className="dt-inner">
                        <span className="fz-16 fc-1">임의조정 구분</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {
                    props.adjustDiv === 'single'
                      ? <Fragment>
                        <div className="comp-radio">
                          <input type="radio" id="inp-radio-03" value='plus' disabled checked={adjust?.adjustDiv === 'plus'} />
                          <label htmlFor="inp-radio-03">가산</label>
                        </div>
                        <div className="comp-radio">
                          <input type="radio" id="inp-radio-04" value='minus' disabled checked={adjust?.adjustDiv === 'minus'} />
                          <label htmlFor="inp-radio-04">감산</label>
                        </div>
                      </Fragment>
                      : <Fragment>
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-7">기입하시는 금액에 맞추어 각 충전 사업자 번호의 비즈머니를 감산/가산 합니다.</b>
                            </span>
                          </span>
                        </span>
                      </Fragment>
                  }
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                        <span className="fz-16 fc-1">
                          임의조정 금액 (유상)
                          <Tooltip tooltipCls={'ui-corner-all ui-widget-shadow'} content={<p className="fz-12 fc-0">실제 지급되는 임의조정 금액으로<br/>세금계산서 발행 시 합계 금액에 기입됩니다.</p>}>
                            <i className="ico-tooltip"></i>
                          </Tooltip>
                        </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Input-Group : Start */}
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input type="text" className="tf-comm tf-unit" disabled value={comma(uncomma(adjust?.paidCost)) || 0} />
                      <span className="fz-12 fc-1">원</span>
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                  {/* Input-Group : End */}
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                        <span className="fz-16 fc-1">임의조정 금액 (쿠폰)</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Input-Group : Start */}
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input type="text" className="tf-comm tf-unit" disabled value={comma(uncomma(adjust?.freeCost)) || 0} />
                      <span className="fz-12 fc-1">원</span>
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                  {/* Input-Group : End */}
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                        <span className="fz-16 fc-1">
                           유효기간 (유상)
                        </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input type="text" className="tf-comm" readOnly disabled value={adjust?.paidExpireTime || ''} />
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                        <span className="fz-16 fc-1">유효기간 (쿠폰)</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input type="text" className="tf-comm" readOnly disabled value={adjust?.freeExpireTime || ''} />
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <div className="dt-inner">
                        <span className="fz-16 fc-1">임의조정 사유</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Input-Group : Start */}
                  <div className="input-group small expand">
                    <div className="inner-input-group">
                      <textarea className="tf-textarea" readOnly disabled value={adjust?.comment || ''} ></textarea>
                    </div>
                  </div>
                  {/* Input-Group : End */}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AccAdjustInfoDet;
