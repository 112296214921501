import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';

const Error = () => {
  const navigate = useNavigate();
  const goMain = () => {
    navigate('/main');
  };
  return (<Fragment>
        <div className="box-group">
            <h2 className="fz-28 fc-1 fw-medium lh15">죄송합니다.<br/>현재 찾을 수 없는 페이지를 요청하셨습니다.</h2>
            <p className="fz-14 fc-4 lh20">존재하지 않는 주소를 입력하셨거나, 요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.<br/>궁금한 점이 있으시면 언제든
                고객센터를 통해 문의해 주시기 바랍니다.</p>
            <button type="button" className="btn btn-primary large" onClick={goMain}>홈 화면으로 이동</button>
        </div>
    </Fragment>
  );
};
export default Error;
