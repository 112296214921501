import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {Dialog} from "rc-easyui";
import NoDataTemplate from "../../common/NoDataTemplate";
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import dayjs from "dayjs";
import { ApiUtil2 } from '@biz/api'
import CreationDataInspHisPreviewDialog from "./CreationDataInspHisPreviewDialog";
import CreationDataInspHisCustomTooltip from "./CreationDataInspHisCustomTooltip";
import WabizInspMsgDialog from "./WabizInspMsgDialog";

const SearchUrlMap: any = {
    admin: '/api/mngAd/creation/getInspHis',
    maker: '/api/camp/make02/getInspHis'
}
const WabizInspStatusMap: any = {
    ing: '검수요청',
    ok: '승인',
    fail: '반려'
}
const CreationTypeMap: any = {
    image: '이미지',
    video: '영상',
    slide: '슬라이드'
}

const timeRenderer = (props: ICellRendererParams) => {
    if (props.value === undefined || props.value === null) return '-';
    else return (dayjs(props.value).format('YY-MM-DD HH:mm:ss'));
}

const creationTypeRenderer = (props: ICellRendererParams) => {
    return CreationTypeMap[props.value];
}

const wabizInspStatusRenderer = (props: ICellRendererParams) => {
    return WabizInspStatusMap[props.value];
}

const CreationDataInspHisDialog = (props: any) => {
    const dialogRef = useRef<any>();
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>([]);

    // 소재 미리보기 다이얼로그
    const [showCreationDataPreviewDialog, setShowCreationDataPreviewDialog] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<any>('');

    // 소재 사유 다이얼로그
    const [isWabizInspMsgDialogOpened, setIsWabizInspMsgDialogOpened] = useState<boolean>(false);
    const [wabizInspMsg, setWabizInspMsg] = useState<boolean>(false);

    const setCreationDataPreviewParams = (data: any) => {
        setPreviewData(data);
        setShowCreationDataPreviewDialog(true);
    }

    // 소재 미리보기 다이얼로그파람값
    const previewRenderer = (props: ICellRendererParams) => {
        return (props.data.wabizInspStatus == 'ing') ? <span>  <button id={`preview${props.data.id}`}
                                                                       className="btn btn-primary xsmall"
                                                                       onClick={() => setCreationDataPreviewParams(props.data)}>미리보기</button></span> : null;
    }

    const setInspMsgAndDialogOpened = (data: any) => {
        setWabizInspMsg(data);
        setIsWabizInspMsgDialogOpened(true);
    }

    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'creationType',
            headerName: '유형',
            cellRenderer: creationTypeRenderer
        },
        {
            field: 'wabizInspStatus',
            headerName: '상태',
            cellRenderer: wabizInspStatusRenderer
        },
        {
            field: 'wabizInspReqTime',
            headerName: '검수요청일시',
            cellRenderer: timeRenderer
        },
        {
            field: 'wabizInspConfirmTime',
            headerName: '검수일시',
            cellRenderer: timeRenderer
        },
        {
            field: 'wabizInspMsg',
            headerName: '사유',
            cellRenderer: (props: any) => {
                let message = props.data.wabizInspMsg;
                return (message != null) ?
                    <span>
                        <button id={`inspMsg-${props.data.id}`}
                                className="btn btn-primary xsmall"
                                onClick={() =>
                                    setInspMsgAndDialogOpened(message)
                        }>사유 보기</button>
                    </span>
                    : null;
            }
        },
        {
            field: 'preview',
            headerName: '비고',
            cellRenderer: previewRenderer,

        },
    ]);
    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        // resizable: false,
        suppressMovable: true,
        sortable: false,
        headerClass: 'ag-center-aligned-header',
        cellClass: 'ag-center-aligned-cell',
        tooltipComponent: CreationDataInspHisCustomTooltip,
    }), []);

    const moveToCenter = () => {
        dialogRef.current.center();
    };

    const closeEvent = () => {
        props.setShowCreationDataInspHisDialog(false);
    }

    const loadData = (params: any) => {
        ApiUtil2.get(SearchUrlMap[props.searchType], {
            params: {
                creationDataId: props.creationDataId
            },
        }).then((resp) => {
            setRowData(resp.data.data);
        });
    }
    return (
        <Fragment>
            {
                isWabizInspMsgDialogOpened? <WabizInspMsgDialog setIsWabizInspMsgDialogOpened={setIsWabizInspMsgDialogOpened} wabizInspMsg={wabizInspMsg}/> : null
            }
            {
                showCreationDataPreviewDialog ? <CreationDataInspHisPreviewDialog previewData={previewData}
                                                                                  setShowCreationDataPreviewDialog={setShowCreationDataPreviewDialog}/> : null
            }
            <form>
                <Dialog title="검수이력" modal resizable shadow={false} ref={dialogRef} style={{width: '1200px'}}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="wrap-section wrap-datagrid">
                                <div className="box-body">
                                    <div className="ag-grid">
                                        <div className="ag-grid-inner">
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={rowData}
                                                rowHeight={48}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                domLayout={'autoHeight'}
                                                noRowsOverlayComponent={NoDataTemplate}
                                                onGridReady={loadData}
                                                onGridSizeChanged={moveToCenter}
                                                tooltipShowDelay={0}
                                                tooltipHideDelay={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-primary large" onClick={closeEvent}>확인</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>
    );

};

export default CreationDataInspHisDialog;
