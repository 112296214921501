import React, { useEffect } from 'react';


const UsingGuideRedirect = () => {
    useEffect(() => {
        window.open('https://bizstatic.wadiz.kr/static/usingGuide/%EC%99%80%EB%94%94%EC%A6%88+%EB%B9%84%EC%A6%88%EC%84%BC%ED%84%B0+%EC%9D%B4%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C.pdf');
        window.history.back();
    }, []);
    return null;
};

export default UsingGuideRedirect;