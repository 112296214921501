import React, { Fragment, useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { ApiUtil } from '@biz/api'

export const cookies = new Cookies();
export const setCookie = (name: string, value: string, option?: any) => cookies.set(name, value, { ...option });
export const getCookie = (name: string) => cookies.get(name);

const NoticePopUp = () => {
  const [data, setData] = useState<any[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    ApiUtil.get('/api/makerCommon/notice/listForPopUp').then((resp) => {
      setData(resp.data.data);
      setIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    for (let i = 0; i < data.length; i += 1) {
      const hiddenElement = $('<span style="visibility:hidden;white-space:nowrap;">' + data[i].text + '</span>').appendTo(`noticePopUp-${i}`);
      $(`#noticePopUp-${i}`).dialog({
        width: hiddenElement.width(), title: data[i].title, closeOnEscape: true, draggable: false, autoOpen: true, modal: false,
      });
      hiddenElement.remove();
      // eslint-disable-next-line no-loop-func
      $(document).on('click', `#close-${i}`, () => {
        $(`#noticePopUp-${i}`).dialog('close');
      });

      // eslint-disable-next-line no-loop-func
      $(document).on('click', `#noShowAnymore-${i}`, (e) => {
        setCookie(`popN-${e.target.dataset.id}`, e.target.dataset.id, { maxAge: 60 * 60 * 24 * 30 });
        $(`#noticePopUp-${i}`).dialog('close');
      });
    }
    return () => {
      for (let i = 0; i < data.length; i += 1) {
        $(`#noticePopUp-${i}`).dialog('destroy');
      }
    };
  }, [isLoaded]);

  const PopUpItem = (item: any): any => {
    const popUpCookie = getCookie(`popN-${item.item.boardId}`);
    if (Number(popUpCookie) === Number(item.item.boardId)) {
      return '';
    }
    return (
        <div className='popUpDialog' id={`noticePopUp-${item.i}`} key={item.i} style={{ display: 'none' }}>
          <div className="dialog-body">
              <span className="fz-16 fc-2" dangerouslySetInnerHTML={{ __html: item.item.text }}/>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-secondary-outline large" id={`noShowAnymore-${item.i}`} data-id={item.item.boardId}>더 이상
              보지 않기
            </button>
            <button type="button" className="btn btn-secondary-outline large" id={`close-${item.i}`}>닫기</button>
          </div>
        </div>
    );
  };

  return (
        <Fragment>
            { data.map((item:any, i:number) => (
                <PopUpItem key={i} item={item} i={i}/>))}
        </Fragment>
  );
};

export default React.memo(NoticePopUp);
