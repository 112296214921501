import { forwardRef, Fragment, useContext, useImperativeHandle, useState } from 'react'
import { CampIdContext } from './Make02Empty'
import { ApiUtil2 } from '@biz/api'
import { useQuery } from '@tanstack/react-query'
import CreationDataEditingDialog from './CreationDataEditingDialog'

interface CreationDataEditingValidInfo {
  ok: boolean
  data: boolean
}

const Make02CreationDataEditingValid = (props: any, ref: any) => {
  const { campIdParam } = useContext(CampIdContext)
  const [editingDialog, setEditingialog] = useState<boolean>(false)

  // 캠페인 조회
  const validCrationDataEditing = async () => {
    const { data } = await ApiUtil2.get<CreationDataEditingValidInfo>('/api/camp/make02/validCrationDataEditing', {
      params: { campId: campIdParam },
    })
    return data
  }
  const validCrationDataEditingApi = useQuery(['validCrationDataEditing'], validCrationDataEditing, {
    enabled: false,
  })

  const validCrationDataEditingApiEvent = () => {
    validCrationDataEditingApi.refetch().then((r) => {
      if (r.isSuccess) {
        setEditingialog(r.data.data)
      }
    })
  }

  useImperativeHandle(ref, () => ({
    validCrationDataEditingApiEvent,
  }))

  return (
    <Fragment>
      {editingDialog ? <CreationDataEditingDialog setEditingConfirmDialog={setEditingialog} /> : null}
    </Fragment>
  )
}
export default forwardRef(Make02CreationDataEditingValid)
