import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useQuery, useQueryClient } from '@tanstack/react-query' // try next react-query;
import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useRoleQuery, useShadowLoginQuery, useMemberIdQuery } from '@biz/query'
import { ShadowList } from './Header'
import alertify from 'alertifyjs'

interface ShadowListProps {
  items: ShadowList[] | undefined
  isAdmin: boolean
  setMakerNm: Dispatch<SetStateAction<string>>
  setIsShadowLogout: Dispatch<SetStateAction<boolean>>
  shadowSearchText: string
  isShadowLogout: boolean
  makerHeaderInfoEvent: any
}

const ShadowLoginList = (props: ShadowListProps) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: shadowLogin, update: setShadowLogin } = useShadowLoginQuery()
  const { update: setLoginMemberId } = useMemberIdQuery()
  const { update: setAuth } = useRoleQuery()
  const [memberId, setMemberId] = useState(0)
  // const { authFailRedirect } = useContext(AuthFailRedirectContext)
  const getAuthShadow = async () => {
    const { data } = await ApiUtil.get('/api/auth')
    return data
  }
  const getAuthShadowApi = useQuery(['getAuthShadow'], getAuthShadow, {
    enabled: false,
  })
  const shadowLoginEvent = (event: React.MouseEvent<HTMLElement>) => {
    ApiUtil2.get<ShadowList>(`/api/shadowLogin/login?makerId=${event.currentTarget.dataset.id}`).then((response) => {
      if (response.data) {
        // queryClient.invalidateQueries('curAuth');
        // 쉐도우 로그인시 상태 여부를 체크한다.
        getAuthShadowApi.refetch().then((r) => {
          if (r.isSuccess) {
            if (r.data !== undefined) {
              localStorage.removeItem('shadow')
              localStorage.removeItem('shadowRole')
              alertify.alert('쉐도우 로그인', '쉐도우 로그인성공')
              // 쉐도우 로그인 성공시 메뉴 리스트 리로딩
              props.setMakerNm(response.data.memberName)
              props.setIsShadowLogout(false)
              setMemberId(response.data.id)
              setLoginMemberId(response.data.id)
              localStorage.setItem('shadow', JSON.stringify(response.data))
              localStorage.setItem('shadowRole', JSON.stringify(r.data))
              $('#shadowLogoutBtn').show()
              queryClient.invalidateQueries(['menus'])
              queryClient.invalidateQueries(['subMenus'])
              if (r.data.indexOf('ROLE_USER_MAKER') >= 0) {
                setAuth('ROLE_USER_MAKER')
              } else {
                setAuth('ROLE_ADMIN')
              }
              if (r.data.indexOf('ROLE_ADMIN_ACTION') < 0) {
                // 권한이 없으면
                setShadowLogin(false)
              } else {
                setShadowLogin(true)
              }
              // 쉐도우 로그인 완료 후에 헤더에서 사용하는 메이커 기능 로드
              props.makerHeaderInfoEvent()
              navigate('/')
            }
          }
        })
      }
    })
  }
  const filterData = props.items?.filter((res) => {
    if (props.shadowSearchText === '') {
      return res
    }
    if (res.memberName.toLowerCase().includes(props.shadowSearchText.toLowerCase())) {
      return res
    }
    return undefined
  })

  // 브라우저에서 이중으로 쉐도우 로그인이 발생했을 경우 로그인 리스트 현재 메이커 선택 변경
  const checkShadowInfo = localStorage.getItem('shadow')
  useEffect(() => {
    if (shadowLogin) {
      if (checkShadowInfo !== null) {
        const shadowInfo = JSON.parse(checkShadowInfo)
        props.setMakerNm(shadowInfo.memberName)
        props.setIsShadowLogout(false)
        setMemberId(shadowInfo.id)
      }
    }
  }, [checkShadowInfo])

  useEffect(() => {
    // 메이커 계정 관리에서 메이커가 쉐도우 로그인했을때 호출
    if (shadowLogin) {
      const shadow = localStorage.getItem('shadow')
      if (shadow !== null) {
        const shadowInfo = JSON.parse(shadow)
        props.setMakerNm(shadowInfo.memberName)
        props.setIsShadowLogout(false)
        setMemberId(shadowInfo.id)
      }
      getAuthShadowApi.refetch().then((r) => {
        if (r.isSuccess) {
          if (r.data !== undefined) {
            localStorage.setItem('shadowRole', JSON.stringify(r.data))
            if (r.data.indexOf('ROLE_USER_MAKER') >= 0) {
              setAuth('ROLE_USER_MAKER')
            } else {
              setAuth('ROLE_ADMIN')
            }
            if (r.data.indexOf('ROLE_ADMIN_ACTION') < 0) {
              // 권한이 없으면
              setShadowLogin(false)
            } else {
              setShadowLogin(true)
            }
          }
        }
      })
    } else {
      setMemberId(0)
    }
  }, [shadowLogin])
  // useEffect(() => {
  //   // 권한 오류로 인한 좌측 메뉴와 헤더 부분 관련 변경 처리
  //   // ApiUtil2InterceptorSetup 에서 authFailRedirect를 변경 처리 후 메인으로 리다이렉트 한다.
  //   if (authFailRedirect) {
  //     const shadow = localStorage.getItem('shadow')
  //     // const shadowRoleInfo = localStorage.getItem('shadowRole');
  //     if (shadow === null) {
  //       localStorage.removeItem('shadow')
  //       localStorage.removeItem('shadowRole')
  //       props.setMakerNm('메이커 로그인')
  //       props.setIsShadowLogout(true)
  //       $('#shadowLogoutBtn').hide()
  //       setAuth('ROLE_ADMIN')
  //       setMemberId(0)
  //     } else {
  //       const shadowInfo = JSON.parse(shadow)
  //       props.setMakerNm(shadowInfo.memberName)
  //       props.setIsShadowLogout(false)
  //       $('#shadowLogoutBtn').show()
  //       setMemberId(shadowInfo.id)
  //       setAuth('ROLE_USER_MAKER')
  //     }
  //     $('#searchText').val('')
  //     queryClient.invalidateQueries(['menus'])
  //     queryClient.invalidateQueries(['subMenus'])
  //   }
  // }, [authFailRedirect])
  if (filterData !== undefined && filterData.length > 0) {
    return (
      <Fragment>
        {filterData?.map((member) => {
          // 입력한 검색어
          const searchMakerText = $('#searchText').val()
          // 검색어에 따라 리플레이스한다.
          const filterMemberNm = member.memberName.replace(
            `${searchMakerText}`,
            `<span class="txt-mark">${searchMakerText}</span>`
          )
          return (
            <li
              id={`shadowLoginList_${member.id}`}
              className={`opt-menu ${member.id === memberId ? 'selected' : ''}`}
              key={member.id}
              onClick={shadowLoginEvent}
              data-id={member.id}
              role="presentation"
            >
              {/* dangerouslySetInnerHTML 로 html 코드를 같이 삽입 */}
              <p className="fc-1">
                <span id={`shadowLogin_${member.id}`} dangerouslySetInnerHTML={{ __html: filterMemberNm }}></span>
              </p>
            </li>
          )
        })}
      </Fragment>
    )
  }
  return (
    <Fragment>
      <li className="comp-no-data">
        <div className="box-group">
          <i className="ico ico-no-search"></i>
          <p className="fz-14 fc-2">검색 결과가 없습니다.</p>
          <p className="fz-12 fc-3">검색어를 다시 입력하여 검색해주세요.</p>
        </div>
      </li>
    </Fragment>
  )
}
export default ShadowLoginList;
