import React, { Fragment, useEffect, useRef } from 'react';
import FileUploaderItgCreation from '../../fileUploader/FileUploaderItgCreation';

const CreationFileUploadItg = (props:any) => {
  const creationFileUploadRef = useRef<any>(null);
  useEffect(() => {
    $('#guideDialogOpen').click(() => {
      // $('#creationFileUploadGuideDialog').dialog({ width: 900, title: '소재 가이드', closeOnEscape: true });
      // // 오픈시 해줄것
      // $('#creationFileUploadGuideDialog').dialog('open');
      window.open('https://bizstatic.wadiz.kr/static/usingGuide/%5B%E1%84%87%E1%85%B5%E1%84%8C%E1%85%B3%E1%84%89%E1%85%A6%E1%86%AB%E1%84%90%E1%85%A5%5D%E1%84%8B%E1%85%AA%E1%84%83%E1%85%B5%E1%84%8C%E1%85%B3+%E1%84%90%E1%85%A1%E1%84%80%E1%85%A6%E1%86%BA%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%89%E1%85%A9%E1%84%8C%E1%85%A2%E1%84%80%E1%85%A5%E1%86%B7%E1%84%89%E1%85%AE+%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3+%E1%84%8C%E1%85%A5%E1%86%A8%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%80%E1%85%B5%E1%84%8C%E1%85%AE%E1%86%AB(ver.202306).pdf')
    });
    $('#fileUploadItgdialogClose').click(() => {
      creationFileUploadRef.current!.uploadInit();
      $('#creationFileUploadItgDialog').dialog('close');
    });
    $('.ico-tooltip').tooltip({
      content: '<p class="fz-12 fc-0"><br/>이미지<br/>권장 사이즈: 1080 * 1080 픽셀<br/>최소 사이즈: 500 * 500 픽셀<br/>파일 형식: JPEG, JPG, PNG, GIF<br/>파일 크기: 30MB 이내<br/><br/>영상<br/>권장 길이: 15초<br/>최대 길이: 60분<br/>화면 비율: 세로방향 (4:5)<br/소리: 사용(캡션 포함)<br/>파일형식: MP4, MOV, GIF<br/>해상도 최소 너비: 600 픽셀<br/>파일 크기: 100 MB 이내</p>',
    });
    // 파일 업로드 제출
    $('#fileCheckUploadItg').click(() => {
      creationFileUploadRef.current!.fileCheckUpload();
    });
    return () => {
      $('.tooltip').remove();
    };
  }, []);
  useEffect(() => {
    $('[id^=fileSpanIdItg_]').on('click', (e) => {
      const afFileId = e.currentTarget.id.split('_')[1];
      creationFileUploadRef.current!.previewFileSelect(afFileId, e.currentTarget.dataset.url, e.currentTarget.dataset.filediv, e.currentTarget.dataset.afname);
    });
  }, [props.makerFiles]);
  return (
  <Fragment>
    <div className="dialog-body">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-4">
            <div className="wrap-section wrap-tbl wrap-thumb-history">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      { props.makerFiles.length !== 0
                        ? props.makerFiles.map((files:any, index:any) => {
                          const makeKey = `itgCreationFile_${index}`;
                          return (<div className="form-group" key={makeKey}>
                              <div className="flex-container">
                                {files.map((file:any) => {
                                  const makeFileKey = `fileDivKeyItg_${file.id}`;
                                  const makeFileId = `fileDivIdItg_${file.id}`;
                                  const makeFileSpanId = `fileSpanIdItg_${file.id}`;
                                  const { width } = file.afMeta;
                                  const { height } = file.afMeta;

                                  $(`#${makeFileSpanId}`).text(`${width}x${height}`);
                                  return (
                                      <div className="box-group col-10-5" key={makeFileKey}>
                                        <div className="comp-thumb">
                                          <span className="txt-size" id={makeFileSpanId} data-url={file.fileName} data-filediv={file.afMeta.fileDiv} data-afname = {file.afName}>{width}x{height}</span>
                                          {file.afMeta.fileDiv === 'VIDEO'
                                          // eslint-disable-next-line jsx-a11y/media-has-caption
                                            ? <video
                                                  id={makeFileId}
                                                  autoPlay
                                                  controls
                                                  muted
                                                  loop
                                                  // style={{ width: '100%' }}
                                                  src={`${file.fileName}`}
                                              >
                                              </video>
                                            : <img
                                                  id={makeFileId}
                                                  src={`${file.fileName}`}
                                                  // style={{ width: '100%' }}
                                                  alt=""/>
                                          }
                                        </div>
                                      </div>
                                  );
                                })}
                              </div>
                            </div>);
                        })
                        : null
                      }
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-8">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <button type="button" className="btn btn-primary" id="guideDialogOpen">소재 가이드</button>
                      </div>
                      <FileUploaderItgCreation ref={creationFileUploadRef} />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="dialog-footer">
      <button type="button" className="btn btn-secondary-outline large" id="fileUploadItgdialogClose" >취소</button>
      <button type="button" className="btn btn-primary large" id='fileCheckUploadItg'>제출</button>
    </div>
  </Fragment>);
};
export default CreationFileUploadItg;
