import axios from 'axios'
import alertify from 'alertifyjs'
// const { protocol } = window.location;
// const { host } = window.location;

import { baseOptions } from '../axios/baseOptions'
let gErrDiv = ''

const ApiUtil2 = axios.create({
  ...baseOptions,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (params) => {
    return Object.keys(params)
      .map(
        (key) =>
          `${key}=${
            typeof params[key] === 'object' || key === 'data'
              ? encodeURIComponent(JSON.stringify(params[key]))
              : params[key]
          }`
      )
      .join('&')
  },
})

ApiUtil2.interceptors.request.use(
  (config) => {
    // 요청 로딩바
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.remove('none')
    return config
  },
  (error) => {
    // 에러시 로딩 종료
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.add('none')
    alertify.error(`ApiUtil2.interceptors.request11 ${error.message}`)
    return Promise.reject(error)
  }
)
ApiUtil2.interceptors.response.use(
  (response) => {
    // 권한 해제로 인한 API 호출 오류시
    if (response.data !== undefined || response.data.data !== undefined) {
      if (response.data.ok !== undefined && !response.data.ok) {
        if (response.data.data.msg !== undefined) {
          const commonLoader = document.querySelector('.loader')
          if (commonLoader !== null) commonLoader.classList.add('none')
          const errDiv = document.querySelector('.ajs-message.ajs-error.ajs-visible')! as any
          if (errDiv !== null) {
            gErrDiv = errDiv
            if (!errDiv.textContent.includes(response.data.data.msg) && response.data.data.action === undefined) {
              alertify.error(response.data.data.msg)
            }
          }
          if (gErrDiv === '' && errDiv === null && response.data.data.action === undefined) {
            alertify.error(response.data.data.msg)
          } else if (errDiv !== gErrDiv && response.data.data.action === undefined) {
            alertify.error(response.data.data.msg)
          }
          // const errDiv2 = (document.querySelector<HTMLElement>('.ajs-message.ajs-error.ajs-visible')! as any);
          // gErrDiv = errDiv2;
          gErrDiv = ''
          return Promise.reject(response)
        }
      } else {
        gErrDiv = ''
      }
    }
    // 그리드의 height 사이즈 변경으로 인한 공통 처리 부분
    const grid = document.querySelector<HTMLElement>(
      '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height'
    )! as any
    if (grid !== null) {
      if (response.data !== undefined) {
        if (response.data.length !== undefined) {
          if (response.data.length === 0) {
            ;(
              document.querySelector<HTMLElement>(
                '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height'
              )! as any
            ).style.height = '548px'
            ;(
              document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any
            ).style.height = '548px'
          } else {
            ;(
              document.querySelector<HTMLElement>(
                '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height'
              )! as any
            ).style.height = ''
            ;(
              document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any
            ).style.height = ''
          }
        }
      }
      if (response.data.data !== undefined) {
        if (response.data.data.length === 0) {
          ;(
            document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any
          ).style.height = '548px'
          ;(document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height =
            '548px'
        } else {
          ;(
            document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any
          ).style.height = ''
          ;(document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height =
            ''
        }
      }
    }
    // 요청완료 로딩 종료
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.add('none')
    return response
  },
  (error) => {
    // 에러시 로딩 종료
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.add('none')
    const errDiv3 = document.querySelector<HTMLElement>('.ajs-message.ajs-error.ajs-visible')! as any
    if (errDiv3 !== null) {
      gErrDiv = errDiv3
    }
    if (gErrDiv === '' && errDiv3 === null) {
      if (error.response?.data?.data != null) {
        alertify.error(error.response.data.data.msg)
      } else {
        alertify.error(error.message)
      }
    } else if (errDiv3 !== gErrDiv) {
      alertify.error(error.message)
    }
    gErrDiv = errDiv3

    return Promise.reject(error)
  }
)

export { ApiUtil2 }
