const COLOR = Object.freeze({
  BLUE_100: '#eff3fd',
  BLUE_200: '#d0dbfb',
  BLUE_300: '#b1c3f9',
  BLUE_400: '#99b2ff',
  BLUE_500: '#7396ff',
  BLUE_600: '#557cf2',
  BLUE_700: '#4167d9',
  BLUE_800: '#395bbf',

  GREY_000: '#f9fafb',
  GREY_100: '#f2f4f6',
  GREY_200: '#e9ecef',
  GREY_300: '#dde2e6',
  GREY_400: '#cdd3d8',
  GREY_500: '#adb5bd',
  GREY_600: '#868e96',
  GREY_700: '#495057',
  GREY_800: '#343a40',
  GREY_900: '#212529',

  MINT_100: '#e7f9f9',
  MINT_200: '#b9eeee',
  MINT_300: '#8be4e4',
  MINT_400: '#5cdede',
  MINT_500: '#2ed5d5',
  MINT_600: '#00c4c4',
  MINT_700: '#00b2b2',
  MINT_800: '#00a2a2',

  RED_100: '#fff1f1',
  RED_200: '#ffd5d5',
  RED_300: '#ffb9b9',
  RED_400: '#ff9d9d',
  RED_500: '#ff8181',
  RED_600: '#f66',
  RED_700: '#f25555',
  RED_800: '#e54545',

  YELLOW_100: '#fff8e7',
  YELLOW_200: '#ffeab9',
  YELLOW_300: '#ffdd8b',
  YELLOW_400: '#ffcc4c',
  YELLOW_500: '#ffbe1a',
  YELLOW_600: '#ffad15',
  YELLOW_700: '#faa60a',
  YELLOW_800: '#f69301',

  RGBA_LIGHT_68: 'rgba(255, 255, 255, 0.68)',
  RGBA_LIGHT_84: 'rgba(255, 255, 255, 0.84)',
  RGBA_LIGHT_100: '#ffffff',

  RGBA_DARK_30: 'rgba(0, 0, 0, 0.3)',
  RGBA_DARK_54: 'rgba(0, 0, 0, 0.54)',
  RGBA_DARK_68: 'rgba(0, 0, 0, 0.68)',
  RGBA_DARK_100: '#000',
});

export default COLOR;
