import React, {Fragment, useEffect, useRef} from 'react';
import {Dialog} from "rc-easyui";

const WabizInspMsgDialog = (props: any) => {
    const dialogRef = useRef<any>();

    const closeEvnet = () => {
        props.setIsWabizInspMsgDialogOpened(false);
    }
    return (
        <Fragment>
            <Dialog title='소재 검수 사유' modal resizable shadow={false} ref={dialogRef} style={{ width: '600px' }}>
            <div className="dialog">
                    <div className="dialog-body">
                        <section className="wrap-section wrap-tbl wrap-terms">
                            <div className="box-body" id="bs">
                                <div className="tbl">
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <ul className="inner-content-terms">
                                                    <li>
                                                        <span className="fz-14">{props.wabizInspMsg}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline large" onClick={closeEvnet}>닫기</button>
                    </div>
            </div>
            </Dialog>
        </Fragment>
    );
};

export default WabizInspMsgDialog;