import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ApiUtil2 } from '@biz/api'
import NoticePopUp from './NoticePopUp'
import NoNoticeTemplate from '../../common/NoNoticeTemplate'

const NoticeForHome = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    ApiUtil2.get('/api/makerCommon/notice/listForHome', {
      params: { data: { boardDiv: 'ALL' } },
    }).then((resp) => {
      setData(resp.data.data)
    })
  }, [])
  const NoticeItem = (item: any) => {
    const divTap: any = {
      시스템: <div className="txt-state red">시스템</div>,
      이벤트: <div className="txt-state mint">이벤트</div>,
      가이드: <div className="txt-state blue">가이드</div>,
      일반: <div className="txt-state black">일반</div>,
    }
    const formattedDate = `${item.item.regTime.date.year}-${
      item.item.regTime.date.month < 10 ? `0${item.item.regTime.date.month}` : item.item.regTime.date.month
    }-${item.item.regTime.date.day < 10 ? `0${item.item.regTime.date.day}` : item.item.regTime.date.day}`
    return (
      <div className="form-group">
        <span className="fz-14 fc-4">{formattedDate}</span>
        {divTap[item.item.boardDiv]}
        <Link to={`/makerCommon/notice/detail/${item.item.boardId}`} className="fz-14 fc-2 txt-dot">
          {item.item.title}
        </Link>
      </div>
    )
  }

  const navigator = useNavigate()
  return (
    <Fragment>
      <NoticePopUp />
      <div className="row">
        <div className="col col-12">
          <div className="box box-notice-list">
            <section className="wrap-section wrap-tbl">
              <div className="box-header">
                <div className="box-tit">
                  <h2 className="fz-20 fw-bold fc-1">공지사항</h2>
                </div>
                <div className="box-option">
                  <button
                    type="button"
                    className="btn btn-secondary-txt btn-ico"
                    onClick={() => navigator('/makerCommon/notice')}
                  >
                    <i className="ico ico-more"></i>더보기
                  </button>
                </div>
              </div>
              <div className="box-body">
                {data.length === 0 ? (
                  <NoNoticeTemplate />
                ) : (
                  <div className="tbl">
                    <dl className="vertical">
                      <dd id="list">
                        {data.map((item: any, index: any) => (index < 7 ? <NoticeItem key={index} item={item} /> : ''))}
                      </dd>
                    </dl>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default React.memo(NoticeForHome);
