import React, {
  createContext,
  Fragment, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useUsableCostQuery } from '@biz/query'
import {
  AdminContext,
  CampIdContext,
  CampTabClickContext,
  CampViewStatusContext,
  CatProhUpdateYnContext,
  CatProhValidDataContext,
  GdnCreationDataIdContext,
  ExistEditingContext,
  GdnCreationFileUploadIdContext,
} from './Make02Empty'
import Make02Step03CreationFb, { fbCreationFormData } from './Make02Step03CreationFb'
import MasonryLayout from './MasonryLayout'
import CreationFileUploadFb from './CreationFileUploadFb'
import CreationFileUploadGuide from './CreationFileUploadGuide'
import alertify from 'alertifyjs'
import Make02InspReqValidation from './Make02InspReqValidation';
import dayjs from "dayjs";
import {useNavigate} from "react-router";
import Make02Step03PageLeaveDialog from "./Make02Step03PageLeaveDialog";
import CreationDataEditingValid from "./Make02CreationDataEditingValid";
import { useCallbackPrompt } from '../../util/useCallbackPrompt';
import CampAttachDialog from "./CampAttachDialog";

interface IFileTypes {
  uploadId: number;
  fileId: number;
  publicUrl: string;
  afName: string;
  fileName: string;
  imageDiv: string;
  object: File;
}
interface CampData {
  ok: boolean;
  campId:number;
  campNm: string;
  campStartTime: string;
  campEndTime: string;
  campBudgetTotal1: number;
  adminCampYn: boolean;
  mediaDiv4:string;
  projectId:string;
  payType:string;
}
interface CampDatas {
  ok: boolean;
  data:CampData;
}
interface fbCreationData{
  ok: boolean;
  id: number;
  adTitle01: string;
  adTitle02: string;
  adDesc: string;
  sortOrder: number;
  clickBtnDiv:string;
  preview: boolean;
  // 수정 불가능.
  nonEditable: boolean;
  afList: IFileTypes[];
  campBudget:number;
  creationViewStatus:string;
  adSetDatas:any;
  clickBtnDivs: string[];
  wabizInspStatus:string;
  editStatus: string;
  wabizInspConfirmTime: string;
  blockSubMenu: boolean;
}
interface fbCreationLink{
  ok: boolean;
  id:number;
  snsApiOkYn:boolean;
  snsAdId:number;
  syncLastTime:string;
  adSetOrder:number;
  creationViewStatus:string;
  creationData:fbCreationData;
  projectId: string;
}
interface fbCreationLinks{
  ok: boolean;
  data:fbCreationLink[];
}
interface fbAdSetStatusData {
  ok:boolean;
  id:number;
  creationDataId:string;
  creationViewStatus:string;
  adSetOrder:number;
  useYn:boolean;
}
interface fbAdSetStatusDatas {
  ok:boolean;
  data:fbAdSetStatusData[];
}
interface CalculateBalance{
  ok:boolean;
  data:any;
  campId:number;
  // changeCampBudget:number;
  additionalCharge: boolean;
  changeCampBudgetTotal1: number;
  cost:number;
}
interface CampBizmoneyInfo {
  ok: boolean;
  data:any;
  currentCost: number;
  payType: string;
}
export const reloadCountContext = createContext({
  reloadCount: 0,
  setReloadCount: (value: any) => value,
});
let globalTimeout: string | number | NodeJS.Timeout | null | undefined = null;
const Make02Step03Fb = () => {
  const navigate = useNavigate();
  const campInspFbStep03Ref = useRef<any>(null);
  const creationDataValidStep03Ref = useRef<any>(null);
  const { campIdParam } = useContext(CampIdContext);
  const { setTabClick } = useContext(CampTabClickContext);
  const { setGdnFileUploadId } = useContext(GdnCreationFileUploadIdContext);
  const { gdnCreationDataId } = useContext(GdnCreationDataIdContext);
  const { campViewStatusParam } = useContext(CampViewStatusContext);
  const { adminParam } = useContext(AdminContext);
  const { update: setUsableCostChange } = useUsableCostQuery()
  const { getValues, setValue } = useForm<fbCreationFormData>();
  const calculateBalanceForm = useForm<CalculateBalance>();
  const [campFbCreationForm, setCampFbCreationForm] = useState<any>([]);
  const [makerFiles, setMakerFiles] = useState<any>([]);
  const [reloadCount, setReloadCount] = useState<any>(0);
  const [editStatuses, setEditStatuses] = useState<string[]>([]);
  const [project2Id, setProject2Id] = useState<string>('');

  const { existEditing, setExistEditing } = useContext(ExistEditingContext);

  // 페이지 이탈 관련
  const [showPageLeaveDialog, setShowPageLeaveDialog] = useState<boolean>(false);
  const [showPageLeaveDialog2, setShowPageLeaveDialog2] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showPageLeaveDialog)

  const [isReComponentComplete, setIsReComponentComplete] = useState<boolean>(false);

  // 소재 추가 버튼 과클릭 방지 true 일때 추가 액션이 동작한다.
  const [isAddCreationBtn, setIsAddCreationBtn] = useState<any>(true);
  // 비즈머니 or 후불머니
  const getMoney = async () => {
    const { data } = await ApiUtil.get<CampBizmoneyInfo>('/api/camp/make02/getMoneyInfo',{ params: { campId:  campIdParam, payType: getValues('payType')  } });
    return data;
  };
  const bizmoneyApi = useQuery(['getBizmoney'], getMoney, { enabled: false })
  const calculateBalanceApi = async () => {
    const { data } = await ApiUtil2.get<CalculateBalance>('/api/camp/make02/calculateBalance', { params: { campId: campIdParam } });
    return data;
  };
  const calculateBalanceEvent = useQuery(['calculateBalanceApi'], calculateBalanceApi, {
    enabled: false,
  })
  const getFbCreationDatas = async () => {
    const { data } = await ApiUtil2.get<fbCreationLinks>('/api/camp/make02/fbCreationDataSearch', { params: { campId: campIdParam } });
    return data;
  };
  const getFbCreationDatasApi = useQuery(['getFbCreationDatas'], getFbCreationDatas, {
    enabled: false,
  })

  const fbCreationLinkSearchApi = async () => {
    const { data } = await ApiUtil2.get<fbAdSetStatusDatas>('/api/camp/make02/fbCreationLinkSearch', { params: { campId: campIdParam } });
    return data;
  };

  const fbCreationLinkSearchEvent = useQuery(['fbCreationLinkSearch'], fbCreationLinkSearchApi, {
    enabled: false,
  })

  // 등록 처리
  const fbCreationDataInsertApi = () => {
    const {
      campId, sortOrder, clickBtnDiv, isCampBudgetChange, changeCampBudget,
    } = getValues();
    const result = ApiUtil2.post<fbCreationFormData>('/api/camp/make02/fbCreationDataInsert', {
      campId, sortOrder, clickBtnDiv, isCampBudgetChange, changeCampBudget,
    });
    return result;
  };
  const fbCreationDataInsert = useMutation(fbCreationDataInsertApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 상단 비즈머니 업데이트
        if (getValues('isCampBudgetChange') && getValues('payType') === 'BIZ_MONEY') {
          bizmoneyApi.refetch().then((r) => {
            if (r.isSuccess) {
              setUsableCostChange(r.data.data.currentCost.toLocaleString());
            }
          });
        }
        setValue('isCampBudgetChange', false);
        // eslint-disable-next-line no-use-before-define
        setReComponent();
      }
    },
  });

  const getCampDatas = async () => {
    const { data } = await ApiUtil2.get<CampDatas>('/api/camp/make02/getCampInfo', { params: { campId: campIdParam } });
    return data;
  };
  const getCampDatasApi = useQuery(['getCampDatas'], getCampDatas, {
    enabled: false,
  })
  // 행동유도버튼 데이터 가져오기
  const getClickBtnDiv = async () => {
    const { data } = await ApiUtil2.get('/api/camp/make02/fbClickBtnInfos', { params: { campId: campIdParam } });
    return data;
  };
  const getClickBtnDivApi = useQuery(['getClickBtnDiv'], getClickBtnDiv, {
    enabled: false,
  })
  const tabClickEvent = (value:any) => {
    if(existEditing){
      setShowPageLeaveDialog2(true);
    }else{
      setTabClick(value);
    }
  };

  // 등록
  const AddFbCreation = useCallback(() => <Fragment>{ campFbCreationForm }</Fragment>, [campFbCreationForm]);
  const fbCreationDataInsertEvent = (isReLoading:boolean) => {
    // 소재 추가 + 버튼 과다클릭 방지를 위한...
    setIsAddCreationBtn(false);
    setValue('sortOrder', campFbCreationForm.length + 1);
    setValue('clickBtnDiv', 'LEARN_MORE_FB');
    fbCreationDataInsert.mutate();

    if (isReLoading) {
      // eslint-disable-next-line no-use-before-define
      // setReComponent();
    }
  };
  // 삭제
  const removeCreationFbEvent = () => {
    // eslint-disable-next-line no-use-before-define
    setReComponent();
  };
  const creationComponentRefresh = () => {
    // eslint-disable-next-line no-use-before-define
    setReComponent();
  };
  // 복사
  const copyCreationFbEvent = () => {
    if (campFbCreationForm.length >= 10) {
      alertify.error('소재는 10개까지 추가 가능합니다.');
      $('#fbAddCreationBtn').attr('disabled', 'disabled');
    } else {
      // eslint-disable-next-line no-use-before-define
      setReComponent();
    }
  };
  // 빈 소재 추가
  const addCreationFbEvent = () => {
    if ((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' && (adminParam.isAdmin && adminParam.forAdmin))
        || (campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' && (!adminParam.isAdmin && !adminParam.forAdmin))
        || (campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' && (!adminParam.isAdmin && !adminParam.forAdmin))
        || (campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' && (adminParam.isAdmin && !adminParam.forAdmin))
        || (adminParam.isAdmin && adminParam.forAdmin)) {

      if (campFbCreationForm.length >= 10) {
        alertify.error('소재는 10개까지 추가 가능합니다.');
        $('#fbAddCreationBtn').attr('disabled', 'disabled');
      } else if (getValues('campBudgetTotal1') !== undefined) {


        calculateBalanceEvent.refetch().then((r) => {
          if (r.isSuccess) {
            if(r.data.data.additionalCharge){
              alertify.confirm(`소재당 1일 최소 5천원의 예산이 필요합니다. 캠페인 총 예산을${r.data.data.changeCampBudgetTotal1.toLocaleString()}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 ${getValues('payType') === 'POST_PAYMENT'? '후불머니' : '비즈머니'} 에서 ${r.data.data.cost.toLocaleString()} 원 (VAT포함)이 차감됩니다.`, () => {
                setValue('isCampBudgetChange', true);
                fbCreationDataInsertEvent(true);
              }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
            } else {
              fbCreationDataInsertEvent(true);
            }
          }
        });

        // 기타상태일 경우 가져온 캠페인 예산을 가지고 소재 추가시 예산 체크한다.(비즈머니 체크는?)
        // const startDays = dayjs(dayjs(getValues('campStartTime')).format('YYYY-MM-DD'));
        // const endDays = dayjs(dayjs(getValues('campEndTime')).format('YYYY-MM-DD'));
        // const periodData = Number(endDays.diff(startDays, 'day', false) + Number(1));
        // const chaBudget = (campFbCreationForm.length + 1) * 5000 * periodData;
        //
        // // 현재 설정된 예산체크 추가했을때 예산이 초과되면??
        // if (getValues('campBudgetTotal1') < chaBudget) {
        //   const changeCampBudgetTxt = chaBudget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        //   // calculateBalanceForm.setValue('changeCampBudget', chaBudget);
        //   calculateBalanceEvent.refetch().then((r) => {
        //     if (r.isSuccess) {
        //       alertify.confirm(`소재당 1일 최소 5천원의 예산이 필요합니다. 캠페인 총 예산을${changeCampBudgetTxt}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 ${getValues('payType') === 'POST_PAYMENT'? '후불머니' : '비즈머니'} 에서 ${r.data.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 (VAT포함)이 차감됩니다.`, () => {
        //         setValue('changeCampBudget', chaBudget);
        //         setValue('isCampBudgetChange', true);
        //         fbCreationDataInsertEvent(true);
        //       }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        //     }
        //   });
        // } else {
        //   fbCreationDataInsertEvent(true);
        // }
      } else {
        fbCreationDataInsertEvent(true);
      }
    }
  };
  // 컴포넌트 재생성
  const setReComponent = () => {
    if (globalTimeout != null) clearTimeout(globalTimeout);
    // eslint-disable-next-line no-use-before-define
    globalTimeout = setTimeout(() => searchFbCreation(), 200);
    setIsReComponentComplete(true);
  };
  const setMakerFilesEvent = (files:any) => {
    setMakerFiles(files);
  };
  const validCheckEvent = () => {
    campInspFbStep03Ref.current!.validCheckEvent();
  };
  const dataLoad2 = (callback:any) => {
    const creationLinkDatas:any = [];
    getCampDatasApi.refetch().then((rr) => {
      if (rr.isSuccess) {
        setValue('campBudgetTotal1', rr.data.data.campBudgetTotal1);
        setValue('campStartTime', rr.data.data.campStartTime);
        setValue('campEndTime', rr.data.data.campEndTime);
        setValue('payType', rr.data.data.payType);

        fbCreationLinkSearchEvent.refetch().then((rr2) => {
          if (rr2.isSuccess) {
            rr2.data.data.forEach((data) => {
              creationLinkDatas.push(data);
            });
            callback(creationLinkDatas);
          }
        });
      }
    });
  };

  const allDataLoad = (creationLinkDatas:any) => {
    getFbCreationDatasApi.refetch().then((r) => {
      if (r.isSuccess) {
        // 페이스북 인스타그램은 행동 유도 버튼
        setProject2Id(r.data.data[0].projectId);
        getClickBtnDivApi.refetch().then((rr) => {
          if (rr.isSuccess) {
            setValue('clickBtnDivs', rr.data.data);
            // 초기화
            const defaultDatas: fbCreationData[] = [];
            const editStatusArr: string[] = [];
            r.data.data.map((data, index) => {
              const tempData = data.creationData;
              editStatusArr.push(tempData.editStatus);
              tempData.creationViewStatus = data.creationViewStatus;
              tempData.clickBtnDivs = getValues('clickBtnDivs');
              // 수정 선택된 소재ID에 대해 미리보기 false
              if (gdnCreationDataId !== null) {
                if (`${tempData.id}_` === `${gdnCreationDataId}_`) {
                  tempData.preview = false;
                } else {
                  tempData.preview = true;
                }
              } else if (r.data.data.length === index + 1) {
                tempData.preview = false;
              } else {
                tempData.preview = true;
              }
              if ((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete')
                  || (campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' && (!adminParam.isAdmin && !adminParam.forAdmin))) {
                const filterAdsetDatas = creationLinkDatas.filter((d:any) => `${d.creationDataId}` === `${tempData.id}`).map((dd:any) => dd);
                tempData.adSetDatas = filterAdsetDatas;
                tempData.campBudget = getValues('campBudgetTotal1');
              }

              // 관리자가 해당 소재를 승인 or 반려했는지 확인한다.
              if(tempData.wabizInspConfirmTime !== null && tempData.wabizInspConfirmTime !== undefined){
                // 서브메뉴를 보여줄것인지 아닌지 판별함.
                tempData.blockSubMenu = false;
                // 관리자가 소재를 승인한 시점 이후 , 메타에 동기화가 완료되기 전
                if((data.syncLastTime === undefined ||tempData.wabizInspConfirmTime === undefined || data.syncLastTime < tempData.wabizInspConfirmTime) && tempData.wabizInspStatus === 'ok') {
                  tempData.preview = true;
                  tempData.nonEditable = true;
                  tempData.blockSubMenu = true;
                } else if(tempData.editStatus === 'editing') {
                  tempData.preview = false;
                } else {
                  tempData.preview = true;
                }

              }

              // 종료일 경우 모두 미리보기 모드로만...
              if (campViewStatusParam === 'complete' || campViewStatusParam === 'del_ready' || campViewStatusParam === 'del_complete' || (!adminParam.isAdmin && adminParam.forAdmin)) {
                tempData.preview = true;
              }
              defaultDatas.push(tempData);
              return tempData;
            });
            setEditStatuses(editStatusArr);
            setExistEditing(editStatusArr.includes("editing"));
            if (defaultDatas.length >= 10) {
              defaultDatas.sort((a, b) => b.sortOrder - a.sortOrder);
              const components = defaultDatas.map((data:any) => <Make02Step03CreationFb step3InspReqValidEvent={validCheckEvent} setMakerFilesEvent={setMakerFilesEvent} maxSize={defaultDatas.length} creationComponentRefresh={ creationComponentRefresh} copyCreationGdnEvent={copyCreationFbEvent} creationData={data} creationLinkData={data.adSetDatas} key={data.sortOrder} order={data.sortOrder} removeCreationFbEvent={removeCreationFbEvent} payType = {getValues('payType')}/>);
              setCampFbCreationForm(components);
              $('#fbAddCreationBtn').attr('disabled', 'disabled');
            } else {
              defaultDatas.sort((a, b) => b.sortOrder - a.sortOrder);
              const components = defaultDatas.map((data:any) => <Make02Step03CreationFb step3InspReqValidEvent={validCheckEvent} setMakerFilesEvent={setMakerFilesEvent} maxSize={defaultDatas.length} creationComponentRefresh={ creationComponentRefresh} copyCreationGdnEvent={copyCreationFbEvent} creationData={data} creationLinkData={data.adSetDatas} key={data.sortOrder} order={data.sortOrder} removeCreationFbEvent={removeCreationFbEvent} payType = {getValues('payType')}/>);
              setCampFbCreationForm(components);
              $('#fbAddCreationBtn').removeAttr('disabled');
            }
            setReloadCount(0);
            // 소재 추가 + 버튼 과다클릭 방지를 위한...
            setIsAddCreationBtn(true);
          }
        });
      }
    });
  };
  // 소재 초기 세팅
  const searchFbCreation = () => {
    globalTimeout = null;
    let creationLinkDatas:any = [];
    if ((campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete')
        || (campViewStatusParam !== null && campViewStatusParam !== 'temp' && campViewStatusParam !== 'complete' && campViewStatusParam !== 'del_ready' && campViewStatusParam !== 'del_complete' && (!adminParam.isAdmin && !adminParam.forAdmin))) {
        dataLoad2((callbackData:any) => {
        creationLinkDatas = callbackData;
        allDataLoad(creationLinkDatas);
      });
    } else {
      allDataLoad(creationLinkDatas);
    }
  };

  useEffect(() => {
    if(campIdParam !== null){
      creationDataValidStep03Ref.current!.validCrationDataEditingApiEvent()
    }
  }, [campIdParam]);
  useEffect(() => {
    if (reloadCount !== 0) {
      setReComponent();
    }
  }, [reloadCount]);

  useEffect(() => {
    if(editStatuses.includes('editing')){
      setExistEditing(true);
      setShowPageLeaveDialog(true);
    }
  }, [editStatuses]);

  useEffect(() => {
    if(isReComponentComplete){
      MasonryLayout();
      window.addEventListener('resize', MasonryLayout);
    }
  }, [isReComponentComplete]);
  useEffect(() => {
    if (campIdParam !== null) {
      setValue('campId', campIdParam);
    }
    setReComponent();

    if (campViewStatusParam === 'complete' || campViewStatusParam === 'del_ready' || campViewStatusParam === 'del_complete') {
      $('#creationAddFbBtn').hide();
    } else {
      ApiUtil.get('/api/common/getUploadId')
        .then((response) => {
          setGdnFileUploadId(response.data.data);
        });
    }
    return () => {
      // 여기서 다이얼로그 관련된것 destory(필수)
      const dialogCheck = $('#creationFileUploadFbDialog');
      const dialogCheck2 = $('#creationFileUploadGuideDialog');
      if (dialogCheck.length > 0) {
        $('#creationFileUploadFbDialog').dialog('destroy');
      }
      if (dialogCheck2.length > 0) {
        $('#creationFileUploadGuideDialog').dialog('destroy');
      }
      $('.tooltip').remove();
    };
  }, []);
  /** 231211 M0011 */
  const [isAttachDialogOpened, setIsAttachDialogOpened] = useState<boolean>(false);
  return (
      <Fragment>
        {
          showPrompt? <Make02Step03PageLeaveDialog showDialog={showPrompt} confirmNavigation = {confirmNavigation} cancelNavigation = {cancelNavigation} mode={'navi'} /> : null
        }
        {
          showPageLeaveDialog2? <Make02Step03PageLeaveDialog setShowPageLeaveDialog={setShowPageLeaveDialog2} mode={'tab'} tabAfter={'step02'} tabBefore={'step03'} setTabClick={setTabClick}/> : null
        }
        {
          isAttachDialogOpened ? <CampAttachDialog project2Id={project2Id} campId={getValues('campId')} setIsAttachDialogOpened={setIsAttachDialogOpened} campViewStatusParam={campViewStatusParam}/> : null
        }
        <section className="wrap-section wrap-tbl">
          <div className="box-body">
            <div className="tbl">
              <dl className="vertical">
                <dd>
                  <div className="form-group">
                    <div className="container-masonry">
                      <div className="masonry-item" id="creationAddFbBtn" style={{ gridRowEnd: 'span 64' }}>
                        <div className="wrap-preview">
                          <div className="box-body">
                            <div className="box-group flex-container-center h-749">
                              { isAddCreationBtn ? <button type="button" className="btn btn-add" id="fbAddCreationBtn" onClick={addCreationFbEvent}></button> : <button type="button" className="btn btn-add"></button> }
                            </div>
                          </div>
                        </div>
                      </div>
                      { campFbCreationForm.length > 0 ? <AddFbCreation/> : null }
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div id="creationFileUploadFbDialog" className="dialog" style={{ display: 'none' }}>
            <reloadCountContext.Provider value={{ reloadCount, setReloadCount }}>
              <CreationFileUploadFb makerFiles={makerFiles}/>
            </reloadCountContext.Provider>
          </div>
          <div id="creationFileUploadGuideDialog" className="dialog" style={{ display: 'none' }}>
            <CreationFileUploadGuide />
          </div>
          <div className="box-footer">
            <div className="box-left">
              <button type="button" onClick= {() => navigate('/mngCamp/mngCamp')} className="btn btn-primary-outline">캠페인 목록</button>
              {
                  (campViewStatusParam === 'temp' || campViewStatusParam === undefined)
                  && <button type="button" className="btn btn-primary" onClick={validCheckEvent}>검수요청</button>
              }
              <button type="button" onClick= {() => setIsAttachDialogOpened(true)} className="btn btn-secondary-outline">증빙 서류 첨부</button>
            </div>
            <div className="box-right">
              <button type="button" className="btn btn-secondary-outline" onClick={() => tabClickEvent('step02')}>이전</button>
            </div>
          </div>
          <Make02InspReqValidation ref={campInspFbStep03Ref}/>
          <CreationDataEditingValid ref={creationDataValidStep03Ref} />
        </section>
      </Fragment>
  );
};
export default Make02Step03Fb;
