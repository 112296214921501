import React, {Fragment,} from 'react';


const FailCreationDialog = (props : any) => {

    return (
        <Fragment>
            <form>
                <div id="returnDialog" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl no-border">
                            <div className="box-body">
                                <div className="input-group expand">
                                    <div className="inner-input-group">
                                        <input id="campId" type="hidden" defaultValue={props.campId!!}/>
                                        <input id="creationDataId" type="hidden" defaultValue={props.creationDataId?.toString()}/>
                                        <textarea id="msg" className="tf-textarea" placeholder="반려사유를 입력해주세요."></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id="closeBtn">취소</button>
                        <button type="button" className="btn btn btn-primary" id="okBtn">반려</button>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};

export default FailCreationDialog;
