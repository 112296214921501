import { ConfigProvider, type ThemeConfig } from 'antd';

export const antdTheme: ThemeConfig = {
  token: {
    // colorBgElevated: '#000000',
    colorBorder: '#cdd3d8', // $color-grey-400
    colorPrimary: '#00c4c4', // $color-mint-600
    colorPrimaryActive: '#00b2b2', // $color-mint-700
    colorPrimaryHover: '#00b2b2', //$color-mint-700

    colorErrorHover: '#f66', // $color-danger-600

    controlHeightLG: 48,
    borderRadius: 4,
  },
  components: {
    Breadcrumb: {
      lastItemColor: '#212529',
      itemColor: '#868E96',
      separatorColor: '#ADB5BD',
    },
    Table: {
      borderColor: '#F2F4F6',
      headerColor: '#212529', //$color-grey-900
      headerBg: '#F2F4F6', //Gray/G100
      headerSplitColor: undefined, //'#F2F4F6', //Gray/G300
      headerBorderRadius: 0,
      cellPaddingBlock: 8,
    },
    Carousel: {
      dotWidth: 4,
      dotHeight: 4,
      dotActiveWidth: 4,
    },
    Badge: {
      colorError: '#FF6666', // $color-danger-600
    },
  },
};

export const AntdSection: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ConfigProvider theme={antdTheme}>
      <section style={{ width: 1140 }}>{children}</section>
    </ConfigProvider>
  );
};
