import NoticeForHome from '../views/maker/home/NoticeForHome'
import BellAlertForHome from '../views/maker/home/BellAlertForHome'
import ConsultingApplicationStatus from '../views/mngAd/consulting/ConsultingApplicationStatus'

const HomeForAdmin = () => (
  <>
    <div className="box-top">
      <h1 className="main-title">와디즈 비즈센터</h1>
      <p className="fz-30 fw-light fc-1">
        <span className="fw-medium">와디즈 비즈센터</span>에 오신 것을 환영합니다.
      </p>
      <button
        type="button"
        className="btn btn-primary large" /* onClick={() => alertify.alert('', '준비 중입니다.')} */
        onClick={() =>
          window.open(
            'https://wabiz-static-prod.s3.ap-northeast-2.amazonaws.com/static/usingGuide/%ED%83%80%EA%B2%9F%EA%B4%91%EA%B3%A0%2B%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C.pdf'
          )
        }
      >
        이용 가이드
      </button>
    </div>
    <div className="box-bottom">
      <div className="container-fluid">
        <div className="row">
          <ConsultingApplicationStatus />
          <div className="col col-8">
            <NoticeForHome />
            <BellAlertForHome />
          </div>
        </div>
      </div>
    </div>
  </>
)

export default HomeForAdmin
