import React, {
  Fragment, useRef,
} from 'react';
import { Dialog } from 'rc-easyui';
import dayjs from 'dayjs';
import alertify from 'alertifyjs'

const CreationDataEditingDialog = (props:any) => {
  const dialogRef = useRef<any>();

  const cancelEvent = () => {
      props.setEditingConfirmDialog(false);
  };

  return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="wrap-section wrap-tbl">
                                <div className="box-body">
                                    <div className="tbl">
                                        <dl className="vertical">
                                            <dd>
                                                <div className="form-group">
                                                    <p className="comp-txt center">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-24 fc-1 fw-medium">수정 중인 소재가 있습니다.</b>
                                                                </span>
                                                            </span>
                                                    </p>
                                                </div>
                                            </dd>
                                        </dl>
                                        <dl className="vertical">
                                            <dd>
                                                <div className="form-group">
                                                    <p className="comp-txt">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-14 fc-2">이전 수정 후 ‘변경요청‘ 혹은 ‘검수요청‘을 진행하지 않은 소재가 있습니다.</b>
                                                                    <b className="fz-14 fc-2">‘변경요청’ 및 ‘검수요청’을 진행하지 않은 경우 변경하시거나 등록하신 소재 정보가 정상적으로 반영되지 않습니다.</b>
                                                                    <b className="fz-14 fc-2">수정된 소재 정보를 반영하시길 희망하신다면 ‘변경요청‘ 혹은 ‘검수요청‘을 진행해 주시기 바랍니다.</b>
                                                                    <b className="fz-14 fc-7">* 버튼을 누르지 않은 소재는 테두리의 색이 빨간색으로 표시되오니 확인하시고 '변경요청' 혹은 '검수요청' 버튼을 눌러주세요.</b>
                                                                </span>
                                                            </span>
                                                    </p>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-secondary-outline" onClick={cancelEvent}>확인</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>

  );
};
export default CreationDataEditingDialog;
