import React, {
  Fragment,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

const BzmTab = () => {
  const url = useLocation();
  return (
        <Fragment>
            <div className="comp-tab">
                <Link to="/bzmAdmin/mng" className={url.pathname === '/bzmAdmin/mng' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">환불 신청 내역</span>
                </Link>
                <Link to="/bzmAdmin/mng/mngDeposit" className={url.pathname === '/bzmAdmin/mng/mngDeposit' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">무통장 입금 내역</span>
                </Link>
                <Link to="/bzmAdmin/mng/mngMaker" className={url.pathname === '/bzmAdmin/mng/mngMaker' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">메이커 별 비즈머니</span>
                </Link>
                <Link to="/bzmAdmin/mng/mngDebtsByMaker" className={url.pathname === '/bzmAdmin/mng/mngDebtsByMaker' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">메이커 별 부채</span>
                </Link>
            </div>
        </Fragment>
  );
};
export default BzmTab;
