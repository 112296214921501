import { Typography, Divider, Flex } from 'antd';
import { Breadcrumb } from '../Breadcrumb';
import { AntdSection } from '../AntdSection';
import { PeriodPicker } from './PeriodPicker';

const { Title } = Typography;

export const FinanceDpPush = () => (
  <AntdSection>
    <Breadcrumb path={['리포트 관리', 'DP/PUSH : 재무 리포트']} />
    <Flex gap={40} vertical style={{ marginTop: 24 }}>
      <Title level={2}>{'DP/PUSH : 재무 리포트'}</Title>
      <Flex vertical>
        <Title level={3}>{'만들기'}</Title>
        <Divider style={{ margin: 0 }} />
      </Flex>
      <PeriodPicker />
    </Flex>
  </AntdSection>
);
