import React, { Fragment, useEffect } from 'react';
import { Dialog } from 'rc-easyui';

const CreationGifSelectDialog = (props:any) => {
  const selectImg = () => {
    props.setGifDiv('img');
    $('#creationGifSelectDialog').dialog('close');
    if (props.selectedYn) {
      props.setImageUrl(props.fileUrl);
    } else {
      props.fileUploadFirstStep02(props.gifFile);
    }
  };

  const selectVideo = () => {
    props.setGifDiv('vod');
    $('#creationGifSelectDialog').dialog('close');
    if (props.selectedYn) {
      props.setImageUrl(props.fileUrl);
    } else {
      props.fileUploadFirstStep02(props.gifFile);
    }
  };
  useEffect(() => () => {
    // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
    $('#creationGifSelectDialog').dialog('destroy');
  }, []);
  return (
        <Fragment>
            <form>
                <Dialog>
                    <div id="creationGifSelectDialog" className="dialog" style={{ display: 'none' }}>
                        <div className="dialog-body">
                            <div className="tbl">
                                <dl className="vertical">
                                    <dd>
                                        <div className="form-group">
                                            <p className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-4">움직이는 GIF 이미지 업로드를 원하실 경우 반드시 영상을 선택해주세요.<br/><br/></b>
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                    </dd>
                                </dl>

                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-secondary-outline" onClick={selectImg}>이미지</button>
                            <button type="button" className="btn btn-primary" onClick={selectVideo}>영상</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>

  );
};
export default CreationGifSelectDialog;
