import React, { Fragment, useEffect } from 'react';

const AfDialog = () => {
  useEffect(() => {
    $('#close').click(() => {
      $('#afDialog').dialog('close');
    });
    return () => {
      $('#afDialog').dialog('destroy');
    };
  }, []);

  return (
        <Fragment>
            <form>
            <div id="afDialog" className="dialog" style={{ display: 'none' }}>
                <div className="dialog-body">
                    <div className="wrap-section wrap-tbl no-border">
                        <div className="box-body">
                            <div className="comp-thumb expand" id="original">
                                <img id="image"
                                     src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Thumb-800x400.png"
                                     alt="img1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button type="button" className="btn btn-secondary-outline large" id="close">닫기
                    </button>
                </div>
            </div>
            </form>
        </Fragment>
  );
};

export default AfDialog;
