import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import dayjs, { Dayjs } from 'dayjs';
import { DataFormat } from 'select2';
import { useForm } from 'react-hook-form';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Tooltip } from 'rc-easyui';
import { useOutletContext } from 'react-router';
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from '../common/NoDataTemplate'
import Pagination from '../common/Pagination'
import { IMultiSelect, multiSelectHandler, toMultiSelect } from './AccAdjustTargetUtil';
import SingleSelect2 from './SingleSelect2';
import RangeDatePicker from './RangeDatePicker';
import DlDialog from "../mngMakerAccount/DlDialog";

interface IAdjustTaxbillSearchForm {
  startDate: string;
  endDate: string;
  bizDiv: string,
  taxbillStatus: string | undefined,
  searchType: string | undefined,
  keyword?: string | undefined,
}

// type Select2Ids = 'bizDiv' | 'taxbillStatus' | 'searchType';

const taxbillInfoLinkRenderer = (props: ICellRendererParams) => {
  const { adjustTargetId } = props.data;
  return <Link className="txt-link" to={`/accAdjust/taxbill/info?id=${adjustTargetId}&prev=taxbill`}>{props.value}</Link>;
};

// const taxbillInfoErrorMsgRenderer = (props: ICellRendererParams) => (props.data.taxbillStatus === 'fail' ? props.value : props.value);
const taxbillInfoErrorMsgRenderer = (props: ICellRendererParams) => {
  if (props.data.taxbillStatus === 'fail') {
    // tooltip 내에 엘리먼트로 하나 감싸줘야함. 그래서 <p>로 감싸줌. 문제 생기면 다른걸로 바꾸기
    return <Tooltip tooltipCls={'ui-corner-all ui-widget-shadow'} content={<p className="fz-12 fc-0">{props.data.taxbillErrMsg}</p>}><p>{props.value}</p></Tooltip>;
  }
  return props.value;
};

const taxbillStatusData = {
  all: { text: '전체', selected: true },
  wait: { text: '미발행', selected: true },
  published: { text: '발행', selected: true },
  cancel: { text: '취소', selected: true },
  reserved: { text: '발행예약', selected: true },
  cancelReserved: { text: '예약취소', selected: true },
  fail: { text: '실패', selected: true },
};

const AccAdjustTaxbillList = () => {
  // const navigate = useNavigate();
  const setTitle = useOutletContext<any>();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [tbStatus, setTbStatus] = useState<IMultiSelect>(toMultiSelect(taxbillStatusData));
  const [tbStatusLabel, setTbStatusLabel] = useState<string>('전체');
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);

  // form
  const {
    register, setValue, handleSubmit, getValues,
  } = useForm<IAdjustTaxbillSearchForm>();

  // datepicker
  const registerDate = (start:Dayjs, end:Dayjs) => {
    setValue('startDate', start.format('YYYYMMDD'));
    setValue('endDate', end.format('YYYYMMDD'));
  };

  // select
  // const makeSelect2:any = (id:Select2Ids, data:DataFormat[], width?:number) => {
  //   $(`#${id}`).select2({
  //     width: width == null ? '150' : width.toString(),
  //     minimumResultsForSearch: Infinity,
  //     data,
  //   }).on('select2:select', (e) => {
  //     if (e.params.data.id === '') {
  //       setValue(id, undefined);
  //     } else {
  //       setValue(id, e.params.data.id);
  //     }
  //   });
  // };

  const defaultColDef: ColDef = {
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  };

  const columnDefs = [
    {
      headerName: '발행대상 정보 및 발행상태',
      children: [
        { field: 'makerLoginId', headerName: '메이커 ID', cellRenderer: taxbillInfoLinkRenderer },
        { field: 'userNo', headerName: '사업자번호/주민번호', cellRenderer: taxbillInfoLinkRenderer },
        { field: 'bizDivDesc', headerName: '구분' },
        { field: 'taxbillStatusDesc', headerName: '발행상태', cellRenderer: taxbillInfoErrorMsgRenderer },
        { field: 'sendDate', headerName: '발행일자' },
        { field: 'comName', headerName: '상호', cellClass: 'ag left-aligned-cell' },
        { field: 'comRepNm', headerName: '대표자명/개인 성명' },
        { field: 'comEmail', headerName: '이메일' },
      ],
    },
    {
      headerName: '임의조정 금액',
      children: [
        { field: 'cost', headerName: '공급가액', cellClass: 'ag-right-aligned-cell' },
        { field: 'vat', headerName: '부가세', cellClass: 'ag-right-aligned-cell' },
        { field: 'total', headerName: '합계', cellClass: 'ag-right-aligned-cell' },
      ],
    },
    {
      headerName: '수정 정보',
      children: [
        { field: 'updTime', headerName: '최근 수정일시' },
        { field: 'updName', headerName: '수정자' },
      ],
    },
  ];

  const onGridReady = () => {
    const data:IAdjustTaxbillSearchForm = getValues();
    window.console.table(data);
    ApiUtil2.get('/api/accAdjust/taxbill/list', {
      params: {
        data: { ...data },
      },
    }).then((resp) => {
      window.console.table(resp.data.data);
      setRowData(resp.data.data);
    });
  };

  const changePagination = () => {
    childRef?.current?.onPaginationChanged();
  };

  const downloadHandler = () => {
    // const data:IAdjustTaxbillSearchForm = getValues();

    setDlParam({
      ...getValues()
    });


    setIsDlDialogOpened(true);

    // const url = `/api/accAdjust/taxbill/list/download?data=${encodeURIComponent(JSON.stringify(data))}`;
    // window.location.assign(url);
  };

  const updateTbStatusForm = (value: string) => setValue('taxbillStatus', value);
  const updateTbStatusLabel = (label: string) => setTbStatusLabel(label);
  const updateTbStatus = (newState: Map<string, boolean>) => setTbStatus({ ...tbStatus, selects: newState });

  const bizDivs:DataFormat[] = [{ id: '', text: '전체', selected: true }, { id: 'biz', text: '사업자' }, { id: 'user', text: '개인' }];
  const searchTypes:DataFormat[] = [{ id: 'makerId', text: '메이커ID', selected: true }, { id: 'userNo', text: '사업자번호/주민번호' }, { id: 'comName', text: '상호' }, { id: 'comRepName', text: '대표자명/개인 성명' }, { id: 'comEmail', text: '이메일' }];

  const updateBizDiv = (value: any) => {
    setValue('bizDiv', value);
  };

  const updateSearchType = (value: any) => {
    setValue('searchType', value);
  };

  useEffect(() => {
    setTitle('임의조정 세금계산서');
    // const startDate = dayjs().startOf('day').subtract(30, 'd');
    // const endDate = dayjs().startOf('day');
    // registerDate(startDate, endDate);

    // $('#datepicker').daterangepicker({
    //   startDate,
    //   endDate,
    //   opens: 'left',
    //   ranges: {
    //     '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
    //     '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
    //     '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')],
    //     '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)],
    //     '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
    //     '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
    //   },
    // }, (start:any, end:any) => {
    //   registerDate(start, end);
    // });

    // const bizDivs:DataFormat[] = [{ id: '', text: '전체', selected: true }, { id: 'biz', text: '사업자' }, { id: 'user', text: '개인' }];
    // makeSelect2('bizDiv', bizDivs);

    // const searchTypes:DataFormat[] = [{ id: 'makerId', text: '메이커ID', selected: true }, { id: 'userNo', text: '사업자번호/주민번호' }, { id: 'comName', text: '상호' }, { id: 'comRepName', text: '대표자명/개인 성명' }, { id: 'comEmail', text: '이메일' }];
    // makeSelect2('searchType', searchTypes, 180);
  }, []);

  return (
    <Fragment>
      {/* Wrap-Datagrid : Start */}
      <section className="wrap-section wrap-datagrid wrap-double-header">
        {/* Wrap-Filter : Start */}
        <form onSubmit={handleSubmit(onGridReady)}>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">조회기간</p>
                  </div>
                  <div className="box-filter">
                    {/*
                    <div className="comp-datepicker">
                      <div className="inner-datepicker">
                        <i className="ico ico-calendar"></i>
                        <input id="datepicker" type="text" className="tf-comm datepicker-range right" readOnly></input>
                      </div>
                    </div>
                    */}
                    <RangeDatePicker changeEvent={registerDate} option={{ startDate: dayjs().startOf('day').subtract(30, 'd') }} />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">구분</p>
                  </div>
                  <div className="box-filter">
                    {/* <select className="select2-single w-150" id="bizDiv" {...register('bizDiv')} /> */}
                    <SingleSelect2 data={bizDivs} selectEvent={updateBizDiv} />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">발행 상태</p>
                  </div>

                  <div className="box-filter">
                    <div id="taxbillStatus" className="comp-dropdown select2 w-150 selected">
                      <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                        <div className="box-left">
                          <span className="fz-14">{tbStatusLabel}</span>
                        </div>
                        <div className="box-right">
                          <i className="ico ico-arrow"></i>
                        </div>
                      </a>
                      <div className="dropdown-menu expand">
                        <ul className="opt-selectbox"
                            onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                          {
                            Array.from(tbStatus.texts, ([key, value]) => ({ key, value }))
                              .map(({ key, value }) => {
                                const checkboxId = `ts-cb-${key}`;
                                return <li className="opt-menu" key={key}>
                                  <div className="comp-checkbox small">
                                    <input name="makerStatus" type="checkbox"
                                           id={checkboxId}
                                           onChange={() => 0}
                                           onClick={(e: any) => { multiSelectHandler(key, e.target.checked, tbStatus, updateTbStatus, updateTbStatusLabel, updateTbStatusForm); }}
                                           checked={tbStatus.selects.get(key) === true}/>
                                    <label htmlFor={checkboxId}>{value}</label>
                                  </div>
                                </li>;
                              })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색 구분</p>
                  </div>
                  <div className="box-filter">
                    {/* <select className="select2-single w-150" id="searchType" /> */}
                    <SingleSelect2 data={searchTypes} selectEvent={updateSearchType} option={{ width: '180' }} />
                  </div>
                </div>

                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input type="text" className="tf-comm"
                               placeholder="검색어를 입력해 주세요." {...register('keyword')}></input>
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico"><i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* Wrap-Filter : End */}
        <div className="box-header">
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}><i className="ico ico-download"></i>다운로드
            </button>
          </div>
        </div>

        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={() => onGridReady()}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef}/>
        </div>
      </section>
      {/* Wrap-Datagrid : End */}
      {/* Comp-Help : Start */}
      <div className="comp-help">
        <ul className="help-list">
          <li className="list">
            <i className="ico ico-help"></i><span className="fz-14 fc-2">유의사항</span>
          </li>
          <li className="list">
            <span className="fz-14 fc-4 bullet">세금계산서 발행 취소는 정산 연월의 익월 10일까지 만 가능합니다.</span>
          </li>
          <li className="list">
            <span className="fz-14 fc-4 bullet">익월 10일 이후 발행 취소가 필요한 경우 가비아 사이트 혹은 국세청 사이트를 이용하여 취소를 진행하셔야 합니다.</span>
          </li>
          <li className="list">
            <span className="fz-14 fc-4 bullet">정산 연월 익월 10일 이후 신규 세금계산서 발행 시 가산세가 부과될 수 있는 점 유의해 주시기 바랍니다.
            (신규 발행 시 정산 연월의 말일로 공급일자가 적용)</span>
          </li>
        </ul>
      </div>
      {/* Comp-Help : End */}
      {
        isDlDialogOpened ?
          <DlDialog setIsDlDialogOpened={setIsDlDialogOpened}
                    dlUrl={'/api/accAdjust/taxbill/list/download'}
                    dlParam={dlParam}
          /> : null
      }
    </Fragment>
  );
};
export default AccAdjustTaxbillList;
