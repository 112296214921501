import React, { useEffect } from 'react';

const DisplayAdRedirect = () => {
  useEffect(() => {
    window.open('http://business.wadiz.kr/adstudio/display/step01')
    window.history.back();
  }, []);
  return null;
};

export default DisplayAdRedirect;
